import React from "react";
import { SearchIcon, SearchInput } from "./Search.styles";

/**
 * Search component can be used to search different data (f.ex. users by their name, email or PIC/SSN) from Kaiku.
 */
// eslint-disable-next-line react/display-name
const Search = React.forwardRef((props: SearchProps, ref: any) => (
  <div>
    <SearchIcon material name="search" size="medium" />
    <SearchInput
      placeholder={props.placeholder}
      additionalClass={props.additionalClass}
      ref={ref}
      onClick={e => props.onClick && props.onClick(e)}
    />
  </div>
));

export type SearchProps = {
  /** Text to be shown on search input, f.ex. "Search" */
  placeholder: string;
  /** Id of the input field */
  ref: React.Ref<any>;
  /** Additional classnames to append to the className list */
  classNames?: string;
  onClick?: (...args: any[]) => any;
  additionalClass?: boolean;
};

export default Search;
