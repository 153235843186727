// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import "./polyfills";
import store, { injectReducer } from "./store";
import React, { useEffect, useState, ReactElement, Suspense } from "react";
import ReactDOM from "react-dom";
import { TransitionGroup } from "react-transition-group";
import { Provider } from "react-redux";
import { initIntl } from "common/utils/intl";
import { defaultLocale, SiteSettings } from "common/utils/holvikaari";
import {
  areConversationsEnabled,
  cleanUrlLocalePrefix,
} from "common/utils/general";
import { pageLoadFailed } from "common/utils/api";
import AppWrapper from "common/containers/AppWrapper";
import InputForm from "shared/components/InputForm/InputForm.lazy";
import ModalProvider from "shared/components/Modal";
import TooltipProvider from "common/components/Tooltip";
import GlobalFontsProvider from "common/components/GlobalFontsProvider";
import UserDataTable from "hcp/containers/UserDataTable.lazy";
import { getUser, setLocale } from "common/actions/user";
import { once } from "lodash";
import Tagger from "hcp/components/Staff/Tagger";
import Conversation from "shared/components/Conversation/Conversation.lazy";
import Markdown, { PlainMarkdown } from "common/components/Markdown";
import { epic$ } from "epic";
import WelcomeStaff from "hcp/containers/WelcomeStaff.lazy";
import SearchBar from "common/components/SearchBar";
import { SymptomTableContainer as SymptomTable } from "shared/components/SymptomTable";
import Feedback from "shared/components/Feedback/Feedback.lazy";
import LoginPage from "shared/components/LoginPage/LoginPage.lazy";
import ExternalLoginPageFooter from "shared/components/LoginPage/ExternalLoginPageFooter.lazy";
import InvitePage from "client/components/InvitePage";
import { CompareValues } from "client/components/CompareAnswers";
import CrcModalLink from "shared/components/Modal/CrcModalLink";
import { MobilePsaModalLink } from "shared/components/Modal/MobilePsaModal";
import DownloadView from "shared/components/DownloadView";
import PopulationDashboard from "hcp/containers/PopulationDashboard.lazy";
import Ecsc from "shared/components/Ecsc/Ecsc.lazy";
import MonitoringAndCareTeam from "hcp/containers/MonitoringAndCareTeam.lazy";
import OnlineHelp from "hcp/components/OnlineHelp/OnlineHelp.lazy";
import MonitoringProgramTable from "hcp/containers/MonitoringProgramTable.lazy";
import SelfServiceFlipper from "shared/components/SelfServiceFlipper/SelfServiceFlipper.lazy";
import IxrsApiTestTool from "hcp/components/IxrsApiTestTool/IxrsApiTestTool.lazy";
import PDFExport from "hcp/containers/PDFExport";
import Caregivers from "hcp/containers/Caregivers.lazy";
import { RootStyles } from "./styles/holvikaari.styles";
import SymptomComparisonExport from "hcp/components/PDFExport/SymptomComparisonExport";
import EditProgramView from "hcp/containers/EditProgramView.lazy";
import AriaDescriptionProvider from "common/hooks/UseAriaDescription/AriaDescriptionProvider";
import UserFeedbackBox from "shared/components/UserFeedbackBox";
import LoginAuthentication from "shared/components/LoginAuthentication/LoginAuthentication.lazy";
import MonitoringProgramSelect from "hcp/components/MonitoringProgramSelect/MonitoringProgramSelect.lazy";
import CopyToClipboard from "hcp/components/Clipboard/CopyToClipboard.lazy";
import { getIntegrationTestAxe } from "common/utils/a11y";
import InvitePatients from "hcp/components/Invites/InvitePatients.lazy";
import ClientConversationList from "shared/components/ConversationList/ClientConversationList.lazy";
import MessagingRouter from "shared/components/Messaging/MessagingRouter.lazy";
import ViewImages from "common/components/ViewImages";
import AccountStatus from "hcp/containers/AccountStatus.lazy";
import PatientRecords from "hcp/components/PatientRecords/PatientRecords.lazy";
import DocumentsSharedByCareTeam from "hcp/containers/ReceivedDocuments";
import DocumentsSharedByClient from "hcp/containers/SentDocuments";
import CalendarEntries from "hcp/containers/CalendarEntries";
import CreateUser from "hcp/components/Users/CreateUser.lazy";
import UpdateUser from "hcp/components/Users/UpdateUser.lazy";
import { Spinner } from "@netmedi/frontend-design-system";
import TeamsRouter from "hcp/containers/TeamsRouter.lazy";
import NavBarWrapper from "hcp/components/NavBar";
import SinglePatientExport from "hcp/components/SinglePatientExport/SinglePatientExport";
import Absences from "hcp/components/Absences/Absences";
import PatientDashboardBottomLeft from "hcp/components/PatientDashboard/BottomLeft.lazy";
import KeycloakStatusPanel from "hcp/components/KeycloakStatusPanel/KeycloakStatusPanel";
import ProgramDataInsights from "hcp/containers/ProgramDataInsights.lazy";
import ServiceStatusTable from "hcp/components/ServiceStatusTable/ServiceStatusTable.lazy";

const mountProvider = (component: ReactElement) => {
  const root = document.createElement("div");
  document.body.insertBefore(root, document.body.firstChild);
  ReactDOM.render(component, root);
};

// Called once when first Serena component is mounted
const init = once((loggedOut: boolean) => {
  mountProvider(<Serena view="ModalProvider" noSpinner />);
  mountProvider(<Serena view="TooltipProvider" noSpinner />);
  mountProvider(<Serena view="GlobalFontsProvider" noSpinner />);

  initIntl();
  if (loggedOut) {
    store.dispatch(setLocale(defaultLocale));
  } else {
    getUser()
      .then(action => store.dispatch(action))
      .catch(pageLoadFailed);
  }
  store.dispatch({ type: "SET_HOLVIKAARI" });
});

const initView = (view: string) => {
  switch (view) {
    case "Conversation":
      epic$.next(require("shared/epics/chat").default);
      break;
  }
};

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  matchPath,
  useLocation,
} from "react-router-dom";
import { RedirectToNoLocale } from "./routes.tsx";

const isSameDomain = (url1, url2 = window.location.href) => {
  return new URL(url1).origin === new URL(url2).origin;
};

function dbg(...args) {
  if (process.env.NODE_ENV === "development") {
    console.log(...args); // eslint-disable-line no-console
  }
}

function RailsIframe(_props) {
  const ref = React.useRef();
  const history = useHistory();
  const location = useLocation();

  // TODO: Flexbox?

  useEffect(() => {
    const iframe = ref.current;

    function onNavigate(event) {
      const newUrl = new URL(event.destination.url);
      const { component: newComponent } = routes.find(({ path, exact }) =>
        matchPath(cleanUrlLocalePrefix(newUrl.pathname), { path, exact }),
      );
      let handler = null;

      if (!event.canIntercept) {
        dbg("*** Can't intercept: Blocked", event);
      } else if (!event.userInitiated) {
        dbg("*** Won't intercept: Not user init", event);
      } else if (event.formData) {
        dbg("*** Won't intercept: Form data present", event);
      } else if (event.navigationType === "traverse") {
        dbg("*** Won't intercept: History traversal", event);
      } else if (!isSameDomain(newUrl)) {
        // External navigation: Redirect parent frame
        dbg("*** Intercept external navigation", event.destination.url, event);
        handler = () => {
          window.location.href = newUrl.toString();
        };
      } else if (event.navigationType === "push") {
        // Internal links: Intercept and use React-Router
        dbg(
          "*** Intercept iframe navigation targetting parent",
          newUrl.pathname,
          newComponent,
          event,
        );
        handler = () => {
          history.push(cleanUrlLocalePrefix(newUrl.pathname));
        };
      }
      handler && event.intercept({ handler });
    }

    // Be VERY CAREFUL NOT TO LEAVE MEMORY LEAKS HERE
    // Hanging listeners seem to prevent the entire frame from loading
    // Causing the tab to be out-of-memory fast
    function onLoad(event) {
      dbg("*** Iframe loaded, hooking in", event);
      iframe.contentWindow.navigation.addEventListener("navigate", onNavigate);
    }

    iframe.addEventListener("load", onLoad);

    return () => {
      dbg("*** Iframe dismounted, removing hooks");
      iframe?.removeEventListener?.("load", onLoad);
      iframe?.contentWindow?.navigation?.removeEventListener?.(
        "navigate",
        onNavigate,
      );
    };
  });

  return (
    <iframe
      title="iframe"
      ref={ref}
      key={location.pathname}
      src={location.pathname}
      width="100%"
      scrolling="no"
      frameBorder="0"
      style={{
        overflow: "auto",
      }}
    />
  );
}

const components = {
  InputForm,
  ModalProvider,
  TooltipProvider,
  GlobalFontsProvider,
  Tagger,
  Conversation,
  UserDataTable,
  ClientConversationList,
  MessagingRouter,
  WelcomeStaff,
  SearchBar,
  SymptomTable,
  LoginPage,
  ExternalLoginPageFooter,
  Feedback,
  CompareValues,
  CrcModalLink,
  MobilePsaModalLink,
  PopulationDashboard,
  Ecsc,
  MonitoringAndCareTeam,
  OnlineHelp,
  MonitoringProgramTable,
  IxrsApiTestTool,
  DownloadView,
  SelfServiceFlipper,
  InvitePage,
  PDFExport,
  SymptomComparisonExport,
  Caregivers,
  EditProgramView,
  UserFeedbackBox,
  LoginAuthentication,
  CopyToClipboard,
  InvitePatients,
  ViewImages,
  MonitoringProgramSelect,
  AccountStatus,
  PatientRecords,
  DocumentsSharedByCareTeam,
  DocumentsSharedByClient,
  CalendarEntries,
  CreateUser,
  UpdateUser,
  TeamsRouter,
  SinglePatientExport,
  Absences,
  PatientDashboardBottomLeft,
  KeycloakStatusPanel,
  ProgramDataInsights,
  ServiceStatusTable,
  NavBarWrapper,
} as { [viewId: string]: any };

const hcpSerenaComponents = {
  ...components,
  RailsIframe,
  RedirectToNoLocale,
  RedirectToFeed: () => <Redirect to={"/feed"} />,
};

const loggedOutComponents = [
  "LoginPage",
  "ExternalLoginPageFooter",
  "InvitePage",
  "DownloadView",
  "LoginAuthentication",
];

type SerenaProps = {
  view: any;
  noSpinner?: boolean;
  noPadding?: boolean;
};

// document.onclick(function(event) {
//   dbg(event);
//   event.preventDefault();
//   redirect(this.href);
// });

const routes = [
  ...SiteSettings.user_available_locales.map((lang: string) => ({
    path: `/${lang}`,
    component: "RedirectToNoLocale",
  })),
  { path: "/", exact: true, component: "RedirectToFeed" },
  // TODO: Prepend <SearchBar />
  { path: "/feed", exact: true, component: "WelcomeStaff" },
  { path: "/absences", exact: true, component: "Absences" },
  // { path: "/clients/:id", component: null },
  { path: "/clients", exact: true, component: "UserDataTable" },
  { path: "/conversations", exact: true, component: "MessagingRouter" },
  { path: "/conversations/:id", exact: true, component: "Conversation" },
  { path: "/dashboard", exact: true, component: "PopulationDashboard" },
  { path: "/online_help", exact: true, component: "OnlineHelp" },
  { path: "/people/new", exact: true, component: "CreateUser" },
  { path: "/people", exact: true, component: null },
  { path: "/teams", exact: true, component: "TeamsRouter" },
  {
    path: "/treatment_programs",
    exact: true,
    component: "MonitoringProgramTable",
  },
  { path: "/", component: "RailsIframe" },
];

const getProps = (url, body) =>
  fetch(url, {
    method: "GET",
    headers: { Accept: "application/json", "X-Action": "props" },
    body: JSON.stringify(body),
  }).then(resp => {
    if (resp.ok) return resp.json();
    throw new Error(`Code ${resp.status}`);
  });

function PropLoader({ component, propLoadPath }) {
  const [data, setData] = useState(null);

  if (!data) {
    const req = getProps(propLoadPath || window.location.pathname)
      .then(setData)
      .catch(setData);
    setData(req);
    return <Spinner />;
  }
  if (data instanceof Promise) return <Spinner />;

  // TODO data instanceof Error
  // dbg("***", component, hcpSerenaComponents[component]);
  // dbg("*** data", data);
  const Component = hcpSerenaComponents[component];
  return (
    <>
      <Component holvikaari {...(data[component] || {})} />
    </>
  );
}

function HcpSerena(props: SerenaProps) {
  useEffect(() => {
    init(loggedOutComponents.indexOf(props.view) !== -1);
    initView(props.view);
  }, [props.view]);

  return (
    <Provider store={store}>
      <AppWrapper>
        <RootStyles className="serena-wrap">
          <AriaDescriptionProvider>
            <Suspense fallback={<Spinner />}>
              <Router>
                {/* TODO: Static path known to include NavBarWrapper props */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                  }}
                >
                  <PropLoader
                    component="NavBarWrapper"
                    propLoadPath="/clients"
                  />
                  <div style={{ flexGrow: "1" }}>
                    <Switch>
                      {routes.map(({ path, exact, ...rest }, idx) => (
                        <Route key={idx} path={path} exact={exact || false}>
                          <PropLoader {...rest} />
                        </Route>
                      ))}
                    </Switch>
                  </div>
                </div>
              </Router>
            </Suspense>
          </AriaDescriptionProvider>
        </RootStyles>
      </AppWrapper>
    </Provider>
  );
}

function Serena(props: SerenaProps) {
  useEffect(() => {
    init(loggedOutComponents.indexOf(props.view) !== -1);
    initView(props.view);
  }, [props.view]);

  const { view, noSpinner, noPadding, ...rest } = props;
  const Component = components[view];
  return (
    <Provider store={store}>
      <AppWrapper {...{ noSpinner, noPadding }}>
        <RootStyles className="serena-wrap">
          <AriaDescriptionProvider>
            <Suspense fallback={<Spinner />}>
              <Component holvikaari {...(rest as any)} />
            </Suspense>
          </AriaDescriptionProvider>
        </RootStyles>
      </AppWrapper>
    </Provider>
  );
}

function wrapRouterStub(Component) {
  return function wrappedComponent(props) {
    return (
      <Router forceRefresh={true}>
        <Component {...props} />
      </Router>
    );
  };
}

// site-specific features
if (areConversationsEnabled()) {
  injectReducer("chat", require("shared/reducers/chat").default);
}

window.React = React;
(window.React as any).addons = { CSSTransitionGroup: TransitionGroup };
window.ReactDOM = ReactDOM;
window.Serena = wrapRouterStub(Serena);
window.HcpSerena = HcpSerena;
window.NavBarWrapper = wrapRouterStub(NavBarWrapper);
window.LoginPage = LoginPage;
window.InvitePage = InvitePage;
window.Markdown = Markdown;
window.PlainMarkdown = PlainMarkdown;

if (process.env.TEST) {
  window.axe = getIntegrationTestAxe();
}
