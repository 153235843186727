import styled from "styled-components";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import Alert from "shared/components/Modal/Alert";

export const StyledAlert = styled(Alert)`
  & > div {
    border: solid 1px;
    border-color: ${colors.gray500};
  }
`;
