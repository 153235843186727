import styled from "styled-components";
import { StepsGraphProps } from "./SymptomTableStepsGraph";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";

export const StepsGraphTd = styled.td<StepsGraphProps>`
  && {
    ${({ isCompact }) => `
      padding-left: ${isCompact ? rem(25) : rem(10)};
      padding-right: ${isCompact ? rem(25) : rem(10)};
    `}
  }
`;
