import { useMemo } from "react";

type Class<T> = new (...args: any[]) => T;

const API_V2_BASE_URL = "/api/v2";

export const useClient = <ApiT,>(
  apiClass: Class<ApiT>,
  apiBaseUrl?: string,
) => {
  const csrfToken = (
    document.querySelector('meta[name="csrf-token"]') as
      | HTMLMetaElement
      | undefined
  )?.content;

  const defaultApiUrl = apiBaseUrl ?? API_V2_BASE_URL;

  const api = useMemo(() => {
    return new apiClass(defaultApiUrl ?? apiBaseUrl, {
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });
  }, [apiClass, csrfToken]);

  return api;
};

export default useClient;
