import React from "react";
import { UserProps, generateInitials } from "./helpers";
import { InitialsIcon, CaregiverInitialsIcon } from "./InitialsIcon.styles";

export interface InitialsIconProps {
  user: UserProps;
  forCaregiver?: boolean;
}

export const InitialsIconStatic = ({ user }: InitialsIconProps) => {
  const [fname, lname] = generateInitials(user as UserProps);
  return (
    <InitialsIcon data-testid="user-initials-icon">
      <span>{fname}</span>
      <span>{lname}</span>
    </InitialsIcon>
  );
};

export const InitialismIconStatic = ({
  user,
  forCaregiver = false,
}: InitialsIconProps) => {
  // return icon with initialism or just the first letter of email
  const [fname, _] = generateInitials(user as UserProps);
  if (forCaregiver) {
    return (
      <CaregiverInitialsIcon data-testid="caregiver-initialism-icon">
        <span>{fname || user?.email?.slice(0, 1).toUpperCase()}</span>
      </CaregiverInitialsIcon>
    );
  }
  return (
    <InitialsIcon data-testid="user-initialism-icon">
      <span>{fname || user?.email?.slice(0, 1).toUpperCase()}</span>
    </InitialsIcon>
  );
};
