import React from "react";
import Page, { Header } from "common/components/Page";
import { ButtonExtras } from "@netmedi/frontend-design-system";
const { LinkButton } = ButtonExtras;

const Error404 = () => (
  <Page>
    <Header translated title="The page you were looking for doesn't exist." />
    <p>You may have mistyped the address or the page may have moved.</p>
    <LinkButton size="small" type="accent" to="/">
      Go back
    </LinkButton>
  </Page>
);

export default Error404;
