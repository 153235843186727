import { del, endpoints, get, put } from "common/utils/api";
import { SiteTeam } from "hcp/models/treatmentProgram";

export type SetTeamsAction = {
  type: "SET_TEAMS";
  data: SiteTeam[];
};

export type UpdateTeamAction = {
  type: "UPDATE_TEAM";
  data: SiteTeam;
};

export type DeleteTeamAction = {
  type: "DELETE_TEAM";
  data: SiteTeam;
};

export const getTeams = () =>
  get(endpoints.teams.index).then((data: any) => ({
    type: "SET_TEAMS",
    data: data.hcp_teams,
  }));

export const updateTeam = (params: any, team_id: number | string) =>
  put(endpoints.teams.update(team_id), params).then((data: any) => ({
    type: "UPDATE_TEAM",
    data: data,
  }));

export const deleteTeam = (team_id: number | string) =>
  del(endpoints.teams.delete(team_id)).then((data: any) => ({
    type: "DELETE_TEAM",
    data: data,
  }));
