import React from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { assign, markDone } from "hcp/actions/staff";
import { get, find } from "lodash";
import Confirm from "./Confirm";
import { RootState } from "store";
import { Dismiss } from "./Modal.types";

type Issue = {
  id: string | number;
  updated_at: string;
  state?: string;
  assigned_to_text?: string;
};
type IssueAction = {
  name?: string;
  params: { assignee_id?: string; role?: string; team?: string };
};
type Staff = RootState["staff"];

const issueStateText = (issue: Issue, issueAction: IssueAction) =>
  issue.state === "assigned" ? (
    <FormattedMessage
      id={`issue.just_assigned_${
        issueAction.params["assignee_id"] ? "person" : "role"
      }`}
      values={{ assignee: issue.assigned_to_text }}
    />
  ) : issue.state === "done" ? (
    <FormattedMessage
      id={`issue.just_done`}
      values={{ assignee: issue.assigned_to_text }}
    />
  ) : null;

const issueConfirmText = (issueAction: IssueAction, staff: Staff) => {
  if (issueAction.name === "assign") {
    const assigneeType = issueAction.params.assignee_id ? "person" : "role";
    const assignee = (assigneeType === "person"
      ? get(find(staff, { id: issueAction.params.assignee_id }), "name")
      : issueAction.params.role) || (
      <FormattedMessage id={`issue.that_you_chose`} />
    );

    return (
      <FormattedMessage
        id={`issue.are_you_sure_assign_${assigneeType}`}
        values={{ assignee }}
      />
    );
  } else if (issueAction.name === "done")
    return <FormattedMessage id="issue.are_you_sure_mark_done" />;
};

const mapStateToProps = ({ staff }: RootState) => ({ staff });

const mapDispatchToProps = { assign, markDone };

export type IssueUpdateErrorProps = {
  type: "issue_update_error";
  issue: Issue;
  issueAction: IssueAction;
};
type Props = IssueUpdateErrorProps & Dismiss & ConnectedProps<typeof connector>;

function IssueUpdateError({
  staff,
  issue,
  issueAction,
  assign,
  markDone,
  dismiss,
}: Props) {
  return (
    <Confirm
      title={<FormattedMessage id="modal.are_you_sure" />}
      body={
        <span>
          {issueStateText(issue, issueAction)}{" "}
          {issueConfirmText(issueAction, staff)}
        </span>
      }
      confirm={
        issueAction.name === "done"
          ? markDone.bind(null, issue)
          : assign.bind(null, issue, issueAction.params)
      }
      dismiss={dismiss}
    />
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(IssueUpdateError);
