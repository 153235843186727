import { FailedInviteAction, PeopleAction } from "hcp/actions/staff";
import { SetPeopleAction } from "hcp/actions/people";

type State = {
  users: any[];
  search: any[];
  invites: any;
  failedInvites: any[];
  links: any[];
  meta: any[];
  searchActive: string;
};

const initialState: State = {
  users: [],
  search: [],
  invites: {},
  failedInvites: [],
  links: [],
  meta: [],
  searchActive: "",
};

export default (
  state = initialState,
  action: PeopleAction | SetPeopleAction | FailedInviteAction,
): State => {
  switch (action.type) {
    case "SET_PEOPLE":
      return {
        ...state,
        search: [],
        users: action.data,
        invites: action.invites || {},
        meta: action.meta,
        links: action.links,
      };
    case "SET_PEOPLE_FILTERED":
      return {
        ...state,
        search: action.data,
        invites: { ...state.invites, ...action.invites },
        meta: action.meta,
        links: action.links,
      };
    case "ADD_STAFF":
      return {
        ...state,
        search: state.search.map(u =>
          u.id === action.client ? { ...u, own: true } : u,
        ),
        users: state.users.map(u =>
          u.id === action.client ? { ...u, own: true } : u,
        ),
      };
    case "UPDATE_INVITE":
      return {
        ...state,
        invites: { ...state.invites, [action.data.id]: action.data },
      };
    case "SET_FAILED_INVITES":
      return {
        ...state,
        failedInvites: action.data,
        meta: action.meta,
        links: action.links,
      };
    case "RM_INVITE":
      return {
        ...state,
        search: state.search.filter(u => u.invite !== action.id),
        users: state.users.filter(u => u.invite !== action.id),
      };
    default:
      return state;
  }
};
