import { User } from "common/models/user";

export type UserProps = Pick<
  User,
  "id" | "first_name" | "last_name" | "full_name" | "email"
>;

export type InvitedUserProps = {
  id: string;
  email: string;
  full_name?: string;
};

export const generateName = ({
  first_name,
  last_name,
  full_name,
}: UserProps) =>
  first_name && last_name ? `${first_name} ${last_name}` : full_name;

const firstCapitalLetter = (name = "") => {
  const letters = name.match(/\p{L}/gu);
  return letters ? letters.find(char => char === char.toUpperCase()) || "" : "";
};

export const generateInitials = ({
  first_name,
  last_name,
  full_name,
}: UserProps) => {
  // [ ${firstName}, ${lastName | ""} ]
  const first_and_last = full_name ? full_name.split(", ").reverse() : [];

  return [
    firstCapitalLetter(first_name ? first_name : first_and_last[0]),
    firstCapitalLetter(
      last_name
        ? last_name
        : (() => (first_and_last.length < 2 ? "" : first_and_last[1]))(),
    ),
  ];
};
