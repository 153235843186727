import styled from "styled-components";
import rtl from "styled-components-rtl";
import {
  colors,
  primaryColor,
  scaffolding,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";

export const PaginationList = styled.ul`
  direction: ${props => props.theme.dir};
  text-align: center;
  display: flex;
  justify-content: center;
  button {
    padding: ${rem(8)};
    font-size: ${rem(16)};
    font-weight: normal;
    min-height: 100%;
    background-color: ${colors.white};
    border: 0px;
    display: block;
    cursor: pointer;
    &:hover {
      background: ${colors.gray50};
      text-decoration: none;
    }
    &[data-focus-visible-added] {
      outline-width: 2px;
      outline-style: solid;
      outline-color: ${colors.blue700};
    }
  }
`;

export const PaginationListItem = styled.li.attrs(() => ({
  "data-testid": "sensible-pagination-item",
}))`
  display: inline-block;
  border: ${scaffolding.gridBorderColor} 1px solid;
  ${rtl`
    border-left-width: 0;
    padding-left: ${rem(1)};
    padding-right: ${rem(1)};
  `};
  min-height: 100%;
  ${rtl`
    &:first-child {
      padding-left: 0;
      border-left-width: 1px;
    }
  `}
`;

export const CurrentListItem = styled(PaginationListItem)`
  && {
    padding: ${rem(8)} ${rem(9)}; /* override .serena-wrap li for now */
  }
  background: ${primaryColor};
  color: ${colors.white};
`;
