import { SimpleLineGraph } from "common/components/SimpleLineGraph";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SymptomNameLabel } from "shared/components/SymptomTable/SymptomTable.styles";
import {
  FootstepContainer,
  FootstepRowStyled,
  FootstepValue,
  PdfFootstepContainer,
  PrintableIcon,
  SymptomDataTitleStyled,
  SymptomRowStyled,
} from "./SymptomPage.styles";
import { GroupedColumnRule } from "./SymptomTitleRow";

export interface SymptomPageFootstepsProps {
  footsteps: { time: number; value: number }[];
  titles: GroupedColumnRule[];
}

const mapDataToTitles = <T,>(
  titles: GroupedColumnRule[],
  data: T[],
  dateCallback: (val: T) => Dayjs,
) => {
  return titles.map(title => {
    return data.filter(d => {
      const valueDate = dateCallback(d).toISOString();
      if (title.type === "exactDate") {
        const columnDate = dayjs(title.date).toISOString();
        return valueDate === columnDate;
      } else if (title.type === "betweenDates") {
        const columnStartDate = dayjs(title.startDate).toISOString();
        const columnEndDate = dayjs(title.endDate).add(1, "day").toISOString();
        return valueDate >= columnStartDate && valueDate < columnEndDate;
      } else if (title.type === "beforeDate") {
        const columnDate = dayjs(title.date).toISOString();
        return valueDate <= columnDate;
      }
    });
  });
};

const SymptomPageFootsteps: React.FC<SymptomPageFootstepsProps> = ({
  footsteps,
  titles,
}) => {
  const footstepColumns = mapDataToTitles(titles, footsteps, t =>
    dayjs(t.time * 1000),
  );

  // group adjacent columns (as 2 dimensional array)
  const footstepGroups: {
    groupCount: number;
    footsteps: { time: number; value: number }[];
  }[] = [];
  footstepColumns.forEach((col, i) => {
    if (col.length === 0 || (footstepColumns[i - 1]?.length ?? 0) === 0) {
      // Add new array to create new column
      footstepGroups.push({ groupCount: 1, footsteps: col });
    } else {
      // Append to the last array to merge the data as single cell
      footstepGroups[footstepGroups.length - 1] = {
        groupCount: footstepGroups[footstepGroups.length - 1].groupCount + 1,
        footsteps: [
          ...footstepGroups[footstepGroups.length - 1].footsteps,
          ...col,
        ],
      };
    }
  });

  return (
    <FootstepContainer>
      <SymptomRowStyled data-test-id="SymptomPageFootsteps">
        <SymptomDataTitleStyled>
          <FormattedMessage id="symptom_table.steps.footsteps" />
          <br />
          <SymptomNameLabel>
            <FormattedMessage id="symptom_table.steps.daily_average" />
          </SymptomNameLabel>
        </SymptomDataTitleStyled>
        {footstepColumns.map((column, i) => {
          const col = column[0];
          const prevValues = footstepColumns.slice(0, i).flat();
          const prevValue = prevValues[prevValues.length - 1]?.value;

          return (
            <FootstepValue key={col?.time ?? i}>
              <span>
                <PrintableIcon
                  direction="up"
                  color="green"
                  hidden={!col?.value || !prevValue || prevValue >= col?.value}
                />
                <br />
                {col?.value}
                <br />
                <PrintableIcon
                  direction="down"
                  color="red"
                  hidden={!col?.value || !prevValue || prevValue <= col?.value}
                />
              </span>
            </FootstepValue>
          );
        })}
      </SymptomRowStyled>
      <FootstepRowStyled data-test-id="FootstepRowStyled">
        <SymptomDataTitleStyled />
        {footstepGroups.map((footsteps, i) => (
          <PdfFootstepContainer
            style={{ flex: `${footsteps.groupCount} 1` }}
            key={i}
          >
            <SimpleLineGraph
              data={footsteps.footsteps}
              height={100}
              margin={{ left: 55, right: 55 }}
            />
          </PdfFootstepContainer>
        ))}
      </FootstepRowStyled>
    </FootstepContainer>
  );
};

export default SymptomPageFootsteps;
