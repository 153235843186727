import { combineReducers } from "redux";
import { draftSlice } from "./draftMessage";
import { messageSlice } from "./messages";
import { conversationsSlice } from "./conversations";

const combinedReducer = combineReducers({
  conversations: conversationsSlice.reducer,
  draft: draftSlice.reducer,
  messages: messageSlice.reducer,
});

export type CombinedState = ReturnType<typeof combinedReducer>;

export default combinedReducer;
