import { openModal, dismissModal } from "common/actions/modal";
import pageChangeStream from "common/epics/pageChange";
import { updateUser } from "common/actions/user";
import { slideContent } from "client/components/SlideCarousel/symptomCategoryOnboarding";
import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import store, { State } from "../../store";
import { filter, map, take } from "rxjs/operators";

// Modals triggered by feature flags are defined in ./flipperUINotification.tsx

// If the consent is already shown to the user during this login and is dismissed,
// the sessionStorage will be updated so that it won't be shown again before next login
const shouldShowFeedInfoModal = ({ data: consents }: any) =>
  consents.length > 0 && !sessionStorage.hideConsent;

export const shouldShowCarouselModal = (
  features: { seen_symptom_category_introduction: boolean },
  section: {
    display?: {
      modal?: {
        symptom_category_introduction?: boolean;
      };
    };
  },
): boolean => {
  const modalSeen = features["seen_symptom_category_introduction"];

  return !modalSeen && section.display?.modal?.symptom_category_introduction
    ? true
    : false;
};

export const consentModal = (
  action$: ActionsObservable<any>,
  state$: StateObservable<State>,
) =>
  action$.pipe(
    ofType("SET_OFFERABLE_CONSENTS"),
    take(1),
    filter(shouldShowFeedInfoModal),
    map(() =>
      openModal({
        type: "consent_modal",
        disableOutsideClick: true,
        consent: state$.value.user.offerable_consents[0],
      }),
    ),
  );

export const carouselModal = (
  action$: ActionsObservable<any>,
  state$: StateObservable<State>,
) =>
  action$.pipe(
    ofType("SET_FORM_SECTION"),
    filter(({ data: section }) =>
      shouldShowCarouselModal(state$.value.user.features, section),
    ),
    take(1),
    map(() => {
      const current = state$.value;
      return openModal({
        type: "carousel_modal",
        disableOutsideClick: true,
        slideContents: slideContent(
          current.app.mobile,
          current.user.first_name,
          current.user.features["seen_symptom_category_introduction"],
          seen_feature =>
            store.dispatch(
              updateUser(current.user.id, {
                features: {
                  ["seen_symptom_category_introduction"]: seen_feature,
                },
              }) as any,
            ),
        ),
        dismiss: dismissModal,
      });
    }),
  );

export const clearModalOnPageChange = (action$: ActionsObservable<any>) =>
  pageChangeStream(action$).pipe(map(() => ({ type: "DISMISS_MODAL" })));
