import React from "react";
import { Button } from "@netmedi/frontend-design-system";
import { FormattedMessage } from "react-intl";
import Logo from "common/components/Logo";
import { Modal, ModalLogo, ModalNav } from "./Modal.styles";
import { TitleAndBody, Dismiss } from "./Modal.types";

export type AlertProps = TitleAndBody & {
  type: "alert";
  /** Text to be shown on the dismiss-button */
  dismissText?: string;
  /** Whether to show the Kaiku Health -logo on top of the modal */
  logo?: boolean;
  className?: string;
};

/** Alert modal shows the user an alert with a dismiss-button. */
const Alert = ({
  dismiss,
  dismissText,
  title,
  body,
  logo,
  className,
}: AlertProps & Dismiss) => (
  <Modal
    className={className}
    onClick={(e: any) => e.stopPropagation()}
    role="alertdialog"
  >
    {logo && (
      <ModalLogo>
        <Logo />
      </ModalLogo>
    )}
    {title && <h3>{title}</h3>}
    {body}
    <ModalNav>
      <Button size="small" onClick={dismiss}>
        <FormattedMessage id={dismissText || "modal.dismiss"} />
      </Button>
    </ModalNav>
  </Modal>
);

export default Alert;
