import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";

type Label = {
  labelHidden?: boolean;
  disabled?: boolean;
};

const checkboxWidth = "22px";
const checkboxHeight = "22px";

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

const labelHiddenStyles = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const RadioButtonLabel = styled.label.attrs(
  ({ labelHidden }: Label) => ({
    "data-testid": labelHidden ? "hiddenLabelTestId" : "labelTestId",
  }),
)<Label>`
  ${rtl`margin-left: 16px;`}
  ${({ labelHidden }) => labelHidden && labelHiddenStyles}
  ${({ disabled }) => (disabled ? "cursor: not-allowed;" : "cursor: pointer;")}
`;

export const InputWrapper = styled.div`
  position: relative;
  width: ${checkboxWidth};
  height: ${checkboxHeight};
  input {
    opacity: 0;
    & + label::before {
      background-color: ${colors.white};
      border: 1px solid ${colors.gray500};
      border-radius: 50%;
      content: "";
      height: ${checkboxHeight};
      ${rtl`left: 0;`}
      position: absolute;
      top: 0;
      width: ${checkboxWidth};
    }
    & + label::after {
      content: "";
      background-color: ${colors.gray900};
      top: 6px;
      ${rtl`left: 6px;`}
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 3px solid ${colors.gray900};
      opacity: 0;
      position: absolute;
      transition: opacity 0.2s ease-in-out;
      cursor: pointer;
    }
    &:hover + label::after {
      opacity: 0.2;
    }
    &:checked + label::after {
      opacity: 1;
    }
    &:disabled {
      cursor: not-allowed;
      &:hover + label::after {
        opacity: 0;
      }
      &:checked + label::after {
        opacity: 1;
      }
      & + label::before {
        cursor: not-allowed;
      }
      & + label::after {
        cursor: not-allowed;
        background-color: ${colors.gray400};
        border-color: ${colors.gray400};
      }
    }
    &[data-focus-visible-added] + label::before {
      outline: none;
      box-shadow:
        0 0 0 2px ${colors.white},
        0 0 0 4px ${colors.blue700};
      outline: 3px solid transparent; /* For Windows high contrast mode. */
    }
  }
`;
