import React from "react";
import { Card } from "@netmedi/frontend-design-system";
interface IProps {
  children: string;
}

const Info = ({ children }: IProps) => {
  return (
    <Card type="neutral">
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </Card>
  );
};

export default Info;
