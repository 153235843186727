import React, { useState, useEffect } from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { Document } from "shared/components/DocumentCard/SharedDocumentCard";
import {
  SharedDocumentsTableWrapper,
  NoFiles,
  FileLink,
  FileDescription,
} from "./SharedDocuments.styles";
import { Icon } from "@netmedi/frontend-design-system";
import { track } from "common/utils/api";
import { SensiblePagination } from "common/components/Pagination";

export type SharedDocumentsProps = {
  documents: Document[];
  type: "shared-by-client" | "shared-to-client";
  descriptionHidden?: boolean;
  clientId: number;
  userId: number;
};

const SharedDocumentsTable = ({
  descriptionHidden = false,
  type,
  documents,
  clientId,
  userId,
}: SharedDocumentsProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedDocuments, setPaginatedDocuments] =
    useState<Document[]>(documents);
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    setPaginatedDocuments(documents.slice(start, end));
  }, [currentPage, documents]);

  return (
    <div>
      {paginatedDocuments.map((d: Document) => (
        <div
          key={d.id}
          className="contentRow"
          data-testid={`${type}-documents-table`}
        >
          <span className="firstCol">
            <FormattedDate
              value={d.date}
              year="numeric"
              month="numeric"
              day="numeric"
            />
            {" – "}
            <FormattedTime value={d.date} />
          </span>
          <span className="secondCol">
            <FileLink
              onClick={() => {
                track(
                  "File downloaded from Patient Overview",
                  {
                    patient_id: clientId,
                    user_id: userId,
                    category: "Conversations",
                  },
                  { patient_id: "people", user_id: "people" },
                );
              }}
              data-testid="file-link"
              target="_blank"
              href={d.url}
            >
              <Icon name="file" />
              <span>{d.name}</span>
            </FileLink>
            {!descriptionHidden && d.description ? (
              <FileDescription>{d.description}</FileDescription>
            ) : (
              d.sender && (
                <FileDescription>
                  {d.sender + " ("}
                  <FormattedMessage id={`clients.domains.${d.domain}`} />
                  {")"}
                </FileDescription>
              )
            )}
          </span>
        </div>
      ))}
      <SensiblePagination
        move={(page: number) => {
          setCurrentPage(page);
        }}
        totalPages={Math.ceil(documents.length / ITEMS_PER_PAGE)}
        currentPage={currentPage}
      />
    </div>
  );
};

const SharedDocuments = (props: SharedDocumentsProps) => {
  return (
    <SharedDocumentsTableWrapper>
      <div className="headerRow">
        <span className="firstCol">
          <FormattedMessage id="shared_documents.date_shared" />
        </span>
        <span className="secondCol">
          <FormattedMessage id="shared_documents.filename" />
          {!props.descriptionHidden && (
            <span>
              {" & "}
              <FormattedMessage id="shared_documents.description" />
            </span>
          )}
        </span>
      </div>
      {props.documents.length !== 0 ? (
        <SharedDocumentsTable {...props} />
      ) : (
        <NoFiles>
          <FormattedMessage id="shared_documents.no_files" />
        </NoFiles>
      )}
    </SharedDocumentsTableWrapper>
  );
};

export default SharedDocuments;
