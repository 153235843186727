import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import {
  navigation,
  scaffolding,
  choiceActiveBg,
  typography,
  colors,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { InitialsIcon } from "../Profile/InitialsIcon.styles";

export const ChangeProfileElement = styled.div(rtl`
  ${rtl`text-align: left;`}
  margin-bottom: 1em;
`);

export const SidebarContainer = styled.div(rtl`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${navigation.sidebar.width}px;
  background: ${scaffolding.sidebarBackground};
  border-right: ${scaffolding.gridBorderColor} 1px solid;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  + #content .user_icon {
    display: none;
  }
`);

export const StyledSidebar = styled.div`
  display: table;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const StyledSidebarLogo = styled.div`
  padding: ${navigation.logo.paddingVertical}px
    ${navigation.logo.paddingHorizontal}px;
  text-align: center;
  height: ${navigation.logo.containerHeight}px;

  .kaiku {
    width: ${navigation.logo.width}px;
    height: ${navigation.logo.height}px;
  }
`;

export const StyledSidebarList = styled.ul`
  li {
    ${rtl`text-align: left;`}
    position: relative;
    line-height: 1;
    font-size: ${typography.fontSizeBase};

    a {
      display: flex;
      align-items: center;
      color: ${navigation.linkColor};
      padding: 1.25rem;
      transition: color 0.2s ease;

      &.Sidebar_active {
        background-color: ${choiceActiveBg};
      }

      &:hover {
        color: ${navigation.sidebar.linkHoverColor};
      }
    }

    a[data-focus-visible-added] {
      ${rtl`margin-right: 1px;`}
      outline-width: 2px;
      outline-style: solid;
      outline-color: ${navigation.linkColor};
      outline-offset: -2px;
    }

    .Sidebar_active {
      font-weight: 500;
      color: ${navigation.sidebar.linkHoverColor};
    }
  }
`;

export const StyledName = styled.span`
  ${rtl`margin-left: 10px;`}
`;

export const ProfileButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-family: Graphik, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: ${rem(16)};
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    ${InitialsIcon},
    ${StyledName} {
      color: ${colors.black};
    }
  }

  &:hover {
    ${InitialsIcon} {
      outline: 1px solid ${colors.black};
    }
  }

  &:focus {
    ${InitialsIcon} {
      outline: 2px solid ${colors.blue500};
    }
  }
`;

export const StyledSidebarProfile = styled.div`
  position: relative;
  width: 100%;
  height: ${navigation.sidebar.profileHeight}px;
  line-height: ${navigation.sidebar.profileHeight}px;
  color: ${navigation.sidebar.profileColor};
  vertical-align: middle;
  display: table;
  ${rtl`
    text-align: left;
    padding-left: 1.25rem;
  `}

  > * {
    vertical-align: middle;
  }

  a {
    display: block;
  }
`;

export const StyledSidebarItem = styled.div`
  display: table-row;
  height: 1px;
`;

type ListItem = { sectionItem?: boolean; spacingAfter?: boolean };

export const StyledSidebarListItem = styled.li<ListItem>`
  ${({ sectionItem }) =>
    sectionItem &&
    rtl`
    min-height: 2.25rem;
    padding: 2.25rem 1rem 0.75rem 1.5rem;
    visibility: visible;
    color: ${navigation.sidebar.itemColor};
  `}
  ${({ spacingAfter }) =>
    spacingAfter &&
    css`
      margin-top: 2.25rem;
    `}
`;

export const StyledSidebarLinkText = styled.span(rtl`
  display: inline-block;
  font-size: ${typography.fontSizeBase};
  line-height: 1;
  word-break: break-word;
  ${rtl`margin: 0 0 0 0.75em;`}
`);
