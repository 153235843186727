import bugsnag, { Breadcrumb, BrowserConfig } from "@bugsnag/js";
import { SiteSettings } from "common/utils/holvikaari";

declare global {
  interface Window {
    Bugsnag: typeof Bugsnag;
    bugsnagOnBreadcrumb: any;
  }
}

// We need to attach this function to the `window` object
// for ease of access in testing environment
window.bugsnagOnBreadcrumb = (bc: Breadcrumb) => {
  if (bc.metadata && bc.metadata.targetText) {
    bc.metadata.targetText = "[REDACTED]";
  }
  return bc;
};

const isNodeTestEnv = process.env.NODE_ENV === "test";

// Should not post to Bugsnag if saved locally (proto == "file:")
const enableBugsnag =
  !process.env.DISABLE_BUGSNAG &&
  (!isNodeTestEnv || process.env.TEST || location.protocol === "https:");

const apiKey =
  (enableBugsnag && process.env.BUGSNAG_FRONTEND) ||
  "deadbeefdeadbeefdeadbeefdeadbeef";

const BugsnagOptions: BrowserConfig = {
  apiKey,
  appVersion: process.env.VERSION || "test",
  onBreadcrumb: window.bugsnagOnBreadcrumb,
  generateAnonymousId: false,
};

if (isNodeTestEnv) {
  BugsnagOptions.enabledReleaseStages = ["production", "staging"];
  BugsnagOptions.releaseStage = process.env.NODE_ENV;
}

const Bugsnag = enableBugsnag
  ? bugsnag.start(BugsnagOptions)
  : { setUser: () => {}, addMetadata: () => {}, notify: () => {} };

export const setupBugsnag = (user: any) => {
  Bugsnag.setUser(`${SiteSettings.site}-${user.id}`);
  Bugsnag.addMetadata("user", {
    id: user.id,
    site: SiteSettings.site,
  });
};

window.Bugsnag = Bugsnag;

export default Bugsnag;
