import { useIntl } from "react-intl";
import { i18nTranslate } from "shared/utils/translation";

//* This hook is used to translate strings in the frontend

//* Usage: const t = useI18nTranslate();
//* t("my_translation")

const useI18nTranslate = () => {
  const intl = useIntl();
  const t = i18nTranslate(intl);
  return t;
};

export default useI18nTranslate;
