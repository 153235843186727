import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import { formatDate } from "common/utils/general";
import { Form } from "react-final-form";
import useI18nTranslate from "common/hooks/useI18nTranslate";
import { CardContent } from "../Users/CreateUser.styles";
import SinglePatientExportFields from "./SinglePatientExportFields";
import { stringify as formatQuerystring } from "qs";
import {
  SinglePatientExportContainer,
  StyledCard,
  StyledSubmitButton,
} from "./SinglePatientExport.styles";

type SinglePatientExportProps = {
  client_export_path: string;
  client_id: string;
};

export interface SinglePatientExportValues {
  fileType: "pdf" | "csv";
  dataSelection: string[];
  startDate: string;
  endDate: string;
}

// eslint-disable-next-line max-lines-per-function
const SinglePatientExport = ({
  client_export_path,
}: SinglePatientExportProps) => {
  const submit = (values: any) => {
    if (values.fileType === "pdf") {
      const querystring = queryStringForPdf(values);
      window.open(`${client_export_path}.pdf?${querystring}`, "_blank");
    } else if (values.fileType === "csv") {
      const querystring = queryStringForCsv(values);
      window.open(`${client_export_path}.csv?${querystring}`, "_blank");
    }
  };

  const queryStringForPdf = (values: any) =>
    formatQuerystring({
      include: {
        conversations: values.dataSelection.includes("messages") ? 1 : 0,
        input_forms: {
          value: values.dataSelection.includes("values") ? 1 : 0,
          form: values.dataSelection.includes("forms") ? 1 : 0,
          adverse_effect_package: values.dataSelection.includes("symptoms")
            ? 1
            : 0,
        },
      },
      ...(values.startDate
        ? { start_date: formatDate(dayjs(values.startDate)) }
        : {}),
      ...(values.endDate
        ? { end_date: formatDate(dayjs(values.endDate)) }
        : {}),
    });

  const queryStringForCsv = (values: any) =>
    formatQuerystring({
      include: {
        conversations: values.dataSelection.includes("conversations") ? 1 : 0,
        input_form_answers: values.dataSelection.includes("input_forms")
          ? 1
          : 0,
        people: values.dataSelection.includes("user_information") ? 1 : 0,
        observations: values.dataSelection.includes("observations") ? 1 : 0,
        treatment_program_subscriptions: values.dataSelection.includes(
          "programs",
        )
          ? 1
          : 0,
        calendar_events: values.dataSelection.includes("calendar_entries")
          ? 1
          : 0,
        documents: values.dataSelection.includes("documents") ? 1 : 0,
      },
      ...(values.startDate
        ? { start_date: formatDate(dayjs(values.startDate)) }
        : {}),
      ...(values.endDate
        ? { end_date: formatDate(dayjs(values.endDate)) }
        : {}),
    });

  const t = useI18nTranslate();

  const initialValues: SinglePatientExportValues = useMemo(
    () => ({
      fileType: "pdf",
      dataSelection: [],
      startDate: "",
      endDate: formatDate(dayjs()),
    }),
    [],
  );

  return (
    <SinglePatientExportContainer>
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormattedMessage
                tagName="h1"
                id="csv_export.title_data_export"
              />

              <StyledCard type="white">
                <CardContent>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormattedMessage tagName="p" id={`csv_export.info`} />
                    <FormattedMessage
                      tagName="p"
                      id={`csv_export.link_info`}
                      values={{
                        link: (
                          <a
                            href="/data_export_documentation"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("csv_export.link_text")}
                          </a>
                        ),
                      }}
                    />
                  </div>
                </CardContent>
              </StyledCard>

              <SinglePatientExportFields />

              <StyledSubmitButton
                submit
                type="secondary"
                disabled={values.dataSelection.length === 0}
              >
                {t("csv_export.settings.generate_button")}
              </StyledSubmitButton>
            </form>
          );
        }}
      />
    </SinglePatientExportContainer>
  );
};

export default SinglePatientExport;
