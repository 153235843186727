import { track, trackBeacon } from "common/utils/api";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";

const isBeaconAPISupported = () => {
  return !!navigator.sendBeacon;
};

type EventProps = {
  name: string;
  eventName: string;
  path: string;
  hashingKeys: Record<string, string>;
  params: any;
  location: {
    pathname: string;
  };
};

/**
 * Simple utility to track how long user has spent in a client view. See routes.tsx for usage
 * example.
 */
class TimeTracker {
  private routeName: string;
  private eventName: string;
  private path: string | undefined;
  private hashingKeys: Record<string, string> | undefined;
  private entered: number | null;
  private params: any;
  private location: string | undefined;

  // for more information about route callback methods, see https://github.com/ReactTraining/react-router/blob/v3/docs/API.md#route

  constructor(routeName: string, eventName: string) {
    this.routeName = routeName;
    this.eventName = eventName;
    this.entered = null;
  }

  // See https://caniuse.com/mdn-api_document_visibilitychange_event
  // and https://developers.google.com/web/updates/2018/07/page-lifecycle-api for details
  useEvents = (props: EventProps) => {
    const visibilityListener = () => {
      if (document.visibilityState === "hidden") {
        this.leave();
      } else if (document.visibilityState === "visible") {
        this.enter(props);
      }
    };
    const enterListener = () => this.enter(props);
    const leaveListener = () => this.leave();

    document.addEventListener("visibilitychange", visibilityListener);
    window.addEventListener("pageshow", enterListener);
    window.addEventListener("pagehide", leaveListener);
    window.addEventListener("beforeunload", leaveListener);

    return {
      clear: () => {
        document.removeEventListener("visibilitychange", visibilityListener);
        window.removeEventListener("pageshow", enterListener);
        window.removeEventListener("pagehide", leaveListener);
        window.removeEventListener("beforeunload", leaveListener);
      },
    };
  };

  static trackEffect(props: EventProps) {
    const tracker = new TimeTracker(
      props.name,
      props.eventName || "visited route",
    );
    return () => {
      // This corresponds to componentDidMount
      tracker.enter(props);
      const events = tracker.useEvents(props);

      // this corresponds to the componentWillUnMount
      return () => {
        tracker.leave();
        events.clear();
      };
    };
  }

  enter({ params, path, hashingKeys, location }: EventProps) {
    this.entered = dayjs().valueOf();
    this.params = cloneDeep(params);
    this.path = path;
    this.hashingKeys = hashingKeys;
    // Indexroute doesn't have a path
    this.location = location?.pathname;
  }

  leave() {
    if (!this.entered) return;
    this.sendTrack((dayjs().valueOf() - this.entered) / 1000);
    this.entered = null;
  }

  getParams(duration: number) {
    return {
      name: this.routeName,
      path: this.location, // Fallback just in case; makes backpopulation easier
      path_formats: this.path,
      hashing_keys: this.hashingKeys,
      params: this.params,
      duration_seconds: duration,
    };
  }

  sendTrack(duration: number) {
    const params = this.getParams(duration);
    if (isBeaconAPISupported() && trackBeacon(this.eventName, params, {})) {
      return;
    }
    track(this.eventName, params, {});
  }
}

export default TimeTracker;
