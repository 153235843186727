// These are used in the Tech Support column. Each language
// has their own feedback form (defaults to en).
export const feedbackFormIdsByLanguage: { [key: string]: string } = {
  en: "shrirkHnEalPBqGnA",
  fi: "shrFDdqaPxSLu2eIK",
  sv: "shr0UKtxfhwmoWaVW",
  de: "shrdrMcKvX9D9ekUb",
  fr: "shrDRjPCb8YAlCHEo",
  it: "shrIDkU68dpsIi8jx",
  nl: "shrSZBDUtZZ677yWw",
  da: "shr8BaSY2Iflq5X4b",
  es: "shrzFA6Jz3FtHBs6Q",
  el: "shrbHDaJIbZx8WZXD",
  et: "shrPODTbbVgW5RzNA",
  nb: "shrxuYyvp2C6ZrNNj",
  pl: "shrRePXlbkACRZ4TG",
  pt: "shr2sMHTNesxKlx6T",
  ru: "shrfroenTIoD8qeLp",
  tr: "shrDzCD25d0e3cqp2",
  "es-419": "shrMI1FASewkauBNi",
};
