import { SiteSettings } from "common/utils/holvikaari";
import { useIntl } from "react-intl";
import { Option } from "common/models/domain";

const useAvailableSiteDomains = (): Option[] => {
  const intl = useIntl();
  return SiteSettings.available_domains.map((domain: string) => ({
    value: domain,
    label: intl.formatMessage({ id: `clients.domains.${domain}` }),
  }));
};

export default useAvailableSiteDomains;
