import React from "react";
import { Icon } from "@netmedi/frontend-design-system";
import { LinkProps } from "react-router-dom";
import {
  ListHeading,
  ListLink,
  ListStyled,
  IconContainer,
  LinkIconContainer,
  ListItemStyled,
  ListContainer,
  ListContent,
} from "./List.styles";

/** Use List -component to have unified styling for lists with a separator.
 */
export const List = ({
  children,
  spacing = "s",
  noPadding = false,
  ...rest
}: ListProps) => {
  return (
    <ListStyled noPadding={noPadding} spacingProp={spacing} {...rest}>
      {children}
    </ListStyled>
  );
};

type ListProps = {
  /** Content of the card */
  children?: React.ReactNode;
  /** Additional classNames */
  className?: string;
  /** Define the spacing used */
  spacing?: "xs" | "s";
  /** Whether to have outer spacing */
  noPadding?: boolean;
};

export const ListItem = ({
  icon,
  heading,
  children,
  spacing = "s",
  linkTo,
  alignItems,
  ...rest
}: ListItemProps) => {
  const headingComponent = (
    <ListHeading spacingProp={spacing}>{heading}</ListHeading>
  );

  let content = (
    <>
      {icon && <IconContainer spacingProp={spacing}>{icon}</IconContainer>}
      <ListContainer>
        {heading && headingComponent}
        <ListContent>{children}</ListContent>
      </ListContainer>
      {linkTo && (
        <LinkIconContainer spacingProp={spacing}>
          <Icon name="arrow_right" size="medium" />
        </LinkIconContainer>
      )}
    </>
  );

  if (linkTo) {
    content = <ListLink to={linkTo}>{content}</ListLink>;
  }

  return (
    <ListItemStyled
      spacingProp={spacing}
      isLink={!!linkTo}
      alignItems={alignItems}
      {...rest}
    >
      {content}
    </ListItemStyled>
  );
};

type ListItemProps = {
  /** Content of the card */
  children?: React.ReactNode;
  /** Optional heading for the component */
  heading?: React.ReactChild;
  /** Additional classNames */
  className?: string;
  /** Attach Icon-component */
  icon?: React.ReactElement;
  /** Define the spacing used */
  spacing?: "xs" | "s";
  /** Give a Link -element to make the item actionable. */
  linkTo?: LinkProps["to"];
  /** Set flex align to other than default center */
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
};

export const LinkedListItem = ({ to, ...rest }: ListItemProps & LinkProps) => (
  <ListItem linkTo={to} {...rest} />
);
