import { BodyPart } from "./DefaultBodyPartsSvg";

const LymphNodesBodyPartsArray: BodyPart[] = [
  {
    name: "neck",
    d: "M219.29 87.744L219.314 75.4536C219.364 75.5104 219.415 75.5678 219.466 75.6257C219.732 75.9283 220.011 76.2457 220.302 76.5699C221.148 77.5125 222.084 78.4981 222.972 79.1615C226.436 81.7466 230.338 84.5742 235.15 84.5742C239.926 84.5742 243.057 82.6528 246.584 80.0197C247.46 79.3655 248.68 78.1747 249.809 77.0231C250.377 76.4442 250.912 75.8861 251.387 75.3887L251.365 82.852V82.8542V87.744C251.365 91.1282 252.746 94.5152 254.842 97.389C256.94 100.265 259.793 102.681 262.823 104.113L262.838 104.12L262.853 104.126L263.144 103.435L262.854 104.126L262.854 104.126L262.855 104.127L262.859 104.128L262.875 104.135L262.939 104.162L263.192 104.269C263.415 104.363 263.746 104.503 264.177 104.686C265.037 105.053 266.296 105.591 267.886 106.277C270.79 107.531 274.798 109.281 279.506 111.385C276.555 113.013 273.304 114.646 269.826 116.175C259.573 120.682 247.469 124.236 235.328 124.236C223.187 124.236 211.082 120.682 200.829 116.175C197.351 114.646 194.1 113.013 191.15 111.385C195.857 109.281 199.866 107.531 202.769 106.277C204.359 105.591 205.618 105.053 206.479 104.686C206.909 104.503 207.24 104.363 207.464 104.269L207.717 104.162L207.781 104.135L207.797 104.128L207.801 104.127L207.802 104.126L207.802 104.126L207.512 103.435L207.802 104.126L207.817 104.12L207.832 104.113C210.863 102.681 213.715 100.265 215.813 97.389C217.909 94.5153 219.29 91.1285 219.29 87.7445C219.29 87.7443 219.29 87.7442 219.29 87.744Z",
  },
  {
    name: "left_groin",
    d: "M269.432 327.862L269.431 327.862L269.427 327.866L269.426 327.866L269.423 327.868L269.415 327.875L269.414 327.875L269.409 327.879C265.543 330.856 261.163 334.229 256.86 337.941C250.25 343.641 242.586 353.31 237.496 362.231L236.675 340.156C239.766 335.004 245.055 329.208 250.659 323.07L250.659 323.07L250.663 323.065L250.664 323.064L250.685 323.042L250.685 323.041C251.867 321.747 253.061 320.44 254.246 319.124L253.689 318.622L254.246 319.124C261.015 311.613 266.754 307.044 271.067 305.35L270.794 304.654L271.067 305.35C273.015 304.584 275.177 304.553 277.15 305.024C279.129 305.496 280.858 306.46 281.947 307.608C282.998 308.716 283.612 310.068 283.675 311.572C283.738 313.076 283.261 315.038 281.591 317.348C281.416 317.589 281.214 317.844 280.983 318.112L280.528 318.64L281.02 319.132L318.422 356.534L318.907 357.019L319.434 356.579C323.251 353.387 328.166 351.466 333.532 351.466C345.677 351.466 355.523 361.311 355.523 373.456C355.523 385.602 345.677 395.447 333.532 395.447C321.387 395.447 311.541 385.602 311.541 373.456C311.541 367.419 313.974 361.95 317.914 357.975L318.44 357.445L317.912 356.917L280.606 319.611L280.086 319.091L279.556 319.601C277.816 321.274 275.355 323.273 272.269 325.67C271.362 326.376 270.415 327.105 269.433 327.861L269.433 327.861L269.432 327.862Z",
  },
  {
    name: "right_groin",
    d: "M201.191 327.862L201.191 327.862L201.195 327.866L201.196 327.866L201.199 327.868L201.207 327.875L201.208 327.875L201.213 327.879C205.079 330.856 209.459 334.229 213.763 337.941C220.372 343.641 228.036 353.31 233.127 362.231L233.947 340.156C230.856 335.004 225.567 329.208 219.963 323.07L219.963 323.07L219.959 323.065L219.958 323.064L219.937 323.042L219.937 323.041C218.755 321.747 217.561 320.44 216.376 319.124L216.933 318.622L216.376 319.124C209.607 311.613 203.868 307.044 199.555 305.35L199.828 304.654L199.555 305.35C197.607 304.584 195.445 304.553 193.472 305.024C191.493 305.496 189.764 306.46 188.675 307.608C187.624 308.716 187.011 310.068 186.948 311.572C186.885 313.076 187.362 315.038 189.031 317.348C189.206 317.589 189.408 317.844 189.64 318.112L190.094 318.64L189.602 319.132L152.2 356.534L151.715 357.019L151.188 356.579C147.371 353.387 142.456 351.466 137.09 351.466C124.945 351.466 115.099 361.311 115.099 373.456C115.099 385.602 124.945 395.447 137.09 395.447C149.235 395.447 159.081 385.602 159.081 373.456C159.081 367.419 156.648 361.95 152.708 357.975L152.182 357.445L152.71 356.917L190.016 319.611L190.536 319.091L191.066 319.601C192.806 321.274 195.267 323.273 198.353 325.67C199.26 326.376 200.207 327.105 201.189 327.861L201.189 327.861L201.191 327.862Z",
  },
  {
    name: "right_armpit",
    d: "M137.641 127.776L137.171 127.305L136.647 127.717C132.907 130.66 128.189 132.416 123.059 132.416C110.914 132.416 101.068 122.571 101.068 110.425C101.068 98.2804 110.914 88.4349 123.059 88.4349C135.204 88.4349 145.05 98.2804 145.05 110.425C145.05 116.698 142.424 122.357 138.21 126.364L137.653 126.894L138.197 127.438L162.225 151.466L162.696 151.937L163.22 151.525C165.721 149.555 168.876 148.381 172.308 148.381C180.427 148.381 187.009 154.963 187.009 163.082C187.009 169.521 182.869 174.995 177.104 176.983C176.275 173.421 175.424 170.1 174.573 167L174.443 166.525L173.955 166.456C173.556 166.4 173.173 166.512 172.85 166.667C172.521 166.825 172.189 167.057 171.861 167.331C171.205 167.879 170.481 168.666 169.726 169.593C168.327 171.312 166.755 173.6 165.232 175.971C160.685 173.47 157.606 168.635 157.606 163.082C157.606 159.124 159.169 155.532 161.714 152.889L162.224 152.359L161.704 151.838L137.641 127.776Z",
  },
  {
    name: "left_armpit",
    d: "M333.344 126.883L333.815 126.412L333.403 125.889C330.46 122.148 328.704 117.43 328.704 112.301C328.704 100.156 338.55 90.3101 350.695 90.3101C362.84 90.3101 372.686 100.156 372.686 112.301C372.686 124.446 362.84 134.291 350.695 134.291C344.422 134.291 338.764 131.666 334.756 127.452L334.226 126.894L333.683 127.438L309.113 152.008L308.6 152.521L309.095 153.05C311.548 155.678 313.049 159.204 313.049 163.082C313.049 168.635 309.97 173.47 305.424 175.971C303.901 173.6 302.328 171.312 300.929 169.593C300.175 168.666 299.451 167.879 298.795 167.331C298.467 167.057 298.135 166.825 297.805 166.667C297.482 166.512 297.1 166.4 296.701 166.456L296.213 166.525L296.083 167C295.232 170.1 294.381 173.421 293.552 176.983C287.787 174.995 283.646 169.521 283.646 163.082C283.646 154.963 290.228 148.381 298.348 148.381C301.86 148.381 305.082 149.611 307.611 151.665L308.135 152.091L308.614 151.613L333.344 126.883Z",
  },
];

export { LymphNodesBodyPartsArray };
