import React, { Fragment, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { supportMail } from "common/utils/user";
import { modalHandle } from "common/utils/semanticHandles";
import { SiteSettings } from "common/utils/holvikaari";
import { User } from "common/models/user";

import { connect } from "react-redux";
import { dismissModal } from "common/actions/modal";
import { RootState } from "store";

import Alert from "./Alert";
import Confirm from "./Confirm";
import Error from "./Error";
import ExpirationModal from "./ExpirationModal";
import Consent from "./Consent";
import IssueUpdateError from "./IssueUpdateError";
import MassMessageModal from "./MassMessageModal";
import CrcModal from "./CrcModal";
import MobilePsaModal from "./MobilePsaModal";
import Loader from "./Loader";
import CarouselModal from "./CarouselModal";
import ImagesModal from "./ImagesModal";
import { ModalOverlay, ModalOverlayWrapper } from "./Modal.styles";
import { ModalParams, Dismiss } from "./Modal.types";
import MassNotificationSummaryModal from "./MassNotificationSummaryModal";
import ShareDocumentModal from "hcp/components/Documents/ShareDocumentModal";
import InputFormInfoModal from "./InputFormInfoModal";
import TimeWindowModal from "./TimeWindowModal";
import AddCaregiverModal from "./AddCaregiverModal";
import RemoveCaregiversModal from "./RemoveCaregiversModal";

export type ModalProviderProps = ModalParams & Dismiss & { user: User };

export const values = (user: ModalProviderProps["user"]) => ({
  product_name: SiteSettings.product_name,
  support_email: (
    <a href={`mailto:${supportMail(user)}`}>{supportMail(user)}</a>
  ),
});

const getContent = (props: ModalProviderProps) => {
  switch (props.type) {
    case "alert":
      return <Alert {...props} />;
    case "confirm":
      return <Confirm {...props} />;
    case "error":
      return <Error {...props} />;
    case "expiration_warning":
      return <ExpirationModal {...props} />;
    case "loader":
      return <Loader {...props} />;
    case "offline":
      return <Error {...props} body={<FormattedMessage id="offline" />} />;
    case "issue_update_error":
      return <IssueUpdateError {...props} />;
    case "500":
    case "page_load_error":
    case "critical_error":
      return (
        <Error
          {...props}
          title={<FormattedMessage id="modal.error_critical" />}
          body={
            <Fragment>
              <FormattedMessage id="modal.error_critical_text" />
              <FormattedMessage
                id={
                  props.user?.show_support_email
                    ? "modal.error_critical_support_text"
                    : "modal.error_critical_alt_support_text"
                }
                values={values(props.user)}
              />
            </Fragment>
          }
        />
      );
    case "mass_message":
      return <MassMessageModal {...props} />;
    case "crc_modal":
      return <CrcModal {...props} />;
    case "mobile_psa_modal":
      return <MobilePsaModal {...props} />;
    case "consent_modal":
      return <Consent {...props} />;
    case "carousel_modal":
      return <CarouselModal {...props} />;
    case "images_modal":
      return <ImagesModal {...props} />;
    case "mass_notification_summary":
      return <MassNotificationSummaryModal {...props} />;
    case "share_document":
      return <ShareDocumentModal {...props} />;
    case "input_form_info_modal":
      return <InputFormInfoModal {...props} />;
    case "time_window_modal":
      return <TimeWindowModal {...props} />;
    case "add_caregiver_modal":
      return <AddCaregiverModal {...props} />;
    case "remove_caregivers":
      return <RemoveCaregiversModal {...props} />;
  }
};

export function ModalProvider(props: ModalProviderProps) {
  const escFunction = useCallback(
    event => {
      if (event.key === "Escape" && typeof props.dismiss === "function") {
        props.dismiss();
      }
    },
    [props.dismiss],
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return !props.type ? null : (
    <ModalOverlay
      onClick={props.disableOutsideClick ? undefined : props.dismiss}
      {...modalHandle()}
    >
      <ModalOverlayWrapper>{getContent(props)}</ModalOverlayWrapper>
    </ModalOverlay>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  ...state.app.modal,
});

export default connect(mapStateToProps, { dismiss: dismissModal })(
  ModalProvider,
);
