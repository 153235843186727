import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import { RootState } from "store";

export interface PatientRecords {
  unread: (string | number)[];
}

const patientRecordSlice = createSlice({
  name: "patientRecords",
  initialState: { unread: [] } as PatientRecords,
  reducers: {
    setUnread: (state, { payload }: PayloadAction<(string | number)[]>) => {
      state.unread = payload;
    },
    updateUnread: (
      state,
      { payload }: PayloadAction<{ id: string | number; isUnread: boolean }>,
    ) => {
      if (payload.isUnread) {
        state.unread = uniq([...state.unread, payload.id]);
      } else {
        state.unread = state.unread.filter(id => id !== payload.id);
      }
    },
  },
});

export const patientRecordActions = patientRecordSlice.actions;
export default patientRecordSlice.reducer;

export const selectUnreadPatientRecordIds = (state: RootState) =>
  state.patientRecords.unread;
