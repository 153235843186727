import React, {
  MouseEvent,
  ChangeEvent,
  useState,
  useRef,
  useEffect,
} from "react";
import { ButtonExtras, Spinner } from "@netmedi/frontend-design-system";
const { LinkButton } = ButtonExtras;
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { debounce } from "lodash";
import { dropdown, rmTooltip } from "common/utils/tooltip";
import Search from "../Search";
import { redirect, SiteSettings, userPath } from "common/utils/holvikaari";
import UserList from "../UserList";
import { User } from "common/models/user";

import { connect, ConnectedProps } from "react-redux";
import { getUsers } from "hcp/actions/people";
import { RootState } from "store";
import { pageLoadFailed } from "common/utils/api";

import {
  SearchBarWrapper,
  SearchBarStyled,
  InputContainer,
  DropDownContainer,
} from "./SearchBar.styles";

const mapStateToProps = ({ people: { users, search } }: RootState) => ({
  users,
  search,
});

const mapDispatchToProps = { getUsers };

export type SearchBarProps = ConnectedProps<typeof connector> &
  WrappedComponentProps;

// eslint-disable-next-line max-lines-per-function
export const SearchBar = (props: SearchBarProps) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(-1);
  const [usersAbortController, setUsersAbortControler] =
    useState<AbortController | null>(null);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const searchInputRef = useRef<any>(null);

  useEffect(() => {
    loadUsers();
    return () => window.removeEventListener("keydown", listenToKeys);
  }, []);

  useEffect(() => {
    onUpdate();
  });

  function onUpdate() {
    // search area is in focus if user has clicked to the input field or is
    // scrolling through the search results (then active > -1)
    const searchAreaInFocus =
      document.activeElement === searchElem() || active > -1;

    if (target && searchAreaInFocus) {
      window.addEventListener("keydown", listenToKeys);
      createDropdown(target);
    }
  }

  function loadUsers() {
    // Get all of the user's own clients
    setLoading(true);
    props
      .getUsers({
        type: "Client",
        filter: "own",
        mapRelations: true,
      })
      .then(() => setLoading(false))
      .catch(pageLoadFailed);
  }

  function search(e: ChangeEvent<HTMLInputElement>) {
    const query = e.target.value;
    const loading = !!query.length;

    setQuery(query);
    setLoading(loading);
    setTarget(e.target);
    doSearch(query);
  }

  const doSearch = debounce((query: string) => {
    if (usersAbortController) usersAbortController.abort();
    const abortController = new window.AbortController();
    const { signal } = abortController;
    setUsersAbortControler(abortController);

    props
      .getUsers(
        {
          query,
          type: "Client",
          filter: "own",
          mapRelations: true,
        },
        undefined,
        signal,
      )
      .then(() => setLoading(false))
      .catch((err: Error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(err); // eslint-disable-line no-console
        }
      });
  }, 400);

  function searchResults(): any[] {
    // If there is no search query, the dropdown will show all of the user's own clients
    return query === "" ? props.users : props.search;
  }

  function createDropdown(target: HTMLElement) {
    dropdown({
      content: loading ? (
        <DropDownContainer>
          <Spinner noPadding />
        </DropDownContainer>
      ) : query && props.search?.length === 0 ? (
        <DropDownContainer>
          <FormattedMessage id="feed.no_search_results" />
          <a href="/clients#all">
            <FormattedMessage id="feed.link_to_all_clients" />
          </a>
        </DropDownContainer>
      ) : (
        <UserList
          items={searchResults()}
          onClick={(user: User) => redirect(userPath(user))}
          activeIndex={active}
        />
      ),
      className: "SearchBar_dropdown",
      target,
      onClose: () => {
        resetSearch();
        window.removeEventListener("keydown", listenToKeys);
      },
      modifiers: { preventOverflow: { enabled: false } },
    });
  }

  function searchBarClicked(e: MouseEvent<HTMLInputElement>) {
    const query = e.currentTarget.value;
    if (query === "") {
      // when user clicks the search bar without any search words, display all
      search({ ...e, target: e.currentTarget });
    } else {
      onUpdate();
    }
  }

  function listenToKeys(e: KeyboardEvent) {
    const [UP, DOWN, ESC, ENTER, TAB] = [38, 40, 27, 13, 9];

    if (!isResultsPopupDisplayed()) {
      return;
    }
    const el = searchElem();
    if (e.keyCode === ESC) {
      setActive(-1);
      setQuery("");
      rmTooltip();
      window.removeEventListener("keydown", listenToKeys);
      el.value = "";
    } else if (e.keyCode === UP) {
      upPressedInSearchResults(() => updateActiveElement(el));
    } else if (e.keyCode === DOWN) {
      // disables the userlist from being scrolled down (with keys) so that the first result is not fully visible
      e.preventDefault();
      downPressedInSearchResults(() => updateActiveElement(el));
    } else if (e.keyCode === ENTER && active > -1) {
      const user = searchResults()[active];
      redirect(userPath(user));
    } else if (e.keyCode === TAB) {
      rmTooltip();
      resetSearch();
      window.removeEventListener("keydown", listenToKeys);
    }
  }

  function updateActiveElement(searchInput: HTMLInputElement) {
    if (active === -1) {
      searchInput.value = query;
      moveCursorToEnd(searchInput);
    } else {
      searchInput.value = searchResults()[active].full_name;
    }
  }

  function isResultsPopupDisplayed() {
    return target && searchResults();
  }

  function upPressedInSearchResults(callback: (...args: any[]) => any) {
    setActive(active > -1 ? active - 1 : searchResults().length - 1);
    callback();
  }

  function downPressedInSearchResults(callback: (...args: any[]) => any) {
    setActive(active < searchResults().length - 1 ? active + 1 : -1);
    callback();
  }

  function moveCursorToEnd(el: HTMLInputElement) {
    setTimeout(() => {
      el.selectionStart = el.value.length;
      el.selectionEnd = el.value.length;
      el.focus();
    }, 0);
  }

  function resetSearch() {
    setActive(-1);
    searchElem().value = query;
  }

  function searchElem() {
    return searchInputRef.current;
  }

  const placeholder = props.intl.formatMessage({ id: "feed.search" });
  const additionalClass = active > -1;

  return (
    <SearchBarWrapper>
      <SearchBarStyled onChange={search}>
        <InputContainer>
          <Search
            placeholder={placeholder}
            ref={searchInputRef}
            additionalClass={additionalClass}
            onClick={e => searchBarClicked(e)}
          />
        </InputContainer>

        {!SiteSettings.enable_ixrs_invites && (
          <LinkButton primary holvikaari size="small" href="/invites/new">
            <FormattedMessage id="clients.new_client" />
          </LinkButton>
        )}
      </SearchBarStyled>
    </SearchBarWrapper>
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(injectIntl(SearchBar));
