import React from "react";
import DOMPurify from "dompurify";
import { zip } from "lodash";

export interface ParseLinksProps {
  children: string;
}

const ParseLinks: React.FC<ParseLinksProps> = ({ children }) => {
  const purify = DOMPurify(window);
  // Cannot use positive lookbehind in Safari
  const urlRegexp =
    /(?:\s|^)(?:https?:\/\/|www\.)?[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9@:%_+.~#?&/=]*)/g;

  // sanitize the input
  // otherwise, if the source contains html the result will get ugly
  const sanitized = purify
    .sanitize(children, { ALLOWED_TAGS: [] })
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");

  // Separate urls from non-url text pieces
  const urls = sanitized.match(urlRegexp) || [];
  const nonMatches = sanitized.split(urlRegexp);

  // turn urls into links
  const links = urls.map((match, index) => {
    const url = match.trim();

    return (
      <React.Fragment key={`match-${index}`}>
        {
          // keep the leading whitespace
          match.match(/^\s.*/) && match[0]
        }
        <a
          href={url.startsWith("http") ? url : `http://${url}`}
          target="_blank"
          rel="noopener noreferrer"
          key={`match-${index}`}
        >
          {url}
        </a>
      </React.Fragment>
    );
  });

  // zip the links and text pieces together
  const textWithLinks = zip(nonMatches, links);

  return <>{textWithLinks}</>;
};

export default ParseLinks;
