import { isClient } from "./user";
import { User } from "common/models/user";
import { inMobileApp } from "./inMobileApp";
import { SiteSettings } from "common/utils/holvikaari";

export const isClientWithPhoneNumber = (user: Pick<User, "phone" | "type">) => {
  return isClient(user) && user.phone !== null && user.phone !== "";
};

export const showMobileAppPanel = (user: Pick<User, "phone" | "type">) => {
  return (
    SiteSettings.allow_mobile_app &&
    !inMobileApp() &&
    isClientWithPhoneNumber(user)
  );
};

export const showMobileAppDeviceGeneratedDataNotification = (
  user: User,
): boolean =>
  isClient(user) &&
  !!SiteSettings.enable_device_generated_data &&
  inMobileApp();

export const mobileAppDeepLink = (site: string): string =>
  `https://m.kaikuhealth.com/${site}`;
// Fallback to the App Store app page on desktop
export const mobileAppDeepLinkApple = (mobileAppDeepLink: string): string =>
  `${mobileAppDeepLink}?$fallback_url=https%3A%2F%2Fapple.co%2F2ZFrqAn`;
// Fallback to the Play Store app page on desktop
export const mobileAppDeepLinkGoogle = (mobileAppDeepLink: string): string =>
  `${mobileAppDeepLink}?$fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.kaikuhealth`;
