import React, { useEffect } from "react";
import SharedDocuments from "./ClientDashboardDocumentSection";
import { Document } from "shared/components/DocumentCard/SharedDocumentCard";

type SharedClientProps = {
  clientId: number;
  documents: Document[];
  getSentDocuments: any;
  userId: number;
};

const DocumentsSharedByClient = (props: SharedClientProps) => {
  useEffect(() => {
    props.getSentDocuments(props.clientId);
  }, []);

  return (
    <SharedDocuments
      type={"shared-by-client"}
      descriptionHidden={true}
      {...props}
    />
  );
};

export default DocumentsSharedByClient;
