import {
  createSlice,
  ActionReducerMapBuilder,
  PayloadAction,
} from "@reduxjs/toolkit";
import Message from "shared/models/message";
import { filterById } from "common/utils/general";
import { partChat } from "./conversations";

type DraftState = Partial<Message> | null;

export const draftSlice = createSlice({
  name: "draft",
  initialState: null as DraftState,
  reducers: {
    setDraft(_state, { payload }: PayloadAction<Partial<Message>>) {
      return payload ?? {};
    },
    updateDraft(
      state,
      {
        payload,
      }: PayloadAction<
        Pick<Partial<Message>, "id" | "content" | "attachments"> | undefined
      >,
    ) {
      if (!payload) {
        return null;
      }
      return {
        ...state,
        ...payload,
      };
    },
    addDraftAttachment(
      state,
      {
        payload: { draft_message_id, ...newAttachment },
      }: PayloadAction<{
        draft_message_id: string;
        id: string;
        url: string;
        name: string;
      }>,
    ) {
      if (!state) {
        state = {};
      }
      state.id = draft_message_id;
      state.content = state.content ?? "";
      state.attachments = (state.attachments ?? []).concat([newAttachment]);
    },
    removeDraftAttachment(
      state,
      { payload: draftAttachmentId }: PayloadAction<string>,
    ) {
      if (!state?.attachments) return;
      state.attachments = state.attachments.filter(
        filterById(draftAttachmentId),
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DraftState>) => {
    builder.addCase(partChat, () => {
      return null;
    });
  },
});

export const draftActions = draftSlice.actions;
