import React, { MouseEventHandler, useState, useEffect } from "react";
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
  IntlShape,
} from "react-intl";
import { Button, Spacing, Spinner } from "@netmedi/frontend-design-system";
import { values, isNil, isUndefined, pickBy, isEqual, isEmpty } from "lodash";
import { openModal } from "common/actions/modal";
import { index, create, update, destroy } from "shared/actions/alertCondition";
import {
  AlertCondition,
  State as ConditionRecord,
} from "shared/reducers/AlertCondition";
import { connect } from "react-redux";
import { RootState } from "store";
import { SiteSettings } from "common/utils/holvikaari";
import {
  AddOn,
  ErrorMsg,
  FormFieldInput,
} from "common/components/Form/FormField.styles";
import {
  Modal,
  ModalPsaMobileWrapper,
  ModalPsaButton,
  ModalPsaLimitText,
  ModalButtons,
} from "./Modal.styles";
import { showErrorFlash, showSuccessFlash } from "hcp/utils/flash";
const not = (pred: typeof isUndefined) => (value: any) => !pred(value);

type MobilePSAModalLink = {
  client_id: string | number;
  openModal: (...args: any[]) => any;
  button?: boolean;
  className?: string;
  index: (...args: any[]) => any;
  alert_conditions: ConditionRecord;
};

class MobilePsaModalLinkComponent extends React.Component<MobilePSAModalLink> {
  constructor(props: MobilePSAModalLink) {
    super(props);
    this.props.index(this.props);
    this.getPsaLimits = this.getPsaLimits.bind(this);
  }

  componentDidUpdate(prevProps: MobilePSAModalLink) {
    if (!isEqual(prevProps.alert_conditions, this.props.alert_conditions)) {
      this.props.index(this.props);
    }
  }

  // showing the current PSA limits on the P&S-page
  getPsaLimits() {
    const { alert_conditions } = this.props;
    const alerts = Object.values(alert_conditions).filter(
      (i: any) => typeof i === "object" && !i.deleted_at && (i.min || i.max),
    );

    if (alerts && alerts.length > 0) {
      const latest: any = alerts.slice(-1)[0];
      const min_limit = latest.min;
      const max_limit = latest.max;
      const unit = latest.unit;

      if (min_limit && max_limit) {
        return `${min_limit} - ${max_limit} ${unit}`;
      } else if (min_limit) {
        return `> ${min_limit} ${unit}`;
      } else {
        return `< ${max_limit} ${unit}`;
      }
    }
    return "-";
  }

  render() {
    const { className, client_id, openModal, alert_conditions } = this.props;

    return (
      SiteSettings.mobile_psa && (
        <ModalPsaMobileWrapper>
          <ModalPsaButton
            size="small"
            className={className}
            onClick={() =>
              openModal({
                type: "mobile_psa_modal",
                client_id: client_id,
                disableOutsideClick: true,
              })
            }
          >
            <FormattedMessage id="modal.mobile_psa.set_alert_limits" />
          </ModalPsaButton>
          <ModalPsaLimitText>
            {!isEmpty(alert_conditions) && (
              <FormattedMessage id="modal.mobile_psa.current_limits">
                {text => (
                  <p>
                    {text} <strong>{this.getPsaLimits()}</strong>
                  </p>
                )}
              </FormattedMessage>
            )}
          </ModalPsaLimitText>
        </ModalPsaMobileWrapper>
      )
    );
  }
}

export const MobilePsaModalLink = connect(
  ({ alert_conditions }: RootState) => ({ alert_conditions }),
  { openModal, index },
)(MobilePsaModalLinkComponent);

type InputProps = {
  field_id: any;
  unit: any;
  onChange: (field_id: any, value: any) => void;
  defaultValue: number | null;
};
const Input = ({ field_id, unit, onChange, defaultValue }: InputProps) => (
  <div>
    <AddOn as="label" htmlFor={`psa_field${field_id}`}>
      <FormattedMessage
        id={`modal.mobile_psa.input_label_${field_id}`}
        values={{ unit }}
      />
    </AddOn>
    <FormFieldInput
      type="text"
      id={`psa_field${field_id}`}
      onChange={({ target: { value } }) => onChange(field_id, value)}
      defaultValue={defaultValue ?? undefined}
    />
  </div>
);

function relevantAlert(alert_conditions: ConditionRecord) {
  const relevantAlerts = values(alert_conditions).filter(
    ({
      input_form_external_id: form,
      field_external_id: field,
      treatment_program_subscription_id: tp,
      deleted_at,
      unit,
    }) =>
      isNil(deleted_at) &&
      isNil(tp) &&
      isEqual({ field, form, unit }, SiteSettings.mobile_psa),
  );
  return relevantAlerts[0] || {};
}

function handlePsaModalResult<T>(promise: Promise<T>, intl: IntlShape) {
  promise
    .then(() =>
      showSuccessFlash(
        intl.formatMessage({
          id: "modal.mobile_psa.save_successful",
        }),
      ),
    )
    .catch(() =>
      showErrorFlash(
        intl.formatMessage({
          id: "modal.mobile_psa.save_error",
        }),
      ),
    );
}

export type MobilePsaModalProps = {
  type: "mobile_psa_modal";
  alert_conditions: ConditionRecord;
  index: typeof index;
  create: typeof create;
  update: typeof update;
  dismiss: MouseEventHandler;
  destroy: typeof destroy;
  client_id: number;
} & WrappedComponentProps;

type MobilePsaModalState = {
  loading: boolean;
  values: { min?: number; max?: number };
  submit_lock: boolean;
  error: any;
};

// eslint-disable-next-line max-lines-per-function
const MobilePsaModal = (props: MobilePsaModalProps) => {
  const [state, setState] = useState<MobilePsaModalState>({
    loading: true,
    values: {},
    submit_lock: false,
    error: null,
  });

  useEffect(() => {
    props.index(props).then(() => setState({ ...state, loading: false }));
  }, []);

  function setAnswer(key: string, value: string) {
    const parsedVal = !value.replace(/\s/g, "").length
      ? null // Empty or only spaces
      : Number(value);

    const error = validateAnswer(key, parsedVal);
    setState({
      ...state,
      values: { ...state.values, ...{ [key]: parsedVal } },
      error: error,
      submit_lock: !!error,
    });
  }

  function validateAnswer(key: string, value: any) {
    if (isNaN(value)) return "modal.mobile_psa.not_a_number";

    const oldAlert = relevantAlert(props.alert_conditions);
    const min = state.values.min ?? oldAlert.min;
    const max = state.values.max ?? oldAlert.max;

    const min_error =
      value !== null && key === "min" && max !== null && value > max;
    const max_error =
      value !== null && key === "max" && min !== null && value < min;

    if (min_error || max_error) return "modal.mobile_psa.min_higher_than_max";
  }

  function nothingChanged({ min, max }: AlertCondition) {
    const oldAlert = relevantAlert(props.alert_conditions);
    return [
      [min, oldAlert.min],
      [max, oldAlert.max],
    ].every(([v1, v2]) => v1 === v2 || (isNil(v1) && isNil(v2)));
  }

  function submit(unsavedAlert: AlertCondition) {
    const { dismiss, destroy, create, update } = props;
    const methodSelection: [
      (unsavedAlert: AlertCondition) => Promise<any>,
      (unsavedAlert: AlertCondition) => boolean,
    ][] = [
      [() => Promise.resolve(), nothingChanged],
      [destroy, ({ min, max }: AlertCondition) => [min, max].every(isNil)],
      [create, ({ id }: AlertCondition) => isNil(id)],
    ];
    const method = (methodSelection.find(([, pred]) => pred(unsavedAlert)) ?? [
      update,
    ])[0];
    // Lock submit button. It will be unlocked when modal is opened again.
    setState({ ...state, submit_lock: true });
    return handlePsaModalResult(method(unsavedAlert).then(dismiss), props.intl);
  }

  if (state.loading) return <Spinner />;
  const { values } = state;
  const { client_id, dismiss } = props;
  const spacing = "xs";
  const unsavedAlert = {
    ...{
      client_id,
      field_external_id: SiteSettings.mobile_psa.field,
      input_form_external_id: SiteSettings.mobile_psa.form,
      unit: SiteSettings.mobile_psa.unit,
    },
    ...relevantAlert(props.alert_conditions),
    ...pickBy(values, not(isUndefined)),
  };

  return (
    <Modal onClick={(e: any) => e.stopPropagation()}>
      <Spacing {...{ spacing }}>
        <h3>
          <FormattedMessage id="modal.mobile_psa.set_alert_limits" />
        </h3>
      </Spacing>
      <Spacing {...{ spacing }}>
        <FormattedMessage id="modal.mobile_psa.modal_body" tagName="div" />
      </Spacing>
      <Spacing {...{ spacing }}>
        <Input
          field_id="min"
          unit={SiteSettings.mobile_psa.unit}
          defaultValue={unsavedAlert.min}
          onChange={setAnswer}
        />
        <Input
          field_id="max"
          unit={SiteSettings.mobile_psa.unit}
          defaultValue={unsavedAlert.max}
          onChange={setAnswer}
        />
        {state.error && (
          <ErrorMsg>
            <FormattedMessage id={state.error} />
          </ErrorMsg>
        )}
        <ModalButtons>
          <Button
            size="small"
            onClick={() => submit(unsavedAlert)}
            disabled={state.submit_lock}
          >
            <FormattedMessage id="modal.mobile_psa.submit" />
          </Button>
          <Button size="small" type="accent" onClick={dismiss}>
            <FormattedMessage id="people.cancel" />
          </Button>
        </ModalButtons>
      </Spacing>
    </Modal>
  );
};

export default injectIntl(
  connect(({ alert_conditions }: RootState) => ({ alert_conditions }), {
    index,
    create,
    update,
    destroy,
  })(MobilePsaModal),
);
