import React from "react";

import {
  SearchIcon,
  SearchWrapperContainer,
  StyledInput,
} from "./DataTable.styles";

type Props = {
  placeholder: string;
  onChange: (...args: any[]) => any;
  value?: string;
};

function SearchWrapper(props: Props) {
  const { placeholder, onChange, value } = props;

  return (
    <SearchWrapperContainer>
      <SearchIcon material name="search" size="medium" />
      <StyledInput
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </SearchWrapperContainer>
  );
}

export default SearchWrapper;
