import {
  groupBy,
  isNil,
  values,
  map,
  flatMap,
  sortBy,
  uniqBy,
  mapValues,
  uniq,
  min,
  max,
  some,
  isEmpty,
  every,
  get,
  find,
} from "lodash";
import { parseIsoString, splitBy } from "common/utils/general";
import { IntlShape } from "react-intl";

const OVERVIEW_FIELD_ID = "pseudoForm/overview";
const OVERVIEW_SECTION_ID = "overview";
const NON_ALERT_ROWS_SECTION_ID = "nonAlerting";
const ALERT_ROWS_SECTION_ID = "alerting";

const minMax = <T,>(list: T[]) => uniq([min(list), max(list)]);

// A poor man's markdown escape: Remove backticks and wrap in a codeblock.
const esc = (value?: string) =>
  value ? `\`${value.toString().replace(/`/g, "'")}\`` : "";

const sourceHasAlert = ({ sources }: CellItem, active = false) =>
  some(sources, s => !isEmpty(s[active ? "alerts" : "configured_alerts"]));

// A predicate for groupping a row to "has alerts" or "has no alerts" section
// Has alerts = has had a configured alert at some time
const isAlertRow = (symptom_table_row: SymptomTableRow) =>
  some(symptom_table_row.data, ({ sources }) =>
    some(sources, ({ data_points }) =>
      some(data_points, ({ configured_alerts }) => !isEmpty(configured_alerts)),
    ),
  );

type InAlertSection = (cellItem: CellItem) => boolean;

const alertIcon = (cellItems: Cell, inAlertSection: InAlertSection) => {
  const someAlerts = some(cellItems, d => sourceHasAlert(d, true));
  const allHaveConfiguredAlerts = every(cellItems, d =>
    sourceHasAlert(d, false),
  );
  if (someAlerts) return "bell";
  if (allHaveConfiguredAlerts) return "ok_circle";
  if (inAlertSection(cellItems[0])) return "question_circle";
  return null;
};

// these types only contain just enough for this file, in truth there are more fields
type DataPoint = {
  caption?: string;
  content?: string;
  unit?: string;
  alerts: unknown[];
  configured_alerts: unknown[];
};
type Source = { data_points: DataPoint[]; time: string };
type RowData = { date: string; grade?: number; sources: Source[] };
type SymptomTableRow = {
  name: string;
  id: string;
  item_type: string;
  data: RowData[];
};
type Data = { symptom_table_rows: SymptomTableRow[] };

type CellItem = Omit<SymptomTableRow, "data"> &
  Omit<RowData, "sources"> & {
    sources: (Omit<Source, "data_points"> & DataPoint)[];
    data: null;
  };
type Cell = CellItem[];

type Section = {
  id: string;
  name: string | null;
  questions: any[];
};

// The items in cell are grouped by external_id and have the same unit, display the fist one.
const cellUnit = (cellItems: Cell) => {
  const unit = flatMap(cellItems, ({ sources }) => map(sources, "unit"))[0];
  return unit ? ` ${unit}` : "";
};

// Text fields: Comma seperated concatenation
const concatDataPointContent = (cell: Cell) =>
  flatMap(cell, ({ sources }) => map(sources, "content")).join(", ");

const emptyCellItem = ({ grade, sources }: CellItem) =>
  isNil(grade) && isNil(sources[0].content);

// Nil grade => render text content
const combineValuesOfCells = (cell: Cell) =>
  some(cell, ({ grade }) => !isNil(grade))
    ? minMax(map(cell, "grade")).join(" - ") + cellUnit(cell)
    : concatDataPointContent(cell);

const emptyCell = (cellItems: Cell) =>
  cellItems.every(emptyCellItem) && { value: "" };

// Transform from question -> date double nested grouping to sorted date-grouping
// Removes unneccessary nesting, eg [{sources: [{data_points: [...]}, ...]}, ...]
const transposeAndFlatten = (symptom_table_rows: SymptomTableRow[]) =>
  sortBy(
    values(
      symptom_table_rows.reduce(
        (columns, row) => {
          row.data.forEach(data => {
            columns[data.date] = columns[data.date] || [];
            columns[data.date].push({
              ...row,
              ...data,
              sources: data.sources.map(source => ({
                ...source,
                ...source.data_points[0],
                data_points: null,
              })),
              data: null,
            });
          });
          return columns;
        },
        {} as Record<string, Cell>,
      ),
    ),
    "0.date",
  );

// eslint-disable-next-line max-lines-per-function
export const symptomToCompareConverter = (
  formatMessage: IntlShape["formatMessage"],
) => {
  const t = (id: string, values = {}) => formatMessage({ id }, values);

  const getAlertsList = (sortedFields: Cell) => {
    const alerts = uniqBy(
      flatMap(sortedFields, ({ sources }) =>
        flatMap(sources, "configured_alerts"),
      ),
      "id",
    );
    const alertsList = alerts
      .map(({ min, max }) => {
        const key = isNil(min)
          ? isNil(max)
            ? ""
            : "over"
          : isNil(max)
            ? "under"
            : "outside_of";
        const values = { limit_min: min, limit_max: max };
        return `&nbsp;&nbsp;– ${t("value_comparison.tooltip." + key, values)}`;
      })
      .join("\n\n");
    return alertsList;
  };

  const getMeasurementList = (sortedFields: Cell) => {
    const measurementList = sortedFields
      .filter(({ grade }) => !isNil(grade))
      .map(({ sources }) => {
        const source = sources[0];
        const { caption, content, time } = source;
        const timestamp = esc(parseIsoString(time).format("LT"));
        const dpCaption = caption ? esc(`(${caption})`) : "";
        return `&nbsp;&nbsp;– ${timestamp}:&nbsp; ${esc(content)} ${dpCaption}`;
      })
      .join("\n\n");
    return measurementList;
  };

  // Markdown source for the tooltip
  const markdownCaption = (grouppedCells: Cell) => {
    const sortedFields = sortBy(
      grouppedCells,
      ({ sources }) => sources[0].time,
    );
    const alertsList = getAlertsList(sortedFields);
    if (grouppedCells.length <= 1 && alertsList === "") return null;
    const measurementList = getMeasurementList(sortedFields);
    const measurementsTitle = t("value_comparison.tooltip.measurements");
    let body = `##### ${measurementsTitle}\n${measurementList}`;
    if (alertsList !== "") {
      const alertsTitle = t("value_comparison.tooltip.alerts");
      body += `\n\n##### ${alertsTitle}\n${alertsList}`;
    }
    return body;
  };

  // Combines multiple measurements that might potentially land in a single cell.
  // If there are multiple values "<min> - <max>" will be displayed on the cell
  // and all values listed in the tooltip (caption)
  const combineCells = (cell: Cell, inAlertSection: InAlertSection) =>
    emptyCell(cell) || {
      value: combineValuesOfCells(cell),
      positivity: min(map(cell, "relative_positivity")),
      caption: markdownCaption(cell),
      markdown_caption: true,
      icon: alertIcon(cell, inAlertSection),
    };

  // Add number of alerts in this column -field
  const overviewField = (column: Cell) => {
    const cells = values(groupBy(column, "id"));
    const numberOfAlerts = cells.filter(cell =>
      some(cell, cellAtom => sourceHasAlert(cellAtom, true)),
    ).length;
    return {
      [OVERVIEW_FIELD_ID]: {
        value: t(
          `value_comparison.overview_has${
            numberOfAlerts === 0 ? "_no" : ""
          }_alerts`,
          { number_of_alerts: numberOfAlerts },
        ),
        icon: numberOfAlerts > 0 ? "bell" : null,
      },
    };
  };

  // One column normally corresponds to one InputFormAnswer
  // Group all the data from single day to a single pseudo-form-answer
  const computeFormAnswers = (
    columns: Cell[],
    hasOverviewRow: boolean,
    inAlertSection: InAlertSection,
  ) => {
    return columns.map((column, idx) => ({
      id: `pseudoForm${idx}`,
      date: column[0].date,
      fields: {
        ...mapValues(groupBy(column, "id"), groupped =>
          combineCells(groupped, inAlertSection),
        ),
        ...(hasOverviewRow ? overviewField(column) : {}),
      },
    }));
  };

  // Divide non-alerting and alerting rows to different sections
  // Display overview section (how many triggered alerts in current colummn), if there is alert section
  const computeSections = (
    symptom_table_rows: SymptomTableRow[],
    hasOverviewRow: boolean,
  ) => {
    const [alertRows, nonAlertRows] = splitBy(symptom_table_rows, isAlertRow);
    const allSections: Section[] = [];

    if (hasOverviewRow) {
      allSections.push({
        id: OVERVIEW_SECTION_ID,
        name: null,
        questions: [
          {
            text: t("value_comparison.overview"),
            id: OVERVIEW_FIELD_ID,
          },
        ],
      });
    }
    if (alertRows.length) {
      allSections.push({
        id: ALERT_ROWS_SECTION_ID,
        name: t("value_comparison.with_alerts"),
        questions: sortBy(alertRows, ({ name }) => name.toUpperCase()).map(
          ({ id, name: text }) => ({ id, text }),
        ),
      });
    }
    if (nonAlertRows.length) {
      allSections.push({
        id: NON_ALERT_ROWS_SECTION_ID,
        name: t("value_comparison.without_alerts"),
        questions: sortBy(nonAlertRows, ({ name }) => name.toUpperCase()).map(
          ({ id, name: text }) => ({ id, text }),
        ),
      });
    }
    // Display section titles only if there are multiple sections
    if (allSections.length === 1) allSections[0].name = null;
    return allSections;
  };

  // Source data format:
  // symptom_table_rows: [symptom_table_row]
  // symptom_table_row = {name, id, item_type: "DataPoint", data: [symptom_table_row_data]}
  // symptom_table_row_data = {description, grade: <actual value>, date: <YYYY-MM-DD>, relative_positivity, sources: [source_item] }
  // source_item = {form: <name of the form>, reported_by: <Person.full_name>, time: <Full stamp>, data_points: [data_point]}
  // data_point = {title, content, unit: <unit used>, alerts: [<AlertConditionSerializer], configured_alerts: [<same>]}
  //
  // Target data format:
  // {
  //   input_form_answers: [ // Must contain all datapoints from one day here, or you get multiple columns per day
  //     {id, date, fields: {<External id>: {value: <Cell content>, positivity, caption: <Tooltip content>, icon}}}
  //   ],
  //   meta: {
  //     form: {
  //       sections: [ // Determines titles and order of the rows
  //         {name, questions: {id: <External id>, name: <First column "row header">}}
  //       ]
  //     },
  //     legend: {<Icon name>: <Text explanation>}
  //   }
  // }
  return ({ symptom_table_rows }: Data) => {
    const hasOverviewRow = some(symptom_table_rows, isAlertRow);
    const sections = computeSections(symptom_table_rows, hasOverviewRow);
    // Query if question is in alert section
    const inAlertSection = ({ id }: CellItem) => {
      const alertSectionQuestions = get(
        find(sections, { id: ALERT_ROWS_SECTION_ID }),
        "questions",
      );
      return Boolean(find(alertSectionQuestions, { id }));
    };
    const transposed = transposeAndFlatten(symptom_table_rows);
    return {
      input_form_answers: computeFormAnswers(
        transposed,
        hasOverviewRow,
        inAlertSection,
      ),
      meta: {
        form: {
          name: null,
          scores: [],
          sections,
        },
        legend: hasOverviewRow
          ? {
              ok_circle: t("value_comparison.legend.ok_circle"),
              question_circle: t("value_comparison.legend.question_circle"),
              bell: t("value_comparison.legend.bell"),
            }
          : {},
      },
    };
  };
};
