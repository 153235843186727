import UAParser from "ua-parser-js";

export const getUserAgent = () => navigator.userAgent;

// Extend the UA Parser rules to explictly detect the iOS and Android apps as
// mobile devices
const kaikuMobileRule = [
  [/KaikuHealth-Mobile\/([\w.]+)/i],
  [[UAParser.DEVICE.TYPE, UAParser.DEVICE.MOBILE]],
];

export const mobileDevice = () => {
  const parser = new UAParser(getUserAgent(), { device: kaikuMobileRule });
  const device = parser.getDevice();

  return (
    !!device.type && (device.type === "mobile" || device.type === "tablet")
  );
};

export const mobileUA = mobileDevice();
