import React, { useEffect, useRef, useState } from "react";
import { Viewport } from "common/epics/viewport";
import {
  HeadingContainer,
  PanelContainer,
  PanelContentContainer,
  PanelContent,
} from "./Panel.styles";

export type PanelType = "plain" | "highlight";

export interface IProps {
  children?: React.ReactNode;
  type?: PanelType;
  noPadding?: boolean;
  noBorder?: boolean;
  heading?: React.ReactChild;
  className?: string;
  id?: string;
  targetId?: string;
  // Makes content overflow visible instead of auto
  overflowVisible?: boolean;
  // Makes panel headers sticky
  sticky?: boolean;
  // Makes panel to have position fixed so tabs can be set to be sticky
  centered?: boolean;
  // Makes the panel content use the full width of the container
  fullWidth?: boolean;
  newSpacing?: boolean;
  spacing2020?: boolean;
  columns?: number;
  role?: string;
}

/** Panel is a component for creating layout boxes with different styling options. */
const Panel = (props: IProps) => {
  const [width, setWidth] = useState<number | null>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  //private node: any;

  function updateWidth() {
    if (props.sticky) {
      const element = nodeRef.current;
      if (element) {
        // TODO calculate scrollbar offset programmatically instead of using 18, which just seems
        // to work with different browsers
        setWidth(element.clientWidth - 18);
      }
    }
  }

  function determineWidth() {
    if (width === null) {
      return undefined;
    } else {
      return width + "px";
    }
  }

  const {
    heading,
    children,
    className,
    columns,
    centered,
    fullWidth,
    overflowVisible,
    noPadding,
    noBorder,
    sticky,
    newSpacing,
    spacing2020,
    type = "plain",
    id,
    targetId,
    ...rest
  } = props;

  const viewportWidth = document.documentElement.clientWidth;
  const columnCount = columns === undefined ? 0 : columns;
  const panelContentWidth =
    columnCount > 1 && viewportWidth > Viewport.MEDIUM
      ? `${100 / columnCount}`
      : undefined;

  return (
    <PanelContainer
      className={className}
      ref={nodeRef}
      fullWidth={!!fullWidth}
      noPadding={!!noPadding}
      noBorder={!!noBorder}
      sticky={!!sticky}
      newSpacing={!!newSpacing}
      spacing2020={!!spacing2020}
      type={type}
      id={id}
      {...rest}
    >
      <PanelContentContainer centered={centered}>
        {!!heading && (
          <HeadingContainer width={determineWidth()}>
            {React.isValidElement(heading) ? (
              heading
            ) : (
              <h2 id={targetId}>{heading}</h2>
            )}
          </HeadingContainer>
        )}
        {children && (
          <PanelContent
            fullWidth={!!fullWidth}
            overflowVisible={!!overflowVisible}
            tabContent={columnCount > 1}
            width={panelContentWidth}
          >
            {children}
          </PanelContent>
        )}
      </PanelContentContainer>
    </PanelContainer>
  );
};

export default Panel;
