import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { getStaff } from "common/actions/staff";
import { getClientStaff, clearClient, assign } from "hcp/actions/staff";
import { Spinner } from "@netmedi/frontend-design-system";
import { tap } from "common/utils/general";
import { countBy, intersection, get } from "lodash";
import UserDropdown from "../UserDropdown";
import { RootState } from "store";
import { getTeams } from "hcp/actions/teams";
import { SiteSettings } from "common/utils/holvikaari";

const mapStateToProps = (state: RootState) => ({
  staff: state.staff,
  clientStaff: state.client.staff,
  user: state.user,
  roles: state.user.assignable_roles,
  teams: state.teams,
});

const mapDispatchToProps = {
  assign,
  getClientStaff,
  getStaff,
  clearClient,
  getTeams,
};

type Props = ConnectedProps<typeof connector> & {
  issue: Record<any, any>;
  onAssign: (...args: any) => any;
  updatePosition?: () => void;
};

// eslint-disable-next-line
const AssignDropdown = (props: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { staff, getClientStaff, getStaff, issue, teams, getTeams } = props;
    const maybeFetchStaff = staff.length ? Promise.resolve() : getStaff();
    const maybeFetchTeams =
      teams && teams.length ? Promise.resolve() : getTeams();
    Promise.all([
      maybeFetchStaff,
      getClientStaff(issue.issuer_id, false),
      maybeFetchTeams,
    ]).then(() => setLoading(false));
    return () => {
      props.clearClient();
    };
  }, []);

  function assignToPerson(person: any) {
    return assignTo({ assignee_id: person.id });
  }

  function assignToRole(role: any) {
    return assignTo({ role: role.id });
  }

  function assignTo(personOrRoleOrTeam: any) {
    return props
      .assign(props.issue, personOrRoleOrTeam)
      .then(tap(props.onAssign));
  }

  function assignToTeam(team: any) {
    return assignTo({ team: team.id });
  }

  if (loading)
    return (
      <div>
        <Spinner noPadding />
      </div>
    );

  // Filter out assignees who don't share a domain with the issue
  // Cross domain assigns are valid, but unneccessary in most use cases
  // This is a convinience feature implemented only in frontend
  if (
    !SiteSettings.cross_domain_data_sharing &&
    get(props, "issue.domains.length") > 0
  ) {
    const filterAssigneesByDomain = (hcp: any) =>
      intersection(hcp.domains, props.issue.domains).length > 0;
    props = {
      ...props,
      staff: props.staff.filter(filterAssigneesByDomain),
      clientStaff: props.clientStaff.filter(filterAssigneesByDomain),
    };
  }

  const { staff, clientStaff } = props;

  const roleCounts = countBy(clientStaff, "role");
  const roles = props.roles
    ?.filter(role => roleCounts[role.name])
    .map(role => ({
      id: role.id,
      full_name: role.name,
      count: roleCounts[role.name] || 0,
    }));
  const teams = props.teams;

  const content = [
    {
      title: "issue.client_staff",
      items: clientStaff,
      onClick: assignToPerson,
    },
    {
      title: "issue.roles",
      items: roles ?? [],
      onClick: assignToRole,
      noSearch: true,
    },
    ...(teams.length > 0
      ? [
          {
            title: "issue.teams",
            items:
              teams.map(team => ({
                full_name: team.name,
                id: team.id as number,
                type: team.type ?? "Team",
                abbreviation: team.abbreviation,
                description: team.description,
              })) ?? [],
            onClick: assignToTeam,
            noSearch: true,
          },
        ]
      : []),
    {
      title: "issue.all_staff",
      items: staff,
      onClick: assignToPerson,
    },
  ];

  return (
    <UserDropdown
      onClick={assignToPerson}
      items={content}
      updatePosition={props.updatePosition}
    />
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AssignDropdown);
