import { cloneDeep, find, isEmpty, mapValues, omit, values } from "lodash";
import dayjs from "dayjs";
import { getVisibleQuestions } from "shared/utils/inputForm";
import {
  InputForm as InputFormModel,
  Section,
  Question,
  SubQuestion,
} from "shared/models/inputForm.types";
import { InputFormAction } from "shared/actions/inputForm";

const questionWithRelatives = (
  questions: Question[],
  q: SubQuestion | Question,
) => {
  const parent =
    find(questions, { external_id: (q as SubQuestion).parent_field_id }) || q;
  return [parent.external_id, ...((parent as Question).sub_question_ids || [])];
};

type QuestionId = Question["id"];

function isVisible(question: Question | SubQuestion, visibleIds: QuestionId[]) {
  return (
    visibleIds.includes(question.external_id) ||
    visibleIds.includes((question as SubQuestion).parent_field_id as QuestionId)
  );
}

function clearAnswersAndErrorsIfInvisible(
  questions: Question[],
  visibleIds: (string | number)[],
): Question[] {
  return questions.map(q => {
    if (isVisible(q, visibleIds)) {
      // Keep the question as it is.
      return q;
    } else {
      // Omit both data_point and error(s) if any in case question is not visible.
      return omit(q, "data_point", "errors") as Question;
    }
  });
}

function clearAnswersAndErrorsInSections(
  state: InputFormModel,
  sections: Record<string, Section>,
): Record<string, Section> {
  const allQuestions = values(sections)
    .map(s => s.questions)
    .reduce((a, b) => a.concat(b));

  const ids = getVisibleQuestions(state, allQuestions).map(q => q.external_id);

  return mapValues(sections, s => ({
    ...s,
    questions: clearAnswersAndErrorsIfInvisible(s.questions, ids),
  }));
}

const updateQuestion = (state: InputFormModel, data: any): InputFormModel => {
  if (isEmpty(state)) return state;

  const questions = state.sections[state.activeSection].questions;
  const sections = {
    ...state.sections,
    [state.activeSection]: {
      ...state.sections[state.activeSection],
      questions: questions.map(q =>
        Object.assign({}, q, {
          data_point: data.data_points[q.external_id] || q.data_point,
          // Clear old errors if question or one of the sub questions received a data point
          errors: questionWithRelatives(questions, q).some(
            e_id => data.data_points[e_id],
          )
            ? data.dp_errors[q.external_id]
            : data.dp_errors[q.external_id] || q.errors,
        }),
      ),
    },
  };

  return {
    ...state,
    nextSection: data.next_section,
    prevSection: data.prev_section,
    sections: clearAnswersAndErrorsInSections(state, sections),
    errors: data.dp_errors,
  };
};

const redirectStateAfterCompletion = (
  state: InputFormModel,
  data: any,
): InputFormModel => {
  const { status, flash_warning } = data.finishedResponse;
  const newInputFormState = cloneDeep(state);
  newInputFormState.redirecting = true;

  if (data.app.holvikaari) {
    newInputFormState.forceNavigateOutsideReact = true;
    newInputFormState.redirectPath = state.links?.completed;
  } else if (state.links?.feedback) {
    newInputFormState.redirectPath = `/input_form_answers/${state.answer_id}/feedback`;
  } else {
    newInputFormState.flash = {
      status,
      flash_warning: flash_warning ? "alert" : "success",
      persist_over_first_page_change: true,
    };
    newInputFormState.redirectPath = "/";
  }
  return newInputFormState;
};

const updateOptimisticQuestion = (
  state: InputFormModel,
  data: any,
): InputFormModel => {
  const old_data_point = data[0].data_point || {};
  const field_id = data[0].id;
  const choice = typeof data[1] === "string" ? 1 : data[1];

  const questions = state.sections[state.activeSection].questions;

  const sections = {
    ...state.sections,
    [state.activeSection]: {
      ...state.sections[state.activeSection],
      questions: questions.map(q => {
        if (q.id === field_id) {
          return Object.assign({}, q, {
            data_point: {
              ...old_data_point,
              field_id: field_id,
              choice: choice,
            },
            errors: old_data_point.dp_errors,
          });
        }
        return q;
      }),
    },
  };

  return {
    ...state,
    sections: clearAnswersAndErrorsInSections(state, sections),
  };
};

const resetErrors = (state: InputFormModel): InputFormModel => ({
  ...state,
  lastSubmit: dayjs().valueOf(),
  sections: mapValues(state.sections, s => ({
    ...s,
    questions: s.questions.map(q => omit(q, "errors")) as Question[],
  })),
});

export default (
  state = {} as InputFormModel,
  action: InputFormAction,
): InputFormModel => {
  switch (action.type) {
    case "SET_FORM":
      return {
        ...action.data,
        sections: {},
        activeSection: action.data.initial_section_id,
      };
    case "SET_FORM_SECTION":
      return {
        ...state,
        activeSection: (
          [
            [action.background, state.activeSection],
            [action.initialLoad, state.initial_section_id],
            [true, action.data.index],
          ] as [boolean, number][]
        ).find(([selector]) => selector)?.[1] as number,
        ...(!action.background
          ? {
              nextSection: action.next_section,
              prevSection: action.prev_section,
              sectionsCount: action.sections_count,
              sectionsDone: action.sections_done,
              visibleSummarySections: action.visible_summary_sections ?? [],
            }
          : { nextSection: state.nextSection, prevSection: state.prevSection }),
        sections: Object.assign({}, state.sections, {
          [action.data.index]: action.data,
        }),
      };
    case "SECTION_LOCK":
      return { ...state, locked: action.data.lock };
    case "CHANGE_SECTION":
      return resetErrors(state);
    case "SET_FORM_ANSWER_STATUS":
      return updateQuestion(state, action.data);
    case "SET_OPTIMISTIC_FORM_ANSWER_STATUS":
      return updateOptimisticQuestion(state, action.data);
    case "LEAVE_FORM":
      return {} as InputFormModel;
    case "FINISH_FORM_ANSWER":
      return redirectStateAfterCompletion(state, action.data);
    default:
      return state;
  }
};
