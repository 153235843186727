import styled from "styled-components";
import rtl from "styled-components-rtl";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";

export const ProgramChip = styled.div`
  ${rtl`margin-right: 12px;`}
  min-width: 60px;
  max-width: 60px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const ChipAndInfo = styled.div`
  display: flex;
  justify-content: start;
  min-height: 48px;
`;

export const TeamDescription = styled.div`
  color: ${colors.gray950};
`;
