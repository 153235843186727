import { connect } from "react-redux";
import { getUserAppointments } from "client/actions/calendar";
import { RootState } from "store";
import CalendarEntriesContainer from "hcp/components/CalendarEntries/CalendarEntriesContainer";

const mapStateToProps = (state: RootState) => ({
  entries: state.calendar,
});

export default connect(mapStateToProps, {
  getUserAppointments,
})(CalendarEntriesContainer);
