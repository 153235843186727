import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

/**
 * This hook helps creating a selector which depends on a component state
 * @param selectorCreator a function which returns a selector
 * @param args to pass for the selector creator
 * @returns memoized selector
 */
const useSelectorMemo = (
  selectorCreator: (...args: any[]) => (state: RootState) => any,
  ...args: any[]
) => {
  const selector = useCallback(selectorCreator(...args), [...args]);
  return useSelector(selector);
};

export default useSelectorMemo;
