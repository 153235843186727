import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";

import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import {
  colors,
  typography,
} from "@netmedi/frontend-design-system/dist/styles/variables";

export const BorderArcWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const BorderArcBefore = styled.span`
  box-sizing: border-box;
  display: block;
  border: solid 4px navy;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
`;

export const BorderArc = styled.div`
  overflow: hidden;
  position: absolute;
  top: -1px;
  bottom: 50%;
  ${rtl`
    right: 50%;
    left: -1px;`}
  transform-origin: 100% 100%;
`;

export const ScoreSeverity = styled.span`
  display: inline-block;
  width: ${rem(48)};
  height: ${rem(48)};
  line-height: ${rem(48)};
  border-radius: 50%;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  text-align: center;
  vertical-align: middle;
  margin: auto;
  font-size: ${typography.fontSizeSmall};
  background: ${colors.white};
`;

export const ScoreSeverityInnerWrap = styled.span`
  position: absolute;
  top: 0;
  ${rtl`left: 0;`}
  width: 100%;
`;

type UnderLineProps = {
  $underline?: boolean;
};

export const UnderLineWrapper = styled.span<UnderLineProps>`
  ${({ $underline }) =>
    $underline &&
    css`
      text-decoration: underline;
    `}
`;

export const CalendarEntry = styled.div.attrs(() => ({
  "data-testid": "calendar-entry",
}))`
  background-color: ${colors.blue500};
  width: 100%;
  height: 50%;
  color: ${colors.white};
  z-index: 2;
  position: relative;
`;
