import React from "react";
import cn from "classnames";
import { StyledDisc } from "./ProgressBar.styles";
import { injectIntl, IntlShape } from "react-intl";

export type DiscProps = {
  className?: string;
  value: number;
  max: number;
  visualizeSeverity?: boolean;
  size?: "small" | "medium" | "large";
};

const Disc = ({
  className,
  value,
  max,
  intl,
  visualizeSeverity = false,
  size = "small",
}: DiscProps & { intl: IntlShape }) => {
  const label = `${intl.messages["attributes.reported_grade.grade"]} ${value}`;
  return (
    <div
      className={cn(className)}
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={value}
      aria-label={label}
      role="progressbar"
    >
      {[...Array(max).keys()].map(i => (
        <StyledDisc
          key={i}
          $size={size}
          $visualizeSeverity={visualizeSeverity}
          $fill={value}
          $filled={i < value}
        />
      ))}
    </div>
  );
};

export default injectIntl(Disc);
