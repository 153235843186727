import { ConfirmProps } from "shared/components/Modal/Confirm";

export type ModalAction =
  | {
      type: "ALERT" | "CONFIRM" | "LOADER" | "OPEN_MODAL";
      data: any;
    }
  | {
      type: "DISMISS_MODAL";
    };

export const confirm = (
  options: Omit<ConfirmProps, "type" | "confirm">,
  onConfirm: ConfirmProps["confirm"],
): ModalAction => ({
  type: "CONFIRM",
  data: {
    ...options,
    disableOutsideClick: true,
    confirm: onConfirm,
  },
});

export const loader = (options: any): ModalAction => ({
  type: "LOADER",
  data: {
    ...options,
    disableOutsideClick: true,
  },
});

export const openModal = (data: any): ModalAction => ({
  type: "OPEN_MODAL",
  data,
});

export const dismissModal = (): ModalAction => ({ type: "DISMISS_MODAL" });
