import React, { useState } from "react";
import { Icon, IconExtras } from "@netmedi/frontend-design-system";
import { FormattedMessage, useIntl } from "react-intl";
import { TitleAndBody, Dismiss } from "./Modal.types";
import { endpoints, post } from "common/utils/api";
import { focusOn } from "common/components/Form/utils";
import { Form } from "react-final-form";
import useWindowSize from "shared/hooks/useWindowSize";
import {
  CaregiverCenteredDiv,
  CaregiverModal,
  CaregiverNextButton,
  CaregiverStyledDiv,
  CaregiverSvgImageContainer,
  ErrorContainer,
  ErrorIcon,
  ModalErrors,
  ModalNavBottomFixed,
} from "./Modal.styles";
import { CustomButton } from "client/components/WelcomeClient/AppDownload/AppDownload.styles";
import {
  CaregiverFormContainer,
  Clear,
} from "client/components/Profile/Profile.styles";
import AddCaregiverFormFields from "client/components/Profile/Caregivers/AddCaregiverFormFields";
import { SiteSettings } from "common/utils/holvikaari";
import { getCaregivers } from "client/actions/caregivers";
import { useDispatch, useSelector } from "store";

export type AddCaregiverModalProps = Partial<TitleAndBody> & {
  type: "add_caregiver_modal";
  dismiss: () => void;
};

interface AddCaregiverBodyProps {
  handleOnClick: () => void;
}

const { SvgImage } = IconExtras;

const AddCaregiverPage = ({
  handleOnClick,
}: Pick<AddCaregiverBodyProps, "handleOnClick">) => {
  return (
    <div>
      <CaregiverSvgImageContainer>
        <SvgImage size="xxl" name="caregiver_user" />
      </CaregiverSvgImageContainer>
      <CaregiverCenteredDiv>
        <FormattedMessage tagName="h2" id={"add_caregiver.heading"} />
        <CaregiverStyledDiv>
          <FormattedMessage
            tagName="span"
            id={"modal.add_caregiver_modal.page_1_description"}
          />
        </CaregiverStyledDiv>
      </CaregiverCenteredDiv>
      <ModalNavBottomFixed className="add_caregiver_modal_nav">
        <CaregiverNextButton onClick={handleOnClick}>
          <FormattedMessage tagName="span" id={"modal.continue"} />
        </CaregiverNextButton>
      </ModalNavBottomFixed>
    </div>
  );
};

const InviteCaregiverPage = ({
  handleOnClick,
}: Pick<AddCaregiverBodyProps, "handleOnClick">) => {
  const [error, setError] = useState<string | null>(null);
  const intl = useIntl();
  const submit = async (values: any) => {
    try {
      await post(endpoints.caregiver_invites.create, {
        email: values.email,
        phone: values.phone,
      });

      handleOnClick();
    } catch (error: any) {
      const errorMessage = error.data?.errors
        ? error.data.errors
        : intl.formatMessage({
            id: "invites.new.error_message_for_unknown_error",
          });

      setError(errorMessage);
    }
  };

  return (
    <div>
      <Form
        initialValues={{ email: "", emailConfirmation: "", phone: "" }}
        onSubmit={() => {
          // Do nothing and let the form submission happen via the browser
        }}
      >
        {({ handleSubmit, form }) => (
          <>
            <CaregiverFormContainer
              onSubmit={event => {
                // Run the React Final Form submit handler
                handleSubmit(event);
                event.preventDefault();

                // If there are validation errors, focus on the first one
                if (form.getState().hasValidationErrors) {
                  focusOn(
                    "input[aria-invalid=true], [aria-invalid=true] input",
                  );
                } else {
                  // If there are no validation errors, submit the form
                  submit(form.getState().values);
                }
              }}
            >
              <FormattedMessage
                tagName="h2"
                id={"modal.add_caregiver_modal.invite_caregiver"}
              />
              <FormattedMessage
                tagName="span"
                id={"modal.add_caregiver_modal.page_2_description"}
              />
              <AddCaregiverFormFields />
              {error && (
                <ErrorContainer>
                  <ErrorIcon name="alert_circle" />
                  <ModalErrors>
                    <span>{error}</span>
                  </ModalErrors>
                </ErrorContainer>
              )}
              <ModalNavBottomFixed className="add_caregiver_modal_nav">
                <CaregiverNextButton submit>
                  <Icon align="left" size="medium" name="send" />
                  <FormattedMessage
                    tagName="span"
                    id={"invites.new.send_invite"}
                  />
                </CaregiverNextButton>
              </ModalNavBottomFixed>
              <Clear />
            </CaregiverFormContainer>
          </>
        )}
      </Form>
    </div>
  );
};

const InviteSentPage = ({
  handleOnClick,
}: Pick<AddCaregiverBodyProps, "handleOnClick">) => {
  return (
    <div>
      <CaregiverSvgImageContainer>
        <SvgImage size="xxl" name="invitation_sent" />
      </CaregiverSvgImageContainer>
      <CaregiverCenteredDiv>
        <FormattedMessage
          tagName="h2"
          id={"modal.add_caregiver_modal.invitation_sent"}
        />
        <CaregiverStyledDiv>
          <FormattedMessage
            tagName="span"
            id={"modal.add_caregiver_modal.page_3_description"}
            values={{ product_name: SiteSettings.product_name }}
          />
        </CaregiverStyledDiv>
      </CaregiverCenteredDiv>
      <ModalNavBottomFixed className="add_caregiver_modal_nav">
        <CaregiverNextButton onClick={handleOnClick}>
          <FormattedMessage tagName="span" id={"modal.confirm_cap"} />
        </CaregiverNextButton>
      </ModalNavBottomFixed>
    </div>
  );
};

const AddCaregiverModal = ({
  dismiss,
}: Omit<AddCaregiverModalProps, "type"> & Dismiss) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { innerWidth } = useWindowSize();
  const dispatch = useDispatch();
  const isSmallScreen = innerWidth < 640;

  const client = useSelector(({ user }) => user);

  const handleOnClick = () => {
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    } else {
      dispatch(getCaregivers(client.id));
      dismiss();
    }
  };

  return (
    <CaregiverModal
      onClick={(e: any) => e.stopPropagation()}
      role="dialog"
      fullScreen={isSmallScreen}
      narrow
    >
      <CustomButton
        position="absolute"
        right={24}
        top={24}
        margin={0}
        padding={0}
        color="#000"
        type="ghost"
        onClick={() => dismiss()}
      >
        <Icon name="remove" size="small" />
      </CustomButton>
      {currentPage === 1 && <AddCaregiverPage handleOnClick={handleOnClick} />}
      {currentPage === 2 && (
        <InviteCaregiverPage handleOnClick={handleOnClick} />
      )}
      {currentPage === 3 && <InviteSentPage handleOnClick={handleOnClick} />}
    </CaregiverModal>
  );
};

export default AddCaregiverModal;
