import { setLoadingStatus } from "common/actions/loading";
import {
  LoadingStateOption,
  RequestStatus,
  selectLoadingStatus,
} from "common/reducers/loading";
import { useDispatch } from "react-redux";
import useSelectorMemo from "./useSelectorMemo";

/**
 * Save status of an API reqest in redux to prevent fetching the same data multiple times.
 * @param requestType Type of the request
 * @param entityId Optional id for the entity, using "all" by default
 * @returns [loadingStatus, setLoadingStatus]
 */
const useRequestStatus = (
  requestType: LoadingStateOption,
  entityId?: string,
): [RequestStatus, (status: RequestStatus) => void] => {
  const dispatch = useDispatch();
  const value = useSelectorMemo(
    selectLoadingStatus,
    requestType,
    entityId ?? "all",
  ) as LoadingStateOption;

  const setStatus = (status: RequestStatus) => {
    dispatch(setLoadingStatus(requestType, status, entityId));
  };

  return [value as RequestStatus, setStatus];
};

export default useRequestStatus;
