import React from "react";
import { isSameInterval } from "../SymptomTableCompact/isSameInterval";
import { StepsState } from "../SymptomTableCompact/types";
import { ValidInterval } from "./SymptomTable";
import {
  DownIcon,
  Steps,
  StepsCount,
  StepsWrapper,
  UpIcon,
} from "./SymptomTable.styles";
import { Dayjs } from "dayjs";

export type SymptomTableStepsProps = {
  columnTimes: Dayjs[][];
  interval: ValidInterval;
  steps: StepsState;
};

export const SymptomTableSteps = ({
  columnTimes,
  interval,
  steps,
}: SymptomTableStepsProps): JSX.Element => {
  return (
    <>
      {columnTimes.map(([start_date, end_date], idx) => {
        const isMatchingColumn = isSameInterval({
          start_date: start_date.valueOf(),
          end_date: end_date.valueOf(),
          interval,
        });
        const matchedSteps = steps.data.observations.find(isMatchingColumn);
        if (matchedSteps) {
          const valueType = interval === "day" ? "value" : "daily_average";
          const index = steps.data.observations.findIndex(isMatchingColumn);
          const prev = steps.data.observations[index - 1];
          const isBigger =
            prev !== undefined && matchedSteps[valueType] > prev[valueType];
          const isSmaller =
            prev !== undefined && matchedSteps[valueType] < prev[valueType];
          return (
            <StepsWrapper key={"steps-" + idx}>
              <Steps>
                <UpIcon
                  name="arrowhead_up_16px"
                  size="small"
                  $hidden={!isBigger}
                />
                <StepsCount>{matchedSteps[valueType]}</StepsCount>
                <DownIcon
                  name="arrowhead_down_16px"
                  size="small"
                  $hidden={!isSmaller}
                />
              </Steps>
            </StepsWrapper>
          );
        }
        return <StepsWrapper key={"steps-" + idx} />;
      })}
    </>
  );
};
