import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";

export const InitialsIcon = styled.span`
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  background: ${colors.yellow100};
  font-family: "Graphik", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${colors.black};
  outline-offset: 2px;
  ${rtl`direction: ltr;`}
  cursor: pointer;

  &:hover {
    outline: 1px solid ${colors.black};
  }

  &:focus {
    outline: 2px solid ${colors.blue500};
  }
`;

export const CaregiverInitialsIcon = styled.span`
  border-radius: 100%;
  width: 42px;
  height: 42px;
  background: ${colors.yellow100};
  font-family: "Graphik", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: ${rem(18)};
  margin: 0.1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${colors.black};
  outline-offset: 2px;
  ${rtl`direction: ltr;`}

  &:focus {
    outline: 2px solid ${colors.blue500};
  }
`;
