import { dataTableActions } from "common/reducers/data_table";
import { DataTableName } from "common/containers/DataTable";
import { get, parseJson } from "common/utils/api";

export const startLoading =
  (name: DataTableName, endpoint: (...args: any[]) => any) => (params: any) =>
    dataTableActions.startLoading({
      name,
      data: { params, endpoint: endpoint(params) },
    });

export const fetchTableData = ({
  payload: {
    name,
    data: { endpoint, params },
  },
}: ReturnType<typeof dataTableActions.startLoading>) =>
  get(endpoint)
    .then(data => parseJson(data, { mapRelations: true }))
    .then(({ data, total_pages, page }: any) =>
      dataTableActions.setContent({
        name,
        data: {
          items: data,
          page: {
            number: page,
            size: params.page.size,
          },
          total_pages,
          filter: params.filter,
          query: params.query,
          loading: false,
        },
      }),
    );
