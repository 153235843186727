/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from "react";
import FormField, { PlainFormField } from "common/components/Form/FormField";
import DatePicker from "common/components/Form/DatePicker";
import { Field, useForm, useFormState } from "react-final-form";
import useI18nTranslate from "common/hooks/useI18nTranslate";
import { useSelector } from "react-redux";
import { selectStaff } from "common/reducers/staff";
import {
  AbsenceFieldsStyled,
  HelpText,
  HorizontalContainer,
  TextMuted,
} from "./Absences.styles";
import AbsencePersonSelect from "./AbsencePersonSelect";
import dayjs, { Dayjs } from "dayjs";

const domainsOverlap = (a: string[], b: string[]) => {
  return a.some(domain => b.includes(domain));
};

const AbsenceFields: React.FC = () => {
  const t = useI18nTranslate();
  const staff = useSelector(selectStaff);
  const absentPersonOptions = useMemo(
    () =>
      staff.map(s => ({
        value: s.id,
        label: s.full_name,
        formattedLabel: (
          <>
            {s.full_name}
            <TextMuted> - {s.role}</TextMuted>
          </>
        ),
      })),
    [staff],
  );
  const { values } = useFormState();
  const { change } = useForm();
  const absentPerson = staff.find(s => s.id === values.absent_person_id);

  const substitutePersonOptions = staff
    .filter(
      substitute =>
        substitute.id !== values.absent_person_id &&
        domainsOverlap(substitute.domains, absentPerson?.domains ?? []),
    )
    .map(s => ({
      value: s.id,
      label: s.full_name,
      formattedLabel: (
        <>
          {s.full_name}
          <TextMuted> - {s.role}</TextMuted>
        </>
      ),
    }));

  useEffect(() => {
    if (values.substitute_person_id === values.absent_person_id) {
      change("substitute_person_id", undefined);
    }
  }, [values.absent_person_id]);

  const validateAbsentPerson = (absent_person_id: number) =>
    !absent_person_id
      ? t("absences.validation.absent_person_required")
      : undefined;
  const validateSubstitutePerson = (substitute_person_id: number) =>
    !substitute_person_id
      ? t("absences.validation.substitute_required")
      : undefined;
  const validateBeginDate = (value: Dayjs) => {
    if (!value || !value.isValid()) {
      return t("absences.validation.begin_date_required");
    }
  };
  const validateEndDate = (value: Dayjs, { begin_date }: any) => {
    if (!value || !value.isValid()) {
      return t("absences.validation.end_date_required");
    }
    if (begin_date && value < begin_date) {
      return t("absences.validation.end_date_after_begin_date");
    }
    if (value?.isBefore(dayjs().startOf("day"))) {
      return t("absences.validation.end_date_in_past");
    }
  };

  return (
    <AbsenceFieldsStyled data-testid="AbsenceFields">
      {/* ABSENT PERSON SELECT */}
      <AbsencePersonSelect
        name="absent_person_id"
        options={absentPersonOptions}
        validate={validateAbsentPerson}
      />

      {/* SUBSTITUTE PERSON SELECT */}
      <AbsencePersonSelect
        name="substitute_person_id"
        options={substitutePersonOptions}
        validate={validateSubstitutePerson}
      />
      <HelpText>{t("absences.substitute_help")}</HelpText>

      <HorizontalContainer>
        {/* BEGIN DATE */}
        <Field name="begin_date" validate={validateBeginDate}>
          {fieldProps => (
            <FormField
              name="begin_date"
              labelOnTop
              showRequiredIndicator
              required
              {...fieldProps}
            >
              <DatePicker
                {...fieldProps.input}
                fullWidth
                onChange={date =>
                  date ? fieldProps.input.onChange(date) : undefined
                }
              />
            </FormField>
          )}
        </Field>

        {/* END DATE */}
        <Field name="end_date" validate={validateEndDate}>
          {fieldProps => (
            <FormField
              name="end_date"
              labelOnTop
              showRequiredIndicator
              required
              {...fieldProps}
            >
              <DatePicker
                {...fieldProps.input}
                fullWidth
                onChange={date =>
                  date ? fieldProps.input.onChange(date) : undefined
                }
              />
            </FormField>
          )}
        </Field>
      </HorizontalContainer>

      {/* DESCRIPTION */}
      <PlainFormField
        name="description"
        labelOnTop
        label={"absences.description"}
      />
    </AbsenceFieldsStyled>
  );
};

export default AbsenceFields;
