import { stringify } from "qs";
import { Params, EndpointParams } from "../api2.types";
import { SiteSettings } from "common/utils/holvikaari";
import { endpoints, get } from "common/utils/api";
import { parseJsonRelations } from "common/utils/json_api";
import store from "store";

const calendar = ({ calendar_api_url }: Record<string, string>) => {
  const { katedraali_calendar } = store.getState()?.user?.features ?? {};
  const useKatedraali =
    calendar_api_url &&
    (katedraali_calendar || process.env.NODE_ENV === "test");
  const baseUrl = (userId: number) =>
    calendar_api_url &&
    `${calendar_api_url}/calendar_entries/${SiteSettings.site}/${userId}`;
  return {
    appointments: ({ userId }: EndpointParams["appointments"]) => {
      // new implementation: /calendar_entries/:siteId/:userId/appointments
      // old implementation: /rest/calendars/:userId/appointments
      const url = useKatedraali
        ? `${baseUrl(userId)}/appointments`
        : endpoints.appointments(userId.toString());

      return {
        get: (params: Params["appointments"] = {}) =>
          // the following line may get "??" in string because of buggy qs.stringify, maybe use URLSearchParams instead
          get(`${url}?${stringify(params)}`.replace("??", "?")).then(
            parseJsonRelations,
          ),
      };
    },
  };
};

export default calendar;
