import styled from "styled-components";
import {
  spacing,
  mediaQueries,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { mediaMax } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";
import rtl from "styled-components-rtl";

export const MarkdownContainer = styled.div`
  li {
    list-style-type: disc;
    ${rtl`margin-left: ${spacing.m}px;`}
  }

  ul,
  ol {
    margin-bottom: ${spacing.s}px;
    margin-top: ${spacing.s}px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${mediaMax(mediaQueries.small)} {
    li {
      ${rtl`margin-left: ${spacing.xs}px;`}
    }
    ul,
    ol {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
  }
`;

export const InlineVideo = styled.div`
  ${rtl`margin-left: ${-spacing.l}px;`}
  ${rtl`margin-right: ${-spacing.l}px;`}
  margin-bottom: ${spacing.s}px;
  margin-top: ${spacing.s}px;

  ${mediaMax(mediaQueries.large)} {
    ${rtl`margin-left: ${-spacing.m}px;`}
    ${rtl`margin-right: ${-spacing.m}px;`}
  }

  ${mediaMax(mediaQueries.medium)} {
    ${rtl`margin-left: 0px;`}
    ${rtl`margin-right: 0px;`}
  }
`;

export const CenteredText = styled.div`
  text-align: center;
`;
