import styled from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import Select from "common/components/Form/Select";

import {
  colors,
  spacing,
  linkHoverColor,
  linkColor,
  scaffolding,
  typography,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { IntervalOption } from "../SymptomTable/SymptomTable";
import { AttachmentBadge } from "../SymptomTable/SymptomTable.styles";

export const SymptomTable = styled.table`
  overflow: visible;
  font-size: ${typography.fontSizeMedium};
  margin-bottom: ${spacing.xs}px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 320px;
`;

export const SymptomNameCell = styled.td`
  position: absolute;
  z-index: 2;
  white-space: nowrap;
`;

export const SymptomName = styled.div`
  position: sticky;
  text-transform: uppercase;
  background-color: white;
  padding: 4px;
  max-width: 90vw;
  overflow-x: hidden;
  text-overflow: ellipsis;
  letter-spacing: 1px;
  ${rtl`left: 0;`}
`;

export const GradeWrapper = styled.td`
  position: relative;
  padding-top: ${rem(34)};
  padding-bottom: ${rem(18)};
  text-align: center;
  background-image: linear-gradient(#e7e7e7, #e7e7e7);
  background-size: 1px 100%;
  background-repeat: no-repeat;
  background-position: center center;

  ${AttachmentBadge} {
    top: ${rem(24)};
    ${rtl`right: ${rem(10)};`}
  }
`;

export const ToggleGradeSymptoms = styled.div`
  padding: 12px;
  background-color: ${colors.yellow50};
  border: 1px solid ${colors.gray300};
  text-align: center;
  cursor: pointer;
  width: 50%;
  min-width: 300px;
  margin: 0 auto ${spacing.xs}px;

  a {
    font-weight: ${scaffolding.bold};
    color: ${linkColor};

    &:hover {
      color: ${linkHoverColor};
    }
  }
`;

export const IntervalSelect = styled(Select<IntervalOption>)`
  width: 50%;
  margin-bottom: 12px;
  position: relative;
  z-index: 4;
`;

export const DateRows = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  flex: 1;
`;

export const DateRowScroller = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const DateRow = styled.div<{ dataIsEmpty: boolean }>`
  padding-top: 16px;
  background-color: white;
  text-align: center;

  ${({ dataIsEmpty }) => dataIsEmpty && `color: ${colors.gray500};`}
`;

export const VerticallyHiddenDateRow = styled.th`
  height: 16px;
  padding: 0;
  background-image: linear-gradient(#e7e7e7, #e7e7e7);
  background-size: 1px 100%;
  background-repeat: no-repeat;
  background-position: center center;

  div {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
    height: 0;
    padding: 0;
  }
`;

export const SymptomNameLabel = styled.span`
  font-size: ${typography.fontSizeMedium};
  line-height: ${rem(22)};
  color: ${colors.gray700};
  text-transform: initial;
  letter-spacing: initial;
  ${rtl`padding-left: ${rem(10)};`}
`;

const gradientStyles = `
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 12vw;
  opacity: 1;
  transition: opacity 150ms linear;
`;

export const ScrollableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: ${spacing.xs}px;

  &:after {
    ${gradientStyles}
    ${rtl`right: 14px;`}
    ${rtl`background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 30%, white 100%);`}
  }

  &[data-hide-right-gradient]::after {
    opacity: 0;
  }

  &:before {
    ${gradientStyles}
    ${rtl`left: 14px;`}
    ${rtl`background-image: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0) 100%);`}
  }

  &[data-hide-left-gradient]::before {
    opacity: 0;
  }
`;
