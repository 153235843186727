import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "@netmedi/frontend-design-system";
import { downloadBlob } from "common/utils/api";
import getApiClient from "common/utils/api2/getApiClient";

import {
  StyledButton,
  Header,
  Container,
  LabelHeading,
  Error,
} from "./DownloadView.styles";

export type Props = {
  path: string;
  mobileNumberEnding: string;
  intl?: any;
};

export function DownloadView({ path, mobileNumberEnding, intl }: Props) {
  const [accessCode, setAccessCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.documentElement.lang = intl?.locale;
  }, [intl]);

  const onSubmit = () => {
    setIsLoading(true);
    getApiClient()
      .ccdaShareDownload({ path })
      .post({ accessCode })
      .then(response => {
        if (!response.ok) {
          setError(`Invalid access code`);
          return;
        }
        return response.blob();
      })
      .then(blob => {
        if (blob) {
          downloadBlob(blob, "patient_records.zip");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container role="main">
      <Header>
        <FormattedMessage id="download_view.header" />
      </Header>
      <p>
        <FormattedMessage
          id="download_view.text"
          values={{ mobileNumberEnding: mobileNumberEnding }}
        />
      </p>
      <p>
        <FormattedMessage id="download_view.refresh" />
      </p>
      <p>
        <FormattedMessage id="download_view.one_time_download" />
      </p>
      <div role="form">
        <LabelHeading htmlFor="accesscode">
          <FormattedMessage id="download_view.label" />
        </LabelHeading>
        <input
          type="number"
          id="accesscode"
          required
          value={accessCode}
          onChange={e => setAccessCode(e.target.value)}
        />
        {error.length > 0 && <Error>{error}</Error>}
        <StyledButton
          submit
          size="small"
          disabled={accessCode.length === 0}
          onClick={onSubmit}
        >
          {isLoading ? (
            <Spinner noPadding />
          ) : (
            <FormattedMessage id="download_view.button" />
          )}
        </StyledButton>
      </div>
    </Container>
  );
}

export default DownloadView;
