// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import dayjs from 'dayjs';
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(updateLocale);

export function loadLocale(locale: string) {
  return fetch(`/locales/${locale}.json`).then(res => res.json());
}

type NestedMessages = {
  [key: string]: unknown | NestedMessages
}

export function flattenMessages(nestedMessages: NestedMessages, prefix = "") {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      messages[prefixedKey] = value;
    } else if (value) {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {} as Record<string, string>);
}

// Disable the preformat and postformat functions for the Arabic locale,
// which would cause the numbers to be formatted as Eastern Arabic numerals.
const setArabicNumbers = () => {
  const identity = (x: any) => x;
  dayjs.updateLocale("ar", { postformat: identity, preparse: identity });
};

export function initIntl() {
  setArabicNumbers();
}
