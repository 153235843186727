import React from "react";
import { Wrapper } from "./Label.styles";

/**
  Use a label to emphasize classification.
*/
const Label = ({ label, size = "medium", className, ...rest }: Props) => (
  <Wrapper {...rest} size={size} className={className}>
    {label}
  </Wrapper>
);

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  /** Label text to be shown */
  label?: string;
  /** Size of the component */
  size?: "small" | "medium" | "large";
  className: string;
};

export default Label;
