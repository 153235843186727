import React from "react";
import {
  BodyMapContainer,
  BodyMapSvgContainer,
  BodyMapHeading,
  BodyMapText,
} from "./Bodymap.styles";
import { BodymapProps } from "./BodymapQuestion.types";
import { injectIntl } from "react-intl";
import DefaultFrontSvg from "./svg/DefaultFrontSvg";
import SkinChangesFrontSvg from "./svg/SkinChangesFrontSvg";
import GenericPainFrontSvg from "./svg/GenericPainFrontSvg";
import JointPainFrontSvg from "./svg/JointPainFrontSvg";
import LymphNodesSvg from "./svg/LymphNodesSvg";

const BodymapFront = (props: BodymapProps) => {
  const { intl, noText, display } = props;
  const textTopPos = display === "Default" ? "25%" : "0";

  return (
    <BodyMapContainer testId={"front"}>
      <BodyMapHeading hidden={noText}>
        {intl.formatMessage({
          id: "body_map.front",
        })}
      </BodyMapHeading>
      <BodyMapSvgContainer>
        <BodyMapText left={"5%"} top={textTopPos} aria-hidden>
          {!noText &&
            intl.formatMessage({
              id: "body_map.right_side",
            })}
        </BodyMapText>

        {
          {
            Default: <DefaultFrontSvg {...props} />,
            SkinChanges: <SkinChangesFrontSvg {...props} />,
            GenericPain: <GenericPainFrontSvg {...props} />,
            JointPain: <JointPainFrontSvg {...props} />,
            LymphNodes: <LymphNodesSvg {...props} />,
          }[display]
        }

        <BodyMapText right={"5%"} top={textTopPos} aria-hidden>
          {!noText &&
            intl.formatMessage({
              id: "body_map.left_side",
            })}
        </BodyMapText>
      </BodyMapSvgContainer>
    </BodyMapContainer>
  );
};

export default injectIntl(BodymapFront);
