import { SymptomItemData } from "client/reducers/client";
import React from "react";
import SymptomDataPoint, { SymptomDataPointType } from "./SymptomDataPoint";
import { SymptomDataTitleStyled, SymptomRowStyled } from "./SymptomPage.styles";

export interface CellContent {
  [date: string]: {
    hasGapBeforeColumn: boolean;
    items: SymptomItemData[];
  };
}

interface SymptomRowProps {
  title: string;
  itemType: SymptomDataPointType;
  symptomData: CellContent;
}

const SymptomRow: React.FC<SymptomRowProps> = ({
  title,
  symptomData,
  itemType,
}) => {
  return (
    <SymptomRowStyled data-test-id="SymptomRow">
      <SymptomDataTitleStyled>{title}</SymptomDataTitleStyled>
      {Object.keys(symptomData)
        .sort((a, b) => (a < b ? -1 : 1))
        .map(date => (
          <SymptomDataPoint
            key={date}
            itemType={itemType}
            columnData={symptomData[date]}
          />
        ))}
    </SymptomRowStyled>
  );
};

export default SymptomRow;
