import { line } from "d3-shape";
import { scaleLinear, scaleTime } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { select } from "d3-selection";
import { extent } from "d3-array";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { ValueChart } from "./ValuePage.styles";
import theme from "@netmedi/frontend-design-system/dist/themes/baseTheme";

export interface FieldData {
  [key: string]: {
    title: string;
    description: string;
    data: DataPoint[];
  };
}

export interface DataPoint {
  time: number;
  value: number;
}

export interface ValuePageProps {
  chartData: FieldData;
}

const CHART_DIMENSIONS = {
  width: 1000,
  height: 500,
};

const drawChart = (
  container: HTMLDivElement,
  data: DataPoint[],
  valueKey: string,
) => {
  // set the dimensions and margins of the graph
  const margin = { top: 10, right: 30, bottom: 50, left: 60 };
  const width = CHART_DIMENSIONS.width - margin.left - margin.right;
  const height = CHART_DIMENSIONS.height - margin.top - margin.bottom;

  // Clear content from possible earlier renders
  select(container)
    .select(`[data-value-key="${valueKey}"]`)
    .selectAll("*")
    .remove();

  const svg = select(container)
    .select(`[data-value-key="${valueKey}"]`)
    .append("svg")
    .attr("width", CHART_DIMENSIONS.width)
    .attr("height", CHART_DIMENSIONS.height)
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  // Init x axis
  const xAxisScale = scaleTime()
    .domain(extent(data, d => d.time) as [number, number])
    .range([0, width]);
  const xAxis = axisBottom(xAxisScale).tickFormat(d =>
    dayjs(d.valueOf()).format("MM/YY"),
  );

  // Init y axis
  const yAxisScale = scaleLinear()
    .domain(extent(data, d => +d.value) as [number, number])
    .range([height, 0]);

  // Draw horizontal grid
  svg
    .append("g")
    .attr("class", "y axis-grid")
    .call(
      axisLeft(yAxisScale)
        .tickSize(-width)
        .tickFormat(() => ""),
    );

  // Draw y axis
  svg.append("g").attr("class", "y axis").call(axisLeft(yAxisScale));

  // Draw x axis
  svg
    .append("g")
    .attr("class", "x axis")
    .attr("transform", `translate(0, ${height})`)
    .call(xAxis)
    .selectAll("text")
    .style("text-anchor", "end")
    .attr("dx", "-0.6em")
    .attr("dy", ".5em")
    .attr("transform", "rotate(-35)");

  // Draw line
  svg
    .append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", "#777")
    .attr("stroke-width", 3)
    .attr(
      "d",
      line<DataPoint>()
        .x(d => xAxisScale(d.time))
        .y(d => yAxisScale(d.value)),
    );

  // Draw dots
  svg
    .append("g")
    .selectAll("dot")
    .data(data)
    .enter()
    .append("circle")
    .attr("cx", d => xAxisScale(d.time))
    .attr("cy", d => yAxisScale(d.value))
    .attr("r", 8)
    .attr("fill", "#000")
    .attr("stroke-width", 4)
    .attr("stroke", theme.colors.white);

  // Dot labels
  svg
    .append("g")
    .selectAll("text")
    .data(data)
    .enter()
    .append("text")
    .attr("class", "dot-label")
    .attr(
      "transform",
      (d, i) => `translate(${i === data.length - 1 ? -10 : 10}, 5)`,
    )
    .attr("x", d => xAxisScale(d.time))
    .attr("y", d => yAxisScale(d.value))
    .style("text-anchor", (_, i) => (i === data.length - 1 ? "end" : "start"))
    .text(d => Math.round(d.value * 100) / 100);
};

const ValuePage: React.FC<ValuePageProps> = ({ chartData }) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    Object.keys(chartData).forEach(k =>
      drawChart(
        chartContainerRef.current as HTMLDivElement,
        chartData[k].data,
        k,
      ),
    );
  }, [chartContainerRef, chartData]);

  return (
    <div
      data-test-id="ValuePage"
      ref={chartContainerRef}
      style={{ background: "#fff" }}
    >
      {Object.keys(chartData).map(valueKey => (
        <ValueChart
          key={valueKey}
          className="value-chart"
          data-value-key={valueKey}
        />
      ))}
    </div>
  );
};

export default ValuePage;
