import React from "react";
import { Extension } from "./Markdown.types";
import Markdown from ".";
import { renderTooltipLinks } from "./tooltip";
import { openModal } from "common/actions/modal";
import store from "store";

export const ANCHOR_REGEX = /<a[^>]+>.+?<\/a>/g;
export const DELIMITER_TAG = "?modal-content?";
export const CALLBACK_NAME = "__Serena_modalCb";
const CONTENT_DELIMITER = `<p>${DELIMITER_TAG}</p>`;

const b64decode = (str: string) => decodeURIComponent(escape(atob(str)));

declare global {
  interface Window {
    [CALLBACK_NAME]: (event: HTMLAnchorElement, content: string) => void;
  }
}

window[CALLBACK_NAME] = (event, content) => {
  const __html = b64decode(content);
  store.dispatch(
    openModal({
      type: "input_form_info_modal",
      body: <Markdown source={__html} options={{ html: true }} />,
    }),
  );
};

const extension: Extension = {
  name: "modal",
  render: content =>
    renderTooltipLinks(content, ANCHOR_REGEX, CONTENT_DELIMITER, CALLBACK_NAME),
  enableInnerMarkdown: true,
};

export default extension;
