import { IntlShape } from "react-intl";

/**
 * Get easier interface for translation
 * @param intl react-intl instance as IntlShape
 * @returns a function to provide translated texts
 * @example const t = i18nTranslate(intl);
 * const translatedText = t("fullname", {first: "Johnny", last: "Cash"});
 */
export const i18nTranslate =
  (intl: IntlShape) =>
  (id: string, values?: { [key: string]: string | number | undefined }) =>
    intl.formatMessage({ id }, values);
