import styled from "styled-components";
import rtl from "styled-components-rtl";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";

export const dropdownHeight = 400;
export const dropdownWidth = 250;
export const searchHeight = 32;
export const searchPadding = 6;

export const Placeholder = styled.div`
  padding: 8px;
`;

export const Results = styled.div`
  max-height: ${dropdownHeight - searchHeight - 2 * searchPadding}px;
  overflow: auto;
`;

export const Content = styled.div`
  margin-top: ${searchHeight + 2 * searchPadding}px;
`;

export const Search = styled.div(rtl`
  background: ${colors.gray50};
  padding: ${searchPadding}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`);

export const SearchField = styled.input``;
