import { CriticalErrorAction } from "shared/actions/app";
import {
  get,
  endpoints,
  parseJson,
  putForm,
  getWithoutPageLoadError,
} from "common/utils/api";
import { InputForm, Question, Section } from "shared/models/inputForm.types";
import { pick, isEmpty, mapValues, values, groupBy, head } from "lodash";

// used in app and inputForm reducers
export type FormOpenLeaveAction =
  | {
      type: "SET_FORM";
      data: InputForm;
    }
  | { type: "LEAVE_FORM" };

// used in inputForm reducer
export type InputFormAction =
  | FormOpenLeaveAction
  | { type: "CHANGE_SECTION" }
  | ({
      type: "SET_FORM_SECTION";
      initialLoad?: boolean;
      data: Section;
    } & (
      | {
          background: false;
          next_section: InputForm["nextSection"];
          prev_section: InputForm["prevSection"];
          sections_count: InputForm["sectionsCount"];
          sections_done?: InputForm["sectionsDone"];
          visible_summary_sections?: InputForm["visibleSummarySections"];
        }
      | {
          background: true;
        }
    ))
  | {
      type: "SECTION_LOCK";
      data: { lock: InputForm["locked"] };
    }
  | {
      type:
        | "HIDE_NAVIGATION"
        | "SET_FORM_ANSWER_STATUS"
        | "SET_OPTIMISTIC_FORM_ANSWER_STATUS"
        | "FINISH_FORM_ANSWER"
        | "OPEN_MODAL";
      data: any;
    };

export enum Direction {
  next = "DIRECTION/NEXT",
  previous = "DIRECTION/PREVIOUS",
  finish = "DIRECTION/FINISH",
}

// used in inputForm epics
export type ChangeSectionAction = {
  type: "CHANGE_SECTION";
  data: { url: string; direction: Direction };
};
export type SyncQuestionsAction = {
  type: "SYNC_QUESTIONS";
  data: [Question, any, boolean][];
};

export const getForm = (id: string) =>
  getWithoutPageLoadError(endpoints.inputForm(id)).then(
    (data): InputFormAction => ({
      type: "SET_FORM",
      ...(parseJson(data) as any),
    }),
  );

export const leaveForm = (): InputFormAction => ({ type: "LEAVE_FORM" });

export const lockSection = (locked: boolean): InputFormAction => ({
  type: "SECTION_LOCK",
  data: { lock: locked },
});

export const changeSection = (
  inputFormAnswerId: string | number,
  sectionId: string | number,
  direction: Direction,
): ChangeSectionAction => {
  return {
    type: "CHANGE_SECTION",
    data: {
      url: endpoints.section(inputFormAnswerId, sectionId),
      direction,
    },
  };
};

export const getSection = (
  url: string,
  initialLoad = false,
  background = false,
) => {
  return get(url).then(
    (data): InputFormAction => ({
      type: "SET_FORM_SECTION",
      initialLoad,
      background,
      ...(parseJson(data, { mapRelations: true }) as any),
    }),
  );
};

const parseSetAnswerResp = (data: any) => {
  data = parseJson(data);
  return {
    data_points: mapValues(groupBy(data.data || [], "field_id"), head),
    dp_errors: mapValues(data.dp_errors, e => values(e)),
    next_section: data.next_section,
    prev_section: data.prev_section,
  };
};

export const syncQuestionAnswers = (questions: any): SyncQuestionsAction => {
  return {
    type: "SYNC_QUESTIONS",
    data: questions,
  };
};

export const setAnswer = (answers: any, ifa_id: number) => {
  return putForm(endpoints.data_points(ifa_id), {
    data_points: values(answers),
  })
    .then(
      (resp): InputFormAction => ({
        type: "SET_FORM_ANSWER_STATUS",
        data: parseSetAnswerResp(resp),
      }),
    )
    .catch(({ data }): InputFormAction | CriticalErrorAction => {
      const respFields = ["data", "meta"];
      const resp = pick(data, respFields);

      if (!isEmpty(resp)) {
        return {
          type: "SET_FORM_ANSWER_STATUS",
          data: parseSetAnswerResp(resp),
        };
      }

      if (data.error && data.error === "time_window_expired") {
        return {
          type: "OPEN_MODAL",
          data: { type: "time_window_modal" },
        };
      }

      // TODO: Less agressive way to recover?
      return {
        type: "CRITICAL_ERROR",
        data,
      };
    });
};

export const hideNavigation = (hide = true): InputFormAction => ({
  type: "HIDE_NAVIGATION",
  data: hide,
});
