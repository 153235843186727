import React from "react";
import { LogoContainer, LogoImg } from "./Logo.styles";
import { SiteSettings } from "common/utils/holvikaari";
import { isNil } from "lodash";

// Injected by build process
const kaikuLogoUrl = isNil(SiteSettings)
  ? `/assets/images/logos/kaiku.png`
  : `/external_assets/${SiteSettings.product_logo}`;

interface IProps {
  alt?: string;
}

const Logo = ({ alt }: IProps) => (
  <LogoContainer>
    <LogoImg alt={alt || SiteSettings.site + " logo"} src={kaikuLogoUrl} />
  </LogoContainer>
);

export default Logo;
