import { SymptomItemData } from "client/reducers/client";
import { uniq } from "lodash";
import React from "react";
import {
  EmptyDataPointStyled,
  SymptomDataPointContainer,
  SymptomEventStyled,
  SymptomGradeStyled,
  SymptomScoreStyled,
} from "./SymptomPage.styles";

export type SymptomDataPointType = "Grade" | "Score" | "CalendarEntry";

interface SymptomDataPointProps {
  itemType: SymptomDataPointType;
  columnData: {
    hasGapBeforeColumn: boolean;
    items: SymptomItemData[];
  };
}

const SymptomDataPoint: React.FC<SymptomDataPointProps> = ({
  columnData,
  itemType,
}) => {
  const dataPoints = uniq(columnData.items.map(i => i.grade));
  const relativePositivityScores = uniq(
    columnData.items.map(i => i.relative_positivity),
  );
  const minValue = Math.min(...dataPoints);
  const maxValue = Math.max(...dataPoints);
  const minRelativePositivity = Math.min(...relativePositivityScores);
  const strValue =
    dataPoints.length === 1 ? dataPoints[0] : `${minValue}-${maxValue}`;

  return (
    <SymptomDataPointContainer
      drawVerticalRuler={columnData.hasGapBeforeColumn}
    >
      {dataPoints.length > 0 ? (
        <>
          {itemType === "Grade" && (
            <SymptomGradeStyled
              data-test-id="SymptomDataPoint"
              grade={maxValue}
            >
              {strValue}
            </SymptomGradeStyled>
          )}
          {itemType === "Score" && (
            <SymptomScoreStyled
              data-test-id="SymptomDataPoint"
              relativePositivity={minRelativePositivity}
            >
              {strValue}
            </SymptomScoreStyled>
          )}
          {itemType === "CalendarEntry" && (
            <SymptomEventStyled data-test-id="SymptomDataPoint">
              {dataPoints.length}
            </SymptomEventStyled>
          )}
        </>
      ) : (
        <EmptyDataPointStyled />
      )}
    </SymptomDataPointContainer>
  );
};

export default SymptomDataPoint;
