import styled, { css } from "styled-components";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { BodyArea } from "./BodymapQuestion.types";

type PathProps = {
  selected: boolean;
  strokeWidth?: string;
  frozen?: boolean;
  tabindex?: number;
  name: BodyArea;
};

const pathAttributes = (props: PathProps) => {
  const { frozen, name, tabindex } = props;

  return {
    tabIndex: tabindex ?? (frozen ? undefined : 0),
    focusable: frozen ? undefined : true,
    name,
  };
};

export const Path = styled.path.attrs(pathAttributes)<PathProps>`
  fill: ${({ selected }) => (selected ? colors.blue500 : colors.blue100)};
  stroke-width: ${({ strokeWidth = "2" }) => strokeWidth}px;
  stroke: ${colors.blue500};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  cursor: not-allowed;
  transition: 0.3s;
  ${({ frozen }) =>
    !frozen &&
    css`
      cursor: pointer;
      &:hover,
      &:focus {
        opacity: 1;
        stroke: ${colors.blue500} !important;
        stroke-width: 3.5px;
      }
    `}
`;

export const Svg = styled.svg``;

export const BodyOutline = styled.path`
  fill: ${colors.blue100};
  stroke: ${colors.gray700};
  stroke-width: 3px;
`;

export const BodyMapSvgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const BodyMapText = styled.p.attrs(() => ({
  "aria-hidden": true,
}))<{
  left?: string;
  right?: string;
  top?: string;
}>`
  font-size: ${rem(14)};
  font-weight: 500;
  position: absolute;
  ${({ top }) => top && `top: ${top};`}
  ${({ left }) => left && `left: ${left};`}
  ${({ right }) => right && `right: ${right};`}
  white-space: pre-line;
  text-align: center;
  margin: 0;
  line-height: 1.15;
`;

export const BodyMapHeading = styled.h3<{ hidden?: boolean }>`
  font-size: ${rem(16)};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid ${colors.gray300};
    margin: auto 5px;
  }

  ${({ hidden }) => hidden && `visibility: hidden;`}
`;

export const BodyMapsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const BodyMapContainer = styled.div.attrs(
  (props: { testId: string }) => ({
    "data-testid": "body-map-" + props.testId,
  }),
)<{ testId: string }>`
  flex: 1;
  margin: 0 10% 20px;
`;

export const BodymapModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
