import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CalendarEntry } from "client/models/calendarEntries";

const calendarSlice = createSlice({
  name: "calendar",
  initialState: [] as CalendarEntry[],
  reducers: {
    set(_state, action: PayloadAction<CalendarEntry[]>) {
      return action.payload;
    },
  },
});

export const calendarActions = calendarSlice.actions;
export default calendarSlice.reducer;
