import React from "react";
import cn from "classnames";
import { ProgressIcon } from "./ProgressBar.styles";

export type PeopleProps = {
  className?: string;
  value: number;
  max: number;
};

export default function People({ className, value, max }: PeopleProps) {
  return (
    <div
      className={cn(className)}
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={value}
      role="progressbar"
    >
      {[...Array(max).keys()].map(i => (
        <ProgressIcon
          key={i}
          name={i < value ? "user_fill" : "user"}
          size="small"
        />
      ))}
    </div>
  );
}
