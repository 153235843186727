import React, { useCallback, useEffect, useState } from "react";
import { Button, Icon } from "@netmedi/frontend-design-system";
import { FormattedMessage, IntlShape, injectIntl } from "react-intl";
import {
  ImagesModalFigure,
  ImagesModalImage,
  ImagesModalWrapper,
  Modal,
  ModalHeader,
  ImagesModalNav,
} from "./Modal.styles";
import { TitleAndBody, Dismiss } from "./Modal.types";
import { i18nTranslate } from "shared/utils/translation";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";

export type Image = {
  src: string;
  alt?: string;
};

export type ImagesModalProps = TitleAndBody & {
  type: "images_modal";
  className?: string;
  images: Image[];
  initialImageIndex?: number;
  intl: IntlShape;
};

const ImagesModal = ({
  dismiss,
  title,
  className,
  images,
  initialImageIndex,
  intl,
}: ImagesModalProps & Dismiss) => {
  const t = i18nTranslate(intl);
  const initialIndex = Math.min(initialImageIndex || 0, images.length - 1);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const nextImage = () =>
    setCurrentIndex(Math.min(currentIndex + 1, images.length - 1));
  const previousImage = () => setCurrentIndex(Math.max(currentIndex - 1, 0));

  const onKeyDown = useCallback(event => {
    switch (event.key) {
      case "ArrowLeft":
        event.preventDefault();
        previousImage();
        break;
      case "ArrowRight":
        event.preventDefault();
        nextImage();
        break;
      default:
      // Do nothing
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, []);

  return (
    <Modal className={className} onClick={(e: any) => e.stopPropagation()}>
      <ModalHeader>
        {title && <h3>{title}</h3>}
        <Button type="ghost" onClick={dismiss}>
          <Icon
            name="remove"
            size="medium"
            alt={t("modal.close")}
            color={colors.gray900}
          />
        </Button>
      </ModalHeader>
      <ImagesModalWrapper>
        {images.map(image => (
          <ImagesModalFigure index={currentIndex} key={image.src}>
            <ImagesModalImage src={image.src} alt={image.alt || ""} />
          </ImagesModalFigure>
        ))}
      </ImagesModalWrapper>
      <ImagesModalNav>
        <Button
          type="ghost"
          style={currentIndex === 0 ? { visibility: "hidden" } : {}}
          icon="arrowhead_left_16px"
          onClick={previousImage}
        >
          <FormattedMessage id="modal.images_modal.previous" />
        </Button>
        <Button
          type="ghost"
          icon="arrowhead_right_16px"
          iconEdge="right"
          style={
            currentIndex === images.length - 1 ? { visibility: "hidden" } : {}
          }
          onClick={nextImage}
        >
          <FormattedMessage id="modal.images_modal.next" />
        </Button>
      </ImagesModalNav>
    </Modal>
  );
};

export default injectIntl(ImagesModal);
