import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import SlideCarousel from "client/components/SlideCarousel";
import Slide, { SlideContent } from "client/components/SlideCarousel/Slide";
import { Dismiss } from "./Modal.types";
import {
  Modal,
  ModalNavFlex,
  ModalNavCarousel,
  CarouselSubmitButton,
  ModalNavButton,
  Spacer,
} from "./Modal.styles";

/** CarouselModal holds the controls of slide carousel*/

export type CarouselProps = {
  type: "carousel_modal";
  /** Slide contents in an array */
  slideContents: SlideContent[];
  /** Text to be shown on the next-button */
  nextText: string;
  /** Text to be shown on the previous-button */
  previousText: string;
  /** Text to be shown on the submit-button */
  submitText: string;
};

function CarouselModal({
  slideContents,
  previousText,
  nextText,
  dismiss,
  submitText,
}: Omit<CarouselProps, "type"> & Dismiss): JSX.Element {
  const [carouselIndex, setIndex] = useState(0);

  return (
    <Modal>
      <SlideCarousel carouselIndex={carouselIndex}>
        {slideContents.map(slideContent => (
          <Slide
            key={slideContents.indexOf(slideContent)}
            slideContent={slideContent}
            index={slideContents.indexOf(slideContent)}
          />
        ))}
      </SlideCarousel>
      {carouselIndex < slideContents.length - 1
        ? defaultButtons(setIndex, carouselIndex, previousText, nextText)
        : submitButton(submitText || "", dismiss)}
    </Modal>
  );
}

const submitButton = (
  submitText: string,
  dismiss: (...args: any[]) => any,
): JSX.Element => (
  <ModalNavCarousel>
    <CarouselSubmitButton size="small" onClick={dismiss}>
      <FormattedMessage id={submitText || "modal.slide_carousel.submit"} />
    </CarouselSubmitButton>
  </ModalNavCarousel>
);

const defaultButtons = (
  setIndex: React.Dispatch<React.SetStateAction<number>>,
  carouselIndex: number,
  previousText?: string | null,
  nextText?: string | null,
) => {
  return (
    <ModalNavFlex>
      <Spacer flexNbr={3} />
      <ModalNavButton
        direction="prev-button"
        disabled={carouselIndex <= 0}
        type="accent"
        size="small"
        onClick={() => setIndex(carouselIndex - 1)}
      >
        <FormattedMessage
          id={previousText || "modal.slide_carousel.previous"}
        />
      </ModalNavButton>
      <Spacer flexNbr={1} />
      <ModalNavButton
        direction="next-button"
        size="small"
        primary
        onClick={() => setIndex(carouselIndex + 1)}
      >
        <FormattedMessage id={nextText || "modal.slide_carousel.next"} />
      </ModalNavButton>
      <Spacer flexNbr={3} />
    </ModalNavFlex>
  );
};

export default CarouselModal;
