import styled from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import {
  colors,
  forms,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { BaseProps } from "./NativeSelect.types";

export const StyledSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: none;
  border: 1px solid ${forms.borderColor};
  border-radius: 4px;
  color: ${colors.gray900};
  cursor: pointer;
  display: block;
  font-size: ${rem(16)};
  height: 40px;
  line-height: 1.5;
  ${rtl`padding-left: 16px;`}
  ${rtl`padding-right: 32px;`}

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
    outline-offset: 2px;
    border: 1px solid ${colors.blue700};
  }

  /* Render a dropdown chevron using inline SVG */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' fill='%23757575' aria-hidden='true' focusable='false' class='css-8mmkcg'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  ${rtl`background-position: right 0.5em top 50%;`}
` as React.FC<BaseProps>;
