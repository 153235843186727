import React from "react";
import cn from "classnames";
import { range } from "lodash";
import { StyledNumbers } from "./ProgressBar.styles";

export type NumbersProps = {
  className?: string;
  selectedClassname?: string;
  value: number;
  min: number;
  max: number;
};

export default function Numbers({
  className,
  value,
  min,
  max,
  selectedClassname = "default",
}: NumbersProps) {
  return (
    <div
      className={cn(className)}
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={value}
      role="progressbar"
    >
      {range(min, max + 1).map(i => (
        <StyledNumbers
          key={i}
          $selected={i === value}
          $fill={value}
          className={cn({
            [selectedClassname]: selectedClassname && i === value,
          })}
        >
          {i}
        </StyledNumbers>
      ))}
    </div>
  );
}
