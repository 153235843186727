import React from "react";
import { Button, Icon } from "@netmedi/frontend-design-system";
import { FormattedMessage } from "react-intl";
import {
  Modal,
  TimeWindowParagraph,
  TimeWindowIconWrapper,
  ModalNav,
  TimeWindowIcon,
  TimeWindowWrapper,
} from "./Modal.styles";
import { redirect } from "common/utils/holvikaari";

export type TimeWindowModalProps = {
  type: "time_window_modal";
  dismiss: () => void;
};

/**
  Time window modal shows the user information about expired input form answer
  with a dismiss-button that redirects to home page.
**/

const TimeWindowModal = ({ dismiss }: TimeWindowModalProps) => {
  const dismissAndRedirect = () => {
    dismiss();
    redirect("/");
  };

  return (
    <Modal onClick={(e: any) => e.stopPropagation()} role="dialog">
      <TimeWindowWrapper>
        <TimeWindowIconWrapper>
          <TimeWindowIcon>
            <Icon name="access_time" size="large" />
          </TimeWindowIcon>
        </TimeWindowIconWrapper>
        <TimeWindowParagraph>
          <FormattedMessage id="modal.time_window_expired_body" />
        </TimeWindowParagraph>
        <ModalNav align="right">
          <Button size="small" onClick={dismissAndRedirect}>
            <FormattedMessage id="modal.confirm_cap" />
          </Button>
        </ModalNav>
      </TimeWindowWrapper>
    </Modal>
  );
};

export default TimeWindowModal;
