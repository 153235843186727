import { Dayjs } from "dayjs";
import { ValidInterval } from "./SymptomTable";

type Props = {
  firstItem: Dayjs;
  lastItem: Dayjs;
  interval: ValidInterval;
};

export const getSlotsForInterval = ({
  firstItem,
  lastItem,
  interval,
}: Props): number => {
  const MIN_SLOTS = 1;

  const numberOfSlots: number = Math.ceil(
    lastItem.endOf(interval).diff(firstItem, interval, true),
  );

  if (
    (typeof Number.isNaN === "function" && Number.isNaN(numberOfSlots)) ||
    !(lastItem.isValid() && firstItem.isValid())
  ) {
    throw new Error("Invalid date submitted");
  }

  if (numberOfSlots > MIN_SLOTS) {
    return numberOfSlots;
  }

  return MIN_SLOTS;
};
