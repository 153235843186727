import { connect } from "react-redux";
import { defaultsDeep } from "lodash";
import { startLoading } from "../actions/dataTable";
import { endpoints } from "common/utils/api";
import RichDataTable from "../components/DataTable/RichDataTable";
import { RootState } from "store";

const sharedDefaultProps = {
  query: "",
  query_scope: "PERSON_SCOPE",
  page: {
    number: 1,
  },
};

const nameToDefaultProps = {
  conversationListRegular: {
    filter: "ONLY_REGULAR_CONVERSATIONS",
    page: { size: 10 },
  },
  conversationListSupport: {
    filter: "ONLY_SUPPORT_CONVERSATIONS",
    page: { size: 5 },
  },
};

const nameToEndpoint = {
  conversationListRegular: endpoints.conversations.index,
  conversationListSupport: endpoints.conversations.index,
} as const;

export type DataTableName = keyof typeof nameToEndpoint;

const connectDataTable = (name: DataTableName) => {
  return connect(
    ({ data_table }: RootState) => data_table[name] || {},
    { startLoading: startLoading(name, nameToEndpoint[name]) },
    (stateProps, dispatchProps, ownProps) =>
      defaultsDeep(
        {},
        dispatchProps,
        stateProps,
        ownProps,
        nameToDefaultProps[name],
        sharedDefaultProps,
      ),
  )(RichDataTable);
};

export const ConversationListRegular = connectDataTable(
  "conversationListRegular",
);
export const ConversationListSupport = connectDataTable(
  "conversationListSupport",
);
