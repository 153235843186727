import React from "react";
import { BaseProps } from "./NativeSelect.types";
import { StyledSelect } from "./NativeSelect.styles";
import { injectIntl, IntlShape } from "react-intl";

type Option = {
  value: string;
  label: string;
};

type Props = BaseProps & {
  options: Option[];
};

const NativeSelect = ({
  options,
  value,
  intl,
  ...rest
}: Props & { intl: IntlShape }) => {
  const getPlaceholder = () => (
    <option key="default" value="" disabled>
      {intl.formatMessage({
        id: "dashboard.ccda_patient_records.headings.select",
      })}
      ...
    </option>
  );

  const renderOptions = () => [
    getPlaceholder(),
    ...options.map((o: Option, i: number) => (
      <option key={i} value={o.value}>
        {o.label}
      </option>
    )),
  ];

  return (
    <StyledSelect {...rest} value={value ?? ""}>
      {renderOptions()}
    </StyledSelect>
  );
};

export default injectIntl(NativeSelect);
