import { margin } from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import styled from "styled-components";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";

export const AbsencePage = styled.div`
  ${margin("s", "m")};
`;
export const TableActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 1rem;
  width: 100%;
`;

export const TextMuted = styled.span`
  color: ${colors.gray700};
`;

export const AbsenceFieldsStyled = styled.div``;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const AbsenceFormStyled = styled.div`
  max-width: 660px;
  margin: 0 auto;
`;

export const HelpText = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  clear: both;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  align-items: baseline;

  @media (max-width: 640px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
`;
