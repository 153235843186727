import React from "react";
import { uniqueId, pick, values, first } from "lodash";
import ChoiceDisplay from "./ChoiceDisplay";

import {
  QuestionTextWrapper,
  QuestionDescription,
  QuestionText,
  RadioLabel,
  CheckboxLabel,
  ChoiceDiv,
  ImageLabel,
} from "./Choice.styles";
import { IProps } from "./Choice.types";

const onKeyboardToClick = (e: React.KeyboardEvent<HTMLDivElement>) => {
  // Enter or spacebar => click
  // Allows tab navigation.
  if (e.key === "Enter" || e.key === " ") {
    e.preventDefault();
    const querySelector = e.currentTarget.querySelector("label");
    if (querySelector) {
      querySelector.click();
    }
  }
};

const getOnClick =
  ({
    onChange,
    value,
    checked,
  }: Pick<IProps, "onChange" | "value" | "checked">) =>
  () =>
    onChange?.({ target: { value, checked: !checked } });

const DefaultChoice = (props: IProps) => {
  const {
    label,
    type,
    direction = "horizontal",
    id,
    value,
    mobile,
    background,
    disabled,
    minHeight,
    fontWeight,
    tabIndex,
    index,
    checked,
    onChange,
  } = props;

  const fieldId = id || uniqueId(type);
  const Label = type === "radio" ? RadioLabel : CheckboxLabel;

  return (
    <ChoiceDiv
      role={type}
      data-optionindex={`option-${index}`}
      tabIndex={tabIndex ?? 0}
      onKeyPress={onKeyboardToClick}
      onClick={getOnClick({
        onChange: e => !disabled && onChange?.(e),
        value,
        checked,
      })}
      style={{
        minHeight: minHeight,
        fontWeight: fontWeight,
      }}
      direction={!mobile ? direction : undefined}
      fieldType={type}
      background={background}
      highlightSelected
      defaultChoice
      disabled={disabled}
      id={fieldId}
      $checked={checked}
      aria-describedby={props["aria-describedby"]}
    >
      <Label htmlFor={fieldId} role="presentation">
        <QuestionText>{label}</QuestionText>
      </Label>
    </ChoiceDiv>
  );
};

const labelStyles: Readonly<Record<string, string>> = {
  symptomCategorySelection: "symptomCategorySelectionLabel",
} as const;

const ImageChoice = ({
  label,
  type,
  direction,
  id,
  mobile,
  display = {},
  background,
  checked,
  onChange,
  tabIndex,
  value,
  ...rest
}: IProps) => {
  const fieldId = id || uniqueId(type);

  const labelStylesToApply = display.styles
    ? first(values(pick(labelStyles, display.styles)))
    : undefined;

  return (
    <>
      <ChoiceDiv
        tabIndex={tabIndex ?? 0}
        fieldType={type}
        extraPadding={!!display.description}
        direction={!mobile ? direction : undefined}
        highlightSelected={!!display.image_selected}
        borderSelected={!!display.image_selected}
        background={background}
        $checked={checked}
        onClick={getOnClick({ onChange, value, checked })}
        onKeyPress={onKeyboardToClick}
        id={fieldId}
        aria-describedby={rest["aria-describedby"]}
      >
        <ImageLabel
          className={labelStylesToApply}
          htmlFor={fieldId}
          role="presentation"
        >
          <ChoiceDisplay display={display} ariaHidden />
          <QuestionTextWrapper>
            <QuestionText role="presentation">{label}</QuestionText>
            {!!display.description && type === "checkbox" && (
              <QuestionDescription role="presentation">
                {display.description}
              </QuestionDescription>
            )}
          </QuestionTextWrapper>
        </ImageLabel>
      </ChoiceDiv>
    </>
  );
};

const templates: {
  default: (props: IProps) => JSX.Element;
  image: (props: IProps) => JSX.Element;
} = {
  default: DefaultChoice,
  image: ImageChoice,
};

const Choice = (props: IProps) => {
  const ChoiceElement = templates[props.template || "default"];
  return <ChoiceElement {...props} />;
};

Choice.defaultProps = {
  direction: "vertical",
};

export default Choice as React.FC<IProps>;
