import { removePushDevices as removePushDevicesAction } from "client/actions/pushDevices";
import { pushDevicesActions } from "client/reducers/pushDevices";
import { ActionsObservable, ofType } from "redux-observable";
import { filter, map, take } from "rxjs/operators";

export const removePushDevices = (action$: ActionsObservable<any>) =>
  action$.pipe(
    ofType(pushDevicesActions.clear.type),
    take(1),
    map(() => removePushDevicesAction()),
    filter(() => false),
  );
