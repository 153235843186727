import { connect } from "react-redux";
import { setTooltip } from "common/actions/tooltip";
import { getSteps } from "client/actions/client";
import PDFExport from "hcp/components/PDFExport";
import { RootState } from "store";

const mapStateToProps = (state: RootState) => ({
  steps: state.client.steps,
});

export default connect(mapStateToProps, {
  getSteps,
  setTooltip,
})(PDFExport);
