import { Viewport } from "common/epics/viewport";
import { ModalParams } from "shared/components/Modal/Modal.types";
import { AppAction as AppOnlyActions } from "shared/actions/app";
import { ModalAction } from "common/actions/modal";
import { TooltipAction } from "common/actions/tooltip";
import { UpdateIssueErrorAction } from "hcp/actions/staff";
import { ShowMobileNavAction } from "shared/actions/chat";
import { InputFormAction } from "shared/actions/inputForm";
import { ITooltip } from "common/components/Tooltip";

export const initialState = {
  mobile: false,
  show_mobile_nav: true,
  hide_navigation: false,
  show_app_download_modal: false,
};
export type State = {
  mobile?: boolean;
  show_mobile_nav?: boolean;
  show_app_download_modal?: boolean;
  hide_navigation?: boolean;
  small?: boolean;
  holvikaari?: boolean;
  unauthorized?: boolean;
  modal?: ModalParams;
  tooltip?: ITooltip;
  flags?: { [key: string]: unknown };
};

export type AppAction =
  | AppOnlyActions
  | ModalAction
  | TooltipAction
  | UpdateIssueErrorAction
  | ShowMobileNavAction
  | InputFormAction;

// eslint-disable-next-line max-lines-per-function
const app = (state: State = initialState, action: AppAction): State => {
  let forceRefresh;
  switch (action.type) {
    case "VIEWPORT":
      return {
        ...state,
        mobile: action.size === Viewport.MOBILE,
        small: action.size === Viewport.SMALL,
      };
    case "SET_HOLVIKAARI":
      return { ...state, holvikaari: true };
    case "UNAUTHORIZED":
      return { unauthorized: true };
    case "OFFLINE":
      forceRefresh =
        action.forceRefresh ||
        (state.modal &&
          "forceRefresh" in state.modal &&
          state.modal.forceRefresh);
      return {
        ...state,
        modal:
          action.data || forceRefresh
            ? {
                type: "offline",
                disableOutsideClick: true,
                forceRefresh: forceRefresh,
                hideButtons: !forceRefresh,
              }
            : undefined,
      };
    case "UPDATE_ISSUE_ERROR":
      return {
        ...state,
        modal: {
          type: "issue_update_error",
          disableOutsideClick: true,
          issue: action.data,
          issueAction: action.issueAction,
        },
      };
    case "ERROR_500":
      return {
        ...state,
        modal: {
          type: "500",
          disableOutsideClick: true,
          forceRefresh: true,
        },
      };
    case "CRITICAL_ERROR":
      return {
        ...state,
        modal: {
          type: "critical_error",
          disableOutsideClick: true,
          forceRefresh: true,
        },
      };
    case "PAGE_LOAD_ERROR":
      return {
        ...state,
        modal: {
          type: "page_load_error",
          disableOutsideClick: true,
          forceRefresh: true,
        },
      };
    case "ALERT":
      return {
        ...state,
        modal: {
          ...action.data,
          type: "alert",
        },
      };
    case "CONFIRM":
      return {
        ...state,
        modal: {
          ...action.data,
          type: "confirm",
        },
      };
    case "LOADER":
      return {
        ...state,
        modal: {
          ...action.data,
          type: "loader",
        },
      };
    case "OPEN_MODAL":
      return { ...state, modal: action.data, tooltip: undefined };
    case "DISMISS_MODAL":
      return { ...state, modal: undefined };
    case "SET_SHOW_APP_DOWNLOAD_MODAL":
      return {
        ...state,
        show_app_download_modal: action.data,
      };
    case "TOOLTIP":
      return { ...state, tooltip: action.data };
    case "SHOW_MOBILE_NAV":
      return { ...state, show_mobile_nav: action.data };
    case "SET_FORM":
      return {
        ...state,
        hide_navigation: true,
      };
    case "LEAVE_FORM":
      return {
        ...state,
        hide_navigation: false,
      };
    case "HIDE_NAVIGATION":
      return {
        ...state,
        hide_navigation: action.data,
      };
    case "SET_FLAG":
      return {
        ...state,
        flags: { ...state.flags, [action.data.flag]: action.data.value },
      };
    default:
      return state;
  }
};

export default app;
