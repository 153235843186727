import React, { ReactNode } from "react";

/** Carousel for the slide contents, uses either controls from an upper component or self-generated ones */

type SlideCarouselProps = {
  children: Array<ReactNode>;
  carouselIndex: number;
};

const SlideCarousel = (props: SlideCarouselProps): JSX.Element => {
  const { children, carouselIndex } = props;
  return <div>{children[carouselIndex]}</div>;
};

export default SlideCarousel;
