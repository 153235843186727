import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "@netmedi/frontend-design-system/dist/components/Button";
import { Icon } from "@netmedi/frontend-design-system";
import { Modal, ModalNav } from "./Modal.styles";
import { Dismiss } from "./Modal.types";
import { ButtonWrapper } from "../Messaging/MassNotification.styles";
import { MassNotification } from "../Messaging/MassNotification.types";
import MassNotificationSummaryView from "../Messaging/MassNotificationSummaryView";

export type MassNotificationSummaryModalProps = {
  type: "mass_notification_summary";
  showableMassMessages: MassNotification[];
  confirm: () => void;
};

const MassNotificationSummaryModal = ({
  dismiss,
  confirm,
  showableMassMessages,
}: Omit<MassNotificationSummaryModalProps, "type"> & Dismiss) => {
  const closeAndConfirm = () => {
    // dispatch massMessage sending here
    dismiss();
    confirm();
  };

  return (
    <Modal onClick={(e: any) => e.stopPropagation()}>
      <ButtonWrapper>
        <button data-testid="close-modal" onClick={dismiss}>
          <Icon name="remove_16px" />
        </button>
      </ButtonWrapper>
      <MassNotificationSummaryView
        showableMassMessages={showableMassMessages}
      />
      <ModalNav align="right">
        <Button size="small" onClick={closeAndConfirm} icon="send">
          <FormattedMessage id={"conversations.submit"} />
        </Button>
      </ModalNav>
    </Modal>
  );
};

export default MassNotificationSummaryModal;
