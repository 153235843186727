import { composeFeedbackFormLink } from "common/utils/feedback";
// import { TabIcon } from "hcp/components/Staff/CallToAction/CallToAction.styles";
import { Language } from "common/models/language";
import React from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  SendFeedbackLink,
  UserFeedbackBoxStyled,
} from "./UserFeedbackBox.styles";

type FeedbackLinkProps = {
  language: string;
  featureNameKey: string;
} & WrappedComponentProps;

export const FeedbackLink = ({
  language,
  featureNameKey,
  intl,
}: FeedbackLinkProps) => (
  <SendFeedbackLink
    id={`feedbackFormLink_${featureNameKey}`}
    href={
      composeFeedbackFormLink(language ?? "") +
      `&prefill_Short+summary=${intl.formatMessage({
        id: featureNameKey,
      })}`
    }
    textKey="user_feedback.send_feedback"
    iconKey="icons.opens_in_new_window"
    iconName="new_window_16px"
  />
);

export type UserFeedbackBoxProps = {
  /** The formatted message key for the new feature name */
  featureNameKey: string;
  /** Language of the current user  */
  language?: Language | string;
  /** The formatted message key for text to be shown on the box if the default one is not suitable.
   *  Use view_name as a variable if want to add the view name there
   */
  boxTextKey?: string;
} & WrappedComponentProps;

const UserFeedbackBox = ({
  featureNameKey,
  language,
  boxTextKey,
  intl,
}: UserFeedbackBoxProps) => {
  return (
    <UserFeedbackBoxStyled>
      <FormattedMessage
        id={boxTextKey ?? "user_feedback.feedback_about"}
        values={{ view_name: <FormattedMessage id={featureNameKey} /> }}
      />
      {
        <FeedbackLink
          language={language ?? ""}
          featureNameKey={featureNameKey}
          intl={intl}
        />
      }
    </UserFeedbackBoxStyled>
  );
};

export default injectIntl(UserFeedbackBox);
