import React, { FunctionComponent, useState, useEffect } from "react";
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
} from "react-intl";
import UserList from "common/components/UserList";
import { rmTooltip } from "common/utils/tooltip";
import { includes, flatten, uniqBy } from "lodash";
import { mobileUA } from "common/utils/layout";

import {
  Results,
  Placeholder,
  Search,
  Content,
  SearchField,
} from "./UserDropDown.styles";

export type NameAndId = {
  readonly full_name: string;
  readonly id: number | string;
  readonly role?: string;
  readonly type?: string;
};

export type PeopleCategory = {
  readonly items: ReadonlyArray<NameAndId>;
  readonly title: string;
  readonly noSearch?: boolean;
  readonly onClick: (item: NameAndId) => Promise<any>;
};

export type PeopleOrPeopleCategories =
  | ReadonlyArray<NameAndId>
  | ReadonlyArray<PeopleCategory>;

const withCategories = (
  items: PeopleOrPeopleCategories,
): items is ReadonlyArray<PeopleCategory> =>
  items.length > 0 && (items[0] as PeopleCategory).title !== undefined;

const closeOnEsc = (e: KeyboardEvent) => {
  if (e.keyCode === 27) {
    rmTooltip();
  }
};

const handleKeydown = (callback: (e: KeyboardEvent) => void) => {
  useEffect(() => {
    const eventType = "keydown";
    window.addEventListener(eventType, callback);

    return () => {
      window.removeEventListener(eventType, callback);
    };
  }, [callback]);
};

function sortByName(a: NameAndId, b: NameAndId) {
  if (a.full_name < b.full_name) return -1;
  if (a.full_name > b.full_name) return 1;
  return 0;
}

function filterItems(
  items: PeopleOrPeopleCategories,
  searchText: string,
): ReadonlyArray<NameAndId> {
  const persons = withCategories(items)
    ? flatten(items.map(category => (category.noSearch ? [] : category.items)))
    : items;

  const matchingPersons = persons
    .filter(person =>
      includes(person.full_name.toLowerCase(), searchText.toLowerCase()),
    )
    .sort(sortByName);
  return uniqBy(matchingPersons, "id");
}

function matchingItemsList(
  filteredItems: ReadonlyArray<NameAndId>,
  onClickItem: (item: NameAndId) => Promise<any>,
) {
  if (filteredItems.length) {
    return (
      <Results>
        <UserList
          noPadding
          items={filteredItems}
          onClick={(item: NameAndId) => onClickItem(item).then(rmTooltip)}
        />
      </Results>
    );
  } else {
    return (
      <Placeholder>
        <FormattedMessage id="people.no_people_found" />
      </Placeholder>
    );
  }
}

function defaultList(
  items: PeopleOrPeopleCategories,
  onClickItem: (item: NameAndId) => Promise<any>,
) {
  return (
    <Results>
      {withCategories(items) ? (
        items.map((category, i) => (
          <div key={i}>
            <FormattedMessage tagName="h4" id={category.title} />
            <UserList
              items={[...category.items].sort(sortByName)}
              noPadding
              onClick={(item: NameAndId) =>
                (category.onClick || onClickItem)(item).then(rmTooltip)
              }
            />
          </div>
        ))
      ) : (
        <UserList
          noPadding
          items={[...items].sort(sortByName)}
          onClick={onClickItem}
        />
      )}
    </Results>
  );
}

export type UserDropdownProps = {
  items: PeopleOrPeopleCategories;
  updatePosition?: () => void;
  onClick: (item: NameAndId) => Promise<any>;
} & WrappedComponentProps;

const UserDropdown: FunctionComponent<UserDropdownProps> = (
  props: UserDropdownProps,
) => {
  handleKeydown(closeOnEsc);
  useEffect(() => {
    props.updatePosition && props.updatePosition();
  });

  const [searchText, setSearchText] = useState("");
  const filteredItems = filterItems(props.items, searchText);
  const searchRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!mobileUA && searchRef.current !== null) searchRef.current.focus();
  });

  return (
    <Content>
      <Search>
        <SearchField
          ref={searchRef}
          type="text"
          placeholder={props.intl.formatMessage({ id: "generic.search" })}
          onChange={e => setSearchText(e.target.value)}
        />
      </Search>
      {searchText.length > 0
        ? matchingItemsList(filteredItems, props.onClick)
        : defaultList(props.items, props.onClick)}
    </Content>
  );
};

export default injectIntl(UserDropdown);
