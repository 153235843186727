import React from "react";
import { openModal } from "common/actions/modal";
import { Source } from "common/models/symptoms";
import { Attachment } from "shared/models/inputForm.types";
import store from "store";
import Markdown from "../Markdown";
import {
  BodymapTooltipContainer,
  DataPointCaption,
  DataPointTitle,
  ImageThumbnail,
  SourceField,
  SourceTitle,
  UnderlinedSpan,
} from "./SymptomGradeCircle.styles";
import { map, omit, sortBy } from "lodash";
import { parseIsoString } from "common/utils/general";
import { FormattedDate, IntlShape, useIntl } from "react-intl";
import {
  DataType,
  ParentSources,
} from "shared/components/SymptomTable/SymptomTable";
import {
  BodymapProps,
  BodymapTemplate,
} from "shared/components/InputForm/BodyMap/BodymapQuestion.types";
import BodymapFront from "shared/components/InputForm/BodyMap/BodymapFront";
import BodymapBack from "shared/components/InputForm/BodyMap/BodymapBack";

export type NonEmptyCommonProps = {
  isEmpty?: false;
  sizeProportionalToGrade?: boolean;
} & (
  | {
      getSources: () => Promise<Source[]>;
      symptomName: string;
      severityDescription: string;
    }
  | {
      getSources?: never;
      symptomName?: never;
      severityDescription?: never;
    }
);
export type IsRangeProps = NonEmptyCommonProps & {
  isRange: true;
  urgency?: number;
  grade: [number, number];
  underline: "min" | "max" | "both";
  parentSources?: ParentSources;
};
export type NonRangeProps = NonEmptyCommonProps & {
  isRange?: false;
  urgency?: number;
  grade: number;
  parentSources?: ParentSources;
};
export type IsEmtpyProps = {
  isEmpty: true;
  isRange?: false;
  tooltipText?: JSX.Element;
};

export type SymptomGradeCircleProps = (
  | NonRangeProps
  | IsRangeProps
  | IsEmtpyProps
) & {
  onClick?: React.MouseEventHandler;
  className?: string;
  selected?: boolean;
  focusOnContent?: boolean;
  viewedAsStaff?: boolean;
};

export function renderGrade(
  props: SymptomGradeCircleProps & { intl: IntlShape },
) {
  if (props.isEmpty === true) return;

  if (props.isRange) {
    switch (props.underline) {
      case "min":
        return (
          <>
            <UnderlinedSpan>{props.grade[0]}</UnderlinedSpan>–{props.grade[1]}
          </>
        );
      case "max":
        return (
          <>
            {props.grade[0]}–<UnderlinedSpan>{props.grade[1]}</UnderlinedSpan>
          </>
        );
      case "both":
        return (
          <UnderlinedSpan>
            {props.grade[0]}–{props.grade[1]}
          </UnderlinedSpan>
        );
      default:
        ((x: never) => x)(props.underline);
    }
  } else {
    return props.grade;
  }
}

export function getGrade(
  props: SymptomGradeCircleProps & { intl: IntlShape },
): number | undefined {
  if (props.isEmpty === true) return;

  if (props.isRange === true) {
    return Math.max(...props.grade);
  } else {
    return props.grade;
  }
}

export const groupByParent = (data_points: Source["data_points"]) =>
  data_points.reduce(
    (result: { [parent_title: string]: Source["data_points"] }, element) => {
      const parent = element.parent_title ?? "other";
      result[parent] = result[parent] ?? [];
      result[parent].push(element);
      return result;
    },
    { other: [] },
  );

export const symptomDetails = (dps: Source["data_points"]) =>
  dps.map((dp, idx) => (
    <SourceField key={idx}>
      <DataPointTitle>
        <Markdown container="span" source={dp.title} />
      </DataPointTitle>
      <DataPointCaption>{`– ${dp.content}`}</DataPointCaption>
      {dp.caption && dp.text && (
        <DataPointCaption>
          <DataPointTitle>
            <Markdown container="span" source={dp.title} />
          </DataPointTitle>
          <div>{`– ${dp.text}`}</div>
        </DataPointCaption>
      )}
      {!!dp.attachments?.length &&
        dp.attachments.map((attachment: Attachment, index: number) => (
          <ImageThumbnail
            key={attachment.url}
            href={attachment.url}
            title={index.toString()}
            onClick={event => {
              event.preventDefault();
              store.dispatch(
                openModal({
                  type: "images_modal",
                  disableOutsideClick: true,
                  images: dp.attachments?.map((attachment: Attachment) => ({
                    src: attachment.url,
                  })),
                  initialImageIndex: index,
                }),
              );
            }}
          >
            <img src={attachment.url} alt="" />
          </ImageThumbnail>
        ))}
    </SourceField>
  ));

export const isSymptomOpened = (symptom: any, openedSymptom: any) =>
  symptom === openedSymptom;

export const renderMultiplevalue = (
  dps: Source["data_points"],
  title: string,
) => {
  const { bodymap_template: bodymapTemplate } = dps[0] || {};
  const tooltipBodymapProps = bodymapProps(bodymapTemplate || "Default", dps);

  return (
    <SourceField key={title}>
      <DataPointTitle>
        <Markdown container="span" source={title} />
      </DataPointTitle>
      {dps.map(dp => (
        <DataPointCaption key={dp.title}>{`– ${dp.title}`}</DataPointCaption>
      ))}
      {bodymapTemplate && (
        <BodymapTooltipContainer>
          <BodymapFront {...tooltipBodymapProps} />
          <BodymapBack {...tooltipBodymapProps} />
        </BodymapTooltipContainer>
      )}
    </SourceField>
  );
};

export const renderHeader = (form: string, time: string, isChild?: boolean) =>
  !isChild && (
    <SourceTitle>
      {`${form} (`}
      <FormattedDate value={time} />
      {")"}
    </SourceTitle>
  );

export const renderedSources = (sources_data: Source[], isChild?: boolean) =>
  sortBy(sources_data, ({ time }) => parseIsoString(time).valueOf()).map(
    ({ form, time, data_points, lang }, idx) => {
      const grouped = groupByParent(data_points);
      const multipleValue = omit(grouped, "other");
      const other = grouped["other"];
      return (
        <div data-testid="symptom-source-wrapper" key={idx} lang={lang}>
          {renderHeader(form, time, isChild)}
          {map(multipleValue, renderMultiplevalue)}
          {symptomDetails(other)}
        </div>
      );
    },
  );

export const bodymapAreaIds = (sources: any[]): Record<string, number> =>
  sources.reduce(
    (acc, source) => {
      if (source?.area_id) {
        acc[source.area_id] = 1;
      }
      return acc;
    },
    {} as Record<string, number>,
  );

export const bodymapProps = (
  displayTemplate: BodymapTemplate,
  sources: any[],
): BodymapProps => ({
  onClick: () => {},
  onKeyDown: () => {},
  intl: useIntl(),
  noText: true,
  display: displayTemplate,
  choices: bodymapAreaIds(sources),
  frozen: true,
  tooltipMode: true,
});

export const getParentSourcesBodymapProps = (parentSources: ParentSources) => {
  const [_firstKey, firstEntryValues] = Object.entries(parentSources)[0] || [];
  const displayTemplate = firstEntryValues?.[0]?.bodymap_template || "Default";
  const dataTypeValues: DataType[] = Object.values(parentSources).flat();
  const parentSourcesBodymapProps = bodymapProps(
    displayTemplate,
    dataTypeValues,
  );
  return parentSourcesBodymapProps;
};
