import styled from "styled-components";

interface IEventInfo {
  bottomMargin?: boolean;
}

export const EventInfo = styled.div.attrs(() => ({
  "data-testid": "calendar-event-field",
}))<IEventInfo>`
  font-size: 0.9em;
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: 0.5rem;`}
`;
