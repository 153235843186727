import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { rootStyles } from "../../../styles/holvikaari.styles";
import {
  navigation,
  mediaQueries,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { mediaMin } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";

export const StyledApp = styled.div(rtl`
  height: 100%;
  width: 100%;
  animation: fadeIn 0.4s;
  direction: ltr;
  ${rootStyles}
`);

export const StyledToMainLink = styled.a(rtl`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: 999;

  &:focus {
    width: auto;
    height: auto;
    left: 50%;
    top: 10px;
  }
`);

export const StyledRightContainer = styled.div<{
  desktop: boolean;
  desktopNavHidden: boolean;
  mobileNavHidden: boolean;
}>`
  position: absolute;
  top: 0;
  ${rtl`right: 0;`}
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${rtl`left: 0px;`}
  ${mediaMin(mediaQueries.medium)} {
    ${rtl`left: ${navigation.sidebar.width}px;`}
  }

  ${({ desktopNavHidden }) =>
    desktopNavHidden &&
    css`
      ${rtl`left: 0 !important;`}
    `}

  ${({ desktop, mobileNavHidden }) =>
    `bottom: ${desktop || mobileNavHidden ? 0 : navigation.mobileNavHeight}px;`}
`;
