import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@netmedi/frontend-design-system";
import { SiteSettings } from "common/utils/holvikaari";
import { Popover } from "./OnlineHelpPopover.styles";

const OnlineHelpPopover = (props: Props) => {
  return (
    <Popover>
      <FormattedMessage
        id="popover.description"
        values={{
          help_view_name: (
            <strong>
              <FormattedMessage id="navbar.online_help" />
            </strong>
          ),
          product_name: SiteSettings.product_name,
        }}
      />
      <Button size="small" onClick={props.onClick}>
        <FormattedMessage id="popover.button_text" />
      </Button>
    </Popover>
  );
};

export type Props = {
  onClick: () => void;
};

export default OnlineHelpPopover;
