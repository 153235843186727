import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Consents } from "client/models/consents";

const consentsSlice = createSlice({
  name: "consents",
  initialState: {} as Consents,
  reducers: {
    set(state, action: PayloadAction<Consents>) {
      return { ...state, ...action.payload };
    },
  },
});

export const consentsActions = consentsSlice.actions;
export default consentsSlice.reducer;
