import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { Avatar } from "@netmedi/frontend-design-system";
import {
  media,
  mediaMax,
} from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";
import {
  typography,
  colors,
  mediaQueries,
  spacing,
} from "@netmedi/frontend-design-system/dist/styles/variables";

interface IProps {
  responsive?: boolean;
  inactive?: boolean;
  disabled?: boolean;
  noPadding?: boolean;
  horizontal?: boolean;
}

export const UserCardAvatar = styled(Avatar)`
  vertical-align: top;
`;

const responsiveUsersSection = css`
  ${media(mediaQueries.small, mediaQueries.medium)} {
    padding: 0;
    padding-top: 1rem;
    li {
      display: block;
      width: auto;
    }
  }
  ${mediaMax(mediaQueries.small)} {
    line-height: 1.3;
    padding: 0 12px;
    padding-bottom: 24px;
  }
`;

const horizontalStyles = css`
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      ${rtl`
        padding-right: ${spacing.s}px;
      `}
    }
  }
  ${mediaMax(mediaQueries.small)} {
    ul {
      display: block;
      li {
        ${rtl`
          padding-right: 0;
        `}
      }
    }
  }
`;

export const UsersSection = styled.section<IProps>`
  background: ${colors.white};
  font-size: ${typography.fontSizeBase};
  && ul,
  && li {
    ${rtl`margin-left: 0;`}
  }
  li {
    ${({ noPadding }) => !noPadding && `padding: 0.5rem;`}
    position: relative;
    vertical-align: middle;
  }
  ${mediaMax(mediaQueries.small)} {
    li {
      display: inline-block;
      max-width: 100%;
      min-width: 100%;
    }
  }
  ${({ responsive }) => responsive && responsiveUsersSection}
  ${({ horizontal }) => horizontal && horizontalStyles}
`;

const listItemOpacity = css`
  opacity: 0.5;
`;

const listItemInactive = css`
  color: ${colors.gray700};
  opacity: 0.9;
`;

const listItemResponsive = css`
  padding: 0;
  padding-top: 12px;
  font-size: ${typography.fontSizeMedium};
`;

export const UserListItem = styled.li<IProps>`
  ${({ disabled }) => disabled && listItemOpacity}
  ${({ inactive }) => inactive && listItemInactive}
  ${mediaMax(mediaQueries.small)} {
    ${({ responsive }) => responsive && listItemResponsive}
  }
`;
