import { useEffect, useRef } from "react";

const getLastNavigation = () => window.lastNavigation ?? "";

export const useAutofocus = <T extends HTMLElement = HTMLHeadingElement>() => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const prev = window.prevLastNavigation ?? "";
    if (ref.current && prev === getLastNavigation()) {
      ref.current.focus();
      ref.current.setAttribute("data-focus-visible_added", "");
    }
    return () => {
      window.prevLastNavigation = getLastNavigation();
    };
  }, []);

  return ref;
};
