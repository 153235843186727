import { get, post, put, del, endpoints, parseJson } from "common/utils/api";
import { alertConditionActions } from "shared/reducers/AlertCondition";

const makeRequest =
  (
    method: typeof get | typeof post | typeof put | typeof del,
    endpoint: keyof typeof endpoints.alert_conditions,
  ) =>
  (attrs: any) =>
    method(
      endpoints.alert_conditions[endpoint](attrs),
      ...(method !== get ? [{ alert_condition: attrs }] : []),
    ).then(data =>
      alertConditionActions.updateAlertConditions(parseJson(data).data as any),
    );

export const index = makeRequest(get, "index");
export const create = makeRequest(post, "create");
export const update = makeRequest(put, "update");
export const destroy = makeRequest(del, "destroy");
