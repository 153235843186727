import styled from "styled-components";

const Name = styled.strong.attrs(() => ({
  "data-testid": "userlink-name",
}))`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default Name;
