import { isObject, uniqueId } from "lodash";
import { flashActions } from "common/reducers/flash";
import { Flash, FlashType } from "common/components/Flash";

type MessageType = string | Flash["message"];
// message can be either a translation key or an object with props for FormattedMessage
export const getMessageContent = (message: MessageType) =>
  isObject(message) ? message : { id: message };

export const addFlash = (
  message: MessageType,
  type: FlashType = "success",
  persist_over_first_page_change = false,
  id = uniqueId(),
  onClose = null, // use to override standard closing callback
) =>
  flashActions.add({
    id,
    message: getMessageContent(message),
    type,
    persist_over_first_page_change,
    onClose,
  });

export const closeFlash = (id?: string | number) =>
  id !== undefined
    ? flashActions.remove({
        id,
      })
    : undefined;

export const clearFlashes = flashActions.clear;
