import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import { marginBottom } from "@netmedi/frontend-design-system/dist/styles/styleHelpers";

const colMinWidth = "300px";
const overflowBreakpoint = `calc(${colMinWidth} * 2 + 80px)`;

export const IxrsProfileDetailsStyled = styled.div`
  padding: 2em;
  margin: 2em 0;
  border: 1px solid #ddd;

  .details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${colMinWidth}, 1fr));
  }

  .detail-item {
    max-width: calc(${colMinWidth} * 2 + 1px - 1px);
    display: flex;
    line-height: 3em;
    background-color: #f5f5f5;
    padding: 0 1em;

    div {
      flex: 1;
    }

    .title {
      font-weight: bold;
      white-space: nowrap;
    }

    // Colouring for even rows
    @media (min-width: ${overflowBreakpoint}) {
      &:nth-child(4n + 1),
      &:nth-child(4n + 2) {
        background-color: #f9f9f9;
      }
    }
    @media (max-width: calc(${overflowBreakpoint} - 1px)) {
      &:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
`;

export const InviteInformationDiv = styled.div`
  div.sent-invites {
    clear: both;
    overflow: auto;
    margin-bottom: 30px;
  }

  div.sent-invites h3 {
    ${rtl`text-align: left;`}
  }

  div.sent-invites a {
    ${rtl`float: right;`}
  }
`;

export const FieldDescription = styled.div`
  ${rtl`margin-left: 40%;`}
  ${marginBottom("xs")}
`;

export const ErrorMsg = styled.div.attrs(() => ({
  "data-testid": "error-msg",
}))(rtl`
  color: ${colors.red700};
  margin: 0.5em 0 0;
`);
