import React, { useEffect } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  ButtonIcon,
  WizardBackButton,
  WizardButtonContainer,
  WizardPage,
  WizardProgressBar,
} from "./InvitePage.styles";

type InviteWizardProps = {
  page: number;
  setPage: (page: number) => void;
  setLastPage: (value: boolean) => void;
  submitComponent?: React.ReactNode;
  children?: (React.ReactChild | React.ReactFragment | React.ReactPortal)[];
} & WrappedComponentProps;

function InviteWizard(props: InviteWizardProps) {
  const { children, page, setPage, submitComponent } = props;
  const pages = (children || []).filter(Boolean);
  const pagesCount = React.Children.count(pages);

  const previous = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setPage(Math.max(page - 1, 0));
  };

  useEffect(() => {
    props.setLastPage(page === pages.length - 1);
  }, [page, pages]);

  return (
    <>
      <FormattedMessage id="people.back">
        {backText => (
          <WizardBackButton
            size="small"
            type="ghost"
            onClick={previous}
            visible={page > 0}
          >
            <ButtonIcon name="arrow_left_16px" size="medium" />
            {backText}
          </WizardBackButton>
        )}
      </FormattedMessage>

      <WizardProgressBar type="bar" value={page + 1} max={pagesCount} />

      {pages.map((child, index) => (
        <WizardPage key={index} hidden={page !== index}>
          {child}

          <WizardButtonContainer>{submitComponent}</WizardButtonContainer>
        </WizardPage>
      ))}
    </>
  );
}

export default injectIntl(InviteWizard);
