import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataTableName } from "common/containers/DataTable";

export type State = Record<DataTableName, any>;

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState: {
    conversationListRegular: {},
    conversationListSupport: {},
  } as State,
  reducers: {
    setContent(
      state,
      { payload }: PayloadAction<{ name: DataTableName; data: any }>,
    ) {
      state[payload.name] = payload.data;
    },
    startLoading(
      state,
      { payload }: PayloadAction<{ name: DataTableName; data: any }>,
    ) {
      state[payload.name] = {
        ...(state[payload.name] || {}),
        ...payload.data.params,
        loading: true,
      };
    },
  },
});

export default dataTableSlice.reducer;
export const dataTableActions = dataTableSlice.actions;
