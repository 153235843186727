import { fromPairs } from "lodash";
import { getAnswer } from "shared/utils/inputForm";
import { setAnswer } from "shared/actions/inputForm";
import { QuestionOrSubQuestion } from "shared/models/inputForm.types";

export default class InputFormAnswerSync {
  private answerId: number;

  constructor(answerId: number) {
    this.answerId = answerId;
  }

  queueAnswers(
    questions: [
      Pick<QuestionOrSubQuestion, "default_value" | "external_id" | "type">,
      any,
      boolean,
    ][],
  ) {
    const pendingUpdates = {
      ...fromPairs(questions.map(q => getAnswer(...q))),
    };

    return setAnswer(pendingUpdates, this.answerId);
  }
}
