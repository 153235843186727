import dayjs from "dayjs";
import { parseIsoString } from "common/utils/general";
import { ValidInterval } from "../SymptomTable/SymptomTable";
import { Steps } from "./types";

type IsSameIntervalProps = {
  start_date: number;
  end_date: number;
  interval: ValidInterval;
};

export const isSameInterval =
  ({ start_date, end_date, interval }: IsSameIntervalProps) =>
  (s: Steps) =>
    dayjs(start_date).isSame(parseIsoString(s.start_time), interval) &&
    dayjs(end_date).isSame(parseIsoString(s.end_time), interval);
