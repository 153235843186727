import { DocumentAction } from "hcp/actions/sharedDocuments";
import { Document } from "shared/components/DocumentCard/SharedDocumentCard";

export type State = {
  sent: Document[];
  received: Document[];
};

const initialState: State = {
  sent: [],
  received: [],
};

export default (state = initialState, action: DocumentAction) => {
  switch (action.type) {
    case "SET_RECEIVED_DOCUMENTS":
      return {
        ...state,
        received: action.data,
      };
    case "SET_SENT_DOCUMENTS": {
      return {
        ...state,
        sent: action.data,
      };
    }
    case "UPDATE_RECEIVED_DOCUMENTS":
      return {
        ...state,
        received: state.received.concat(action.data),
      };
    default:
      return state;
  }
};
