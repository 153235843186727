import React, { useEffect, useState } from "react";
import {
  Actions,
  KeyclaokStatusPanelContainer,
  KeycloakStatusPanelContent,
  KeycloakStatusPanelHeading,
  LoadingContainer,
  Status,
  Text,
} from "./KeycloakStatusPanel.styles";
import { endpoints } from "common/utils/endpoints";
import { get } from "common/utils/api";
import { Button, Icon, Spinner } from "@netmedi/frontend-design-system";
import { tooltip } from "common/utils/tooltip";
import { IconName } from "@netmedi/frontend-design-system/dist/components/Icon";
import { SiteSettings } from "common/utils/holvikaari";

type Status = "ok" | "error" | "unknown" | "disabled" | "loading";
export interface KeycloakStatusResponse {
  status: Status;
  description?: string;
}

const icons: Record<Status, IconName> = {
  ok: "ok_circle_48px",
  error: "close_circle_48px",
  unknown: "remove_circle_48px",
  disabled: "remove_circle_48px",
  loading: "arrow_round_right_48px",
};
const colors: Record<Status, string> = {
  ok: "#48880d",
  error: "#cd2020",
  unknown: "#b29111",
  disabled: "#646464",
  loading: "#2196f3",
};

export const setErrorTooltip = (e: React.MouseEvent, content: JSX.Element) =>
  tooltip({
    target: e.target,
    position: "bottom",
    content: content,
    autoWidth: true,
  });

const KeycloakStatusPanel: React.FC = () => {
  const [status, setStatus] = useState<Status>("loading");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    (async () => {
      if (!SiteSettings.keycloak_enabled) {
        setStatus("disabled");
        return;
      }

      setStatus("loading");
      try {
        const response = await get<KeycloakStatusResponse>(
          endpoints.admin.status.keycloak,
        );
        setStatus(response.status);
        setDescription(response.description ?? "");
      } catch (error) {
        setStatus("unknown");
        setDescription("Error getting keycloak status");
      }
    })();
  }, []);

  return (
    <KeyclaokStatusPanelContainer data-testid="KeycloakStatusPanel">
      <KeycloakStatusPanelHeading>Keycloak status</KeycloakStatusPanelHeading>
      <KeycloakStatusPanelContent>
        <Status>
          {status === "loading" && (
            <LoadingContainer>
              <Spinner circle />
              Loading...
            </LoadingContainer>
          )}
          {status !== "loading" && (
            <Icon name={icons[status]} color={colors[status]} size="xxl" />
          )}
          <Text status={status}>
            {status === "ok" && "Keycloak is operational"}
            {status === "error" && "Keycloak is not operational"}
            {status === "unknown" && `Unable to determine Keycloak status`}
            {status === "disabled" && `Keycloak is disabled for the site`}
          </Text>
        </Status>
        <Actions>
          {(status === "error" || status === "unknown") && (
            <Button
              size="small"
              type="ghost"
              onClick={(e: React.MouseEvent) =>
                setErrorTooltip(e, <>{description}</>)
              }
            >
              Show details
            </Button>
          )}
        </Actions>
      </KeycloakStatusPanelContent>
    </KeyclaokStatusPanelContainer>
  );
};

export default KeycloakStatusPanel;
