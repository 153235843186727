import { mapGraph } from "common/utils/user";
import _ from "lodash";

import { Feedback } from "shared/models/feedback";
import { Article, ArticleWithContent } from "shared/models/article";
import { StepsState } from "shared/components/SymptomTableCompact/types";
import { RowType } from "shared/components/SymptomTable/SymptomTable";
import { RootState } from "store";

import { ClientAction } from "client/actions/client";
import { FeedbackAction } from "common/actions/feedback";
import {
  CaregiverAction,
  CaregiverInviteAction,
} from "client/actions/caregivers";
import { ClientAction as StaffAction } from "hcp/actions/staff";
import { EmbeddableView } from "common/components/EmbeddableViews";
import { Answer, Form } from "client/components/CompareAnswers";
import { symptomToCompareConverter } from "client/utils/CompareAnswers";
import { Graph } from "common/components/Graph/Graph.types";
import { ClientAnswer } from "hcp/components/Staff/IssueList/IssueList.types";
import { NameAndId } from "hcp/components/Staff/UserDropdown";
import { Document as SharedDocument } from "shared/components/DocumentCard/SharedDocumentCard";
import { Caregiver } from "common/models/caregiver";

type CompareAnswers = {
  input_form_answers: Answer[];
  meta: {
    form: Form;
  };
};

type CompareValues = ReturnType<ReturnType<typeof symptomToCompareConverter>>;

export type ExtendedClientAnswer = ClientAnswer & {
  done: boolean;
  info_issue: boolean;
  form_external_id: string;
};

export const initialState = {
  basic_details: {
    firstName: "",
    lastName: "",
  },
  caregivers: [] as Caregiver[],
  graphs: [] as Omit<Graph, "ratio">[],
  answers: [] as ExtendedClientAnswer[],
  compare: {} as CompareAnswers | CompareValues,
  staff: [] as NameAndId[],
  steps: {} as StepsState,
  articles: [] as Article[],
  documents: [] as SharedDocument[],
  feedbacks: {} as Record<string, Feedback>,
  article: {} as ArticleWithContent,
  loading: {
    allSymptoms: false,
  },
  symptom_table_rows: [] as RowType[],
  embeddable_views: [] as EmbeddableView[],
};

export type State = typeof initialState;

export type Action =
  | ClientAction
  | FeedbackAction
  | CaregiverAction
  | CaregiverInviteAction
  | StaffAction;

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case "SET_BASIC_DETAILS":
      return { ...state, basic_details: action.data };
    case "SET_FEEDBACK":
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          [action.data.input_form_answer]: action.data,
        },
      };
    case "SET_GRAPHS":
      return { ...state, graphs: action.data.map(mapGraph) };
    case "SET_IFAS":
      return { ...state, answers: action.data };
    case "SET_COMPARE":
      return { ...state, compare: action.data };
    case "SET_CLIENT_STAFF":
      return { ...state, staff: action.data };
    case "SET_ARTICLES":
      return { ...state, articles: action.data };
    case "SET_ARTICLE":
      return { ...state, article: action.data };
    case "SET_DOCUMENTS":
      return { ...state, documents: action.data };
    case "CLEAR_CLIENT":
      return initialState;
    case "LOAD_ALL_SYMPTOMS":
      return { ...state, ...action.data };
    case "GET_EMBEDDABLE_VIEWS":
      return { ...state, embeddable_views: action.data };
    case "SET_STEPS":
      return {
        ...state,
        steps: {
          interval: action.interval,
          data: action.data,
        },
      };
    case "SET_CAREGIVERS":
      return {
        ...state,
        caregivers: action.data,
      };
    case "ADD_CAREGIVERS":
      return {
        ...state,
        caregivers: _.sortBy(
          [...state.caregivers, ...action.data],
          ["first_name", "last_name"],
        ),
      };
    case "REMOVE_CAREGIVERS":
      return {
        ...state,
        caregivers: _.sortBy(
          [
            ...state.caregivers.filter(
              cg => !action.data.map(c => c.id).includes(cg.id),
            ),
          ],
          ["first_name", "last_name"],
        ),
      };
    case "REVOKE_INVITE":
      return {
        ...state,
        caregivers: state.caregivers.filter(cg => cg.id !== action.data.id),
      };
    case "ADD_CAREGIVER":
      return {
        ...state,
        caregivers: _.sortBy(
          [...state.caregivers, action.data],
          ["first_name", "last_name"],
        ),
      };
    default:
      return state;
  }
};

export interface SymptomItemData {
  description: string;
  grade: number;
  date: string;
  relative_positivity: number;
  sources: any[];
}

export interface SymptomRowData {
  name: string;
  id: string;
  item_type: "Grade" | "Score";
  data: SymptomItemData[];
}

export const selectClientBasicDetails = (state: RootState) =>
  state.client.basic_details;

export const selectAllSymptomRows = (state: RootState) =>
  (state.client.symptom_table_rows as SymptomRowData[]) || [];
