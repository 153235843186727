import { AnyAction } from "redux";
import { ActionsObservable } from "redux-observable";
import { merge } from "rxjs";
import {
  debounceTime,
  groupBy,
  mergeMap,
  skip,
  switchMap,
  take,
  filter,
} from "rxjs/operators";

import { fetchTableData } from "common/actions/dataTable";
import { dataTableActions } from "common/reducers/data_table";

// Debounces data table requests and discards responses if there are newer requests pending.

const debounceTimeMs = process.env.NODE_ENV === "test" ? 0 : 400;

export const dataTableFetcher = (action$: ActionsObservable<AnyAction>) =>
  action$.pipe(
    filter(dataTableActions.startLoading.match),
    groupBy(({ payload }) => payload.name), // The logic should be table-specific
    mergeMap(group =>
      merge(
        group.pipe(take(1)), // Don't debounce first load
        group.pipe(skip(1), debounceTime(debounceTimeMs)),
      ).pipe(switchMap(fetchTableData)),
    ),
  );
