import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { uniqBy, isBoolean } from "lodash";
import dayjs from "dayjs";
import Message from "shared/models/message";
import { filterById, sortByDate } from "common/utils/general";
import { TaggerIssue } from "hcp/components/Staff/Tagger";
import { People, partChat } from "./conversations";

const maxTimeStamp = (t1?: string, t2?: string) =>
  dayjs(t1).isAfter(dayjs(t2)) ? t1 || t2 : t2 || t1;

const minTimeStamp = (t1?: string, t2?: string) =>
  dayjs(t1).isBefore(dayjs(t2)) ? t1 || t2 : t2 || t1;

type Issues = Record<string, TaggerIssue>;

type MessageState = {
  messages: Message[];
  // whether all messages in current conversation have been loaded
  allMessagesLoaded: boolean;
  loading: boolean;
  first_message_time?: string;
  last_message_time?: string;
  // issues linked to messages
  issues?: Issues;
};

export const initialMessageState = {
  messages: [],
  allMessagesLoaded: false,
  loading: true,
} as MessageState;

export const messageSlice = createSlice({
  name: "messages",
  initialState: initialMessageState,
  reducers: {
    addMessages(
      state,
      {
        payload,
      }: PayloadAction<{
        issue: Issues;
        data: Message[];
        allMessagesLoaded?: boolean;
        first_message_time: string;
        last_message_time: string;
        // people field is used in the conversation reducer
        people: People;
      }>,
    ) {
      state.allMessagesLoaded = isBoolean(payload.allMessagesLoaded)
        ? payload.allMessagesLoaded
        : state.allMessagesLoaded;
      state.loading = false;

      state.first_message_time = minTimeStamp(
        state.first_message_time,
        payload.first_message_time,
      );
      state.last_message_time = maxTimeStamp(
        state.last_message_time,
        payload.last_message_time,
      );

      state.messages = uniqBy(state.messages.concat(payload.data), "id").sort(
        (a, b) => sortByDate(a.created_at, b.created_at),
      );

      state.issues = {
        ...state.issues,
        ...payload.issue,
      };
    },
    // Don't update timestamp to prevent race condition:
    // t=1 poll, t=2 someone else sends message, t=3 I send message, t=4 poll messages older than t=3
    addMessage(state, { payload: message }: PayloadAction<Message>) {
      const existsInMessages = state.messages.find(
        msg => msg.id === message.id,
      );

      if (existsInMessages) {
        return state;
      }

      state.messages.push(message);
      state.messages.sort((a, b) => sortByDate(a.created_at, b.created_at));

      state.loading = false;
    },
    // id is used by the chat epic
    startLoadingMessages(state, { payload: _id }: PayloadAction<string>) {
      state.loading = true;
    },
    setNoMessages(state) {
      state.loading = false;
      state.allMessagesLoaded = true;
    },
    deleteMessage(state, { payload: messageId }: PayloadAction<string>) {
      state.messages = state.messages.filter(filterById(messageId));
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<MessageState>) => {
    builder.addCase(partChat, () => {
      return initialMessageState;
    });
  },
});

export const messageActions = messageSlice.actions;

messageActions.deleteMessage.match;
