import React from "react";
import { FormattedMessage } from "react-intl";
import { IxrsProfileDetailsStyled } from "./InvitePatients.styles";

export interface IxrsProfile {
  study_id: string;
  yob: string;
  gender: string;
  cohort: string;
  indication: string;
}

export interface IxrsProfileDetailsProps {
  profile: IxrsProfile;
}

const IxrsProfileDetails: React.FC<IxrsProfileDetailsProps> = ({ profile }) => {
  return (
    <IxrsProfileDetailsStyled data-test-id="IxrsProfileDetails">
      <h4>
        <FormattedMessage
          id="invites.new.details.title"
          values={{ study_id: profile.study_id }}
        />
      </h4>

      <div className="details">
        <div className="detail-item">
          <div className="title">
            <FormattedMessage id="attributes.study_id" />
          </div>
          <div className="value">{profile.study_id}</div>
        </div>
        <div className="detail-item">
          <div className="title">
            <FormattedMessage id="invites.new.details.gender" />
          </div>
          <div className="value">
            <FormattedMessage id={`invites.show.${profile.gender}`} />
          </div>
        </div>
        <div className="detail-item">
          <div className="title">
            <FormattedMessage id="invites.new.details.birth_year" />
          </div>
          <div className="value">{profile.yob}</div>
        </div>
        <div className="detail-item" />
      </div>
    </IxrsProfileDetailsStyled>
  );
};

export default IxrsProfileDetails;
