import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { Select } from "common/components/Form";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import { Icon } from "@netmedi/frontend-design-system";

export const DomainSelect = styled(Select)`
  min-width: 20rem;
  padding: 8px;
  & svg {
    ${rtl`margin-right: 0;`}
  }
`;

export const DomainLink = styled.a`
  cursor: pointer;
`;

export const SelectHeader = styled.div`
  padding: 8px;
  font-weight: normal;
`;

export const ControlIcon = styled.div`
  ${rtl`margin-left: 12px;`}
`;

export const GreenIcon = styled(Icon)`
  color: ${colors.green500};
`;

export const StyledOption = styled.div<{ $active: boolean }>`
  ${props =>
    props.$active
      ? css`
          & div {
            background-color: unset;
            font-weight: 500;
          }
        `
      : css`
          & div {
            font-weight: normal;
          }
        `}

  & div {
    color: black;
  }

  & svg {
    ${rtl`margin-right: 8px;`}
  }
`;
