import React from "react";
import Bar, { BarProps } from "./Bar";
import Disc, { DiscProps } from "./Disc";
import People, { PeopleProps } from "./People";
import Numbers, { NumbersProps } from "./Numbers";
import Grades, { GradesProps } from "./Grades";

const types = {
  bar: Bar,
  disc: Disc,
  people: People,
  numbers: Numbers,
  grades: Grades,
};

/** Type defines the presentation used in the visualization */
export type ProgressBarProps = { max?: number } & (
  | ({ type: "bar" } & BarProps)
  | ({ type: "disc" } & DiscProps)
  | ({ type: "people" } & PeopleProps)
  | ({ type: "numbers" } & NumbersProps)
  | ({ type: "grades" } & GradesProps)
);

/**
The ProgressBar component is used to visualize progression, fractions or percentages.
*/
export default function ProgressBar({ type, ...props }: ProgressBarProps) {
  const Component = types[type];
  return <Component {...(props as any)} />;
}
