import React from "react";
import { Field } from "react-final-form";
import { CheckBox } from "@netmedi/frontend-design-system";
import { FormField } from "common/components/Form";

import { ConsentInputsContainer, ConsentError } from "./InvitePage.styles";

type Props = { consents: any[] };

const ConsentInputs = ({ consents }: Props) => (
  <ConsentInputsContainer>
    {consents.map(consent => (
      <FormField
        key={consent.external_id}
        name={`consent[${consent.external_id}]`}
        inactiveLabel
        hideLabel
      >
        <Field
          name={`consent[${consent.external_id}]`}
          type="checkbox"
          value="1"
        >
          {fieldProps => (
            <CheckBox
              id={`consent_${consent.external_id}`}
              error={fieldProps.meta.submitFailed && fieldProps.meta.error}
              errorLabel={
                (<ConsentError>{fieldProps.meta.error}</ConsentError>) as any
              }
              label={
                (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: consent.consent_text,
                    }}
                  />
                ) as any
              }
              {...fieldProps.input}
            />
          )}
        </Field>
      </FormField>
    ))}
  </ConsentInputsContainer>
);

export default ConsentInputs;
