import React from "react";
import { Button, Icon } from "@netmedi/frontend-design-system";
import { FormattedMessage } from "react-intl";
import { IFInfoModal, ModalNav } from "./Modal.styles";
import { TitleAndBody, Dismiss } from "./Modal.types";
import { CustomButton } from "client/components/WelcomeClient/AppDownload/AppDownload.styles";

export type InputFormInfoModalProps = Partial<TitleAndBody> & {
  type: "input_form_info_modal";
};

const InputFormInfoModal = ({
  dismiss,
  body,
}: Omit<InputFormInfoModalProps, "type"> & Dismiss) => {
  return (
    <IFInfoModal onClick={(e: any) => e.stopPropagation()} borderRadius>
      <CustomButton
        position="absolute"
        right={24}
        top={24}
        margin={0}
        padding={0}
        color="#000"
        type="ghost"
        onClick={() => dismiss()}
      >
        <Icon name="remove" size="small" />
      </CustomButton>
      {body}
      <ModalNav className="input_form_modal_nav">
        <Button type="primary" onClick={() => dismiss()}>
          <FormattedMessage tagName="span" id={"body_map.modal.button"} />
        </Button>
      </ModalNav>
    </IFInfoModal>
  );
};

export default InputFormInfoModal;
