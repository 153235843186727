import { get, post, del, put, endpoints } from "common/utils/api";
import { redirect } from "common/utils/holvikaari";
import { Id } from "common/utils/types";
import {
  ClientTreatmentProgram,
  IForm,
  SiteTreatmentProgram,
  TaskAction,
  Team,
  TreatmentProgram,
  TreatmentProgramCategory,
} from "hcp/models/treatmentProgram";
import { StaffMember } from "hcp/components/MonitoringAndCareTeam/MonitoringAndCareTeam.types";

export type UpdateTaskAction = {
  type: "UPDATE_TASK";
  data: {
    treatmentProgramId: string;
    data: IForm;
  };
};

export type DeleteTaskAction = {
  type: "DELETE_TASK";
  data: { data: any; tp_id: string; task_id: string };
};

export type TreatmentProgramAction =
  | UpdateTaskAction
  | DeleteTaskAction
  | {
      type: "SET_ALL_TREATMENT_PROGRAMS";
      data: {
        data: ClientTreatmentProgram[];
        meta: { categories: TreatmentProgramCategory[] };
      };
    }
  | {
      type: "GET_CLIENT_TREATMENT_PROGRAMS";
      data: ClientTreatmentProgram[];
    }
  | {
      type: "UPDATE_ALL_TREATMENT_PROGRAMS";
      data: { data: ClientTreatmentProgram[]; client_id: string };
    }
  | {
      type: "SET_ALL_SITE_TREATMENT_PROGRAMS";
      data: SiteTreatmentProgram[];
    }
  | {
      type: "SET_TREATMENT_PROGRAM_STUBS";
      data: TreatmentProgram[];
    }
  | {
      type: "GET_TASK_ACTIONS";
      data: TaskAction[];
    }
  | { type: "CREATE_TASK"; data: { data: any; client_id: string } }
  | {
      type: "GET_TREATMENT_PROGRAM";
      data: ClientTreatmentProgram;
    }
  | { type: "TREATMENT_PROGRAM_ADD_STAFF"; data: StaffMember }
  | { type: "TREATMENT_PROGRAM_ADD_TEAM"; data: Team }
  | {
      type: "TREATMENT_PROGRAM_REMOVE_STAFF";
      staffId: number;
    }
  | {
      type: "TREATMENT_PROGRAM_REMOVE_TEAM";
      teamId: number;
    };

export const getAllTreatmentPrograms = (client_id: string) =>
  get(endpoints.client_treatment_programs.index(client_id)).then(
    (data): TreatmentProgramAction => ({
      type: "SET_ALL_TREATMENT_PROGRAMS",
      data: data,
    }),
  );

export const updateAllClientTreatmentPrograms = (
  client_id: string,
  treatment_programs: [],
  client_path: string,
) =>
  post(
    endpoints.client_treatment_programs.update_treatment_programs(client_id),
    {
      treatment_programs: treatment_programs,
    },
  )
    .then(
      (data): TreatmentProgramAction => ({
        type: "UPDATE_ALL_TREATMENT_PROGRAMS",
        data: { data, client_id },
      }),
    )
    .catch(({ data }) => {
      redirect(client_path + "/follows", true);
      throw data.errors;
    });

export const getClientTreatmentPrograms = (client_id: Id) =>
  get(endpoints.client.treatment_programs(client_id)).then(
    (data): TreatmentProgramAction => ({
      type: "GET_CLIENT_TREATMENT_PROGRAMS",
      data,
    }),
  );

export const getAllSiteTreatmentPrograms = () =>
  get(endpoints.treatment_programs.index()).then(
    (data: SiteTreatmentProgram[]): TreatmentProgramAction => ({
      type: "SET_ALL_SITE_TREATMENT_PROGRAMS",
      data,
    }),
  );

export const setTreatmentProgramStubs = (
  data: TreatmentProgram[],
): TreatmentProgramAction => ({
  type: "SET_TREATMENT_PROGRAM_STUBS",
  data,
});

export const getTreatmentProgramStubs = ({
  domains,
}: {
  domains?: string[] | undefined;
} = {}) =>
  get(endpoints.treatment_programs.stubs(domains)).then(
    setTreatmentProgramStubs,
  );

export const getTaskActions = () =>
  get(endpoints.task_actions)
    .then(
      (data: TaskAction[]): TreatmentProgramAction => ({
        type: "GET_TASK_ACTIONS",
        data,
      }),
    )
    .catch(({ data }) => {
      throw data.errors;
    });

export const updateTask = (client_id: string, tp_id: string, formData: any) =>
  put(
    endpoints.client_treatment_programs.tasks.update(
      client_id,
      tp_id,
      formData.id,
    ),
    formData,
  )
    .then(
      (data): TreatmentProgramAction => ({
        type: "UPDATE_TASK",
        data: {
          data,
          treatmentProgramId: tp_id,
        },
      }),
    )
    .catch(({ data }) => {
      throw data.errors;
    });

export const createTask = (client_id: string, tp_id: string, formData: any) =>
  post(
    endpoints.client_treatment_programs.tasks.create(client_id, tp_id),
    formData,
  )
    .then(
      (data): TreatmentProgramAction => ({
        type: "CREATE_TASK",
        data: { data, client_id },
      }),
    )
    .catch(({ data }) => {
      throw data.errors;
    });

export const deleteTask = (client_id: string, tp_id: string, task_id: string) =>
  del(
    endpoints.client_treatment_programs.tasks.destroy(
      client_id,
      tp_id,
      task_id,
    ),
  )
    .then(
      (data): TreatmentProgramAction => ({
        type: "DELETE_TASK",
        data: { data, tp_id, task_id },
      }),
    )
    .catch(({ data }) => {
      throw data.errors;
    });

export const getProgram = (programId: number) =>
  get(endpoints.treatment_program.index(programId))
    .then(
      (data): TreatmentProgramAction => ({
        type: "GET_TREATMENT_PROGRAM",
        data: data,
      }),
    )
    .catch(({ data }) => {
      throw new Error(data.errors);
    });

export const addStaff = (programId: number, staffId: number) =>
  post(endpoints.treatment_program.addStaff(programId), {
    staff_id: staffId,
  })
    .then(
      (data): TreatmentProgramAction => ({
        type: "TREATMENT_PROGRAM_ADD_STAFF",
        data: data,
      }),
    )
    .catch(({ data }) => {
      throw new Error(data.errors);
    });

export const addTeam = (programId: number, teamId: number) =>
  post(endpoints.treatment_program.addTeam(programId), {
    id: teamId,
  })
    .then(
      (data): TreatmentProgramAction => ({
        type: "TREATMENT_PROGRAM_ADD_TEAM",
        data: data,
      }),
    )
    .catch(({ data }) => {
      throw new Error(data.errors);
    });

export const removeStaff = (programId: number, staffId: number) =>
  del(endpoints.treatment_program.removeStaff(programId, staffId))
    .then(
      (): TreatmentProgramAction => ({
        type: "TREATMENT_PROGRAM_REMOVE_STAFF",
        staffId: staffId,
      }),
    )
    .catch(({ data }) => {
      throw new Error(data.errors);
    });

export const removeTeam = (programId: number, teamId: number) =>
  del(endpoints.treatment_program.removeTeam(programId, teamId))
    .then(
      (): TreatmentProgramAction => ({
        type: "TREATMENT_PROGRAM_REMOVE_TEAM",
        teamId: teamId,
      }),
    )
    .catch(({ data }) => {
      throw new Error(data.errors);
    });
