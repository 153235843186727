import {
  partition,
  map,
  groupBy,
  isEmpty,
  isNil,
  takeWhile,
  zip,
  keyBy,
  omit,
} from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { parseIsoString } from "common/utils/general";
import { isSameInterval } from "../SymptomTableCompact/isSameInterval";
import { Steps, StepsState } from "../SymptomTableCompact/types";
import {
  SymptomTableProps,
  ValidInterval,
  RowType,
  ParentSources,
} from "./SymptomTable";
import { SymptomTableStepsProps } from "./SymptomTableSteps";

export const hasStepsData = (steps: StepsState): boolean =>
  Array.isArray(steps.data?.observations) && steps.data.observations.length > 0;

export const getLastStepsItem = (data: Steps[]): Dayjs =>
  dayjs(data[data.length - 1].end_time);

/**
 * Wraps adjacent non-empty values together.
 *
 * @param collection
 * @returns array
 * @example wrapObjects([null, null, { a: 1 }, { a: 2 }])
 * returns: [null, null, [{ a: 1 }, { a: 2 }]]
 */
export const wrapObjects = <T,>(collection: T[]): (T | NonNullable<T>[])[] =>
  collection.reduce(
    (
      acc: (T | NonNullable<T>[])[],
      curr: T,
      i: number,
      arr: T[],
    ): (T | NonNullable<T>[])[] => {
      const prev = arr[i - 1];

      if (!isEmpty(curr) && !isEmpty(prev)) {
        return acc;
      }

      if (isEmpty(curr)) {
        acc.push(curr);
        return acc;
      }

      const nextNonEmptyValues = takeWhile(arr.slice(i), v => !isEmpty(v));
      acc.push(nextNonEmptyValues as NonNullable<T>[]);
      return acc;
    },
    [],
  );

export const mapStepsToGraph = ({
  columnTimes,
  steps,
  interval,
}: SymptomTableStepsProps) =>
  columnTimes
    .map(([start_date, end_date]) => {
      const isMatchingColumn = isSameInterval({
        start_date: start_date.valueOf(),
        end_date: end_date.valueOf(),
        interval,
      });
      return steps.data.observations.find(isMatchingColumn);
    })
    .map((step: Steps | undefined) => {
      if (step === undefined) {
        return null;
      }
      const valueType = interval === "day" ? "value" : "daily_average";
      return {
        value: step[valueType],
        time: parseIsoString(step.start_time).valueOf(),
      };
    });

type FindMatchingGradingProps = {
  start: number;
  end: number;
  interval: ValidInterval;
};

export const findMatchingGrading =
  ({ start, end, interval }: FindMatchingGradingProps) =>
  ({ data }: SymptomTableProps["rows"][number]) =>
    data?.find(grading => {
      if (isNil(grading) || isNil(grading.start) || isNil(grading.end)) {
        return undefined;
      }
      return (
        grading.start.isSame(dayjs(start), interval) &&
        grading.end.isSame(dayjs(end), interval)
      );
    });

const isGrade = (row: RowType) => row.item_type === "Grade";
const isUrgency = (row: RowType) => row.item_type === "SymptomUrgency";

const sortByLatestGrade = (rows: RowType[]) => {
  return rows.sort((a: RowType, b: RowType) => {
    const { data: dataA = [] } = a;
    const { data: dataB = [] } = b;

    const lastItemA = [...dataA.filter(item => item)].pop();
    const lastItemB = [...dataB.filter(item => item)].pop();

    const lastItemIndexA = dataA.indexOf(lastItemA);
    const lastItemIndexB = dataB.indexOf(lastItemB);

    const lastItemIndex =
      lastItemIndexA > lastItemIndexB ? lastItemIndexA : lastItemIndexB;

    const gradeA = dataA[lastItemIndex]?.grade ?? 0;
    const gradeB = dataB[lastItemIndex]?.grade ?? 0;

    return gradeB - gradeA;
  });
};

export const bundleParentRows = (allRows: RowType[]): RowType[] => {
  const [urgencyRows, rows] = partition(allRows, isUrgency);
  const rowsBefore = rows.slice(0, Math.max(rows.findIndex(isGrade), 0));
  const rowsAfter = rows.slice(rows.map(isGrade).lastIndexOf(true) + 1);
  const gradeRows = rows.filter(isGrade);

  const urgencies = keyBy(urgencyRows, "id");
  gradeRows.forEach((gradeRow: RowType) => {
    gradeRow.data?.forEach((grade: any, index: number) => {
      if (!grade) return;
      grade.urgency = urgencies[gradeRow.id]?.data?.[index]?.grade;
    });
  });

  const [rowsToBeBundled, nonBundledRows] = partition(gradeRows, "parent_id");

  const bundledRows = map(
    groupBy(rowsToBeBundled, "parent_id"),
    (rows, parent_id) => {
      const parent_name = rows[0].parent_name;
      const sub_rows = sortByLatestGrade(rows);
      const cumulatedData = zip(...rows.map(row => row.data));

      const highestCumulatedValues = cumulatedData.map(arr => {
        const value = arr.reduce((p, c) => {
          if (c && p) {
            if (c.grade > p.grade) {
              return c;
            }
            return p;
          }

          if (p && !c) {
            return p;
          }

          if (c && !p) {
            return c;
          }

          return null;
        });

        const max_urgency = Math.max(...arr.map((x: any) => x?.urgency ?? 0));
        const parentSourceData = (data_points: RowType[]) =>
          data_points.reduce(
            (result: ParentSources, row) => {
              row.data?.forEach(d => {
                if (
                  (d && d.date === value?.date && d?.grade === value?.grade) ||
                  d?.urgency === max_urgency
                ) {
                  result[row.name] = result[row.name] ?? [];
                  result[row.name].push(d);
                }
              });
              return result;
            },
            { other: [] },
          );
        const parentSources = omit(parentSourceData(sub_rows), "other");

        return (
          value && {
            ...value,
            grade_min: value.grade,
            urgency: max_urgency,
            parent_sources: parentSources,
          }
        );
      });

      return {
        id: parent_id,
        name: parent_name,
        description: "",
        item_type: "Grade",
        data: [...highestCumulatedValues],
        sub_rows,
      } as RowType;
    },
  );

  return [...rowsBefore, ...nonBundledRows, ...bundledRows, ...rowsAfter];
};
