import React from "react";
import { includes, isEmpty, difference } from "lodash";
import { FormattedMessage } from "react-intl";
import { SiteSettings } from "common/utils/holvikaari";
import { Header, ModalHeader } from "./ManageDomainsModal.styles";
import { Option, Domain } from "common/models/domain";
import { User } from "common/models/user";
import { isAnonymisedDomain, isRestrictedDomain } from "common/utils/domains";

export type ManageDomainsModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  view?: "Conversations" | "Profile";
  getConversations?: () => void;
};

export function sortByLabel(a: Option, b: Option) {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
}

export const filterItems = (items: Option[], searchText?: string) => {
  const matchingDomains = items
    .filter(item =>
      includes(item.label.toLowerCase(), searchText?.toLowerCase()),
    )
    .sort(sortByLabel);
  return searchText ? matchingDomains : items;
};

export const taskSubscrioptionDomains = (user: Partial<User>) => {
  const taskDomainList = user?.task_subscriptions?.map(tp => tp.domain) ?? [];
  return [...new Set(taskDomainList)].filter((v: any) => v) as string[];
};

export const getDomainTranslation = (domain: string) =>
  SiteSettings.domains.find((d: Domain) => d.domain === domain).translation ??
  "";

export const validateDomains = (
  values: Record<string, any>,
  user: Partial<User>,
) => {
  const domains = values.client.domains ?? [];

  if (isEmpty(domains)) {
    return <FormattedMessage id="form_validation.required_domains" />;
  }

  const missingTaskDomains = difference(
    taskSubscrioptionDomains(user),
    domains,
  );
  if (missingTaskDomains.length > 0) {
    return (
      <FormattedMessage
        id="form_validation.domains.missing_task_domain"
        values={{
          domain: getDomainTranslation(missingTaskDomains[0] ?? ""),
        }}
      />
    );
  }

  const inAnonymisedDomain = domains.some((domain: string) =>
    isAnonymisedDomain(domain),
  );
  if (inAnonymisedDomain && domains.length > 1) {
    return <FormattedMessage id="form_validation.domains.inconsistency" />;
  }

  const inRestrictedDomain = domains.some((domain: string) =>
    isRestrictedDomain(domain),
  );
  if (inRestrictedDomain && domains.length > 1) {
    return <FormattedMessage id="form_validation.domains.too_long" />;
  }

  return undefined;
};

export const renderModalHeader: JSX.Element = (
  <ModalHeader>
    <FormattedMessage id="people.add_remove_domains">
      {text => <Header>{text}</Header>}
    </FormattedMessage>
  </ModalHeader>
);

export const domainDisabled = (
  domain: string,
  selectedDomains: string[],
  domainsWithTasks: string[],
  domainsWithOpenIssues: string[],
) => {
  // Disabled if
  // - The domain is selected
  // - The domain has tasks or open issues
  if (
    selectedDomains.includes(domain) &&
    (domainsWithTasks.includes(domain) ||
      domainsWithOpenIssues.includes(domain))
  ) {
    return true;
  }
  // Always enabled if no domains are selected
  if (selectedDomains?.length === 0) return false;

  return false;
};
