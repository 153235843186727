import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Button } from "@netmedi/frontend-design-system";
import { FormattedMessage } from "react-intl";
import { get, post, endpoints } from "common/utils/api";
import { Modal, ModalNav } from "./Modal.styles";
import { Dismiss } from "./Modal.types";

// this could be any requst, the backend makes the session expire if
// requests are sent after the expiration limit
const triggerExpiration = () => get(endpoints.session_expiration);

const extendSession = () => post(endpoints.session_expiration);

export type ExpirationProps = {
  type: "expiration_warning";
  className?: string;
  timeOfExpiration: number;
};

// there doesn't seem to be any in-built way of printing time difference
const formatTimeUntilExpiration = (timeUntilExpiration: number) => {
  const timeInSeconds = Math.floor(timeUntilExpiration / 1000);
  const seconds = timeInSeconds % 60;
  const minutes = Math.floor(timeInSeconds / 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

/**
 * The expiration modal warns of the session being about to expire.
 * It has a button to extend the session, and a button for signing out.
 **/
const ExpirationModal = ({
  className,
  dismiss,
  timeOfExpiration,
}: ExpirationProps & Dismiss) => {
  const [timeUntilExpiration, setTimeUntilExpiration] = useState(
    timeOfExpiration - dayjs().valueOf(),
  );

  useEffect(() => {
    let timeoutID: NodeJS.Timeout;
    const timeUpdateLoop = () => {
      const newTime = timeOfExpiration - dayjs().valueOf();
      if (newTime < 0) {
        triggerExpiration();
        setTimeUntilExpiration(0);
        return;
      }
      setTimeUntilExpiration(newTime);
      timeoutID = setTimeout(timeUpdateLoop, 1000);
    };
    timeUpdateLoop();
    return () => timeoutID && clearTimeout(timeoutID);
  }, [timeOfExpiration]);

  return (
    <Modal
      className={className}
      onClick={(e: any) => e.stopPropagation()}
      role="alertdialog"
    >
      <FormattedMessage
        id={"modal.session_about_to_expire"}
        values={{
          time_until_expiration: (
            <span style={{ fontFamily: "monospace" }}>
              {formatTimeUntilExpiration(timeUntilExpiration)}
            </span>
          ),
        }}
      />
      <ModalNav>
        <Button
          size="small"
          onClick={() => {
            extendSession();
            dismiss();
          }}
        >
          <FormattedMessage id={"modal.extend_session"} />
        </Button>
      </ModalNav>
    </Modal>
  );
};

export default ExpirationModal;
