import styled from "styled-components";

export const KeyclaokStatusPanelContainer = styled.div`
  border: 1px solid #e5e5e5;
  padding: 1rem 2rem;
`;

export const KeycloakStatusPanelHeading = styled.h4``;

export const KeycloakStatusPanelContent = styled.div``;
export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
`;
export const Actions = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Text = styled.div<{ status: string }>`
  font-weight: 500;
  color: ${({ status, theme: { colors } }) =>
    status === "ok"
      ? colors.green700
      : status === "error"
        ? colors.red700
        : status === "unknown"
          ? colors.yellow850
          : colors.gray700};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;
