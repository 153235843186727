import { User } from "common/models/user";
import { UserAction } from "common/actions/user";
import { SetArticlesAction } from "client/actions/client";
import { UserConsentsAction } from "client/actions/user";
import { RootState } from "store";

export default (
  state: User = {} as User,
  action: UserAction | SetArticlesAction | UserConsentsAction,
): User => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, ...action.data, ...action.meta };
    case "SET_ARTICLES":
      return { ...state, has_articles: action.data && action.data.length > 0 };
    case "SET_USER_CONSENTS":
      return { ...state, consents: action.data };
    case "CHANGE_USER_CONSENT":
      return {
        ...state,
        consents: state.consents
          ? state.consents.map(c => (c.id === action.data.id ? action.data : c))
          : [],
      };
    case "SET_PATIENT_ADVISORY_BOARD":
      return { ...state, show_patient_advisory_board: action.data };
    case "SET_OFFERABLE_CONSENTS":
      return { ...state, offerable_consents: action.data };
    default:
      return state;
  }
};

export const selectUser = (state: RootState) => state.user;
