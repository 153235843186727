import React, { useEffect, useState } from "react";
import {
  injectIntl,
  IntlProvider,
  WrappedComponentProps,
  IntlShape,
} from "react-intl";
import html2canvas from "html2canvas";
import { Spinner } from "@netmedi/frontend-design-system";
import SymptomPage, { pagination } from "./SymptomPage/SymptomPage";
import PdfSettingsPanel, { PdfSettings } from "./Settings/PdfSettingsPanel";
import { endpoints, get } from "common/utils/api";
import { useDispatch, useSelector } from "react-redux";
import { selectAllSymptomRows } from "client/reducers/client";
import useRequestStatus from "common/hooks/useRequestStatus";
import { getAllSymptoms } from "client/actions/client";
import { GenerationStatus } from "./types";
import pdfMake from "pdfmake/build/pdfmake";
import GeneralClientPdf from "./PdfDefinitions/GeneralClientPdf";
import { RootState } from "store";
import { formatDate } from "common/utils/general";
import dayjs from "dayjs";

type PDFExportProps = {
  getSteps: any;
  client_id: string;
} & WrappedComponentProps;

interface ClientDetails {
  id: string;
  first_name: string;
  last_name: string;
}

const make = async (client: ClientDetails, intl: IntlShape) => {
  // Lazy loading fonts
  const vfsModule = import("vfsFonts");

  pdfMake.fonts = {
    Arial: {
      normal: `Arial.ttf`,
      bold: `Arial Bold.ttf`,
      italics: `Arial Italic.ttf`,
      bolditalics: `Arial Bold Italic.ttf`,
    },
  };

  const filename = `${client.last_name} ${client.first_name} ${dayjs().format(
    "YYYY-MM-DD",
  )}.pdf`;

  const pageElements = document.querySelectorAll<HTMLElement>(
    ".symptom-div-to-print",
  );

  try {
    const pagePromises = Array.from(pageElements).map(
      (pageHtmlElement: HTMLElement) =>
        html2canvas(pageHtmlElement, {
          onclone: function (tableDiv) {
            const div = tableDiv.querySelector(
              ".symptom-div-to-print",
            ) as HTMLElement;
            div ? (div.style.opacity = "1") : "";
          },
        }),
    );

    const symptomGraphCanvases = await Promise.all(pagePromises);
    const symptomGraphImages = symptomGraphCanvases.map(canvas =>
      canvas.toDataURL("img/png"),
    );

    const generalPdf = new GeneralClientPdf(intl, {
      images: symptomGraphImages,
    });

    pdfMake.vfs = (await vfsModule).default;
    pdfMake.createPdf(generalPdf.definition()).download(filename);
  } catch (e: any) {
    throw new Error(`Couldn't generate PDF. ${e.message}`);
  }
};

const defaultReportSections = {
  symptoms: true,
  values: false,
  forms: false,
  messages: false,
};

const PDFExport = ({ client_id, intl }: PDFExportProps) => {
  const rows = useSelector(selectAllSymptomRows);
  const intlState = useSelector((state: RootState) => state.intl);
  const [loadingSymptoms, setLoading] = useRequestStatus("symptoms", client_id);
  const [status, setStatus] = useState<GenerationStatus>("idle");
  const [settings, setSettings] = useState<PdfSettings>({
    sections: defaultReportSections,
    startDate: formatDate(dayjs().startOf("year")),
    endDate: formatDate(dayjs()),
  });
  const [client, setClient] = useState<ClientDetails>({
    id: "",
    first_name: "",
    last_name: "",
  });
  const loading = loadingSymptoms === "loading";
  const dispatch = useDispatch();

  useEffect(() => {
    get(endpoints.users.user(client_id))
      .then(({ data: { attributes: client } }) => setClient(client))
      .catch(() => {});

    if (loadingSymptoms === "idle") {
      setLoading("succeeded");
      getAllSymptoms(client_id)
        .then(dispatch)
        .catch(() => setLoading("failed"));
    }
  }, []);

  const generate = () => {
    setStatus("generating");
    // Using timeout because printing blocks the rendering and UI lags behind.
    setTimeout(() => make(client, intl).then(() => setStatus("finished")));
  };

  const symptomPagination = pagination(rows);

  return (
    <IntlProvider textComponent="span" locale={`${intl.locale}`} {...intlState}>
      <>
        <PdfSettingsPanel
          onGeneratePdf={generate}
          settings={settings}
          setSettings={setSettings}
          generationStatus={status}
          loadingData={loading}
        />
        <div
          style={{
            overflow: "hidden",
            position: "relative",
          }}
        >
          {loading && <Spinner />}

          {settings.sections?.symptoms &&
            symptomPagination.pages.map(pageNo => {
              return (
                <div
                  key={`${pageNo}`}
                  style={{
                    fontFamily: "sans-serif",
                    opacity: "0.01",
                    position: "absolute",
                  }}
                >
                  <SymptomPage
                    rows={rows}
                    pageNo={pageNo}
                    pageSize={symptomPagination.pageLength}
                    pageCount={symptomPagination.pageCount}
                    settings={settings}
                    interval="week"
                  />
                </div>
              );
            })}
        </div>
      </>
    </IntlProvider>
  );
};

export default injectIntl(PDFExport);
