import MarkdownIt from "markdown-it";
import markdownContainer from "markdown-it-container";
import markdownLinkAttributes from "markdown-it-link-attributes";
import markdownItUnderline from "markdown-it-underline";
import React from "react";
import ReactDOMServer from "react-dom/server";
import ReactPlayer from "react-player";
import tooltip from "./tooltip";
import modal from "./modal";
import icon from "./icon";
import Info from "./Info";
import MediaWithText from "./MediaWithText";
import Token from "markdown-it/lib/token";
import { Extension } from "./Markdown.types";
import {
  CenteredText,
  InlineVideo,
  MarkdownContainer,
} from "./Markdown.styles";

const defaultOptions = {
  html: false,
  linkify: true,
};

// see markdown-it-link-attributes
const defaultLinkOptions = {
  attrs: {
    target: "_blank",
    style: "text-decoration:underline;",
  },
};

/*
Add extensions by adding items in this array. Render method should return a React component.
Use enableInnerMarkdown to allow markdown inside the tag, or disable for extensions like inline videos.
*/
const extensions: Extension[] = [
  { name: "video", render: content => renderPlayer(content) },
  {
    name: "info",
    render: content => renderInfo(content),
    enableInnerMarkdown: true,
  },
  {
    name: "small",
    render: content => renderSmallText(content),
    enableInnerMarkdown: true,
  },
  {
    name: "mediaWithText",
    render: content => renderMediaWithText(content),
    enableInnerMarkdown: true,
  },
  {
    name: "center",
    render: content => renderCenterText(content),
    enableInnerMarkdown: true,
  },
  {
    name: "underline",
    render: content => renderCenterText(content),
    enableInnerMarkdown: true,
  },
  tooltip,
  modal,
];

/**
 * Markdown component renders markdown syntax into HTML, using `markdown-it` (https://markdown-it.github.io/).
 */
export default function Markdown({
  container = "div",
  extensions = false,
  ...props
}: MarkdownProps) {
  const newProps = {
    ...props,
    container,
    extensions,
    source: props.source || "", // MarkdownIt doesn't handle null gracefully
    options: { ...defaultOptions, ...props.options },
    linkOptions: { ...defaultLinkOptions, ...props.linkOptions },
  };

  const { options, linkOptions } = newProps;
  const md = new MarkdownIt(options)
    .use(markdownLinkAttributes, linkOptions)
    .use(markdownItUnderline);

  if (newProps.extensions) {
    enableExtensions(md);
  }

  /*
  Dangerously setting inner html here is intended, as we allow html generated by
  Markdown-it to be rendered correctly for clients. Decorators, like react-remarkable,
  use the same method. Additionally, we're disabled HTML for now and users aren't allowed to input Markdown.
  */
  return (
    <MarkdownContainer as={container}>
      <span dangerouslySetInnerHTML={{ __html: md.render(newProps.source) }} />
    </MarkdownContainer>
  );
}

// Basically same as Markdown but without styled-component styles due to incompability issues with coffeescript files
export const PlainMarkdown = ({
  container = "div",
  extensions = false,
  ...props
}: MarkdownProps) => {
  const newProps = {
    ...props,
    container,
    extensions,
    source: props.source || "", // MarkdownIt doesn't handle null gracefully
    options: { ...defaultOptions, ...props.options },
    linkOptions: { ...defaultLinkOptions, ...props.linkOptions },
  };
  const Container = container;

  const { options, linkOptions } = newProps;
  const md = new MarkdownIt(options)
    .use(markdownLinkAttributes, linkOptions)
    .use(markdownItUnderline);

  if (newProps.extensions) {
    enableExtensions(md);
  }

  return (
    <Container>
      <span dangerouslySetInnerHTML={{ __html: md.render(newProps.source) }} />
    </Container>
  );
};

const enableExtensions = (md: MarkdownIt) => {
  extensions.map(extension => {
    md.use(markdownContainer, extension.name, {
      render: (tokens: Token[], idx: number) => {
        // nesting=1 is the opening tag
        if (tokens[idx].nesting === 1) {
          const extTokens = findTokensBetween(tokens, idx, extension.name);

          const content = extension.enableInnerMarkdown
            ? md.renderer.render(extTokens, md.options, (md as any).env)
            : firstInlineToken(extTokens)?.content;

          // display-none is quantum dirty/clever hack to hide the default rendering of the nodes
          // inside the custom container (the content is rendered by the react component instead)
          return (
            ReactDOMServer.renderToString(extension.render(content as string)) +
            "<div style='display: none;'>"
          );
        } else {
          return "</div>";
        }
      },
    }).use(icon);
  });
};

const firstInlineToken = (tokens: Token[]) =>
  tokens.find(t => t.type === "inline");

// find the tokens between the extension opening and closing tags
const findTokensBetween = (
  tokens: Token[],
  afterIndex: number,
  name: string,
) => {
  const start = tokens.findIndex(
    (e, index) =>
      e.type === "container_" + name + "_open" && index >= afterIndex,
  );
  const end = tokens.findIndex(
    (e, index) =>
      e.type === "container_" + name + "_close" && index >= afterIndex,
  );
  return tokens.filter((_, index) => index > start && index < end);
};

/* Extension renderers */
const renderPlayer = (content: string) => (
  <InlineVideo>
    <ReactPlayer height="100%" width="100%" controls url={content} />
  </InlineVideo>
);

const renderInfo = (content: string) => <Info>{content}</Info>;

const renderMediaWithText = (content: string) => (
  <MediaWithText>{content}</MediaWithText>
);

/*
  Dangerously setting inner html here is intended, as we allow html generated by
  Markdown-it to be rendered correctly for clients. Decorators, like react-remarkable,
  use the same method. Additionally, we're disabled HTML for now and users aren't
  allowed to input Markdown.
  */
const renderSmallText = (content: string) => (
  <small dangerouslySetInnerHTML={{ __html: content }} />
);

const renderCenterText = (content: string) => (
  <CenteredText dangerouslySetInnerHTML={{ __html: content }} />
);

export type MarkdownProps = {
  /** The element which the markdown component is wrapped to. */
  container?: any;
  /** Options to pass to `markdown-it` implementation */
  options?: any;
  /** Options to pass to `markdown-it-link-attributes` extension. By default links open to `_blank` */
  linkOptions?: any;
  /** Set to `true` to enable our custom extensions */
  extensions?: boolean;
  /** The markdown source */
  source?: string;
};
