import React from "react";
import { SimpleLineGraph } from "common/components/SimpleLineGraph";
import { mapStepsToGraph, wrapObjects } from "./helpers";
import { StepsGraphTd } from "./SymptomTableStepsGraph.styles";
import { SymptomTableStepsProps } from "./SymptomTableSteps";

export type StepsGraphProps = {
  isCompact: boolean;
};

type SymptomTableStepsGraphProps = SymptomTableStepsProps & StepsGraphProps;

export const SymptomTableStepsGraph = (props: SymptomTableStepsGraphProps) => {
  const { columnTimes, interval, steps, isCompact } = props;

  const graph = mapStepsToGraph({ columnTimes, steps, interval });
  const wrapped = wrapObjects(graph);

  return (
    <>
      {!isCompact && <td>&nbsp;</td>}
      {wrapped.map((graphData, i: number) => {
        if (graphData === null || !Array.isArray(graphData)) {
          return <td key={i}>&nbsp;</td>;
        }
        return (
          <StepsGraphTd
            isCompact={isCompact}
            key={i}
            colSpan={graphData.length}
          >
            <SimpleLineGraph height={120} data={graphData} />
          </StepsGraphTd>
        );
      })}
    </>
  );
};
