import { connect } from "react-redux";
import DocumentsSharedByClient from "hcp/components/Documents/SharedByClient";
import { RootState } from "store";
import { getSentDocuments } from "../actions/sharedDocuments";

const mapStateToProps = (state: RootState) => ({
  documents: state.sharedDocuments.sent,
});

export default connect(mapStateToProps, {
  getSentDocuments,
})(DocumentsSharedByClient);
