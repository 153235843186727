import React, { ReactNode, useState } from "react";

const defaultValue: { [id: string]: ReactNode } = {};
type ContainerType = typeof defaultValue;
type AriaDescriptionSetter = React.Dispatch<
  React.SetStateAction<ContainerType>
>;

export const AriaDescriptionContext = React.createContext<
  [ContainerType, AriaDescriptionSetter]
>([defaultValue, () => {}]);

/**
 * Provider component for useAriaDescription hooks.
 */
const AriaDescriptionProvider: React.FC = ({ children }) => {
  const [ariaDescriptions, setAriaDescriptions] =
    useState<ContainerType>(defaultValue);

  return (
    <AriaDescriptionContext.Provider
      value={[ariaDescriptions, setAriaDescriptions]}
    >
      {children}

      {/* Render the descriptions to be referred by the children */}
      <div className="aria-description-container" style={{ display: "none" }}>
        {Object.keys(ariaDescriptions).map((k: string) => (
          <span key={k} id={k}>
            {ariaDescriptions[k]}
          </span>
        ))}
      </div>
    </AriaDescriptionContext.Provider>
  );
};

export default AriaDescriptionProvider;
