import styled from "styled-components";
import rtl from "styled-components-rtl";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import { Avatar, Icon } from "@netmedi/frontend-design-system";
import rem, {
  padding,
} from "@netmedi/frontend-design-system/dist/styles/styleHelpers";

export const RemoveIcon = styled(Icon).attrs(() => ({
  "data-testid": "remove-icon",
}))`
  margin-top: 14px;
  cursor: pointer;
  color: ${colors.blue500};
`;

export const UserAvatar = styled(Avatar).attrs(() => ({
  "data-testid": "user-avatar",
}))`
  &&& {
    vertical-align: top;
  }
  ${rtl`margin-left: 3px;
  margin-right: 12px;`}

  min-width: 48px;
`;

export const UserContainer = styled.div.attrs(() => ({
  "data-testid": "user-container",
}))`
  width: inherit;
  white-space: normal;
  display: flex;
  justify-content: space-between;
`;

export const DomainSectionContainer = styled.div.attrs(() => ({
  "data-testid": "domain-section-container",
}))`
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const DomainTooltipContainer = styled.div.attrs(() => ({
  "data-testid": "domain-tooltip-container",
}))`
  ${padding("xxs", "xxs", "xxs", 0)}
  max-height: 60vh;
  overflow: auto;

  li div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const AvatarAndInfo = styled.div`
  display: flex;
  justify-content: start;
`;

export const AdditionalNotes = styled.div.attrs(() => ({
  "data-testid": "additional-notes",
}))`
  font-size: ${rem(12)};
  display: flex;
  justify-content: start;
  color: ${colors.red700};
  #icon {
    ${rtl`padding-right: 8px;`}
    vertical-align: top;
  }
  #inactive {
    color: ${colors.red700};
  }
`;

export const StarIcon = styled(Icon).attrs(() => ({
  "data-testid": "star-icon",
}))`
  color: ${colors.yellow500};
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  line-height: ${rem(0)} !important;
  margin-top: 31px;
`;

export const InfoField = styled.span`
  white-space: normal;
  #domains {
    color: ${colors.gray700};
    max-width: 300px;
  }
`;

export const NameAndLabels = styled.span`
  ${rtl`text-align: left;`}
  white-space: normal !important;
  display: flex;
  text-overflow: normal;

  & > span {
    line-height: ${rem(24)};
  }
`;

export const LabelContainer = styled.span`
  ${rtl`margin: 8px 0px 0px 30px;`}
`;
