import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = { locale: string; messages?: any };

const intlSlice = createSlice({
  name: "intl",
  initialState: {} as Partial<State>,
  reducers: {
    set(state, action: PayloadAction<State>) {
      return { ...state, ...action.payload };
    },
  },
});

export const intlActions = intlSlice.actions;
export default intlSlice.reducer;
