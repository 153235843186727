import Bugsnag from "bugsnag";
import { Middleware } from "redux";

// Catch errors within redux so we can forward them to Bugsnag
// Could anonymize store state here if necessary,
// omitting it for now

const errorCatcherMiddleware: Middleware = () => next => action => {
  try {
    return next(action);
  } catch (err: any) {
    if (process.env.NODE_ENV === "development") {
      console.error(err); // eslint-disable-line no-console
    } else {
      Bugsnag.notify(err, undefined, (_, event) => {
        event.addMetadata("metaData", {
          action: JSON.stringify(action, null, 2), // adds space for readability
          location: window.location.pathname,
        });
      });
    }
    throw err; // still need to throw it for the error to show
  }
};

export default errorCatcherMiddleware;
