import api2 from "./api2";

export type Api2 = ReturnType<typeof api2>;

let realApi: Api2;

const getRealApi = () => {
  if (!realApi) {
    realApi = api2();
  }
  return realApi;
};

const getApiClient = (): Api2 => {
  if (process.env.NODE_ENV !== "test") return getRealApi();

  return (global as any).apiMock?.mock ?? getRealApi();
};

export default getApiClient;
