import { Domain } from "common/models/domain";
import { SiteSettings } from "./holvikaari";

export const isRestrictedDomain = (domainName: string) =>
  SiteSettings.restricted_domains.some(
    (domain: Domain) => domain.domain === domainName,
  );

export const isAnonymisedDomain = (domainName: string) =>
  SiteSettings.anonymised_domains.some(
    (domain: Domain) => domain.domain === domainName,
  );
