import React from "react";
import { Icon } from "@netmedi/frontend-design-system";
import { FormattedMessage } from "react-intl";

import { useSelector } from "store";
import { mobileSelector } from "common/selectors";

import { ManageDomainsButtonStyled } from "./ManageDomainsModal.styles";

type Props = {
  setShowManageDomainsModal: React.Dispatch<React.SetStateAction<boolean>>;
  // for styled components to work
  className?: string;
  customOnClick?: () => void;
};

export const ManageDomainsButton = ({
  setShowManageDomainsModal,
  className,
  customOnClick,
}: Props) => {
  const mobile = useSelector(mobileSelector);

  return (
    <ManageDomainsButtonStyled
      id={`manageDomainsButton${mobile ? "_mobile" : ""}`}
      onClick={() => {
        setShowManageDomainsModal(true);
        customOnClick && customOnClick();
      }}
      className={`manage_domains_button ${className}`}
      type="ghost"
    >
      <Icon align="left" size="medium" name="edit_16px" />
      <FormattedMessage id="people.add_remove_domains" />
    </ManageDomainsButtonStyled>
  );
};
