import React from "react";
import { openModal } from "common/actions/modal";
import { FormattedMessage } from "react-intl";
import { Image } from "shared/components/Modal/ImagesModal";
import store from "store";
import { ViewImagesButton } from "./ViewImages.styles";

interface ViewImagesProps {
  images?: Image[];
  noMargin?: boolean;
}

const ViewImages = ({ images, noMargin }: ViewImagesProps) => {
  return (
    <ViewImagesButton
      type="ghost"
      icon="attachment_16px"
      style={noMargin ? { margin: "0" } : undefined}
      onClick={() =>
        store.dispatch(
          openModal({
            type: "images_modal",
            disableOutsideClick: true,
            images: images,
          }),
        )
      }
    >
      <FormattedMessage id="generic.view_images" />
    </ViewImagesButton>
  );
};

export default ViewImages;
