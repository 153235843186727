import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import omit from "lodash/omit";
import { Flash } from "common/components/Flash";

const flashSlice = createSlice({
  name: "flash",
  initialState: [] as Flash[],
  reducers: {
    add(state, action: PayloadAction<Flash>) {
      return state.concat(action.payload);
    },
    // remove flash with id, unless it has persist_over_first_page_change set
    remove(state, action: PayloadAction<{ id: string | number }>) {
      return state
        .filter(
          flash =>
            flash.persist_over_first_page_change ||
            flash.id !== action.payload.id,
        )
        .map(flash => omit(flash, "persist_over_first_page_change"));
    },
    // clear all that don't have persist_over_first_page_change
    clear(state) {
      return state
        .filter(flash => flash.persist_over_first_page_change)
        .map(flash => omit(flash, "persist_over_first_page_change"));
    },
  },
});

export const flashActions = flashSlice.actions;
export default flashSlice.reducer;
