import { mapKeys } from "lodash";

const enabled = true;
const prepend = (hash: Record<string, unknown>) =>
  enabled ? mapKeys(hash, (_, k) => `data-${k}`) : {};

export const clientMainNavHandle = () =>
  prepend({ component: "clientMainNav" });

export const clientMainNavItemHandle = (id: string) =>
  prepend({ component: "clientMainNavItem", id });

export const modalHandle = () => prepend({ component: "modal" });

export const conversationLinkHandle = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => prepend({ component: "conversationLink", id, name });

export const messageHandle = ({ id, content }: { id: string; content: any }) =>
  prepend({ component: "message", id, content });

export const messageInputHandle = (control: string) =>
  prepend({ component: "messageInput", control });

export const tasksHandle = () => prepend({ component: "tasks" });

export const taskHandle = (id: string) => prepend({ component: "task", id });

export const feedbackPanel = () => prepend({ component: "feedbackPanel" });
