import React, { useCallback, useMemo } from "react";
import { SymptomPageStyled } from "./SymptomPage.styles";
import SymptomRow, { CellContent } from "./SymptomRow";
import SymptomTitleRow, { GroupedColumnRule } from "./SymptomTitleRow";
import { PdfSettings } from "../Settings/PdfSettingsPanel";
import { FormattedMessage } from "react-intl";
import { isEqual, sortBy, times, uniqWith } from "lodash";
import { ValidInterval } from "shared/components/SymptomTable/SymptomTable";
import { SymptomItemData, SymptomRowData } from "client/reducers/client";
import { PaginationDetails } from "../types";
import SymptomPageFootsteps from "./SymptomPageFootsteps";
import { Steps } from "shared/components/SymptomTableCompact/types";
import { formatDate } from "common/utils/general";
import dayjs from "dayjs";

export interface SymptomPageProps {
  rows: SymptomRowData[];
  steps?: Steps[];
  settings: PdfSettings;
  pageNo: number;
  pageSize: number;
  pageCount: number;
  interval?: ValidInterval;
}

// Oldest columns will get grouped in the first column
const MAX_COLUMNS = 10;
const SYMPTOM_ROWS_PER_PAGE = 18;

export const pagination = (rows: any[]): PaginationDetails => {
  const pageCount = Math.ceil((rows ?? []).length / SYMPTOM_ROWS_PER_PAGE) || 1;
  return {
    pageCount,
    pageLength: SYMPTOM_ROWS_PER_PAGE,
    pages: times(pageCount),
  };
};

const filterItemsByTitleRule = (
  titleRule: GroupedColumnRule,
  items: SymptomItemData[],
) =>
  items.filter(
    item =>
      // betweenDates
      (titleRule.type === "betweenDates" &&
        (!titleRule.endDate || item.date <= titleRule.endDate) &&
        (!titleRule.startDate || item.date >= titleRule.startDate)) ||
      // exactDate
      (titleRule.type === "exactDate" && titleRule.date === item.date) ||
      // beforeDate
      (titleRule.type === "beforeDate" &&
        (titleRule.date as string) >= item.date),
  );

const getColumnRulesForRowData = (
  rows: SymptomRowData[],
  dateFilter: (d: SymptomItemData) => boolean,
  interval: ValidInterval,
) =>
  uniqWith(
    rows
      .flatMap(row => row.data.filter(dateFilter).map(d => d.date))
      .sort()
      .map(title =>
        interval === "day"
          ? ({
              type: "exactDate",
              date: title,
              interval,
            } as GroupedColumnRule)
          : ({
              type: "betweenDates",
              startDate: formatDate(dayjs(title).startOf(interval)),
              endDate: formatDate(dayjs(title).endOf(interval)),
              interval,
            } as GroupedColumnRule),
      ),
    isEqual,
  );

const groupOldestValuesToSingleColumn = (
  columnRules: GroupedColumnRule[],
  interval: ValidInterval,
) => {
  const groupColumnIdx = columnRules.length - MAX_COLUMNS;
  const ungroupedColumns = sortBy(
    columnRules.reduce(
      (m, t, i) => (i > groupColumnIdx ? [...m, t] : m),
      [] as GroupedColumnRule[],
    ),
    col => col.date ?? col.startDate,
  );

  // Column to contain all of the oldest data
  const groupColumn: GroupedColumnRule = {
    type: "beforeDate",
    date:
      columnRules[groupColumnIdx].date ?? columnRules[groupColumnIdx].endDate,
    interval,
  };

  return [groupColumn, ...ungroupedColumns];
};

const isAdjacentDays = (a?: string, b?: string) => {
  if (!a || !b) {
    return false;
  }

  return dayjs(a).add(1, "day").isSame(dayjs(), "day");
};

// Items are grouped by the date for each column.
// All overflowing columns (see MAX_COLUMNS)
// will be grouped to a single column by date
const groupByTitles = (
  titles: GroupedColumnRule[],
  items: SymptomItemData[],
) => {
  return titles.reduce((valuesByDate, titleRule, i) => {
    const itemsOfTitle = filterItemsByTitleRule(titleRule, items);

    // A date representing the column.
    // Columns may contain values of wider date span but shouldn't overlap each other
    const colDate = (rule: GroupedColumnRule) =>
      (rule.date ?? rule.endDate) as string;
    const titleDate = colDate(titleRule);

    return {
      ...valuesByDate,
      [titleDate]: {
        items: itemsOfTitle,
        // hasGapBeforeColumn is for the vertical ruler which will be drawn if there
        // is a gap between the dates of two adjacent columns
        hasGapBeforeColumn:
          titles[i - 1] &&
          !isAdjacentDays(colDate(titles[i - 1]), titleRule.startDate),
      },
    };
  }, {} as CellContent);
};

const SymptomPage: React.FC<SymptomPageProps> = ({
  rows,
  steps,
  settings,
  pageNo,
  pageSize,
  pageCount,
  interval = "week",
}) => {
  const dateFilter = useCallback(
    (d: SymptomItemData) =>
      d.date >= settings.startDate && d.date <= settings.endDate,
    [settings],
  );

  const pageStartIdx = pageNo * pageSize;
  const pageEndIdx = pageStartIdx + pageSize;
  const pageRows = useMemo(() => rows.slice(pageStartIdx, pageEndIdx), [rows]);

  // Format date titles for the data
  const titles: GroupedColumnRule[] = useMemo(() => {
    const columns = getColumnRulesForRowData(rows, dateFilter, interval);

    if (columns.length > MAX_COLUMNS) {
      return groupOldestValuesToSingleColumn(columns, interval);
    }

    return columns;
  }, [rows, dateFilter]);

  // Filter rows by date and group the data by the titles
  const filteredWithGroupedItems = useMemo(
    () =>
      pageRows
        ?.filter(row => row.data.filter(dateFilter).length > 0)
        .map(row => ({
          ...row,
          groupedData: groupByTitles(titles, row.data.filter(dateFilter)),
        })),
    [pageRows, dateFilter],
  );

  return filteredWithGroupedItems?.length > 0 ? (
    <>
      <SymptomPageStyled
        data-test-id="SymptomPage"
        className="symptom-div-to-print"
      >
        <SymptomTitleRow titleRules={titles} />
        {filteredWithGroupedItems.map(row => (
          <SymptomRow
            key={row.id}
            itemType={row.item_type}
            title={row.name}
            symptomData={row.groupedData}
          />
        ))}
        {steps && steps.length > 0 && pageNo === pageCount - 1 && (
          <SymptomPageFootsteps
            footsteps={steps.map(s => ({
              time: dayjs(s.start_time).unix(),
              value: s.daily_average,
            }))}
            titles={titles}
          />
        )}
      </SymptomPageStyled>
    </>
  ) : pageNo === 0 ? (
    <div
      className="symptom-div-to-print"
      style={{ width: "1000px", height: "600px" }}
    >
      <FormattedMessage id="pdf_export.symptoms.no_data" />
    </div>
  ) : null;
};

export default SymptomPage;
