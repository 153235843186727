import React, { useState } from "react";
import { Field } from "react-final-form";
import FormField, { PlainFormField } from "common/components/Form/FormField";
import { PhoneInput } from "common/components/Form";
import { useSelector } from "store";
import {
  validateEmail,
  validateEmailConfirmation,
  validatePhone,
} from "common/components/Form/utils";
import { defaultPhoneCountry } from "hcp/components/Invites/InvitePatients";
import { SiteSettings } from "common/utils/holvikaari";
import { getDefaultCountryCode } from "common/utils/phone";
import { FormattedMessage } from "react-intl";
import { InviteFormHelp } from "client/components/InvitePage/InvitePage.styles";

const AddCaregiverFormFields = () => {
  const [countryCode, setCountryCode] = useState<string>();
  const user = useSelector(({ user }) => user);
  const phoneRequired = user.ask_caregiver_phone;
  const defaultCountry = defaultPhoneCountry(user);

  return (
    <div>
      {/* EMAIL */}
      <div data-testid="email-container">
        <Field name="email" validate={validateEmail}>
          {({ input }) => (
            <PlainFormField
              {...input}
              labelWeight="normal"
              labelOnTop
              type="text"
              required
              showRequiredIndicator
            />
          )}
        </Field>
      </div>

      {/* EMAIL CONFIRMATION */}
      <div data-testid="email-confirmation-container">
        <Field
          name="emailConfirmation"
          validate={validateEmailConfirmation as any}
        >
          {({ input }) => (
            <PlainFormField
              {...input}
              labelOnTop
              type="text"
              label="attributes.email_confirmation"
              labelWeight="normal"
              required={true}
              showRequiredIndicator
            />
          )}
        </Field>
      </div>

      {/* PHONE */}
      {phoneRequired && (
        <>
          <div data-testid="phone-container">
            <Field name="phone" validate={validatePhone(phoneRequired)}>
              {fieldProps => (
                <FormField
                  name="phone"
                  {...fieldProps}
                  required={phoneRequired}
                  showRequiredIndicator
                  labelOnTop
                  labelWeight="normal"
                >
                  <PhoneInput
                    {...fieldProps.input}
                    updateCountry={setCountryCode}
                    onChange={(num, country: any) => setCountryCode(country)}
                    defaultCountry={defaultCountry}
                  />
                </FormField>
              )}
            </Field>
          </div>
          <InviteFormHelp>
            <FormattedMessage
              id="phone.instructions"
              values={{
                country_code:
                  countryCode ?? getDefaultCountryCode(defaultCountry),
                placeholder: SiteSettings.phone_placeholder,
              }}
            />
          </InviteFormHelp>
        </>
      )}
    </div>
  );
};

export default AddCaregiverFormFields;
