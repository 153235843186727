import {
  ActionReducerMapBuilder,
  createAction,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { pick, omit } from "lodash";
import { Conversation } from "shared/models/conversation";
import { User } from "common/models/user";
import { IConversation } from "client/components/WelcomeClient/Conversations";
import { filterById } from "common/utils/general";
import { messageActions } from "./messages";

export type People = Record<
  string | number,
  Pick<User, "full_name" | "type" | "status" | "id" | "role">
>;
type Unread = Record<string, number>;

type State = {
  // proper info of people participating in conversation,
  // the Conversation type only has user ids
  people?: People;
  current?: Conversation;
  all: Conversation[];
  recent?: IConversation[];
  unread?: Unread;
};

export const partChat = createAction("chat/partChat");

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState: {
    all: [],
  } as State,
  reducers: {
    setCurrentConversation(
      state,
      {
        payload,
      }: PayloadAction<{
        data: Conversation;
        people: People;
      }>,
    ) {
      state.current = payload.data;
      state.people = { ...state.people, ...payload.people };
    },
    setAllConversations(
      state,
      {
        payload,
      }: PayloadAction<{
        data: Conversation[];
        people: People;
      }>,
    ) {
      state.all = payload.data;
      state.people = { ...state.people, ...payload.people };
    },
    deleteConversation(
      state,
      // redirect is used in EmptyMessage
      { payload: { id } }: PayloadAction<{ id: string; redirect: string }>,
    ) {
      state.current = undefined;
      state.all = state.all.filter(filterById(id));
    },
    setUnread(state, { payload }: PayloadAction<Unread>) {
      // don't set unread for current conversation
      state.unread = omit(payload, state.current?.id ?? "");
    },
    setRecentMessages(
      state,
      {
        payload,
      }: PayloadAction<{
        data: {
          last_message: string;
          new_messages: number;
          conversation: string;
        }[];
        messages: Record<string, IConversation["last_message"]>;
        conversation: Record<string, any>;
        people: People;
      }>,
    ) {
      state.recent = payload.data.map(activity => ({
        last_message: payload.messages[activity.last_message],
        new_messages: activity.new_messages,
        ...payload.conversation[activity.conversation],
      }));
      state.people = { ...state.people, ...payload.people };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    builder.addCase(partChat, state => {
      return pick(state, ["all", "unread"]);
    });
    builder.addCase(messageActions.addMessages, (state, { payload }) => {
      state.people = { ...state.people, ...payload.people };
    });
  },
});

export const conversationActions = conversationsSlice.actions;
