import {
  Content,
  ContentImage,
  ContentText,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import { IntlShape } from "react-intl";
import { i18nTranslate } from "shared/utils/translation";

export interface SymptomChartData {
  images: string[]; // As dataUrl strings
}

class GeneralClientPdf {
  symptomChartData: SymptomChartData;
  intl: IntlShape;

  constructor(intl: IntlShape, symptomChartData: SymptomChartData) {
    this.intl = intl;
    this.symptomChartData = symptomChartData;
  }

  definition(): TDocumentDefinitions {
    return {
      pageSize: "A4",
      content: [...this.symptomCharts()],
      styles: {
        h1: {
          fontSize: 18,
          bold: true,
          margin: [0, 10, 0, 10], // L, T, R, B
        },
        h2: {
          fontSize: 14,
          bold: true,
          margin: [0, 7, 0, 7], // L, T, R, B
        },
      },
      defaultStyle: {
        font: "Arial",
        preserveLeadingSpaces: true, // don't want to strip white space in text
      },
      pageMargins: [20, 20, 20, 20], // L, T, R, B
    };
  }

  symptomCharts() {
    const t = i18nTranslate(this.intl);
    const { images } = this.symptomChartData;
    const title = t("pdf_export.symptoms.title");
    const symptomPageNo = (page: number) =>
      images.length > 1 ? `(${page}/${images.length})` : "";

    return images.flatMap((imgData, i): Content[] => [
      {
        text: `${title} ${symptomPageNo(i + 1)}`,
        style: "h1",
        pageBreak: i > 0 ? "before" : undefined,
      } as ContentText,
      {
        image: imgData,
        width: 550,
      } as ContentImage,
    ]);
  }
}

export default GeneralClientPdf;
