import React, { useEffect } from "react";
import UserLink from "common/components/UserInfo/UserLink";
import { isClient, isActive } from "common/utils/user";
import { UsersSection, UserListItem } from "./Userlist.styles";
import Person from "common/components/Person";
import { User } from "common/models/user";
import Team from "../Team";

// If status information is available, can return false.
// Otherwise treat items as active.
const safeIsActive = (item: any) => (item.status ? isActive(item) : true);

type Props = {
  items: ReadonlyArray<any>;
  className?: string;
  responsive?: boolean;
  mobile?: boolean;
  count?: number;
  editable?: boolean;
  remove: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  activeIndex: number;
  activeIndexId?: string;
  userlistDirection?: "vertical" | "horizontal";
  user: User;
  style?: any;
  noPadding?: boolean;
};

const useArrayRef = () => {
  const refs: HTMLLIElement[] = [];
  return [refs, (el: HTMLLIElement) => el && refs.push(el)] as const;
};

const UserListWrapper = (props: Props) => {
  const [nodes, setNodes] = useArrayRef();

  useEffect(() => {
    const { activeIndex } = props;
    if (activeIndex > -1) {
      nodes[activeIndex]?.focus();
    }
  }, [props.activeIndex]);

  const {
    user,
    items,
    responsive,
    onClick,
    editable,
    remove,
    userlistDirection = "vertical",
    noPadding,
    ...rest
  } = props;
  return (
    <UsersSection
      horizontal={userlistDirection === "horizontal"}
      responsive={responsive}
      noPadding={noPadding}
      {...rest}
    >
      <ul>
        {items.map((item, idx) => (
          <UserListItem
            responsive={responsive}
            inactive={!safeIsActive(item)}
            key={idx}
            ref={setNodes}
            onClick={() => {
              onClick && onClick(item);
            }}
            tabIndex={-1}
          >
            {item.type === "HcpTeam" ? (
              <Team
                name={item.full_name}
                id={item.id}
                abbreviation={item.abbreviation}
                description={item.description}
              />
            ) : (
              <Person
                key={idx}
                person={item}
                user={user}
                showDomains={false}
                onRemove={
                  editable && !isClient(item)
                    ? remove.bind(null, item)
                    : undefined
                }
                userLink={
                  <UserLink
                    displayAsLink={editable && isClient(item)}
                    user={item}
                  />
                }
              />
            )}
          </UserListItem>
        ))}
      </ul>
    </UsersSection>
  );
};

export const UserList = ({ children, ...props }: any) => (
  <UserListWrapper {...props}>{children}</UserListWrapper>
);

export default UserList;
