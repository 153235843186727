import styled from "styled-components";
import { navigation } from "@netmedi/frontend-design-system/dist/styles/variables";

const { logo } = navigation;
const { width: logoWidth, height: logoHeight } = logo;

export const LogoContainer = styled.div`
  max-width: ${logoWidth}px;
  max-height: ${logoHeight}px;
  line-height: ${logoHeight}px;
  display: flex;
  margin: 0 auto;
`;

export const LogoImg = styled.img.attrs(() => ({
  "data-testid": "logo-img",
}))`
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  border-style: none;
  margin: 0 auto;
  object-fit: contain;
`;
