import React from "react";
import { isNil, omit } from "lodash";
import SymptomGradeCircle from "common/components/SymptomGradeCircle";
import { Source } from "common/models/symptoms";
import {
  BorderArcWrapper,
  BorderArcBefore,
  BorderArc,
  ScoreSeverity,
  ScoreSeverityInnerWrap,
  UnderLineWrapper,
  CalendarEntry,
} from "./CellComponents.styles";
import { ParentSources } from "./SymptomTable";

type DynamicBorderProps = { borderColor: string };

function DynamicBorder({ borderColor }: DynamicBorderProps) {
  return (
    <BorderArcWrapper>
      <BorderArcBefore style={{ borderColor: borderColor }} />
      <BorderArc />
    </BorderArcWrapper>
  );
}

type GradeBadgeProps = {
  name: string;
  grade: number;
  urgency?: number;
  grade_min?: number;
  relative_positivity: number;
  inverse_positivity?: boolean;
  sources: Source[];
  description: string;
  selected?: boolean;
  viewedAsStaff?: boolean;
  parent_sources?: ParentSources;
};

function GradeBadge(props: GradeBadgeProps) {
  const {
    grade,
    urgency,
    grade_min,
    inverse_positivity,
    name,
    sources,
    description,
    selected,
    parent_sources,
  } = props;

  if (!isNil(grade_min) && grade_min !== grade) {
    return (
      <SymptomGradeCircle
        isRange
        grade={[grade_min, grade]}
        urgency={urgency}
        underline={inverse_positivity ? "max" : "min"}
        sizeProportionalToGrade
        getSources={() => Promise.resolve(sources)}
        symptomName={name}
        severityDescription={description}
        selected={selected}
        viewedAsStaff={props.viewedAsStaff}
        parentSources={parent_sources}
      />
    );
  } else {
    return (
      <SymptomGradeCircle
        grade={grade}
        urgency={urgency}
        sizeProportionalToGrade
        getSources={() => Promise.resolve(sources)}
        symptomName={name}
        severityDescription={description}
        selected={selected}
        viewedAsStaff={props.viewedAsStaff}
        parentSources={parent_sources}
      />
    );
  }
}

export const scoreColor = (
  relative_positivity: GradeBadgeProps["relative_positivity"] | undefined,
  inverse_positivity: GradeBadgeProps["inverse_positivity"],
) => {
  if (isNil(relative_positivity)) return "rgba(0, 0, 0, 0.2)";

  const colors = ["#923b23", "#f68261", "#e6b328", "#569b3b", "#a7da92"];
  const inverseMultiplier = inverse_positivity ? -1 : 1;
  return colors[
    Math.round(inverseMultiplier * relative_positivity * (colors.length - 1))
  ];
};

type ScoreBadgeProps = {
  grade: number;
  grade_min?: number;
  inverse_positivity?: boolean;
  relative_positivity: number;
  sources?: Source[];
};

function ScoreBadge({
  grade,
  grade_min,
  inverse_positivity,
  relative_positivity,
}: ScoreBadgeProps) {
  return (
    <ScoreSeverity id="scoreSeverity">
      <DynamicBorder
        borderColor={scoreColor(relative_positivity, inverse_positivity)}
      />
      <ScoreSeverityInnerWrap>
        {!isNil(grade_min) && grade_min !== grade && (
          <span>
            <UnderLineWrapper $underline={!inverse_positivity}>
              {grade_min}
            </UnderLineWrapper>
            {"–"}
          </span>
        )}
        <UnderLineWrapper $underline={inverse_positivity && !isNil(grade_min)}>
          {grade}
        </UnderLineWrapper>
      </ScoreSeverityInnerWrap>
    </ScoreSeverity>
  );
}

type CalendarEntryBadgeProps = { sources: Source[] };

function CalendarEntryBadge({ sources }: CalendarEntryBadgeProps) {
  return (
    <CalendarEntry>
      {sources.length > 0 ? sources.length : <span>&nbsp;</span>}
    </CalendarEntry>
  );
}

export type Props =
  | ({ type: "Grade" } & GradeBadgeProps)
  | ({ type: "Score" } & ScoreBadgeProps)
  | ({ type: "CalendarEntry" } & CalendarEntryBadgeProps);

export default function CellComponent(props: Props) {
  switch (props.type) {
    case "CalendarEntry":
      return <CalendarEntryBadge {...omit(props, "type")} />;
    case "Grade":
      return <GradeBadge {...omit(props, "type")} />;
    case "Score":
      return <ScoreBadge {...omit(props, "type")} />;
    default:
      ((x: never) => x)(props);
      return null;
  }
}
