import React, { useState } from "react";
import isNil from "lodash/isNil";
import SymptomGradeCircle from "common/components/SymptomGradeCircle";
import CellComponent from "../SymptomTable/CellComponents";
import SpeechBubble from "common/components/SpeechBubble";
import { FormattedMessage } from "react-intl";
import {
  hasAttachments,
  hasTextFieldAnswer,
  RowType,
  ValidInterval,
} from "../SymptomTable/SymptomTable";
import { findMatchingGrading } from "../SymptomTable/helpers";
import {
  SymptomNameCell,
  SymptomName,
  GradeWrapper,
} from "./SymptomTableCompact.styles";
import { Dayjs } from "dayjs";

import {
  AttachmentBadge,
  DropdownIcon,
} from "../SymptomTable/SymptomTable.styles";

export type Props = {
  rows: RowType[];
  interval: ValidInterval;
  columnTimes: Dayjs[][];
};

type RowProps = {
  row: RowType;
  rowIndex: number;
  isSubRow?: boolean;
} & Omit<Props, "rows">;

const Row = (props: RowProps) => {
  const [showSubRows, setShowSubRows] = useState(false);
  const { row, rowIndex, isSubRow, ...rest } = props;
  const { interval, columnTimes } = rest;
  const hasSubRows = row.sub_rows && !!row.sub_rows?.length;

  return (
    <>
      <tr data-testid={isSubRow ? "sub-row" : "row"}>
        <SymptomNameCell colSpan={columnTimes ? columnTimes.length : 0}>
          <SymptomName>
            {hasSubRows && (
              <DropdownIcon
                data-testid="open-sub-row"
                tabIndex={0}
                open={showSubRows}
                size="medium"
                name="arrowhead_right_16px"
                align="left"
                onClick={() => setShowSubRows(!showSubRows)}
                onKeyPress={() => setShowSubRows(!showSubRows)}
              />
            )}
            {row.name || <FormattedMessage id={"symptom_table." + row.id} />}
          </SymptomName>
        </SymptomNameCell>
      </tr>
      <tr key={"symptom-grade-row-" + rowIndex}>
        {columnTimes &&
          columnTimes.map(([start, end], date_idx) => {
            const matchGrading = findMatchingGrading({
              start: start.valueOf(),
              end: end.valueOf(),
              interval,
            });

            const grading = matchGrading(row);

            return (
              <GradeWrapper key={date_idx + "-" + rowIndex}>
                {hasTextFieldAnswer(grading) && <SpeechBubble />}
                {hasAttachments(grading) && <AttachmentBadge />}

                <span>
                  {!isNil(grading) ? (
                    <CellComponent
                      type={row.item_type}
                      name={row.name}
                      {...(grading as any)}
                    />
                  ) : (
                    <SymptomGradeCircle isEmpty />
                  )}
                </span>
              </GradeWrapper>
            );
          })}
      </tr>
      {hasSubRows &&
        showSubRows &&
        row.sub_rows &&
        row.sub_rows.map((sr: RowType, srIdx: number) => (
          <Row
            key={"symptom-sub-row-" + srIdx}
            row={sr}
            rowIndex={srIdx}
            isSubRow={true}
            {...rest}
          />
        ))}
    </>
  );
};

const SymptomTableCompactRows = (props: Props) => {
  const { rows } = props;

  return (
    <>
      {rows &&
        rows.map((row, rowIndex) => (
          <Row
            key={"symptom-name-row-" + rowIndex}
            row={row}
            rowIndex={rowIndex}
            {...props}
          />
        ))}
    </>
  );
};

export default SymptomTableCompactRows;
