import { css } from "styled-components";

export const hideScrollBarStyles = css`
  &::-webkit-scrollbar {
    appearance: none;
  }
`;

export const scrollBarStyles = css`
  &::-webkit-scrollbar {
    appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #d9d9d9;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 8px;
    width: 10px;
  }
`;
