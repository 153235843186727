import React, { useState } from "react";
import { Field } from "react-final-form";
import {
  DomainSelectionContainer,
  CheckboxErrorIcon,
  DomainCheckboxErrors,
  ErrorContainer,
  Search,
  SearchIcon,
  SearchField,
  DomainInputContainer,
} from "./ManageDomainsModal.styles";
import { DomainList } from "./DomainList";
import { useIntl } from "react-intl";
import { User } from "common/models/user";

type SelectDomainsProps = {
  name: string;
  user: Pick<User, "task_subscriptions">;
};

const SelectDomains: React.FC<SelectDomainsProps> = ({ name, user }) => {
  const [searchText, setSearchText] = useState("");
  const intl = useIntl();

  return (
    <DomainSelectionContainer data-testid="DomainSelectionContainer">
      <Field<string[]> name={name}>
        {({ input, meta }) => {
          return (
            <DomainInputContainer>
              {meta.error && meta.touched && (
                <ErrorContainer>
                  <CheckboxErrorIcon name="alert_circle" />
                  <DomainCheckboxErrors>
                    <span>{meta.error}</span>
                  </DomainCheckboxErrors>
                </ErrorContainer>
              )}
              <div>
                <Search>
                  <SearchIcon material name="search" size="medium" />
                  <SearchField
                    type="text"
                    placeholder={intl.formatMessage({ id: "generic.search" })}
                    onChange={e => setSearchText(e.target.value)}
                  />
                </Search>
                <DomainList
                  name={name}
                  searchText={searchText}
                  input={input}
                  user={user}
                />
              </div>
            </DomainInputContainer>
          );
        }}
      </Field>
    </DomainSelectionContainer>
  );
};

export default SelectDomains;
