import React from "react";
import { FormattedMessage } from "react-intl";

const localizations = "modal.slide_carousel.category_onboarding.";
const onboarding_path = "/assets/symptom_category_onboarding/";

export const slideContent = (
  isMobile: boolean,
  first_name: string,
  selected: boolean,
  changeSelected: (...args: any[]) => any,
): Array<any> => {
  const image_path = isMobile ? "mobile_slide_" : "desktop_slide_";
  const background_image_top =
    isMobile && onboarding_path + "background_phone_top.png";
  const background_image_bot =
    isMobile && onboarding_path + "background_phone_bot.png";
  const style_options = isMobile ? { transform: "translate(-50%, -80%)" } : {};
  return [
    {
      heading: (
        <FormattedMessage
          id={localizations + "heading_1"}
          values={{ client: first_name }}
        />
      ),
      image: {
        background_image: background_image_top,
        image_source: "/assets/logos/kaiku.png",
      },
    },
    {
      heading: <FormattedMessage id={localizations + "heading_2"} />,
      image: {
        background_image: background_image_top,
        image_source: onboarding_path + image_path + "1.png",
      },
    },
    {
      heading: <FormattedMessage id={localizations + "heading_3"} />,
      image: {
        background_image: background_image_top,
        image_source: [
          onboarding_path + image_path + "2.png",
          onboarding_path + image_path + "3.png",
        ],
      },
    },
    {
      heading: <FormattedMessage id={localizations + "heading_4"} />,
      image: {
        background_image: background_image_bot,
        image_source: onboarding_path + image_path + "4.png",
        image_style: style_options,
      },
    },
    {
      heading: <FormattedMessage id={localizations + "heading_5"} />,
      checkbox: {
        label: <FormattedMessage id={localizations + "label"} />,
        selected,
        changeSelected,
      },
    },
  ];
};
