import React from "react";
import Name from "./UserLink.styles";
import { userPath } from "common/utils/holvikaari";
import { track } from "common/utils/api";
import { User } from "common/models/user";

interface IProps {
  /** The user to be linked to */
  user: Pick<User, "id" | "full_name" | "email">;
  /** If false, just displays the name without link */
  displayAsLink?: boolean;
  /** If true, links to to the edit page of the user */
  linkToEdit?: boolean;
  /* Event name if we want to create tracker events of link clicks */
  trackClicks?: string;
}

const UserLink = ({
  user,
  displayAsLink = false,
  linkToEdit = false,
  trackClicks,
}: IProps) => {
  const name = <Name>{user.full_name ?? user.email}</Name>;
  const trackClick = () => {
    if (trackClicks)
      track(trackClicks, { user_id: parseInt(user.id) }, { user_id: "people" });
  };

  if (displayAsLink) {
    return (
      <a href={userPath(user, linkToEdit)} onClick={trackClick}>
        {name}
      </a>
    );
  } else {
    return name;
  }
};

export default UserLink;
