import combineEpicsCatchAll from "shared/utils/epic";
import { viewport } from "common/epics/viewport";
import { setLocale } from "shared/epics/intl";
import {
  getInitialSections,
  inputFormStateMachine,
} from "shared/epics/inputForm";
import { dataTableFetcher } from "common/epics/dataTable";
import {
  consentModal,
  clearModalOnPageChange,
  carouselModal,
} from "shared/epics/modal";
import { queryStringFlashes, clearFlashOnPageChange } from "common/epics/flash";
import { flipperUINotification } from "shared/epics/flipperUINotification";
import {
  postToMobileApp,
  callMobileAppFunction,
  signOutMobileApp,
} from "shared/epics/mobileAppMessaging";
import { removePushDevices } from "client/epics/pushDevices";
import { serviceClosed } from "shared/epics/serviceClosed";
import { onlineHelpPopover } from "hcp/epics/onlineHelpPopover";
import { startExpirationPoll } from "common/epics/expiration";
import { BehaviorSubject } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ActionsObservable, StateObservable } from "redux-observable";
import { State } from "store";

// epic$.next(asyncEpic) can be used to asynchronously load epics
export const epic$ = new BehaviorSubject(
  combineEpicsCatchAll<any>(
    setLocale,
    viewport,
    getInitialSections,
    dataTableFetcher,
    consentModal,
    carouselModal,
    queryStringFlashes,
    flipperUINotification,
    clearModalOnPageChange,
    clearFlashOnPageChange,
    postToMobileApp,
    signOutMobileApp,
    callMobileAppFunction,
    inputFormStateMachine,
    onlineHelpPopover,
    removePushDevices,
    serviceClosed,
    startExpirationPoll,
  ),
);

export default (
  action$: ActionsObservable<any>,
  state$: StateObservable<State>,
) => epic$.pipe(mergeMap(epic => epic(action$, state$, null)));
