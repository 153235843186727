import { get, endpoints, postForm } from "common/utils/api";
import { Document } from "shared/components/DocumentCard/SharedDocumentCard";

export type SharedDocument = Document & { file: File };

export type DocumentAction =
  | {
      type: "SET_SENT_DOCUMENTS";
      data: any;
    }
  | {
      type: "SET_RECEIVED_DOCUMENTS";
      data: any;
    }
  | {
      type: "UPDATE_RECEIVED_DOCUMENTS";
      data: any;
    };

export const getSentDocuments = (clientId: string) =>
  get(endpoints.shared_documents.sent_by_client(clientId))
    .then(data => ({
      type: "SET_SENT_DOCUMENTS",
      data: data.attachments,
    }))
    .catch(({ data }) => {
      throw data.errors;
    });

export const getReceivedDocuments = (clientId?: string) =>
  get(endpoints.shared_documents.show(clientId))
    .then(data => ({
      type: "SET_RECEIVED_DOCUMENTS",
      data: data.attachments,
    }))
    .catch(({ data }) => {
      throw data.errors;
    });

export const shareDocument = (
  clientId: number,
  message: string,
  documents: SharedDocument[],
) =>
  postForm(endpoints.shared_documents.create, {
    client_id: clientId,
    message,
    documents,
  })
    .then(data => ({
      type: "UPDATE_RECEIVED_DOCUMENTS",
      data: data,
    }))
    .catch(({ data }) => {
      throw data.errors;
    });
