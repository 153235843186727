import React, { Component } from "react";
import { first } from "lodash";
import { Icon } from "@netmedi/frontend-design-system";
import {
  createPopper,
  Placement,
  Instance as PopperInstance,
  Modifier,
  State,
} from "@popperjs/core";
import { MakeRequired, MakePartial } from "common/utils/types";

import { connect, ConnectedProps } from "react-redux";
import { clearTooltip as close } from "common/actions/tooltip";
import { RootState } from "store";

import {
  TooltipContainer,
  TooltipTitle,
  TooltipClose,
  TooltipContent,
  TooltipArrow,
  tooltipArrowSize,
  tooltipArrowOffset,
} from "./Tooltip.styles";

export type ComponentType = "auto" | "dropdown" | "large" | "usercard";

export interface ITooltip {
  /** Title of the component */
  title?: string | JSX.Element;
  /** Content of the component */
  content?: string | JSX.Element;
  /** Position of the component, see https://popper.js.org for options */
  position?: Placement;
  /** Type of the component – dropdown, large or usercard */
  type?: ComponentType;
  /** Target of the tooltip */
  target: any;
  /** Function to close the component */
  close?(): void;
  /** Callback to invoke on close */
  onClose?(): void;
  className?: string;
  /** Set to false to avoid styleguidist issues */
  focusOnContent?: boolean;
  modifiers?: Record<string, any>;
  autoWidth?: boolean;
}

type TooltipProps = MakeRequired<ITooltip, "position">;

interface ITooltipState {
  position: Placement;
}

/*
  Renders a tooltip that gets placed by the target element
  on mount. Calls props.close when clicks occur outside the tooltip
*/
class Tooltip extends Component<TooltipProps, ITooltipState> {
  static defaultProps = {
    position: "top",
    focusOnContent: true,
  };

  constructor(props: TooltipProps) {
    super(props);

    this.state = {
      position: "top",
    };

    this.setArrowPosition = this.setArrowPosition.bind(this);
    this.setArrowPositionOnUpdate = this.setArrowPositionOnUpdate.bind(this);
    this.rootRef = React.createRef();
    this.arrowRef = React.createRef();
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    this.createPopper();
    // Workaround: The clicked that opened sometimes closes it
    setTimeout(
      () => document.addEventListener("click", this.handleClick, false),
      500,
    );

    document.addEventListener("keydown", this.handleKeyDown);

    if (this.props.focusOnContent) {
      this.focusContent();
    }
  }

  componentDidUpdate(prevProps: TooltipProps) {
    // need to re-initialize with new target element in case
    // a new tooltip is created before destroying the old one
    if (
      prevProps.target !== this.props.target ||
      prevProps.content !== this.props.content
    ) {
      this.reinitialize = true;
    }

    if (this.reinitialize) {
      this.reinitialize = false;
      this.createPopper();
    } else {
      this.popper?.update();
    }
  }

  componentWillUnmount() {
    this.popper?.destroy();
    this.popper = null;
    document.removeEventListener("click", this.handleClick, false);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  private rootRef: React.RefObject<any>;
  private contentRef: React.RefObject<any>;
  private arrowRef: React.RefObject<any>;
  private reinitialize = false;
  private popper: PopperInstance | null = null;

  focusContent() {
    const element: any = first(this.contentRef.current?.children);
    element?.focus();
  }

  reposition() {
    this.popper?.update();
  }

  // update arrow positioning in case the tooltip flips
  // from the intended side due to viewport limitations
  setArrowPosition(state: Partial<State>) {
    const placement = state?.placement;

    if (placement && this.state.position !== placement) {
      this.setState({ position: placement });
    }
  }
  setArrowPositionOnUpdate(data: { state: Partial<State> }) {
    this.setArrowPosition(data.state);
  }

  createPopper() {
    if (this.popper) {
      this.popper.destroy();
    }

    const onUpdate: Modifier<any, any> = {
      name: "onUpdate",
      enabled: true,
      phase: "afterWrite",
      fn: this.setArrowPositionOnUpdate,
    };

    const totalOffset = tooltipArrowSize + tooltipArrowOffset;
    const offset = { name: "offset", options: { offset: [0, totalOffset] } };

    this.popper = createPopper(this.props.target, this.rootRef.current, {
      placement: this.props.position,
      onFirstUpdate: this.setArrowPosition,
      modifiers: [onUpdate, offset],
    });

    this.popper.update();
  }

  handleClick = (e: MouseEvent) => {
    const { type, close, onClose } = this.props;
    const target = e.target as HTMLElement;

    if (this.reinitialize || !this.rootRef.current) {
      return;
    }

    if (
      !this.rootRef.current.contains(e.target) ||
      (type === "dropdown" &&
        (target.tagName.toLowerCase() === "a" ||
          target.parentElement?.tagName.toLowerCase() === "a"))
    ) {
      if (close) {
        close();
      }

      if (onClose) {
        onClose();
      }
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const { close, onClose } = this.props;
    if (e.key === "Escape") {
      if (close) {
        close();
      }

      if (onClose) {
        onClose();
      }
    }
  };

  render() {
    const { position } = this.state;
    const { content, title, type, close, className, autoWidth } = this.props;

    const repositionFunc = this.reposition.bind(this);

    const ContentEl =
      content &&
      (React.isValidElement<{ updatePosition: typeof repositionFunc }>(content)
        ? React.cloneElement(content, {
            updatePosition: repositionFunc,
          })
        : content);

    return (
      <TooltipContainer
        data-testid="tooltip-container"
        data-tooltip
        ref={this.rootRef}
        position={position}
        tooltipType={type}
        autoWidth={autoWidth}
        className={className}
      >
        {title && (
          <TooltipTitle>
            {title}
            <TooltipClose onClick={close}>
              <Icon material size="small" name="remove_16px" />
            </TooltipClose>
          </TooltipTitle>
        )}

        {ContentEl && (
          <TooltipContent ref={this.contentRef} tooltipType={type}>
            {ContentEl}
          </TooltipContent>
        )}

        <TooltipArrow
          data-popper-arrow
          position={position}
          tooltipType={type}
          ref={this.arrowRef}
        />
      </TooltipContainer>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  tooltip: state.app.tooltip,
});

const mapDispatchToProps = { close };

export type TooltipProviderProps = MakePartial<
  ConnectedProps<typeof connector>,
  "tooltip"
>;

/*
  Render nothing when no tooltip is active,
  otherwise the tooltip component
*/
/** Tooltip is a component displaying informative text when users hover over, focus on, or tap an element. */
export function TooltipProvider(props: TooltipProviderProps) {
  const { tooltip, close } = props;

  if (!tooltip) {
    return null;
  }

  return <Tooltip {...tooltip} close={close} />;
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(TooltipProvider);
