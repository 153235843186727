import React from "react";
import { setTooltip, clearTooltip } from "common/actions/tooltip";
import { updateUser } from "common/actions/user";
import { Epic, ofType } from "redux-observable";
import { AnyAction } from "redux";
import { State } from "../../store";
import OnlineHelpPopover from "hcp/components/Staff/OnlineHelpPopover";
import { User } from "common/models/user";
import { mergeMap, map, take, takeWhile } from "rxjs/operators";
import store from "store";
import { concat, from, of } from "rxjs";
import { UpdateAction } from "hcp/actions/feed";

type UserAndNavElement = {
  user: User;
  helpNavEl: Element | undefined | null;
};

const shouldShowPopup = ({ user, helpNavEl }: UserAndNavElement): boolean => {
  return (
    !!user &&
    (!user.ui_metadata || !user.ui_metadata.online_help_popover) &&
    /** The Help nav element exists only if the feature should be shown to the user,
     * it is still defined even if it would not be currently visible */
    !!helpNavEl &&
    !!targetElement()
  );
};

// Choose the target element for the tooltip based on which is not hidden
const targetElement = (): Element | null => {
  const navBtn: Element | null = document.querySelector(".btn-navbar");
  const moreEl: Element | null = document.querySelector(".dropdown-toggle");
  const helpNavEl: Element | null = document.querySelector(".onlineHelp");

  if (navBtn && window.getComputedStyle(navBtn).display !== "none") {
    return navBtn;
  } else if (
    helpNavEl &&
    window.getComputedStyle(helpNavEl).display !== "none"
  ) {
    return helpNavEl;
  } else return moreEl;
};

const updateUserUIMetadata = (user: User): Promise<AnyAction> =>
  updateUser(user.id, {
    ui_metadata: user.ui_metadata
      ? { ...user.ui_metadata, online_help_popover: true }
      : { online_help_popover: true },
  });

const createTooltip = () =>
  setTooltip({
    target: targetElement(),
    type: "large",
    content: (
      <OnlineHelpPopover
        onClick={async () => {
          store.dispatch(clearTooltip());
        }}
      />
    ),
  });

export const onlineHelpPopover: Epic<UpdateAction, State> = (
  action$,
  store$,
) => {
  const getUserAndNav = (): UserAndNavElement => ({
    user: store$.value.user,
    helpNavEl: document.querySelector(".onlineHelp"),
  });

  return action$.pipe(
    ofType("UPDATE_ASSIGNED_TO_ME"),
    take(1),
    map(getUserAndNav),
    takeWhile(shouldShowPopup),
    mergeMap(userAndNav => {
      const tooltip = createTooltip();
      // Updating the user metadata to not show the tooltip again, after it has been once shown
      const updateU = updateUserUIMetadata(userAndNav.user);

      return concat(of(tooltip), from(updateU));
    }),
  );
};
