import React from "react";
import { ChipAndInfo, ProgramChip, TeamDescription } from "./Team.styles";
import { Typography } from "../Typography/Typography.styles";
import Label from "../Label";

export type TeamProps = {
  name: string;
  type?: string;
  id: string | number;
  abbreviation?: string;
  role?: string;
  description?: string;
};

const truncate = (text: string) => {
  return text.length > 27 ? text.substring(0, 24) + "..." : text;
};

const Team = (props: TeamProps) => {
  return (
    <Typography bodyText="small">
      <ChipAndInfo>
        <ProgramChip>
          <Label
            key={props.name + "-TP-Abbreviation-Label"}
            label={props.abbreviation}
            className={"staff"}
          />
        </ProgramChip>
        <span>
          <div data-testid={"team-component-info"}>
            <strong data-testid={"team-name"}>{props.name}</strong>
          </div>
          {props.description ? (
            <TeamDescription>{truncate(props.description)}</TeamDescription>
          ) : null}
        </span>
      </ChipAndInfo>
    </Typography>
  );
};

export default Team;
