import { isEqual } from "lodash";
import { fromEvent } from "rxjs";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";
import { AppAction } from "shared/actions/app";

export const Viewport = {
  MOBILE: 0,
  SMALL: 640,
  MEDIUM: 1024,
  LARGE: 1400,
  XLARGE: 2500,
};

const getSize = () => {
  if (window.innerWidth < Viewport.SMALL) return Viewport.MOBILE;
  if (window.innerWidth < Viewport.MEDIUM) return Viewport.SMALL;
  if (window.innerWidth < Viewport.LARGE) return Viewport.MEDIUM;
  return Viewport.LARGE;
};

const viewportData = (): AppAction => ({ type: "VIEWPORT", size: getSize() });

export const viewport = () =>
  fromEvent(window, "resize").pipe(
    distinctUntilChanged(isEqual, getSize),
    map(viewportData),
    startWith(viewportData()),
  );
