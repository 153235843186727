import styled from "styled-components";
import rtl from "styled-components-rtl";
import {
  marginLeft,
  padding,
} from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import {
  colors,
  scaffolding,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import ExternalLink from "common/components/ExternalLink";

export const UserFeedbackBoxStyled = styled.div`
  background-color: ${colors.blue50};
  ${padding("xs")}
  margin: 0 auto;
  /* Showing the box only after the possible PSA button */
  ${rtl`clear: right;`}
`;

export const SendFeedbackLink = styled(ExternalLink)`
  font-weight: ${scaffolding.bold};
  ${marginLeft("xxs")};
`;
