import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { groupBy, mapValues } from "lodash";

export type AlertCondition = {
  id: string;
  client_id: number;
  input_form_external_id: string;
  field_external_id: string;
  treatment_program_subscription_id: number;
  deleted_at?: string;
  unit: string;
  min: number | null;
  max: number | null;
};

export type State = Record<string, AlertCondition>;

export const alertConditionSlice = createSlice({
  name: "alertCondition",
  initialState: {} as State,
  reducers: {
    updateAlertConditions(state, { payload }: PayloadAction<AlertCondition[]>) {
      return {
        ...state,
        ...mapValues(groupBy(payload, "id"), "0"),
      };
    },
  },
});

export const alertConditionActions = alertConditionSlice.actions;
export default alertConditionSlice.reducer;
