import React from "react";
import { WrappedComponentProps } from "react-intl";
import SearchWrapper from "./SearchWrapper";
import { HeaderNav, StyledHeader } from "./DataTable.styles";

type Props = {
  title: string | React.ReactElement;
  headerContent?: string | React.ReactElement;
  enableSearch?: boolean;
  onChange: (...args: any[]) => any;
  value?: string;
} & WrappedComponentProps;

export const TableHeader = ({
  title,
  headerContent,
  enableSearch,
  onChange,
  value,
  intl,
}: Props) => (
  <StyledHeader title={title} translated>
    <HeaderNav>
      {headerContent}
      {enableSearch && (
        <SearchWrapper
          placeholder={intl.formatMessage({ id: "generic.search" })}
          onChange={onChange}
          value={value}
        />
      )}
    </HeaderNav>
  </StyledHeader>
);
