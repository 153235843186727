import styled from "styled-components";
import {
  colors,
  mediaQueries,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { mediaMin } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";

export const AcceptBox = styled.div`
  background-color: ${colors.blue50};
  max-width: 650px;
  ${mediaMin(mediaQueries.small)} {
    margin: 100px auto 20px auto;
  }
  margin-bottom: 20px;
  padding: 40px;
`;

export const TextWrapper = styled.div`
  margin-bottom: 40px;
`;

export const BottomDisclaimer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 20px;
`;
