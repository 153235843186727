import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PushDevice = {
  id: string;
  device: {
    brand: string;
    model?: string;
    device_id?: string;
  };
};

const pushDevicesSlice = createSlice({
  name: "pushDevices",
  initialState: [] as PushDevice[],
  reducers: {
    set(_state, action: PayloadAction<PushDevice[]>) {
      return action.payload;
    },
    clear(_state) {
      return [];
    },
  },
});

export const pushDevicesActions = pushDevicesSlice.actions;
export default pushDevicesSlice.reducer;
