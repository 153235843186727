import React, { KeyboardEventHandler, useState } from "react";
import isNil from "lodash/isNil";
import { FormattedMessage } from "react-intl";
import SymptomGradeCircle from "common/components/SymptomGradeCircle";
import useGrid from "shared/hooks/useGrid";
import CellComponent from "./CellComponents";
import {
  DataType,
  getFocusable,
  hasAttachments,
  hasTextFieldAnswer,
  isCalendarEntry,
  RowType,
  stopsVerticalLine,
} from "./SymptomTable";

import {
  SpeechBubbleStyled,
  SymptomName,
  SymptomWrapper,
  PaddingCell,
  VerticalLine,
  DropdownIcon,
  SubRow,
  AttachmentBadge,
} from "./SymptomTable.styles";

interface IProps {
  row: RowType;
  rowIdx: number;
  visibleRows: RowType[];
  focused?: { top: number; left: number };
  onKeyDown?: KeyboardEventHandler;
  gridName?: string;
  viewedAsStaff?: boolean;
}

const SymtomTableSubRow = (
  props: Omit<IProps, "visibleRows"> & { showSubRows: boolean; last: boolean },
) => {
  const { row, rowIdx, showSubRows, last, focused, onKeyDown, gridName } =
    props;
  const { name, data, item_type } = row;

  return (
    <SubRow show={showSubRows} onKeyDown={onKeyDown} data-gridname={gridName}>
      <th>
        <SymptomName>{name}</SymptomName>
      </th>
      {data?.map((grading: DataType | null | undefined, dateIdx: number) => {
        const selected = dateIdx === focused?.left && rowIdx === focused?.top;
        const key = dateIdx + "-" + rowIdx;

        return (
          <td key={"sub_row" + key} data-gridindex={key}>
            {hasTextFieldAnswer(grading) && <SpeechBubbleStyled />}
            <SymptomWrapper>
              {!isNil(grading) ? (
                <CellComponent
                  type={item_type}
                  name={name}
                  selected={selected}
                  viewedAsStaff={props.viewedAsStaff}
                  {...(grading as any)}
                />
              ) : (
                <SymptomGradeCircle isEmpty />
              )}
            </SymptomWrapper>
            {!isCalendarEntry(item_type) && <VerticalLine $last={last} />}
          </td>
        );
      })}
      <PaddingCell />
    </SubRow>
  );
};

const SymptomTableRow = (props: IProps) => {
  const [showSubRows, setShowSubRows] = useState(false);

  const { row, rowIdx, visibleRows, focused } = props;
  const { id, name, data, item_type, sub_rows } = row;
  const gridName = `symptomtablerow-${rowIdx}`;

  const { focused: focusedSub, handleKeyDown } = useGrid(
    (sub_rows ?? []).map(getFocusable),
    gridName,
  );

  const hasSubRows = !!sub_rows && sub_rows.length > 0;

  return (
    <>
      <tr>
        <th scope="row">
          <SymptomName>
            {hasSubRows && (
              <DropdownIcon
                tabIndex={0}
                open={showSubRows}
                size="medium"
                name="arrowhead_right_16px"
                align="left"
                onClick={() => setShowSubRows(!showSubRows)}
                onKeyDown={e =>
                  e.key === "Enter" && setShowSubRows(!showSubRows)
                }
              />
            )}
            {name || <FormattedMessage id={"symptom_table." + id} />}
          </SymptomName>
        </th>
        {data?.map((grading: any, dateIdx: number) => {
          const selected = dateIdx === focused?.left && rowIdx === focused?.top;
          const key = dateIdx + "-" + rowIdx;
          return (
            <td key={key} data-gridindex={key}>
              {hasTextFieldAnswer(grading) && <SpeechBubbleStyled />}
              {hasAttachments(grading) && <AttachmentBadge />}
              <SymptomWrapper>
                {!isNil(grading) ? (
                  <CellComponent
                    type={item_type}
                    name={name}
                    selected={selected}
                    viewedAsStaff={props.viewedAsStaff}
                    {...(grading as any)}
                  />
                ) : (
                  <SymptomGradeCircle isEmpty />
                )}
              </SymptomWrapper>
              {!isCalendarEntry(item_type) && (
                <VerticalLine
                  $last={stopsVerticalLine(visibleRows, rowIdx) && !showSubRows}
                />
              )}
            </td>
          );
        })}
        <PaddingCell />
      </tr>

      {hasSubRows &&
        sub_rows?.map((subRow, index, array) => {
          const last =
            stopsVerticalLine(visibleRows, rowIdx) &&
            index + 1 === array.length;

          return (
            <SymtomTableSubRow
              onKeyDown={handleKeyDown}
              gridName={gridName}
              focused={focusedSub}
              key={"sub_row" + index}
              row={subRow}
              rowIdx={index}
              showSubRows={showSubRows}
              last={last}
              viewedAsStaff={props.viewedAsStaff}
            />
          );
        })}
    </>
  );
};

export default SymptomTableRow;
