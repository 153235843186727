import React from "react";
import { Button } from "@netmedi/frontend-design-system";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import { SiteSettings } from "common/utils/holvikaari";
import { User } from "common/models/user";
import { Dismiss } from "./Modal.types";

import { connect } from "react-redux";
import { changeUserConsent } from "common/actions/user";
import { addFlash } from "common/actions/flash";

import {
  ModalConsentModal,
  ModalConsentSide,
  ModalBoldConsentText,
  ModalConsentButtons,
  ModalConsentAgreeContainer,
  ModalDisagreeButtons,
  ModalConsentFineprint,
} from "./Modal.styles";

export type ConsentParams = {
  type: "consent_modal";
  /** A consent that the user hasn't agreed / disagreed to yet */
  consent: any;
  user: User;
};

type Props = ConsentParams & {
  /** A function that changes user's consents based on what is clicked on the modal */
  changeUserConsent: typeof changeUserConsent;
  /** Function adding the flash that is shown after the consent modal is closed */
  addFlash: typeof addFlash;
};

/** Component to be shown to users right after login if there are new consents of consents have been updated. */
// eslint-disable-next-line max-lines-per-function
const Consent = ({
  dismiss,
  consent,
  changeUserConsent,
  user,
  addFlash,
}: Props & Dismiss) => {
  const closeModal = (accepted: boolean) => {
    changeUserConsent(user.id, {
      accepted: accepted,
      consent_id: consent.id,
      offered_at: dayjs().locale("en").format(),
    });
    return dismiss();
  };

  return (
    <ModalConsentModal onClick={e => e.stopPropagation()}>
      <ModalConsentSide side="left">
        <FormattedMessage tagName="h2" id="people.rwd_consent.header" />
        <FormattedMessage tagName="p" id="people.rwd_consent.introduction" />
        <FormattedMessage
          tagName="p"
          id="people.rwd_consent.more_information"
        />
        <FormattedMessage
          tagName="p"
          id="people.rwd_consent.voluntary"
          values={{ product_name: SiteSettings.product_name }}
        />
      </ModalConsentSide>
      <ModalConsentSide side="right">
        <ModalBoldConsentText>
          <p
            dangerouslySetInnerHTML={{
              __html: consent.consent_text || "boldConsentText",
            }}
          />
        </ModalBoldConsentText>
        <ModalConsentButtons>
          <ModalConsentAgreeContainer>
            <Button
              size="small"
              onClick={() => {
                closeModal(true);
                addFlash(
                  {
                    id: "modal.consent_modal.agree_has_been_saved",
                  },
                  "success",
                );
              }}
            >
              <FormattedMessage id="modal.consent_modal.agree" />
            </Button>
          </ModalConsentAgreeContainer>
          <ModalDisagreeButtons>
            <Button
              size="small"
              type="accent"
              onClick={() => {
                closeModal(false);
                addFlash(
                  {
                    id: "modal.consent_modal.disagree_has_been_saved",
                  },
                  "success",
                );
              }}
            >
              <FormattedMessage id="modal.consent_modal.disagree" />
            </Button>
            <Button
              size="small"
              type="accent"
              onClick={() => {
                sessionStorage.setItem("hideConsent", JSON.stringify(true));
                dismiss();
                addFlash(
                  {
                    id: "modal.consent_modal.we_will_ask_again",
                  },
                  "success",
                );
              }}
            >
              <FormattedMessage id="modal.consent_modal.ask_later" />
            </Button>
          </ModalDisagreeButtons>
        </ModalConsentButtons>

        {consent.short_text && (
          <ModalConsentFineprint>
            <p
              dangerouslySetInnerHTML={{
                __html: consent.short_text,
              }}
            />
          </ModalConsentFineprint>
        )}
      </ModalConsentSide>
    </ModalConsentModal>
  );
};

export default connect(null, { changeUserConsent, addFlash })(Consent);
