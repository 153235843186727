import { ActionsObservable, ofType } from "redux-observable";
import { Subject } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import history from "common/utils/history";
import { SetUserAction } from "common/actions/user";

export default (action$: ActionsObservable<SetUserAction>) =>
  action$.pipe(
    ofType("SET_USER"),
    take(1),
    switchMap(() => {
      const subject = new Subject();
      let prevPath: string | null = null;
      history.listen(location => {
        if (prevPath !== location.pathname) {
          subject.next(location);
        }

        prevPath = location.pathname;
      });
      return subject;
    }),
  );
