import React from "react";
import {
  MessageDescriptor,
  WrappedComponentProps,
  injectIntl,
} from "react-intl";
import Markdown from "common/components/Markdown";

type Props = MessageDescriptor & {
  values?: Record<any, any>;
} & WrappedComponentProps;

const LocalizedMarkdown = ({ intl, values, ...props }: Props) => (
  <Markdown source={intl.formatMessage(props, values)} />
);

export default injectIntl(LocalizedMarkdown);
