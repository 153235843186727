import React, { useState } from "react";
import { Choice } from "common/components/Form";

import { SlideContent } from "./SlideCarousel.styles";

export type CheckboxSlideProps = {
  label?: string;
  selected?: boolean;
  changeSelected?: (checked: boolean) => void;
};

export default function CheckboxSlide(props: CheckboxSlideProps) {
  const { label, selected, changeSelected } = props;
  const [checked, changeChecked] = useState(selected);
  return (
    <SlideContent>
      <Choice
        type="checkbox"
        label={label}
        id={label}
        value={label}
        checked={checked}
        onChange={() => {
          changeChecked(!checked);
          if (changeSelected) {
            changeSelected(!checked);
          }
        }}
        background
      />
    </SlideContent>
  );
}
