import React, { useEffect } from "react";
import CalendarEntries from "client/components/WelcomeClient/CalendarEntries";
import { CalendarEntry } from "client/models/calendarEntries";
import { Container, Header } from "./CalendarEntriesContainer.styles";
import { FormattedMessage } from "react-intl";

type Props = {
  entries: CalendarEntry[];
  getUserAppointments?: any;
  clientId?: number;
};

const CalendarEntriesContainer = ({
  entries,
  clientId,
  getUserAppointments,
}: Props) => {
  useEffect(() => {
    clientId && getUserAppointments(clientId);
  }, [getUserAppointments]);

  return (
    <Container>
      <Header>
        <FormattedMessage id="client.calendar.next" />
      </Header>
      {entries && <CalendarEntries entries={entries} />}
    </Container>
  );
};

export default CalendarEntriesContainer;
