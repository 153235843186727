import { SiteSettings } from "../holvikaari";

import ccda from "./services/ccda";
import articles from "./services/articles";
import calendar from "./services/calendar";

const api2 = (urls: Record<string, string> = {}) => {
  const baseUrls = { ...SiteSettings, ...urls };
  return {
    ...ccda(baseUrls),
    ...articles(baseUrls),
    ...calendar(baseUrls),
  };
};

export default api2;
