import { get, parseJson, endpoints } from "common/utils/api";

export type SetPeopleAction = {
  type: "SET_PEOPLE" | "SET_PEOPLE_FILTERED";
  options: any;
  tab: any;
  meta: any;
  data: any;
  invites: any;
  links: any;
};
export const getUsers = (
  options: any = {},
  activeTab?: any,
  signal?: AbortSignal,
) =>
  get(
    options.url ? options.url : endpoints.users.index(options),
    false,
    signal ? { signal } : {},
  ).then(
    (data): SetPeopleAction => ({
      type: options.query ? "SET_PEOPLE_FILTERED" : "SET_PEOPLE",
      options,
      tab: activeTab,
      meta: data.meta,
      ...(parseJson(data, options) as any),
    }),
  );
