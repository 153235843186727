import React, { useEffect, useState } from "react";
import { endpoints, get } from "common/utils/api";
import { FormattedMessage } from "react-intl";

import { openModal } from "common/actions/modal";
import { connect } from "react-redux";
import { ICrcIssuable } from "hcp/components/Staff/IssueList/IssueList.types";

const mapDispatchToProps = { openModal };

type Props = typeof mapDispatchToProps & {
  issue: ICrcIssuable;
  client_id: string;
  button?: boolean;
  className?: string;
};

const CrcModalLink = (props: Props) => {
  const [issue, setIssue] = useState<ICrcIssuable | null>(null);

  useEffect(() => {
    if (!props.issue) {
      get(endpoints.crc_issues.index(props.client_id)).then(([issue]) =>
        setIssue(issue),
      );
    } else {
      setIssue(props.issue);
    }
  }, [setIssue, props.issue]);

  if (!issue) return null;
  const { button, className, client_id } = props;
  const LinkEl: any = button ? "Button" : "a";
  const { cannot_attend, all_data_present } = issue.item;
  return cannot_attend || all_data_present ? (
    <LinkEl
      className={className}
      onClick={() =>
        props.openModal({
          type: "crc_modal",
          issue,
          client_id: client_id,
        })
      }
    >
      <FormattedMessage id="modal.crc_module.contact_customer" />
    </LinkEl>
  ) : null;
};

export default connect(null, mapDispatchToProps)(CrcModalLink);
