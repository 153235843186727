import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import {
  spacing,
  colors,
  typography,
  getSymptomGradeColor,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { Icon } from "@netmedi/frontend-design-system";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";

type Filled = {
  $filled: boolean;
};

type Selected = {
  $selected: boolean;
};

type DiscProps = {
  $size: "small" | "medium" | "large";
  $visualizeSeverity: boolean;
  $fill: number;
  $filled: boolean;
};

type NumbersProps = {
  $selected: boolean;
  $fill: number;
};

const barHeight = spacing.xxs;

const small = css`
  width: 1rem;
  height: 1rem;
`;
const medium = css`
  width: 1.2rem;
  height: 1.2rem;
`;
const large = css`
  width: 1.5rem;
  height: 1.5rem;
`;

const sizes = { small, medium, large };

export const StyledBar = styled.div`
  height: ${barHeight}px;
  border-radius: ${barHeight / 2}px;
  position: relative;
  background-color: ${colors.gray300};
  > span {
    display: block;
    height: 100%;
    border-radius: ${barHeight / 2}px;
    background-color: ${colors.blue700};
    position: relative;
    overflow: hidden;
  }
`;

const severity = css`
  background-color: ${colors.gray300};
  border-color: ${colors.gray300};
`;

export const StyledDisc = styled.span.attrs(({ $filled }: Filled) => ({
  "data-testid": $filled ? "styled-disc-filled" : "styled-disc",
}))<DiscProps>`
  ${rtl`margin: 0 ${spacing.xxs}px 0 0;`}
  display: inline-block;
  width: ${typography.fontSizeMedium};
  height: ${typography.fontSizeMedium};
  border: 1px solid ${colors.blue700};
  border-radius: 100%;
  ${({ $size }) => $size && sizes[$size]}
  ${({ $filled, $visualizeSeverity }) =>
    $filled &&
    !$visualizeSeverity &&
    css`
      background-color: ${colors.blue700};
    `}
  ${({ $visualizeSeverity }) => $visualizeSeverity && severity}
  ${({ $fill, $filled, $visualizeSeverity }) =>
    $filled &&
    $visualizeSeverity &&
    css`
      background-color: ${getSymptomGradeColor($fill)};
      border-color: ${getSymptomGradeColor($fill)};
    `}
`;

export const ProgressIcon = styled(Icon)`
  ${rtl`margin: 0 ${spacing.xxs}px 0 0;`}
`;

const selectedNumber = (fill: number) => css`
  background-color: ${getSymptomGradeColor(fill)};
  border-color: ${getSymptomGradeColor(fill)};
  color: ${colors.white};
`;

export const StyledNumbers = styled.span.attrs(({ $selected }: Selected) => ({
  "data-testid": $selected ? "styled-number-selected" : "styled-number",
}))<NumbersProps>`
  ${rtl`margin: 0 ${spacing.xxs}px 0 0;`}
  font-size: ${typography.fontSizeSmall};
  display: inline-block;
  width: calc(${typography.fontSizeSmall} + ${rem(15)});
  height: calc(${typography.fontSizeSmall} + ${rem(15)});
  line-height: calc(${typography.fontSizeSmall} + ${rem(15)});
  border: 1px solid ${colors.gray300};
  color: ${colors.gray700};
  border-radius: 100%;
  ${rtl`margin-right: ${spacing.xxs}px;`}
  &:last-child {
    ${rtl`margin-right: 0;`}
  }
  ${({ $selected, $fill }) => $selected && selectedNumber($fill)}
`;
