import React from "react";
import { BodyPart } from "./svg/DefaultBodyPartsSvg";
import { BodymapProps } from "./BodymapQuestion.types";
import { Path } from "./Bodymap.styles";

type Props = {
  id:
    | "front"
    | "back"
    | "default-front"
    | "default-back"
    | "skin-changes-front"
    | "skin-changes-back"
    | "generic-pain-front"
    | "generic-pain-back"
    | "joint-pain-front"
    | "joint-pain-back"
    | "lymph-nodes";
  bodyParts: BodyPart[];
  choicesDisabled?: boolean;
} & BodymapProps;

const BodyParts = (props: Props) => {
  const {
    id,
    bodyParts,
    intl,
    choices,
    frozen,
    onClick,
    onKeyDown,
    pathProps,
  } = props;

  return (
    <g id={"bodyParts_" + id}>
      {bodyParts.map((bp, i) => {
        const {
          name,
          d,
          strokeMiterlimit,
          strokeLinecap,
          strokeLinejoin,
          r,
          cx,
          cy,
          transform,
          tabindex,
        } = bp;

        const translation = intl.formatMessage({
          id: "body_map.body_parts." + name,
        });

        const selected = !!choices[name as string];

        return (
          <Path
            {...pathProps}
            key={name + i}
            name={name}
            role="checkbox"
            aria-label={translation}
            aria-checked={selected}
            frozen={frozen}
            tabindex={tabindex}
            selected={selected}
            onClick={onClick}
            onKeyDown={onKeyDown}
            d={d}
            r={r}
            cx={cx}
            cy={cy}
            transform={transform}
            strokeMiterlimit={strokeMiterlimit}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            as={r ? "circle" : "path"}
          />
        );
      })}
    </g>
  );
};

export default BodyParts;
