import React, { MouseEventHandler } from "react";
import Label from "common/components/Label";
import { User } from "common/models/user";

type SetTooltip = (params: any) => void;

const mouseEnter = (
  setTooltip: SetTooltip,
  treatment_program: any,
  e: React.MouseEvent,
) => {
  setTooltip({
    title: treatment_program.name,
    target: e.target,
    position: "top",
  });
};

const filterTreatmentPrograms = (treatment_programs: any[]) => {
  return treatment_programs.filter(
    treatment_program => treatment_program.type === null,
  );
};

type TreatmentProgramLabelProps = {
  treatment_program: any;
  setTooltip: SetTooltip;
  clearTooltip: MouseEventHandler;
};

export function TreatmentProgramLabel({
  treatment_program,
  setTooltip,
  clearTooltip,
}: TreatmentProgramLabelProps) {
  return (
    <Label
      key={treatment_program.id + "-TP-Abbreviation-Label"}
      label={treatment_program.abbreviation}
      title={treatment_program.name}
      onMouseEnter={mouseEnter.bind(null, setTooltip, treatment_program)}
      onMouseLeave={clearTooltip}
      className={treatment_program.category_id}
    />
  );
}

export type TreatmentProgramLabelsProps = {
  user: Pick<User, "treatment_programs">;
  setTooltip: SetTooltip;
  clearTooltip: MouseEventHandler;
};

export default function TreatmentProgramLabels({
  user,
  setTooltip,
  clearTooltip,
}: TreatmentProgramLabelsProps) {
  return (
    <>
      {filterTreatmentPrograms(user.treatment_programs || []).map(
        treatment_program => (
          <TreatmentProgramLabel
            key={treatment_program.id}
            treatment_program={treatment_program}
            setTooltip={setTooltip}
            clearTooltip={clearTooltip}
          />
        ),
      )}
    </>
  );
}
