/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { ValidationErrors } from "final-form";
import { FormField, PhoneInput } from "common/components/Form";
import { PlainFormField } from "common/components/Form/FormField";
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl,
} from "react-intl";
import { SiteSettings } from "common/utils/holvikaari";
import { omit, pick } from "lodash";
import { User } from "common/models/user";

import {
  BodyStyle,
  ConsentTitle,
  ConsentIcon,
  ConsentPage,
  InviteTitle,
  InvitePageContainer,
  FormContainer,
  InviteFormHelp,
  SubmitButton,
  RadioButtonContainer,
  RequiredFields,
  ShowPasswordWrapper,
  SelectDomainsDescription,
} from "./InvitePage.styles";

import ConsentInputs from "./ConsentInputs";
import InviteWizard from "./InviteWizard";
import { validateInviteForm } from "./validateInviteForm";
import { CheckBox, Icon } from "@netmedi/frontend-design-system";
import RadioButton from "common/components/RadioButton";
import {
  ErrorMsg,
  FieldRequired,
} from "common/components/Form/FormField.styles";
import DOBFields, { DOBFieldsHelp } from "client/utils/DOBFields";
import { focusOn } from "common/components/Form/utils";
import SelectDomains from "../ManageDomainsModal/SelectDomains";
import { birthdate } from "common/utils/general";
import { getDefaultCountryCode } from "common/utils/phone";
import { taskSubscrioptionDomains } from "../ManageDomainsModal/helpers";

export type InvitePageParams = {
  askPhoneNumber: boolean;
  person: User;
  consents: any[];
  anonymised?: boolean;
  personIdLabel: string;
  personIdFieldIsEnabledInInviteFor?: boolean;
  overridePassword?: string;
  formURL: string;
  formAuthenticityToken: string;
  phoneCountries: string[];
  may_edit_email: boolean;
  askDomains: boolean;
  invitedIsPureCaregiver?: boolean;
};

type InvitePageProps = { params: InvitePageParams } & WrappedComponentProps;

function InvitePage(props: InvitePageProps) {
  const submitting = false;
  const [countryCode, setCountryCode] = useState<string>();
  const [page, setPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - this version of react-telephone-input does not export Country, ignore for now
  const updatePhoneCountry = (_phoneNumber: string, country: Country) => {
    setCountryCode(country?.dialCode);
  };

  const {
    params: {
      askPhoneNumber,
      consents,
      person,
      anonymised,
      formURL,
      formAuthenticityToken,
      phoneCountries,
      overridePassword,
      personIdLabel,
      personIdFieldIsEnabledInInviteFor,
      may_edit_email,
      askDomains,
      invitedIsPureCaregiver,
    },
    intl,
  } = props;

  const client = pick(
    person,
    "email",
    "first_name",
    "last_name",
    "person_id",
    "study_id",
    "password",
    "password_confirmation",
    "gender",
    "phone",
    "address",
    "domains",
    "task_subscriptions",
  );

  const initialBirthdate = () => {
    if (person.birthdate) {
      const bdate = birthdate(person.birthdate);
      return {
        day: bdate?.bd_d,
        month: bdate?.bd_m,
        year: bdate?.bd_y,
      };
    } else {
      return {
        day: null,
        month: null,
        year: null,
      };
    }
  };

  const disableDemographicField = (initialValue: string | undefined | null) => {
    return (
      SiteSettings.patient_demographic_fields_read_only &&
      (initialValue || "") !== ""
    );
  };

  const uniqueTaskDomains = taskSubscrioptionDomains(client);

  const RequiredFieldsMessage = () => (
    <RequiredFields aria-hidden>
      <FormattedMessage
        id="people.required_fields_marked"
        values={{
          requiredIndicator: <FieldRequired>*</FieldRequired>,
        }}
      />
    </RequiredFields>
  );
  const defaultCountry = phoneCountries[0];

  return (
    <InvitePageContainer>
      <Form
        enableReinitialize={true}
        keepDirtyOnReinitialize={true}
        initialValues={{
          client: {
            email: client.email || "",
            first_name: client.first_name || "",
            last_name: client.last_name || "",
            person_id: client.person_id || "",
            study_id: client.study_id || "",
            password: "",
            password_confirmation: "",
            gender: client.gender,
            phone: client.phone || "",
            address: client.address || "",
            domains: askDomains ? uniqueTaskDomains : client.domains,
          },
          birthdate_fields: initialBirthdate(),
          birthdate: initialBirthdate(),
          showPassword: false,
        }}
        onSubmit={() => {
          // Do nothing and let the form submission happen via the browser
        }}
        validate={(
          values: Record<string, any>,
        ): ValidationErrors | Promise<ValidationErrors> => {
          return validateInviteForm(
            values,
            props.params,
            page,
            invitedIsPureCaregiver,
          );
        }}
      >
        {({ handleSubmit, form }) => (
          <FormContainer
            onSubmit={event => {
              // Run the React Final Form submit handler
              handleSubmit(event);

              // If there are validation errors, focus on the first one
              if (form.getState().hasValidationErrors) {
                focusOn("input[aria-invalid=true], [aria-invalid=true] input");
              } else {
                // If all fields are valid
                // Do a manual form submission if on the last page
                if (lastPage) {
                  const formEl = event.target as HTMLFormElement;
                  formEl.submit();
                } else {
                  // Advance to the next page if not on the last page
                  // Ensure that the fields aren't marked as errored when moving
                  // between steps
                  setPage((page: number) => page + 1);
                  form.getRegisteredFields().forEach(field => {
                    form.resetFieldState(field);
                  });
                }
              }
            }}
            action={formURL}
            method="POST"
            autoComplete="off"
            noValidate
          >
            <BodyStyle />
            <input type="hidden" name="_method" value="patch" />
            <input type="hidden" name="utf8" value="✓" />
            <input
              type="hidden"
              name="authenticity_token"
              value={formAuthenticityToken}
            />

            <InviteWizard
              page={page}
              setPage={setPage}
              setLastPage={setLastPage}
              submitComponent={
                <SubmitButton submit disabled={submitting}>
                  <FormattedMessage
                    id={lastPage ? "people.register" : "people.next"}
                  />
                </SubmitButton>
              }
            >
              <div>
                <FormattedMessage
                  id="people.register_to_service"
                  tagName="h1"
                  values={{ product: SiteSettings.product_name }}
                >
                  {txt => <InviteTitle>{txt}</InviteTitle>}
                </FormattedMessage>

                <RequiredFieldsMessage />

                {askPhoneNumber && (
                  <>
                    <Field name="client[phone]">
                      {fieldProps => (
                        <FormField
                          name="client[phone]"
                          label="attributes.phone"
                          labelOnTop={true}
                          required={askPhoneNumber}
                          showRequiredIndicator={askPhoneNumber}
                          input={omit(fieldProps.input, "onChange")}
                          {...omit(fieldProps, "input")}
                        >
                          <PhoneInput
                            inputProps={{
                              name: "client[phone]",
                              "aria-labelledby": "client[phone]",
                            }}
                            onChange={(phoneNumber, country) => {
                              updatePhoneCountry(phoneNumber, country);
                              fieldProps.input.onChange(phoneNumber);
                            }}
                            defaultCountry={defaultCountry}
                          />
                        </FormField>
                      )}
                    </Field>
                    {/* Override the phone number input so that the country code isn't submitted */}
                    <input
                      type="hidden"
                      name="client[phone]"
                      value={
                        (form.getState()?.values.client?.phone || "").length > 4
                          ? form.getState()?.values.client?.phone
                          : ""
                      }
                    />
                    <InviteFormHelp>
                      <FormattedMessage
                        id="phone.instructions"
                        values={{
                          country_code:
                            countryCode ??
                            getDefaultCountryCode(defaultCountry),
                          phonenumber: SiteSettings.local_phonenumber,
                          placeholder: SiteSettings.phone_placeholder,
                        }}
                      />
                    </InviteFormHelp>
                  </>
                )}

                <fieldset>
                  <PlainFormField
                    type="email"
                    name="client[email]"
                    label="attributes.email"
                    labelOnTop={true}
                    disabled={!may_edit_email}
                    required
                    showRequiredIndicator
                  />
                </fieldset>

                {overridePassword && overridePassword.length > 0 ? (
                  <>
                    <input
                      type="hidden"
                      name="client[password]"
                      value={overridePassword}
                    />
                    <input
                      type="hidden"
                      name="client[password_confirmation]"
                      value={overridePassword}
                    />
                  </>
                ) : (
                  <>
                    <fieldset>
                      <PlainFormField
                        name="client[password]"
                        label="attributes.password"
                        labelOnTop={true}
                        type={
                          form.getState().values.showPassword
                            ? "text"
                            : "password"
                        }
                        required
                        showRequiredIndicator
                      />
                      <input
                        type="hidden"
                        name="client[password_confirmation]"
                        value={form.getState().values.client.password}
                      />
                      <InviteFormHelp>
                        <FormattedMessage id="people.password_requirements" />
                      </InviteFormHelp>
                      <Field name="showPassword" type="checkbox">
                        {fieldProps => {
                          const { input } = fieldProps;
                          const { name, ...rest } = input;

                          return (
                            <ShowPasswordWrapper>
                              <CheckBox
                                id={name}
                                name={name}
                                label={intl.formatMessage({
                                  id: "loginpage.show_password",
                                })}
                                {...rest}
                              />
                            </ShowPasswordWrapper>
                          );
                        }}
                      </Field>
                    </fieldset>
                  </>
                )}

                {anonymised && (
                  <Field name="client[study_id]">
                    {fieldProps => (
                      <PlainFormField
                        input={{ id: "client_study_id", ...fieldProps.input }}
                        name="client[study_id]"
                        label="attributes.study_id"
                        labelOnTop={true}
                        disabled
                      />
                    )}
                  </Field>
                )}
              </div>

              {!anonymised && (
                <div>
                  <FormattedMessage id="people.create_your_profile">
                    {txt => <InviteTitle>{txt}</InviteTitle>}
                  </FormattedMessage>

                  <RequiredFieldsMessage />

                  <fieldset>
                    <PlainFormField
                      labelOnTop={true}
                      name="client[first_name]"
                      label="attributes.first_name"
                      required
                      disabled={disableDemographicField(person.first_name)}
                      showRequiredIndicator
                    />
                    <PlainFormField
                      labelOnTop={true}
                      name="client[last_name]"
                      label="attributes.last_name"
                      required
                      disabled={disableDemographicField(person.last_name)}
                      showRequiredIndicator
                    />
                  </fieldset>
                  {!invitedIsPureCaregiver && (
                    <>
                      <div>
                        <Field
                          name="birthdate_fields"
                          label="attributes.birthdate"
                          labelOnTop={true}
                          required
                          showRequiredIndicator
                        >
                          {birthdateProps => (
                            <DOBFields
                              formType="invite"
                              locale={intl.locale}
                              birthdateProps={birthdateProps}
                              disabled={disableDemographicField(
                                person.birthdate,
                              )}
                            />
                          )}
                        </Field>
                        {!disableDemographicField(person.birthdate) && (
                          <InviteFormHelp>
                            <DOBFieldsHelp />
                          </InviteFormHelp>
                        )}
                      </div>

                      <FormField
                        name="client[gender]"
                        label="attributes.gender"
                        labelOnTop={true}
                        required
                        inactiveLabel
                        showRequiredIndicator
                      >
                        <div>
                          <RadioButtonContainer
                            disabled={disableDemographicField(person.gender)}
                          >
                            <Field
                              name="client[gender]"
                              type="radio"
                              value="male"
                            >
                              {fieldProps => (
                                <RadioButton
                                  id="male_choice"
                                  disabled={disableDemographicField(
                                    person.gender,
                                  )}
                                  label={intl.formatMessage({
                                    id: "attributes.genders.male",
                                  })}
                                  {...fieldProps.input}
                                />
                              )}
                            </Field>
                          </RadioButtonContainer>
                          <RadioButtonContainer
                            disabled={disableDemographicField(person.gender)}
                          >
                            <Field
                              name="client[gender]"
                              type="radio"
                              value="female"
                            >
                              {fieldProps => (
                                <>
                                  <RadioButton
                                    id="female_choice"
                                    disabled={disableDemographicField(
                                      person.gender,
                                    )}
                                    label={intl.formatMessage({
                                      id: "attributes.genders.female",
                                    })}
                                    {...fieldProps.input}
                                  />
                                  {fieldProps.meta.submitFailed &&
                                    fieldProps.meta.error && (
                                      <ErrorMsg>
                                        {fieldProps.meta.error}
                                      </ErrorMsg>
                                    )}
                                </>
                              )}
                            </Field>
                          </RadioButtonContainer>
                        </div>
                      </FormField>

                      {personIdFieldIsEnabledInInviteFor && (
                        <Field name="client[person_id]">
                          {fieldProps => (
                            <>
                              <PlainFormField
                                input={{
                                  id: "client_person_id",
                                  ...fieldProps.input,
                                }}
                                name="client[person_id]"
                                label={personIdLabel}
                                labelOnTop={true}
                              />
                              <InviteFormHelp>
                                <FormattedMessage id="people.person_id_helper" />
                              </InviteFormHelp>
                              {SiteSettings.enable_person_id_login && (
                                <InviteFormHelp>
                                  <FormattedMessage id="people.username_email_help" />
                                </InviteFormHelp>
                              )}
                            </>
                          )}
                        </Field>
                      )}
                    </>
                  )}
                </div>
              )}
              {Boolean(askDomains) && (
                <div>
                  <FormattedMessage id="people.select_domains">
                    {txt => <InviteTitle>{txt}</InviteTitle>}
                  </FormattedMessage>
                  <RequiredFieldsMessage />
                  <div style={{ display: "flex" }}>
                    <FieldRequired>*</FieldRequired>
                    <SelectDomainsDescription>
                      <FormattedMessage id="people.domains_description" />
                    </SelectDomainsDescription>
                  </div>
                  <SelectDomains name="client[domains]" user={client} />
                </div>
              )}
              {Array.isArray(consents) && consents.length > 0 && (
                <ConsentPage>
                  <ConsentIcon>
                    <Icon name="form_done_48px" size="xxl" />
                  </ConsentIcon>
                  <FormattedMessage id="people.accept_terms" tagName="h1">
                    {txt => <ConsentTitle>{txt}</ConsentTitle>}
                  </FormattedMessage>
                  <FormattedMessage
                    id="people.consent_note"
                    values={{ product_name: SiteSettings.product_name }}
                  />
                  <ConsentInputs consents={consents} />
                </ConsentPage>
              )}
            </InviteWizard>
          </FormContainer>
        )}
      </Form>
    </InvitePageContainer>
  );
}

export default injectIntl(InvitePage);
