import { ActionsObservable, ofType } from "redux-observable";
import { filter, map, take } from "rxjs/operators";
import { addFlash } from "common/actions/flash";
import { isClient } from "common/utils/user";
import { SetUserAction } from "common/actions/user";

export const serviceClosed = (action$: ActionsObservable<SetUserAction>) =>
  action$.pipe(
    ofType("SET_USER"),
    take(1),
    filter(({ data, meta }) => isClient(data) && !!meta?.service_closed),
    map(({ meta }) =>
      addFlash(
        {
          id: "service_closed",
          source: meta?.service_closed,
          markdown: true,
          collapsible: true,
          markdownOptions: {
            html: true,
          },
        },
        "warning",
      ),
    ),
  );
