import { LoadingStateOption, RequestStatus } from "common/reducers/loading";

export type LoadingAction = {
  type: "SET_LOADING";
  fetchType: LoadingStateOption;
  entityId?: string;
  value: RequestStatus;
};

export const setLoadingStatus = (
  fetchType: LoadingStateOption,
  value: RequestStatus,
  entityId?: string,
): LoadingAction => ({
  type: "SET_LOADING",
  fetchType,
  entityId,
  value,
});
