import React from "react";
import {
  BodyMapContainer,
  BodyMapSvgContainer,
  BodyMapHeading,
  BodyMapText,
} from "./Bodymap.styles";
import { BodymapProps } from "./BodymapQuestion.types";
import { injectIntl } from "react-intl";
import DefaultBackSvg from "./svg/DefaultBackSvg";
import SkinChangesBackSvg from "./svg/SkinChangesBackSvg";
import GenericPainBackSvg from "./svg/GenericPainBackSvg";
import JointPainBackSvg from "./svg/JointPainBackSvg";

const BodymapBack = (props: BodymapProps) => {
  const { intl, noText, display } = props;
  const textTopPos = display === "Default" ? "25%" : "0";

  if (display === "LymphNodes") return null;

  return (
    <BodyMapContainer testId="back">
      <BodyMapHeading hidden={noText}>
        {intl.formatMessage({
          id: "body_map.back",
        })}
      </BodyMapHeading>
      <BodyMapSvgContainer>
        <BodyMapText left={"5%"} top={textTopPos}>
          {!noText &&
            intl.formatMessage({
              id: "body_map.left_side",
            })}
        </BodyMapText>

        {
          {
            Default: <DefaultBackSvg {...props} />,
            SkinChanges: <SkinChangesBackSvg {...props} />,
            GenericPain: <GenericPainBackSvg {...props} />,
            JointPain: <JointPainBackSvg {...props} />,
          }[display]
        }

        <BodyMapText right={"5%"} top={textTopPos}>
          {!noText &&
            intl.formatMessage({
              id: "body_map.right_side",
            })}
        </BodyMapText>
      </BodyMapSvgContainer>
    </BodyMapContainer>
  );
};

export default injectIntl(BodymapBack);
