import styled from "styled-components";
import { Button, Icon } from "@netmedi/frontend-design-system";
import rtl from "styled-components-rtl";
import {
  mediaMin,
  mediaMax,
} from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";
import { mediaQueries } from "@netmedi/frontend-design-system/dist/styles/variables";

export const RemoveCaregiversCancelButton = styled(Button)`
  border: none;
  color: #4549c3;
  background-color: white;
  cursor: pointer;
  margin-top: 100px;
  position: absolute;
  bottom: 50px;
  padding: 0;

  ${mediaMin(mediaQueries.medium)} {
    ${rtl`right: 300px;`}
  }
  ${mediaMax(280)} {
    bottom: 50px;
    ${rtl`right: 30px;`}
  }
  :hover {
    background-color: white;
  }
`;

export const RemoveCaregiversRevokeAccessButton = styled(Button)`
  cursor: pointer;
  margin-top: 100px;
  position: absolute;
  bottom: 30px;
  ${rtl`right: 30px;`}
  ${mediaMin(mediaQueries.medium)} {
    margin-top: 70px;
  }
  ${mediaMax(280)} {
    bottom: 90px;
  }
`;

export const RemoveCaregiversRevokeAccessIcon = styled(Icon)`
  cursor: pointer;
  ${rtl`margin-right: 8px;`}
`;

export const RemoveCaregiversModalContent = styled.div`
  background-color: white;
  min-height: 300px;
`;

export const RemoveCaregiversModalParagraph = styled.p`
  margin: 0;
  overflow-wrap: break-word;
`;

export const RemoveCaregiversModalHeader = styled.div`
  background-color: white;
  display: flex;
  margin-bottom: 20px;
  ${mediaMax(280)} {
    overflow-wrap: break-word;
    max-width: 210px;
  }
`;

export const CloseModalIcon = styled(Icon)`
  cursor: pointer;
`;

export const CloseModalButton = styled(Button)`
  padding: 0;
  margin: 0;
  position: absolute;
  top: 30px;
  ${rtl`right: 30px;`}
  ${mediaMax(280)} {
    top: 45px;
  }
`;
