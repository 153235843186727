import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { Link } from "react-router-dom";
import {
  scaffolding,
  mediaQueries,
  colors,
  tabs,
  zIndexes,
  typography,
  navigation,
  linkColor,
  spacing,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { fadeIn } from "@netmedi/frontend-design-system/dist/styles/animations";
import { mediaMax } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { Icon } from "@netmedi/frontend-design-system";

type Page = {
  $compact: boolean;
};

type FlashProps = {
  $absolute: boolean;
};

type HeaderProps = {
  $linkBack?: string;
  $noNavigation: boolean;
  $colorTheme: "light" | "dark" | undefined;
};

type NavProps = {
  $tabs: boolean;
};

const { pagePadding, pagePaddingMobile, gridBorderColor } = scaffolding;

const articleLinkStyles = css`
  font-family: ${typography.fontFamilyHeadings};
  border-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(13)};
  letter-spacing: 1.5px;
`;

const articleHeaderStyles = css`
  font-family: ${typography.fontFamilyHeadings};
  font-size: ${rem(18)};
  font-weight: 400;
  line-height: ${rem(18)};
`;

const colorThemeStyles = {
  light: css`
    a {
      ${articleLinkStyles}
      color: white;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      &:hover {
        color: white;
      }
    }
    h1 {
      ${articleHeaderStyles}
      color: white;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }
  `,
  dark: css`
    a {
      ${articleLinkStyles}
      color: ${linkColor};
    }
    h1 {
      ${articleHeaderStyles}
      color: ${colors.gray900};
    }
  `,
};

const linkBackStyles = css`
  position: relative;
`;

export const LinkWrapper = styled.div`
  display: block;
  margin: 0 0 0.75em 0;
`;

const linkStyles = css`
  display: flex;
  align-items: center;
  color: ${linkColor};
  span {
    display: flex;
  }
`;

export const StyledIcon = styled(Icon)`
  ${({ theme }) => theme.dir === "rtl" && "transform: scale(-1, 1);"}
`;

export const StyledRouterLink = styled(Link).attrs(() => ({
  "data-testid": "router-back-link",
}))`
  ${linkStyles}
`;

export const StyledLink = styled.a.attrs(() => ({
  "data-testid": "page-back-link",
}))`
  ${linkStyles}
`;

const noNavigationStyles = css`
  background: ${colors.yellow300};
  padding: 1rem;
  ${StyledRouterLink}, ${StyledLink} {
    color: ${colors.black};
  }
`;

export const FlashContainer = styled.div<FlashProps>`
  margin: -${pagePadding};
  margin-bottom: 0;
  position: relative;
`;

export const Content = styled.div.attrs(() => ({
  "data-testid": "page-content",
}))`
  position: relative;
  flex: 1;
`;

export const Nav = styled.nav<NavProps>`
  ${rtl`margin-left: -1rem;`}
  margin-bottom: 0.5rem - ${pagePadding};
  font-weight: 500;
  font-size: ${typography.fontSizeMedium};

  li {
    display: inline-block;
    padding: 0.5em 1em;
  }

  ${({ $tabs }) =>
    $tabs &&
    css`
      margin-bottom: -${pagePadding};
      padding-bottom: 0px;
      ${rtl`margin-left: 0;`}
      font-weight: 400;
      font-size: ${typography.fontSizeBase};

      li {
        padding: 0;
      }

      a {
        display: block;
        border-bottom: 3px transparent solid;
        padding: 0.5em 1.5em;
        color: ${navigation.sidebar.linkColor};
        transition: all 0.2s;

        &:hover {
          border-color: ${colors.gray50};
        }

        &[data-focus-visible-added] {
          outline: 2px solid ${colors.blue700};
          outline-offset: -2px;
          border-radius: 2px;
        }
      }
      .active a {
        border-color: ${tabs.pageNavActiveBorder};
      }
    `}
`;

export const PageHeader = styled.header.attrs(() => ({
  "data-testid": "page-header",
}))<HeaderProps>`
  position: relative;
  margin: 0 -${pagePadding} ${pagePadding} -${pagePadding};
  padding: ${pagePadding};
  border-bottom: ${gridBorderColor} 1px solid;
  background: ${colors.white};
  z-index: ${zIndexes.low};
  overflow: hidden;

  h1 {
    margin: 0;
    display: inline-block;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
  ${mediaMax(mediaQueries.small)} {
    margin: 0 -${pagePaddingMobile} ${pagePaddingMobile} -${pagePaddingMobile};
    padding: ${pagePaddingMobile};
    h1 {
      display: block;
    }
  }
  ${({ $linkBack }) => $linkBack && linkBackStyles};
  ${({ $noNavigation }) => $noNavigation && noNavigationStyles};
  ${({ $colorTheme }) => $colorTheme && colorThemeStyles[$colorTheme]};

  &
    + div
    #menu-overlay-status[checked]
    ~ div
    [data-testid="participant-list"]
    section
    [data-testid="user-container"] {
    margin: 0;
  }
`;

const compactStyles = css`
  padding: 0;
  ${FlashContainer} {
    margin: 0;
  }
  ${PageHeader} {
    ${rtl`
      margin-left: 0;
      margin-right: 0;
    `}
    margin-bottom: 0;
  }
`;

const notCompactStyles = css`
  ${mediaMax(mediaQueries.small)} {
    padding: ${pagePaddingMobile};
  }
`;

const checkCompact = (compact: boolean) => {
  if (compact) {
    return compactStyles;
  }
  return notCompactStyles;
};

export const StyledPage = styled.div.attrs(() => ({
  "data-testid": "page-page",
}))<Page>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: ${pagePadding};
  position: relative;
  animation: ${fadeIn} 0.4s;
  ${({ $compact }) => checkCompact($compact)}
`;

export const LinkBackDescription = styled.span.attrs(() => ({
  "data-testid": "link-back-desc",
}))`
  ${rtl`margin-left: ${spacing.xxs}px;`}
  font-weight: 500;
`;

export const Subtitle = styled.span`
  display: inline-block;
  ${rtl`margin-left: 1rem;`}
  color: ${colors.gray700};
`;
