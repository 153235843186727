import { Button } from "@netmedi/frontend-design-system";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";

export const Container = styled.div(rtl`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
  button {
    align-self: flex-end;
  }
`);

export const StyledButton = styled(Button)(rtl`
  align-self: flex-end;
`);

export const LabelHeading = styled.label`
  font-weight: bold;
`;

export const Error = styled.label`
  color: ${colors.red700};
`;

export const Header = styled.h1`
  font-size: ${rem(20)};
`;
