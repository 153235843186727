import { get, del, endpoints, parseJson } from "common/utils/api";
import { pushDevicesActions } from "client/reducers/pushDevices";

export const getPushDevices = () =>
  get(endpoints.pushDevices.index)
    .then(parseJson)
    .then(({ data }: any) => pushDevicesActions.set(data));

export const removePushDevices = () =>
  del(endpoints.pushDevices.index).then(() => pushDevicesActions.clear());
