import { FormField, Select } from "common/components/Form";
import React from "react";
import { Field } from "react-final-form";

interface AbsencePersonSelectProps {
  name: string;
  options: { label: string; value: number; formattedLabel: JSX.Element }[];
  validate?: (value: number, ...rest: any) => string | undefined;
}

const AbsencePersonSelect: React.FC<AbsencePersonSelectProps> = ({
  name,
  options,
  validate,
}) => (
  <Field name={name} validate={validate}>
    {fieldProps => (
      <FormField
        name={name}
        inactiveLabel
        labelOnTop
        showRequiredIndicator
        required
        {...fieldProps}
      >
        {/* The fragment is a hack to prevent FormField from altering Select.
            That breaks the select component, it doesn't show options and does not use the provided onChange. */}
        <>
          <Select
            options={options}
            name={name}
            isSearchable
            isClearable
            onChange={e => fieldProps.input.onChange(+e.value)}
            value={
              options.find(p => p.value === fieldProps.input.value) ?? null
            }
            formatOptionLabel={o => o.formattedLabel}
          />
        </>
      </FormField>
    )}
  </Field>
);

export default AbsencePersonSelect;
