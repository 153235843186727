import React from "react";
import { Field } from "react-final-form";
import {
  CheckboxErrors,
  CheckboxGroupContainer,
  CheckboxGroupInput,
  CheckboxGroupLabel,
  CheckboxGroupOption,
} from "./CheckboxGroup.styles";

export type CheckboxOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type CheckboxGroupProps = {
  name: string;
  options: CheckboxOption[];
  validate?: (value: string[]) => string | JSX.Element | undefined;
  showRequiredIndicator?: boolean;
  required?: boolean;
};

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  name,
  options,
  validate,
}) => {
  return (
    <CheckboxGroupContainer data-testid="CheckboxGroup">
      <Field<string[]> name={name} validate={validate}>
        {({ input, meta }) => {
          return (
            <>
              {options.map(option => (
                <CheckboxGroupOption key={option.value}>
                  <CheckboxGroupInput
                    type="checkbox"
                    value={option.value}
                    disabled={option.disabled}
                    checked={input.value.includes(option.value)}
                    id={`${name}-${option.value}`}
                    onChange={e => {
                      const { checked, value } = e.target;
                      if (checked) {
                        input.onChange([...input.value, value]);
                      } else {
                        input.onChange(
                          (input.value as any[]).filter(
                            (v: string) => v !== value,
                          ),
                        );
                      }
                    }}
                  />
                  <CheckboxGroupLabel htmlFor={`${name}-${option.value}`}>
                    {option.label}
                  </CheckboxGroupLabel>
                </CheckboxGroupOption>
              ))}
              <CheckboxErrors>
                {meta.error && meta.touched && <span>{meta.error}</span>}
              </CheckboxErrors>
            </>
          );
        }}
      </Field>
    </CheckboxGroupContainer>
  );
};

export default CheckboxGroup;
