import React, { ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import { defaultsDeep, pick } from "lodash";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { SensiblePagination } from "common/components/Pagination";
import DataTable from "common/components/DataTable";
import { TableHeader } from "./TableHeader";
import { StyledChildren } from "./DataTable.styles";

const StyledSensiblePagination = styled(SensiblePagination)`
  padding-top: 1.5rem;
`;

const getDataParamsKeys = ["filter", "query", "query_scope", "page"];
interface GetDataParams
  extends Record<(typeof getDataParamsKeys)[number], any> {
  /** Filter to filter the content of the table */
  filter?: string;
  query?: string;
  /** Defines the scope or mode of a query **/
  query_scope?: string;
  /** Number of the current page and total pages */
  page: {
    number: number;
    size?: number;
  };
}

export type RichDataTableProps = GetDataParams & {
  /** Includes column names (name) and way to access wanted data (value) */
  columns: {
    name: string;
    value: string | number | React.ReactElement | ((...args: any[]) => any);
  }[];
  classes?: (row: any) => string;
  startLoading: (...args: unknown[]) => unknown;
  /** Items or data to be shown in the table */
  items?: unknown[];
  /** Number of total pages */
  total_pages: number;
  /** use onClick to define "in place" action */
  onClick?: (...args: unknown[]) => unknown;
  /** use rowHref if a click is to take user to a different url */
  rowHref?: (row: any) => string;
  /** Title of the data table */
  title: string;
  /** The message shown, if the table has no data */
  emptyMessage?: string;
  /** Content appearing on the right side of the title */
  headerContent?: string | React.ReactElement;
  /** Whether to have a search box on the right side of the header  */
  enableSearch?: boolean;
  /** Whether to show the column names or not */
  hideColumnNames?: boolean;
  className?: string;
  children?: React.ReactElement | unknown[];
  loading?: boolean;
} & WrappedComponentProps;

/** RichDataTable is a component used for displaying tabular data or listing objects such as
users. It has more components and possibilities than the regular DataTable, for example a search-function. */
const RichDataTable = (props: RichDataTableProps) => {
  useEffect(() => {
    props.startLoading(queryParams());
  }, []);

  // Combine parameters for the search from container, props and .
  function queryParams() {
    return pick(props, getDataParamsKeys);
  }

  function changePage(number: number) {
    props.startLoading(defaultsDeep({ page: { number } }, queryParams()));
  }

  function search(searchEv: ChangeEvent<HTMLInputElement>) {
    props.startLoading(
      defaultsDeep(
        { query: searchEv.target.value, page: { number: 1 } },
        queryParams(),
      ),
    );
  }

  const {
    children,
    total_pages,
    items = [],
    loading,
    enableSearch = true,
  } = props;
  const { page }: any = queryParams();
  const className = props.className ? { className: props.className } : {};
  return (
    <div {...className}>
      <TableHeader onChange={search} {...props} enableSearch={enableSearch} />
      {children && <StyledChildren>{children}</StyledChildren>}
      <DataTable
        {...props}
        data={items}
        loading={loading && items.length === 0}
        pageLoading={loading}
      />
      {total_pages > 1 && (
        <StyledSensiblePagination
          move={changePage}
          currentPage={page.number}
          totalPages={total_pages}
        />
      )}
    </div>
  );
};

export default injectIntl(RichDataTable);
