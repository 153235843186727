import { SiteTeam } from "hcp/models/treatmentProgram";
import {
  SetTeamsAction,
  UpdateTeamAction,
  DeleteTeamAction,
} from "hcp/actions/teams";

export default (
  state: SiteTeam[] = [],
  action: SetTeamsAction | UpdateTeamAction | DeleteTeamAction,
) => {
  switch (action.type) {
    case "SET_TEAMS":
      return action.data;
    case "UPDATE_TEAM": {
      const i = state.findIndex((t: SiteTeam) => t.id === action.data.id);
      state[i] = action.data;
      return state;
    }
    case "DELETE_TEAM": {
      return state.filter(t => t.id !== action.data.id);
    }
    default:
      return state;
  }
};
