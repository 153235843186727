import React, { FormEventHandler, useEffect, useState } from "react";
import {
  injectIntl,
  IntlProvider,
  IntlShape,
  WrappedComponentProps,
} from "react-intl";
import html2canvas from "html2canvas";
import SymptomPage, { pagination } from "./SymptomPage/SymptomPage";
import { fetchBasicDetails, getAllSymptoms } from "client/actions/client";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllSymptomRows,
  selectClientBasicDetails,
} from "client/reducers/client";
import { Button } from "@netmedi/frontend-design-system";
import useRequestStatus from "common/hooks/useRequestStatus";
import { get, track } from "common/utils/api";
import { GenerationStatus } from "./types";
import pdfMake from "pdfmake/build/pdfmake";
import SymptomComparisonPdf, {
  InputFormAnswerGroup,
  InputFormQuestions,
} from "./PdfDefinitions/SymptomComparisonPdf";
import ValuePage, { FieldData } from "./ValuePage/ValuePage";
import { endpoints } from "common/utils/endpoints";
import { SiteSettings } from "common/utils/holvikaari";
import { RequestStatus } from "common/reducers/loading";
import { ObservationsData } from "shared/components/SymptomTableCompact/types";
import { ValidInterval } from "shared/components/SymptomTable/SymptomTable";
import { max, min } from "lodash";
import { RootState } from "store";
import { formatDate } from "common/utils/general";
import dayjs from "dayjs";

const SYMPTOM_INTERVAL: ValidInterval = "week";

type SymptomComparisonExportProps = {
  client_id: string;
  button_title: string;
} & WrappedComponentProps;

interface ClientDetails {
  firstName: string;
  lastName: string;
}

const make = async (
  client_id: string,
  client: ClientDetails,
  valueData: FieldData,
  intl: IntlShape,
) => {
  // Lazy loading fonts
  const vfsModule = import("vfsFonts");

  pdfMake.fonts = {
    Arial: {
      normal: `Arial.ttf`,
      bold: `Arial Bold.ttf`,
      italics: `Arial Italic.ttf`,
      bolditalics: `Arial Bold Italic.ttf`,
    },
  };

  const filename = `${client.lastName} ${client.firstName} ${intl
    .formatMessage({
      id: "pdf_export.symptoms.title",
    })
    .toLocaleLowerCase()} ${formatDate(dayjs())}.pdf`;

  try {
    const symptomElements = document.querySelectorAll<HTMLElement>(
      ".symptom-div-to-print",
    );
    const symptomPromises = Array.from(symptomElements).map(
      (pageHtmlElement: HTMLElement) =>
        html2canvas(pageHtmlElement, {
          onclone: function (tableDiv) {
            const div = tableDiv.querySelector(
              ".symptom-div-to-print",
            ) as HTMLElement;
            div ? (div.style.opacity = "1") : "";
          },
        }),
    );
    const valueElements =
      document.querySelectorAll<HTMLElement>(".value-chart");
    const valuePromises = Array.from(valueElements).map(
      async (element: HTMLElement) => ({
        fieldId: element.dataset.valueKey as string,
        canvas: await html2canvas(element, {
          onclone: function (tableDiv) {
            const div = tableDiv.querySelector(".value-chart") as HTMLElement;
            div ? (div.style.opacity = "1") : "";
          },
        }),
      }),
    );

    const symptomGraphImages = await Promise.all(symptomPromises).then(
      canvases => canvases.map(canvas => canvas.toDataURL("img/png")),
    );
    const valueGraphImages = await Promise.all(valuePromises).then(images =>
      images.reduce(
        (
          m: {
            [fieldId: string]: string;
          },
          { fieldId, canvas },
        ) => ({
          ...m,
          [fieldId]: canvas.toDataURL("img/png"),
        }),
        {},
      ),
    );

    const questions = get<InputFormQuestions>(
      endpoints.client.symptomComparisonQuestions(client_id),
    );
    const answers = get<InputFormAnswerGroup>(
      endpoints.client.symptomComparisonAnswers(client_id),
    );

    const comparisonPdf = new SymptomComparisonPdf(intl, {
      symptomChartData: {
        images: symptomGraphImages,
      },
      valueChartData: {
        valueData,
        images: valueGraphImages,
      },
      comparisonTableData: {
        questions: await questions,
        answers: await answers,
      },
    });

    pdfMake.vfs = (await vfsModule).default;
    pdfMake.createPdf(comparisonPdf.definition()).download(filename);

    track("generated symptom PDF", { client: +client_id }, {});
  } catch (e: any) {
    throw new Error(`Couldn't generate PDF. ${e.message}`);
  }
};

// eslint-disable-next-line max-lines-per-function
const SymptomComparisonExport: React.FC<SymptomComparisonExportProps> = ({
  client_id,
  button_title,
  intl,
}) => {
  const [status, setStatus] = useState<GenerationStatus>("idle");
  const dispatch = useDispatch();
  const rows = useSelector(selectAllSymptomRows);
  const intlState = useSelector((state: RootState) => state.intl);
  const [loadingBasicDetails, setBasicDetailsLoading] = useRequestStatus(
    "basicDetails",
    client_id,
  );
  const [loadingSymptoms, setSymptomsLoading] = useRequestStatus(
    "symptoms",
    client_id,
  );
  const [loadingValueData, setLoadingValueData] =
    useState<RequestStatus>("idle");
  const [loadingFootstepData, setLoadingFootstepData] =
    useState<RequestStatus>("idle");
  const [valueData, setValueData] = useState<FieldData>({});
  const [footsteps, setFootsteps] = useState<ObservationsData>();

  const loading =
    loadingSymptoms === "loading" ||
    loadingBasicDetails === "loading" ||
    loadingValueData === "loading" ||
    loadingFootstepData === "loading";

  useEffect(() => {
    if (loadingBasicDetails === "idle") {
      fetchBasicDetails(client_id)
        .then(dispatch)
        .then(() => setBasicDetailsLoading("succeeded"))
        .catch(() => setBasicDetailsLoading("failed"));
    }

    if (loadingSymptoms === "idle") {
      setSymptomsLoading("loading");
      getAllSymptoms(client_id)
        .then(dispatch)
        .then(() => setSymptomsLoading("succeeded"))
        .catch(() => setSymptomsLoading("failed"));
    }

    if (loadingValueData === "idle") {
      setLoadingValueData("loading");
      get<FieldData>(endpoints.client.categoryData(client_id, "value"))
        .then(d => setValueData(d))
        .then(() => setLoadingValueData("succeeded"))
        .catch(() => setLoadingValueData("failed"));
    }
  }, []);

  // Load footsteps after symptoms are loaded
  useEffect(() => {
    if (loadingSymptoms === "succeeded" && loadingFootstepData === "idle") {
      const dates = rows.flatMap(s => s.data.map(d => d.date));
      const startDate = dayjs(min(dates)).startOf(SYMPTOM_INTERVAL);
      const endDate = dayjs(max(dates)).endOf(SYMPTOM_INTERVAL);

      if (!SiteSettings.enable_device_generated_data) {
        return;
      }

      get<ObservationsData>(
        endpoints.client.observations(client_id, {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          first_day_of_week:
            dayjs.localeData().firstDayOfWeek() === 1 ? "monday" : "sunday",
          value_type: "steps",
          interval: SYMPTOM_INTERVAL,
          browser_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
      )
        .then(r => setFootsteps(r))
        .then(() => setLoadingFootstepData("succeeded"))
        .catch(() => setLoadingFootstepData("failed"));
    }
  }, [loadingSymptoms]);

  const symptomPagination = pagination(rows);
  const client = useSelector(selectClientBasicDetails);

  const generate: FormEventHandler = e => {
    e.preventDefault();
    setStatus("generating");
    // Using timeout because printing blocks the rendering and UI lags behind.
    setTimeout(() => {
      make(client_id, client, valueData, intl).then(() =>
        setStatus("finished"),
      );
    }, 100);
  };

  // N.B. Not all contents of the PDF is rendered here.
  // Only the symptom chart is rendered to generate the png files for the document.
  // The actual contents of the PDF is defined in SymptomComparisonPdf.
  return (
    <IntlProvider textComponent="span" locale={`${intl.locale}`} {...intlState}>
      <>
        <Button
          type="accent"
          size="small"
          disabled={loading || status === "generating"}
          onClick={generate}
          icon={status === "generating" ? "loader_circle" : "download_16px"}
        >
          {button_title}
        </Button>
        <div
          style={{
            overflow: "hidden",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div
            style={{
              fontFamily: "sans-serif",
              opacity: "0.01",
              clip: "rect(0px,0px,0px,0px)",
              position: "absolute",
            }}
          >
            {symptomPagination.pages.map(pageNo => (
              <SymptomPage
                key={`${pageNo}`}
                rows={rows}
                steps={footsteps?.observations}
                pageNo={pageNo}
                pageSize={symptomPagination.pageLength}
                pageCount={symptomPagination.pageCount}
                settings={{
                  startDate: "1900-01-01",
                  endDate: formatDate(dayjs()),
                }}
                interval={SYMPTOM_INTERVAL}
              />
            ))}
            <ValuePage chartData={valueData} />
          </div>
        </div>
      </>
    </IntlProvider>
  );
};

export default injectIntl(SymptomComparisonExport);
