import { connect } from "react-redux";
import DocumentsSharedByCareTeam from "hcp/components/Documents/SharedByCareTeam";
import { RootState } from "store";
import {
  getReceivedDocuments,
  shareDocument,
} from "../actions/sharedDocuments";

const mapStateToProps = (state: RootState) => ({
  documents: state.sharedDocuments.received,
});

export default connect(mapStateToProps, {
  getReceivedDocuments,
  shareDocument,
})(DocumentsSharedByCareTeam);
