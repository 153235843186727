import { RootState } from "store";
import { LoadingAction } from "common/actions/loading";

export type RequestStatus = "idle" | "loading" | "succeeded" | "failed";

type LoadingState = {
  basicDetails: { [entityId: string]: RequestStatus };
  symptoms: { [entityId: string]: RequestStatus };
  treatmentProgramDetails: { [entityId: string]: RequestStatus };
  massNotificationRecipientCounts: { [entityId: string]: RequestStatus };
};

export type LoadingStateOption = keyof LoadingState;

export default (
  state: LoadingState = {
    basicDetails: {},
    symptoms: {},
    treatmentProgramDetails: {},
    massNotificationRecipientCounts: {},
  },
  action: LoadingAction,
): LoadingState => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        [action.fetchType]: {
          ...state[action.fetchType as LoadingStateOption],
          [action.entityId ?? "all"]: action.value,
        },
      };
    default:
      return state;
  }
};

export const selectLoadingStatus =
  (requestType: LoadingStateOption, entityId: string) => (state: RootState) => {
    return (
      (state.loading?.[requestType]?.[entityId] as RequestStatus) ?? "idle"
    );
  };
