import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { Placement } from "@popperjs/core";
import { ComponentType } from ".";

import {
  typography,
  zIndexes,
  tooltip,
  colors,
  linkColor,
  scaffolding,
  spacing,
} from "@netmedi/frontend-design-system/dist/styles/variables";

type ToolTipProps = {
  $symptomWithDescription: boolean;
};

export const tooltipArrowSize = 8;
export const tooltipArrowOffset = 3;
const dropdownWidth = "280px";

const userCardStyles = `
    background: ${colors.white};
    color:  ${scaffolding.textColor};
    width: 300px;
    border: ${colors.gray300} 1px solid;
    box-shadow: rgba(${colors.black}, 0.1) 5px 5px 5px;
`;

const dropdownStyles = `
    width: ${dropdownWidth};
    background: ${colors.white};
    border: ${colors.gray300} 1px solid;
    box-shadow: rgba(${colors.black}, 0.1) 5px 5px 5px;
    color: ${linkColor};

    a {
        display: block;
        padding: 0.5rem;
        cursor: pointer;

        &:hover,
        &:active {
            background: ${colors.blue50};
        }
    }
`;

const autoStyles = `
    width: auto;
    max-width: 350px;
`;

export const TooltipContainer = styled.div<{
  position?: Placement;
  tooltipType?: ComponentType;
  autoWidth?: boolean;
}>`
  /*  &&& -> .eBhkcF.eBhkcF.eBhkcF 😭 */
  &&& {
    font-size: ${typography.fontSizeMedium};
    position: absolute;
    z-index: ${zIndexes.tooltip};
    width: 220px;
    padding: 1px;
    background: ${tooltip.background};
    color: ${tooltip.color};
    border-radius: 4px;
    max-width: 68vw;
    word-wrap: break-word;

    a {
      color: ${colors.blue300};
      &:hover,
      &:active {
        color: ${colors.blue200};
      }
    }

    ${({ tooltipType }) => tooltipType === "auto" && autoStyles}

    ${({ tooltipType }) => tooltipType === "large" && `width: 350px;`}

    ${({ tooltipType }) => tooltipType === "usercard" && userCardStyles}

    ${({ tooltipType }) => tooltipType === "dropdown" && dropdownStyles}

    ${({ autoWidth }) => autoWidth && `width: auto;`}
  }
`;

export const TooltipTitle = styled.div.attrs(() => ({
  "data-testid": "tooltip-title",
}))`
  position: relative;
  padding: 0.5em;
  ${rtl`padding-right: 2em;`}
`;

export const TooltipClose = styled.div.attrs(() => ({
  "data-testid": "tooltip-close",
}))`
  position: absolute;
  cursor: pointer;
  color: ${tooltip.color};
  top: 0.5em;
  ${rtl`right: 0.5em;`}
`;

export const TooltipContent = styled.div.attrs(() => ({
  "data-testid": "tooltip-content",
}))<{ tooltipType?: ComponentType }>`
  ${rtl`padding: 0 0 0 96px;`}

  ${({ tooltipType }) =>
    tooltipType !== "dropdown" &&
    tooltipType !== "usercard" &&
    "padding: 0.5em;"}

  ${({ tooltipType }) => tooltipType === "dropdown" && `padding: 0;`}

  ${({ tooltipType }) =>
    tooltipType === "usercard" &&
    `
        position: relative;
        min-height: 96px;
    `}

  ul, li {
    list-style-type: disc;
    ${rtl`margin-left: 1rem;`}
  }
`;

const leftArrowStyles = `
    & {
        left: 100%;
        border-right-width: 0;
        border-left-color: ${tooltip.background};
    }
`;

const rightArrowStyles = `
    & {
        right: 100%;
        border-left-width: 0;
        border-right-color: ${tooltip.background};
    }
`;

const topArrowStyles = `
    & {
        top: 100%;
        border-bottom-width: 0;
        border-top-color: ${tooltip.background};
    }
`;

const bottomArrowStyles = `
    & {
        bottom: 100%;
        border-top-width: 0;
        border-bottom-color: ${tooltip.background};
    }
`;

export const TooltipArrow = styled.div<{
  position?: Placement;
  tooltipType?: ComponentType;
}>`
  position: absolute;
  border-color: transparent;
  border-style: solid;
  border-width: ${tooltipArrowSize}px;

  ${({ position }) => position === "left" && leftArrowStyles}

  ${({ position }) => position === "right" && rightArrowStyles}

  ${({ position }) => position === "top" && topArrowStyles}

  ${({ position }) => position === "bottom" && bottomArrowStyles}

  ${({ tooltipType }) =>
    tooltipType === "usercard" &&
    `& {
        border-left-color: ${colors.white};
    }`}

    ${({ tooltipType }) => tooltipType === "dropdown" && `display: none;`}
`;

export const Symptom = styled.div`
  width: ${spacing.s}px;
  height: ${spacing.s}px;
  border-radius: 50%;
  text-align: center;
  padding: 7px;
  margin: 0 auto;
  cursor: pointer;
`;

export const TooltipSymptoms = styled.div.attrs(() => ({
  "data-testid": "tooltip-symptoms",
}))<ToolTipProps>`
  min-height: 45px;
  cursor: pointer;
  display: flex;
  > *:not(${Symptom}) {
    display: inline-flex;
    justify-self: center;
    align-items: center;
  }
  &:hover {
    & > p {
      text-decoration: underline;
    }
  }
  ${({ $symptomWithDescription }) =>
    $symptomWithDescription &&
    css`
      & > p {
        font-weight: ${scaffolding.bold};
      }
    `}
  ${Symptom} {
    display: inline-block;
  }
  p {
    width: 230px;
    margin: 0;
  }
`;
