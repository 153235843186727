import styled from "styled-components";
import rtl from "styled-components-rtl";
import {
  colors,
  label,
} from "@netmedi/frontend-design-system/dist/styles/variables";

const bubbleWidth = 23;
const bubbleHeight = 18;
const arrowVerticalPosition = -3;

const dotsContainerHeight = 14;
const dotHeight = 3;
const dotWidth = 3;
const dotMargin = 2;

export const Container = styled.div.attrs(() => ({
  "data-testid": "speech-bubble",
}))`
  display: inline-block;
  width: ${bubbleWidth}px;
  height: ${bubbleHeight}px;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Dots = styled.div`
  height: ${dotsContainerHeight}px;
  border-radius: ${label.borderRadius}px;
  background-color: ${colors.white};

  &::after {
    position: absolute;
    top: -1px;
    bottom: 3px;
    ${rtl`
      left: -1px;
      right: -1px;
    `}
    background: ${colors.gray400};
    background: conic-gradient(
      ${colors.gray300} 32%,
      ${colors.gray400} 0%,
      ${colors.gray400} 68%,
      ${colors.gray300} 0
    );
    content: "";
    z-index: -1;
    border-radius: ${label.borderRadius + 1}px;
  }

  ul {
    width: 100%;
    height: 100%;
    ${rtl`padding-left: 5px;`}
  }

  li {
    height: ${dotHeight}px;
    width: ${dotWidth}px;
    display: inline-block;
    margin-bottom: 5px;
    background-color: ${colors.gray400};
    ${rtl`margin-right: ${dotMargin}px;`}
  }
`;

export const Arrow = styled.div`
  position: relative;
  background: ${colors.white};
  width: ${bubbleWidth}px;
  &::after,
  &::before {
    border-color: ${colors.transparent};
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    top: 0px;
    ${rtl`left: 50%;`}
  }
  &::after {
    border-top-color: ${colors.white};
    border-width: 6px 3px 6px 3px;
    ${rtl`margin-left: ${arrowVerticalPosition}px;`}
  }
  &::before {
    border-top-color: ${colors.gray400};
    border-width: 8px 4px 8px 4px;
    ${rtl`margin-left: ${arrowVerticalPosition - 1}px;`}
  }
`;
