import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { noop } from "lodash";
import { interval, EMPTY } from "rxjs";
import { startWith, switchMap, map, takeUntil, filter } from "rxjs/operators";
import { RootState } from "store";
import { get, endpoints } from "common/utils/api";
import { openModal } from "common/actions/modal";
import dayjs from "dayjs";
import { parseIsoString } from "common/utils/general";

const getSessionExpiration = () =>
  get(
    endpoints.session_expiration,
    true,
    {},
    {
      // copied from epics/chat, make util?
      responseHandler: res => {
        if (res.status === 403) {
          return true; // ignore response
        } else {
          return false; // pass through
        }
      },
    },
  ).catch(noop);

// How often to poll for expiration
const pollInterval = 60 * 1000;
// How close to session expiration should a user be warned.
// Currently 2 * pollInterval, because otherwise the next poll might
// be sent too close to session expiration
const expirationWarningLimit = 2 * pollInterval;

const expirationPoll = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>,
) =>
  interval(pollInterval).pipe(
    startWith(0),
    filter(() => state$.value.app.modal?.type !== "expiration_warning"),
    switchMap(getSessionExpiration),
    filter(Boolean), // failed request returns undefined, also copied from epics/chat
    map((data: any) => parseIsoString(data.time_of_expiration).valueOf()),
    filter(
      timeOfExpiration =>
        timeOfExpiration - dayjs().valueOf() < expirationWarningLimit,
    ),
    map(timeOfExpiration =>
      openModal({
        type: "expiration_warning",
        disableOutsideClick: true,
        timeOfExpiration,
      }),
    ),
    takeUntil(action$.pipe(ofType("UNAUTHORIZED"))),
  );

export const startExpirationPoll = (
  action$: ActionsObservable<any>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType("SET_USER"),
    switchMap(({ data }) =>
      data.type !== "Client" ? EMPTY : expirationPoll(action$, state$),
    ),
  );

export default startExpirationPoll;
