import styled from "styled-components";
import rtl from "styled-components-rtl";
import { mediaQueries } from "@netmedi/frontend-design-system/dist/styles/variables";
import { Card } from "@netmedi/frontend-design-system";
import { SubmitButton } from "../Users/CreateUser.styles";

export const FieldInstructions = styled.div`
  // Only for desktop
  @media (min-width: ${mediaQueries.small}px) {
    ${rtl`margin-left: 30%;`}
  }
  max-width: 600px;
`;

export const SinglePatientExportContainer = styled.div`
  h1 {
    padding: 0px 32px;
  }
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 700px;
`;

export const StyledCard = styled(Card)`
  padding: 0px 32px;
`;

export const StyledSubmitButton = styled(SubmitButton)`
  @media (min-width: ${mediaQueries.small}px) {
    ${rtl`margin-left: 30%;`}
  }
`;
