import styled from "styled-components";
import rtl from "styled-components-rtl";

export const Container = styled.div`
  max-height: 750px;
  overflow-y: auto;
  margin-top: 25px;
  ${rtl`padding-right: 7%;`}
`;

export const Header = styled.h3`
  ${rtl`margin: 0px 0px -15px 15px;`}
`;
