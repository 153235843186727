import React, { useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import useAbsences, { Absence, useAbsence } from "./useAbsences";
import { Form } from "react-final-form";
import AbsenceFields from "./AbsenceFields";
import { Button, Card, Icon } from "@netmedi/frontend-design-system";
import useI18nTranslate from "common/hooks/useI18nTranslate";
import dayjs from "dayjs";
import { absenceRoutes } from "./Absences";
import { AbsenceFormStyled, ButtonContainer } from "./Absences.styles";
import { SiteSettings } from "common/utils/holvikaari";
import useClient from "common/hooks/useClient";
import {
  AbsencesClient,
  CreateAbsenceDto,
  UpdateAbsenceDto,
} from "@netmedi/hcp-workflow-client";

const defaultValues = {
  absent_person: undefined,
  substitute_person: undefined,
  begin_date: dayjs().startOf("day"),
  end_date: undefined,
  description: "",
};

const AbsenceForm: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const absenceId = useRouteMatch<{ id: string }>()?.params.id ?? "new";
  const { insertAbsence, updateAbsence } = useAbsences();
  const absence = useAbsence(absenceId);
  const createOrUpdate = absenceId === "new" ? "create" : "update";
  const t = useI18nTranslate();
  const history = useHistory();
  const [errors, setErrors] = useState<string[]>([]);
  const absenceClient = useClient(AbsencesClient);

  const save = async (values: { [key: string]: any }) => {
    setSaving(true);

    const absenceValues = {
      ...values,
      begin_date: values.begin_date.format("YYYY-MM-DD"),
      end_date: values.end_date?.format("YYYY-MM-DD"),
    };

    try {
      if (createOrUpdate === "create") {
        const createdAbsence = await absenceClient.create(
          SiteSettings.site,
          absenceValues as CreateAbsenceDto,
        );

        insertAbsence(createdAbsence);
      } else {
        const updatedAbsence = await absenceClient.update(
          SiteSettings.site,
          +absenceId,
          absenceValues as UpdateAbsenceDto,
        );

        updateAbsence(updatedAbsence as Absence);
      }

      history.push(absenceRoutes.index);
    } catch (error: any) {
      if (error?.status !== 400) throw error;

      setErrors(
        error.response?.message?.errors.map(({ code }: { code: string }) =>
          t(`absences.validation.${code}`),
        ),
      );
    } finally {
      setSaving(false);
    }
  };

  const initialValues = useMemo(
    () =>
      createOrUpdate === "update"
        ? {
            ...absence,
            begin_date: dayjs(absence?.begin_date),
            end_date: dayjs(absence?.end_date),
          }
        : defaultValues,
    [absence],
  );

  return (
    <AbsenceFormStyled data-testid="AbsenceForm">
      <h1>{t(`absences.${createOrUpdate}_title`)}</h1>
      <Form onSubmit={save} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AbsenceFields />
            {errors.length > 0 && (
              <Card icon={<Icon name="info_circle_16px" />} type="alert">
                {errors.map(message => (
                  <p key={message}>{message}</p>
                ))}
              </Card>
            )}

            <ButtonContainer>
              <Button type="primary" submit disabled={saving}>
                {t("absences.save")}
              </Button>
              <Button
                onClick={() => history.push(absenceRoutes.index)}
                type="ghost"
              >
                {t("absences.cancel")}
              </Button>
            </ButtonContainer>
          </form>
        )}
      </Form>
    </AbsenceFormStyled>
  );
};

export default AbsenceForm;
