import React from "react";
import { Container } from "./MediaWithText.styles";

interface IProps {
  children: string;
}

const MediaWithText = ({ children }: IProps) => {
  return (
    <Container>
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </Container>
  );
};

export default MediaWithText;
