import {
  ActionsObservable,
  combineEpics,
  Epic,
  StateObservable,
} from "redux-observable";
import { of } from "rxjs";
import Bugsnag from "bugsnag";
import { Action } from "redux";
import { State } from "store";
import { catchError } from "rxjs/operators";
import { CriticalErrorAction } from "shared/actions/app";

const combineEpicsCatchAll = <T extends Action>(
  ...epics: ReadonlyArray<Epic<T, State>>
) =>
  combineEpics(
    ...epics.map(
      epic =>
        (
          action$: ActionsObservable<T>,
          state$: StateObservable<State>,
          dependencies: any,
        ) =>
          epic(action$, state$, dependencies).pipe(
            catchError(err => {
              Bugsnag.notify(err, undefined, (_, event) => {
                event.addMetadata("metaData", {
                  epic: epic.name,
                });
              });
              if (process.env.NODE_ENV === "development") {
                console.error(err); // eslint-disable-line no-console
              }
              return of({
                type: "CRITICAL_ERROR",
                data: {
                  epic: epic.name,
                  err,
                },
              } as CriticalErrorAction);
            }),
          ),
    ),
  );

export default combineEpicsCatchAll;
