import { useEffect } from "react";

import { redirect } from "common/utils/holvikaari";

// from https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
// eslint-disable-next-line max-statements
const getCookie = (cname: string) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const hasSessionChanged = (initialSession: { id: string }) => {
  const currentSessionId = getCookie("aux_session_id");
  if (!initialSession.id) {
    initialSession.id = currentSessionId;
    return false;
  } else if (initialSession.id !== currentSessionId) {
    // prevent several redirects
    initialSession.id = currentSessionId;
    return true;
  } else {
    return false;
  }
};

// Redirect to / when session changes
export const useSessionHandler = () => {
  useEffect(() => {
    const initialSession = { id: "" };
    const intervalId = setInterval(() => {
      if (hasSessionChanged(initialSession)) {
        redirect("/", true);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
};
