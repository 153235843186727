import React from "react";
import { StyledBar } from "./ProgressBar.styles";

export type BarProps = {
  className?: string;
  value: number;
  max: number;
};

export default function Bar({ className, value, max }: BarProps) {
  const percentage = (value / max) * 100;
  return (
    <StyledBar
      className={className}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={value}
    >
      <span style={{ width: `${percentage}%` }} />
    </StyledBar>
  );
}
