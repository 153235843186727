import styled from "styled-components";
import theme from "@netmedi/frontend-design-system/dist/themes/baseTheme";

export const ValueChart = styled.div`
  .axis-grid {
    line {
      stroke: ${theme.colors.gray300};
    }

    path.domain {
      stroke: none;
    }
  }

  .axis {
    .tick {
      text {
        font-size: 18px;
      }
    }
  }

  .dot-label {
    text-shadow: white;
  }
`;
