import React from "react";
import { FormattedMessage } from "react-intl";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

import { List, ListItem } from "common/components/List";
import { Icon } from "@netmedi/frontend-design-system";
import { EventInfo } from "./CalendarEntries.styles";
import { SiteSettings } from "common/utils/holvikaari";
import { CalendarEntry as TCalendarEntry } from "client/models/calendarEntries";
import { ErrorBoundary } from "common/utils/errorBoundary";

export type Props = {
  entries: TCalendarEntry[];
};

type person = { name: string; title?: string };

const addMargin = (entry: { personnel: string[]; location?: string }) => {
  if (entry.personnel || entry.location) return true;
  return false;
};

const timeInTimezone = (deviceTimezone: string, appt_timezone?: string) => {
  const longTimezone = appt_timezone ?? deviceTimezone;

  return {
    long: longTimezone,
    short: String(longTimezone.split("/").pop()?.replace(/_/g, " ")),
  };
};

function CalendarEntry({
  entry: {
    begin_at,
    title,
    end_at,
    date_only,
    personnel,
    location,
    appt_timezone,
    id,
  },
  show_all_timezones,
  deviceTimezone,
}: {
  entry: TCalendarEntry;
  deviceTimezone: string;
  show_all_timezones: boolean; // determines whether timezones are shown for all appointments
}) {
  const timezoneInfo = timeInTimezone(deviceTimezone, appt_timezone);
  const beginAt = dayjs.utc(begin_at).tz(timezoneInfo.long);
  const showTimezoneName = date_only // ultimately the override
    ? false // if date_only, do not show TZ
    : show_all_timezones || appt_timezone !== deviceTimezone; // otherwise, verify conditions

  const timeZoneText = showTimezoneName ? (
    <FormattedMessage
      id="timezone"
      values={{
        name: timezoneInfo.short,
      }}
    />
  ) : (
    ""
  );

  const durationText = () =>
    dayjs(begin_at).to(
      dayjs.utc(end_at).tz(timezoneInfo.long),
      true, // withoutSuffix (in/ago)
    );

  return (
    <ListItem
      spacing="xs"
      heading={<strong>{title}</strong>}
      icon={<Icon name="calendar" size="medium" />}
      alignItems="flex-start"
      data-testid={`event_${id}`}
    >
      <EventInfo bottomMargin={addMargin({ personnel, location })}>
        {beginAt.format("L")}
        {!!SiteSettings.client_sees_calendar_event_times && (
          <span>
            {` ${beginAt.format("LT")} `}
            {showTimezoneName && timeZoneText}
            <>
              <br />
              {durationText()}
            </>
          </span>
        )}
      </EventInfo>
      {SiteSettings.calendar_extra_fields.includes("practitioner") &&
        personnel &&
        personnelList(personnel)}
      {SiteSettings.calendar_extra_fields.includes("location") && location && (
        <EventInfo>{location}</EventInfo>
      )}
    </ListItem>
  );
}

function CalendarEntries({ entries }: Props) {
  const deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // validate tz string is not empty and starts with capital letter
  const isValidTimezone = (tz: string) =>
    typeof tz === "string" && tz.length > 0 && tz[0] === tz[0].toUpperCase(); // will correctly handle unorthodox cases of tz strings such as blank, undefined or "true"/"false" (testHelper:59)

  // check if SiteSettings.always_show_appointment_timezone is true
  // or if SiteSettings.time_zone is different from deviceTimezone
  const show_all_timezones = () =>
    !!SiteSettings.always_show_appointment_timezone ||
    (isValidTimezone(SiteSettings.time_zone) &&
      SiteSettings.time_zone !== deviceTimezone); // if SiteSettings.time_zone is set and different from deviceTimezone

  return (
    <List spacing="xs" noPadding>
      {entries.map(entry => (
        <ErrorBoundary key={`error_boundary_calendar_entry_${entry.id}`}>
          <CalendarEntry
            entry={entry}
            key={entry.id}
            deviceTimezone={deviceTimezone}
            show_all_timezones={show_all_timezones()}
          />
        </ErrorBoundary>
      ))}
    </List>
  );
}

const personnelList = (personnel: string[]) =>
  personnel.map((person, id) => {
    const personInfo: person = JSON.parse(person);
    const title = personInfo.title ? `, ${personInfo.title}` : "";
    return (
      <EventInfo key={"person" + id}>{`${personInfo.name}${title}`}</EventInfo>
    );
  });

export default CalendarEntries;
