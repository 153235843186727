import { ITooltip } from "common/components/Tooltip";

export type TooltipAction = {
  type: "TOOLTIP";
  data?: ITooltip;
};

export const setTooltip = (options: ITooltip) => ({
  type: "TOOLTIP",
  data: options,
});

export const setDropdownTooltip = (options: Partial<ITooltip>, e?: any) =>
  setTooltip({
    type: "dropdown",
    target: e && e.currentTarget,
    position: "bottom-start",
    ...options,
  });

export const clearTooltip = () => ({ type: "TOOLTIP" });
