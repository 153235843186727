import styled from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import { Icon } from "@netmedi/frontend-design-system";
import { ErrorMsg } from "common/components/Form/FormField.styles";

export const ModalHeader = styled.div`
  border-bottom: 1px solid ${colors.gray500};
  white-space: nowrap;
`;

export const ModalContent = styled.div`
  max-height: 65vh;
  margin-top: 1rem;
  overflow-y: auto;
`;

export const CloseModalIcon = styled(Icon).attrs(() => ({
  "data-testid": "close-modal",
}))`
  position: absolute;
  ${rtl`right: 2rem;`};
  font-size: ${rem(32)};
  cursor: pointer;
`;

export const DocumentContainer = styled.div`
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  ${ErrorMsg} {
    margin: 0;
  }
`;

export const FormFieldWrapper = styled.div`
  div {
    padding-bottom: 0;
  }
`;

export const StyledInput = styled.input`
  margin-top: 0.5rem;
  padding: 0.5rem !important;
  border: 0.5px solid ${colors.gray500} !important;
`;

export const MessageInput = styled.textarea`
  margin-top: 0.5rem;
  padding: 0.75rem !important;
  border: 0.5px solid ${colors.gray500} !important;
`;

export const FileInputWrapper = styled.div`
  margin-top: 1rem;

  button {
    ${rtl`margin-left: 0;`}
  }
`;

export const HelperText = styled.p`
  color: ${colors.gray800};
`;

export const Divider = styled.hr`
  border-top: 1px solid ${colors.gray500};
  width: 100%;
`;
