import {
  MassNotificationAction,
  DataAndAttributes,
} from "common/actions/massNotifications";
import { RootState } from "store";

export type State = Record<string, any>;

const recipientCountReducer = (data: DataAndAttributes) => {
  const { attributes } = data;
  if (attributes) {
    return {
      [attributes?.language]: attributes?.recipient_count,
    };
  }
  return {};
};

export default (
  state: State = {
    allMassNotifications: [],
    query: "",
    recipientCounts: {},
  },
  { type, data, params }: MassNotificationAction,
): State => {
  switch (type) {
    case "SET_MASS_NOTIFICATIONS":
      return {
        ...state,
        meta: data.meta,
        allMassNotifications: data.data,
        query: params?.query || "",
      };
    case "SET_MASS_NOTIFICATION":
      return {
        ...state,
        recipientCounts: recipientCountReducer(data.data),
        allMassNotifications: [
          ...state.allMassNotifications.filter(
            (n: any) => n.id !== data.data.id,
          ),
          data.data,
        ].sort((a, b) =>
          b.attributes.created_at < a.attributes.created_at ? -1 : 1,
        ),
        singleMassNotification: data.data,
      };
    case "RESET_MASS_NOTIFICATION":
      return {
        ...state,
        recipientCounts: {},
        singleMassNotification: null,
      };
    case "SET_RECIPIENT_COUNTS":
      return {
        ...state,
        recipientCounts: data,
      };
    default:
      return state;
  }
};

export const selectMassNotificationRecipientCounts = (state: RootState) =>
  state.massNotifications.recipientCounts as { [lang: string]: number };
