import React from "react";
import { pick } from "lodash";
import { SelectedImage, UnSelectedImage } from "./Choice.styles";
import { SiteSettings } from "common/utils/holvikaari";

export type Display = {
  width?: number;
  height?: number;
  image?: string;
  image_selected?: string;
  description?: string;
  styles?: string | string[];
  center_horizontally?: boolean;
};

const imageStyle = (display: Display) => {
  const style: {
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
  } = pick(display, ["width", "height"]);

  if (style["width"]) {
    style["minWidth"] = style["width"];
  }

  if (style["height"]) {
    style["minHeight"] = style["height"];
  }
  return style;
};

interface IProps {
  display: Display;
  ariaHidden?: boolean;
}

/* eslint-disable jsx-a11y/alt-text */
const ChoiceDisplay = ({ display, ariaHidden }: IProps) => {
  const imgStyle = imageStyle(display);
  const displayImage =
    display.image && SiteSettings.cdn_url
      ? display.image.replace("cdn://", SiteSettings.cdn_url + "/")
      : display.image;
  const displayImageSelected =
    display.image_selected && SiteSettings.cdn_url
      ? display.image_selected.replace("cdn://", SiteSettings.cdn_url + "/")
      : display.image_selected;
  return (
    <>
      <UnSelectedImage
        aria-hidden={ariaHidden}
        style={imgStyle}
        center_horizontally={display.center_horizontally}
      >
        {displayImage && <img src={displayImage} draggable="false" />}
      </UnSelectedImage>
      <SelectedImage
        aria-hidden={ariaHidden}
        style={imgStyle}
        center_horizontally={display.center_horizontally}
      >
        <img src={displayImageSelected || displayImage} draggable="false" />
      </SelectedImage>
    </>
  );
};
/* eslint-ensable jsx-a11y/alt-text */

export default ChoiceDisplay;
