import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { mediaMin } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";
import {
  spacing,
  mediaQueries,
  typography,
  colors,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { Icon } from "@netmedi/frontend-design-system";

interface Props {
  $compact: boolean;
}

interface StatusIndicatorProps {
  $status: string;
}

export const TaggerContainer = styled.div.attrs(() => ({
  "data-testid": "tagger-container",
}))<Props>`
  padding-top: 1em;
  font-size: ${rem(14)};
  line-height: 1.5;
  ${mediaMin(mediaQueries.medium)} {
    width: 100%;
    ${({ $compact }) =>
      $compact &&
      css`
        width: auto;
      `}
  }
`;

export const Actions = styled.div.attrs(() => ({
  "data-testid": "tagger-actions",
}))<Props>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  ${mediaMin(mediaQueries.medium)} {
    ${({ $compact }) =>
      $compact &&
      css`
        & {
          ${rtl`
            padding-left: 20px;
            text-align: left;
          `}
        }
      `}
  }
`;

export const TaggerButtonIcon = styled(Icon)`
  ${rtl`margin-left: 10px;`}
`;

export const Status = styled.div`
  margin: 0.5em 0;
  line-height: 1;
  display: flex;
`;

export const StatusIndicator = styled.div.attrs(
  ({ $status }: StatusIndicatorProps) => ({
    "data-testid": `status-indicator-${$status}`,
  }),
)<StatusIndicatorProps>`
  width: ${rem(16)};
  height: ${rem(16)};
  ${rtl`margin-right: ${spacing.xxxs}px;`}
  border: 1px solid ${colors.gray700};
  border-radius: 100%;
  ${({ $status }) =>
    $status === "done" && `background-color: ${colors.green500}; border: 0;`}
  ${({ $status }) =>
    $status === "assigned" &&
    `background-color: ${colors.yellow500}; border: 0;`}
`;

export const StatusText = styled.div`
  padding-top: 1px;
  line-height: ${typography.fontSizeBase};
`;
