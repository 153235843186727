import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { Link } from "react-router-dom";
import {
  spacing,
  scaffolding,
  mediaQueries,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { mediaMax } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";

interface IListStyled {
  noPadding?: boolean;
  spacingProp: "s" | "xs";
}

interface IListItemStyle {
  isLink?: boolean;
  spacingProp: "s" | "xs";
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
}

interface ISpacing {
  spacingProp: "s" | "xs";
}

const paddingBySpacing = {
  s: `padding: ${spacing.s}px 0 !important;`,
  xs: `padding: ${spacing.xs}px 0 !important;`,
};

const listSpacing = {
  s: `margin-bottom: ${spacing.xxs}px;`,
  xs: `margin-bottom: ${spacing.xxxs}px;`,
};

const marginBySpacing = {
  s: `margin: 0 ${spacing.s}px;`,
  xs: `margin: 0 ${spacing.s}px;`,
};

const separatorStyles = css`
  & + & {
    border-top: ${scaffolding.gridBorderColor} 1px solid;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const ListHeading = styled.div<ISpacing>`
  ${({ spacingProp }) => spacingProp && listSpacing[spacingProp]}
`;

export const ListLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ListStyled = styled.ul<IListStyled>`
  list-style-type: none;
  margin: 0;
  padding: 0;
  ${({ spacingProp }) => spacingProp && paddingBySpacing[spacingProp]}
  ${({ noPadding }) => noPadding && `padding: 0;`};
`;

export const IconContainer = styled.div<ISpacing>`
  color: ${scaffolding.textColor};
  ${mediaMax(mediaQueries.small)} {
    ${rtl`margin-left: 0;`}
  }
  ${({ spacingProp }) => spacingProp && marginBySpacing[spacingProp]}
`;

export const LinkIconContainer = styled.div<ISpacing>`
  ${mediaMax(mediaQueries.small)} {
    ${rtl`margin-right: 0;`}
  }
  ${({ spacingProp }) => spacingProp && marginBySpacing[spacingProp]}
`;

export const ListItemStyled = styled.li<IListItemStyle>`
  display: flex;
  ${separatorStyles}
  ${({ spacingProp }) => spacingProp && paddingBySpacing[spacingProp]}
  ${({ alignItems }) =>
    alignItems
      ? `
      align-items: ${alignItems};
      svg { margin-top: 0.8rem; }
    `
      : `align-items: center;`}
`;

export const ListContainer = styled.div`
  flex: 1;
`;

export const ListContent = styled.div`
  color: ${scaffolding.textColor};
`;
