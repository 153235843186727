import styled from "styled-components";
import rtl from "styled-components-rtl";
import {
  colors,
  scaffolding,
  spacing,
  typography,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { Icon, ButtonExtras } from "@netmedi/frontend-design-system";
const { LinkButton } = ButtonExtras;

export const FeaturesList = styled.ul(rtl`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    font-weight: normal;

    li {
        list-style-type: disc;
    }
`);

export const Container = styled.div.attrs(() => ({
  "data-testid": "info-box-container",
}))(rtl`
  display: block;
  position: relative;
  margin: 1em 0;
  padding: 1em;
  padding-left: 4.8em;
  background: ${colors.white};
  transition: all 0.2s;
  border-radius: ${rem(8)};
  border-color: ${colors.gray500};
  border-width: 1px;
  border-style: solid;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  #mobile-app-device-generated-data-infobox {
    display: none;
  }
`);

export const TaskTitle = styled.div`
  display: flex;
`;

export const TaskIcon = styled(Icon)(rtl`
  fill: ${colors.black};
  color: ${colors.black};
  font-weight: 500;
  font-size: ${typography.fontSizeBase};
  position: absolute;
  left: 1.1em;
  top: 1.1em;
  width: ${rem(48)};
  height: ${rem(48)};
`);

export const TaskName = styled.span.attrs(() => ({
  "data-testid": "task-name",
}))`
  fill: ${colors.black};
  color: ${colors.black};
  font-weight: 500;
  font-size: ${typography.fontSizeBase};
  flex-grow: 1;
`;

export const InfoBoxButtons = styled.div`
  padding-top: ${spacing.xs}px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DismissButton = styled(LinkButton)`
  ${rtl`padding-left: 0;`}
  ${rtl`padding-right: 0;`}
`;

export const TaskDescription = styled.div.attrs(() => ({
  "data-testid": "task-description",
}))`
  font-size: ${typography.fontSizeMedium};
  color: ${scaffolding.textColor};
  flex-basis: 100%;
`;
