import { track } from "common/utils/api";
import { IPopulationDashboard } from "hcp/components/PopulationDashboard/PopulationDashboard.types";
import { PopulationDashboardAction } from "hcp/actions/populationDashboard";
import { PopulationDashboardPeopleAction } from "hcp/actions/staff";

export type State = IPopulationDashboard & { fetchingClients: boolean };

const initialState: State = {
  own: true,
  fetchingClients: false,
  symptoms_meta: [],
  sort: "population_dashboard.questionnaire_last_filled",
  sortDirection: "asc",
  ownUsers: [],
  allUsers: [],
  content: { symptoms: [] },
  treatment_program: "",
};

export default (
  state = initialState,
  action: PopulationDashboardAction | PopulationDashboardPeopleAction,
): State => {
  let reversed;
  switch (action.type) {
    case "SET_DASHBOARD_USERS":
      track(
        "eagle eye: changed patient population",
        {
          population: action.data ? "own" : "all",
        },
        {},
      );
      return { ...state, own: action.data };
    case "SET_DASHBOARD_PROGRAM":
      track(
        "eagle eye: changed treatment program filters",
        {
          treatment_program: action.data,
        },
        {},
      );
      return { ...state, treatment_program: action.data };
    case "SET_DASHBOARD_SORT":
      reversed = state.sort === action.data && state.sortDirection === "asc";
      track(
        "eagle eye: changed sort",
        {
          sort: action.data,
          direction: reversed ? "reversed" : "default",
        },
        {},
      );
      return {
        ...state,
        sort: action.data,
        sortDirection: reversed ? "desc" : "asc",
      };
    case "SET_CLIENTS_SYMPTOMS_META":
      return { ...state, symptoms_meta: action.data };
    case "SET_FETCHING_CLIENTS":
      return { ...state, fetchingClients: true };
    case "SET_POPULATION_DASHBOARD_PEOPLE":
      return {
        ...state,
        fetchingClients: false,
        ownUsers:
          action.options.filter === "own" ? action.data : state.ownUsers,
        allUsers:
          action.options.filter === "own" ? state.allUsers : action.data,
        content: {
          ...action.meta,
          symptoms: {
            ...state.content.symptoms,
            ...action.meta.symptoms,
          },
        },
      };
    default:
      return state;
  }
};
