import { Dispatch, AnyAction } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook,
} from "react-redux";
import createReducer from "../reducer";
import rootEpic from "../epic";
import { createEpicMiddleware } from "redux-observable";
import promiseMiddleware from "redux-promise";
import errorCatcher from "./errorCatcher";
import { CombinedState as ConversationsState } from "shared/reducers/chat";

const epicMiddleware =
  process.env.NODE_ENV === "test" ? null : createEpicMiddleware();

const middleware =
  process.env.NODE_ENV === "test"
    ? []
    : [
        ...(epicMiddleware ? [epicMiddleware] : []),
        promiseMiddleware,
        errorCatcher,
      ];

export type State = any;

const store = {
  ...configureStore({
    reducer: createReducer(),
    middleware,
  }),
  asyncReducers: {} as Record<string, any>,
};

export type RootState = ReturnType<typeof store.getState> & {
  chat: ConversationsState;
};

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

// @ts-expect-error - redux doesn't like async actions
export type AppDispatch = Dispatch<AnyAction | Promise<AnyAction>>;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

if (epicMiddleware) epicMiddleware.run(rootEpic);

export function injectReducer(name: string, reducer: any) {
  store.asyncReducers[name] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers) as any);
}

if (module.hot) {
  module.hot.accept("../reducer", () => {
    const nextReducer = require("../reducer").default;
    store.replaceReducer(nextReducer);
  });
}

export default store;
