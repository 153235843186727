import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import {
  panel,
  scaffolding,
  zIndexes,
  mediaQueries,
  colors,
  spacing,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { mediaMin } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";

import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { PanelType } from ".";

export const testIds = {
  panel: "panel",
  heading: "panel-heading",
};

type PanelContentProps = {
  overflowVisible: boolean;
  tabContent: boolean;
  fullWidth: boolean;
  width?: string;
};

export const PanelContent = styled.div.attrs(() => ({
  "data-testid": "panel-content",
}))<PanelContentProps>`
  ${({ overflowVisible }) => overflowVisible && "overflow: visible;"}
  ${({ tabContent }) => tabContent && "overflow: hidden;"}

  ${css`
    &:empty {
      padding: 0 !important;
    }
  `}

  ${mediaMin(mediaQueries.medium + 1)} {
    ${({ tabContent }) =>
      tabContent &&
      css`
        padding-bottom: 0 !important;
      `}
  }
`;

export const HeadingContainer = styled.div.attrs(() => ({
  "data-testid": testIds.heading,
}))<{ width?: string }>`
  ${({ width }) => width && `width: ${width};`}

  padding: ${scaffolding.pagePaddingMobile};
  position: relative;
  z-index: ${zIndexes.low};

  h3 {
    font-size: ${rem(20)};
    font-weight: 400;
    margin: 0;
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .ccda_title {
    margin: 0;
  }
`;

type PanelContainerProps = {
  sticky: boolean;
  type?: PanelType;
  noPadding: boolean;
  noBorder: boolean;
  newSpacing: boolean;
  spacing2020: boolean;
  fullWidth: boolean;
};

export const PanelContainer = styled.section<PanelContainerProps>`
  position: relative;
  border: ${panel.border} 1px solid;
  border-radius: ${panel.borderRadius}px;
  margin: 0;
  border-top: 0;
  ${rtl`border-left: 0`};

  ${PanelContent} {
    padding: ${scaffolding.pagePaddingMobile};
    padding-top: 0px;
    margin-top: 5px;

    &:first-child {
      padding-top: ${scaffolding.pagePaddingMobile};
    }
  }

  ${({ sticky }) =>
    sticky &&
    css`
      & > div {
        height: 100%;
        display: flex;
        display: -ms-flexbox;
        flex-direction: column;
        -ms-flex-direction: column;
      }
    `}

  ${({ type }) =>
    type === "plain" &&
    css`
      background: ${colors.white};

      ${HeadingContainer} {
        background: ${colors.white};
        padding-bottom: 10px !important;
      }
    `}

    ${({ type }) =>
    type === "highlight" &&
    css`
      background: ${colors.gray50};

      ${HeadingContainer} {
        background: ${colors.gray50};
      }
    `}

    ${({ noBorder }) => noBorder && "border: 0;"}


    ${({ newSpacing }) =>
    newSpacing &&
    css`
      ${HeadingContainer}, ${PanelContent} {
        padding: ${spacing.s}px ${spacing.xs}px;
      }

      ${PanelContent} {
        padding-top: 0;

        &:empty {
          padding: 0;
        }

        &:first-child {
          padding-top: ${spacing.s}px;
        }
      }
    `}


    ${({ spacing2020 }) =>
    spacing2020 &&
    css`
      ${HeadingContainer} {
        padding: ${spacing.s}px ${spacing.xs}px;
      }

      ${PanelContent} {
        padding: ${spacing.s}px;
        padding-top: 0;

        &:empty {
          padding: 0;
        }

        &:first-child {
          padding-top: ${spacing.s}px;
        }
      }
    `}

    ${({ noPadding }) =>
    noPadding &&
    css`
      ${PanelContent} {
        padding: 0 !important;
      }
    `}


    ${mediaMin(mediaQueries.small)} {
    ${({ type }) =>
      type === "plain" &&
      css`
        ${HeadingContainer}, ${PanelContent} {
          padding: ${panel.padding};
        }

        ${PanelContent} {
          padding-top: 0;

          &:empty {
            padding: 0;
          }

          &:first-child {
            padding-top: ${panel.padding};
          }
        }
      `}

    ${({ newSpacing }) =>
      newSpacing &&
      css`
        ${HeadingContainer}, ${PanelContent} {
          padding: ${spacing.s}px;
        }

        ${PanelContent} {
          padding: ${spacing.s}px;
          padding-top: 0;

          &:empty {
            padding: 0;
          }

          &:first-child {
            padding-top: ${spacing.s}px;
          }
        }
      `}

      ${({ spacing2020, fullWidth }) =>
      spacing2020 &&
      css`
        ${HeadingContainer} {
          padding: ${spacing.s}px;
        }

        ${PanelContent} {
          padding: ${spacing.m}px;
          padding-top: 0;
          max-width: 720px;
          margin: auto;

          &:empty {
            padding: 0;
          }

          &:first-child {
            padding-top: ${spacing.s}px;
          }

          ${fullWidth &&
          css`
            max-width: none !important;
          `}
        }
      `}
  }
`;

export const PanelContentContainer = styled.div<{ centered?: boolean }>`
  ${({ centered }) =>
    centered &&
    css`
      max-width: ${mediaQueries.medium}px;
      margin: 0 auto;
    `}
`;
