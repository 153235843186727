import { SiteSettings } from "common/utils/holvikaari";
import { feedbackFormIdsByLanguage } from "./feedback_form_ids";
import { ifuUrl } from "./ifu";

export const getByLanguage = (
  obj: Record<string, string> | Record<string, ifuUrl>,
  language: string | undefined,
) =>
  language ? obj[language] || obj[language.substring(0, 2)] || obj.en : obj.en;

export const composeFeedbackFormLink = (language: string) => {
  const formId = getByLanguage(feedbackFormIdsByLanguage, language);
  const clientNameComponent = encodeURIComponent(getClientName(language));
  return (
    `https://airtable.com/${formId}?prefill_Client=${clientNameComponent}` +
    `&hide_Client=true&hide_Sender=true&hide_Short%20summary=true`
  );
};

// Returns a localized client name based on language or a fallback client name
// if there is no localization.
// The fallback "client_name" is a build-time constant and is equal to the
// client name in the default locale of the site.
const getClientName = (language: string) => {
  return (
    (SiteSettings.localized_client_name &&
      SiteSettings.localized_client_name[language]) ||
    SiteSettings.client_name
  );
};
