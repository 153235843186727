import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { Spinner } from "@netmedi/frontend-design-system";
import { isEmpty } from "lodash";
import history from "common/utils/history";
import { AllRoutes, ScrollToAnchor } from "../../routes";
import { Router } from "react-router-dom";
import App from "client/components/App";
import getTheme from "@netmedi/frontend-design-system/dist/themes/themeHelper";
import { SiteSettings } from "common/utils/holvikaari";
import { RootState } from "store";
import { useSessionHandler } from "common/hooks/useSessionHandler";

const mapStateToProps = ({ intl, user }: RootState) => ({
  intl,
  user,
  loaded: Boolean(intl.locale && !isEmpty(user)),
});

type Props = ConnectedProps<typeof connector> & {
  noSpinner?: boolean;
  noPadding?: boolean;
  children?: React.ReactChild;
};

/**
 * Wraps the app with
 * - IntlProvider
 * - ThemeProvider
 * - Router
 * Also calls useSessionHandler hook.
 */
const AppWrapper = ({
  intl,
  user,
  loaded,
  children,
  noSpinner,
  noPadding,
}: Props) => {
  const theme = getTheme(
    SiteSettings.site,
    SiteSettings.theme_variables,
    intl.locale,
  );

  useSessionHandler();

  useEffect(() => {
    if (intl.locale) {
      document.documentElement.lang = intl.locale;
    }
    document.documentElement.dir = theme.dir;
  }, [intl]);

  if (!loaded) return noSpinner ? null : <Spinner noPadding={noPadding} />;
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        textComponent="span"
        locale={`${intl.locale}`}
        {...intl}
        key={intl.locale}
      >
        {children || (
          <Router history={history}>
            <ScrollToAnchor />
            <App>
              <AllRoutes user={user} />
            </App>
          </Router>
        )}
      </IntlProvider>
    </ThemeProvider>
  );
};

const connector = connect(mapStateToProps);

export default connector(AppWrapper);
