import { track } from "common/utils/api";
import Bugsnag from "bugsnag";
import { SiteSettings } from "common/utils/holvikaari";

const siteLogo = `/external_assets/${SiteSettings.product_logo}`;

const appleTouchIcon = () => {
  const el = document.getElementById("apple-touch-icon");
  return el ? el.getAttribute("href") : null;
};

// Use touch icon injected by rails, if available.
let logo: string;
try {
  logo = appleTouchIcon() || siteLogo;
} catch (e) {
  logo = siteLogo;
}

const CLOSE_TIMEOUT = 7000;

// don't care what the user chooses, permission is checked when trying to notify
export const requestPermission = () => {
  if (window.Notification) {
    Notification.requestPermission(perm =>
      track("Desktop notifications", { permission: perm }, {}),
    );
  }
};

/*
  Show a (desktop) notification
  see https://developer.mozilla.org/en-US/docs/Web/API/Notification
  for available options
*/
export const notify = (
  message: string,
  title: string = SiteSettings.product_name,
) => {
  if (!window.Notification || Notification.permission !== "granted") return;
  try {
    const notification = new Notification(title, { body: message, icon: logo });
    notification.onclick = function () {
      window.focus();
      this.close();
    };
    setTimeout(function () {
      notification.close();
    }, CLOSE_TIMEOUT);
  } catch (err: any) {
    // https://bugs.chromium.org/p/chromium/issues/detail?id=481856
    if (!(err instanceof TypeError))
      Bugsnag.notify(err, undefined, (_, event) => {
        event.addMetadata("metaData", {
          location: window.location.pathname,
        });
      });
  }
};
