import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import {
  SearchField,
  dropdownWidth,
  dropdownHeight,
  searchHeight,
} from "../hcp/components/Staff/UserDropdown/UserDropDown.styles";
import {
  colors,
  scaffolding,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { mediaMax } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";

const searchBarDropdownHeight = 400;
const searchBarMaxWidth = 440;
const searchBarMinWidth = 140;

export const rootStyles = rtl`
  ${
    // Disable animations when running tests
    process.env.TEST &&
    css`
      * {
        animation: none !important;
      }
    `
  }

  .SymptomGradeCircle_tooltip {
    width: 350px !important;
  }

  .UserDropdown_userDropdown {
    box-sizing: border-box;
    width: ${dropdownWidth}px;
    max-height: ${dropdownHeight}px;
    overflow: hidden;
    color: ${scaffolding.textColor};

    ${SearchField} {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      height: ${searchHeight}px;
      line-height: ${searchHeight}px;
    }

    h4 {
      background: ${colors.gray50};
      color: ${scaffolding.textColor};
      font-size: ${rem(14)};
      font-weight: 500;
      margin: 0;
      padding-left: 8px;
    }

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: block;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      vertical-align: middle;
      transition: all 0.1s;
    }

    li:hover {
      background: ${colors.blue50};
    }
  }

  .SearchBar_dropdown {
    box-sizing: border-box;
    width: 100%;
    max-width: ${searchBarMaxWidth}px;
    min-width: ${searchBarMinWidth}px;
    max-height: ${searchBarDropdownHeight}px;
    overflow: hidden;
    color: ${scaffolding.textColor};
    position: absolute;
    z-index: 500;
    overflow: auto;
    margin-top: 32px;

    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      display: block;
      padding: 8px;
      cursor: pointer;
      vertical-align: middle;
      transition: all 0.1s;
    }

    li:focus {
      background: ${colors.blue50};
      outline: none;
    }

    li:hover {
      background: ${colors.gray50};
    }

    a {
      padding: 0;
    }
  }

  // Will always wrap the button to a new row at 460px
  ${mediaMax(460)} {
    .SearchBar_dropdown {
      width: 90%;
      margin: 32px 1em 0;
    }
  }

  input[type="date"],
  input[type="time"] {
    height: 2.5rem;
  }
`;

export const RootStyles = styled.div(rootStyles);
