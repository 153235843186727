import { isNil } from "lodash";
import { inMobileApp } from "common/utils/inMobileApp";
import {
  CallMobileAppFunctionAction,
  SetUserAction,
} from "common/actions/user";
import { ActionsObservable, ofType } from "redux-observable";
import { filter, map } from "rxjs/operators";
import { SiteSettings } from "common/utils/holvikaari";

const isSignedIn = (data: any) => !isNil(data.id);

const postMessage = (data: any) =>
  window.ReactNativeWebView.postMessage(JSON.stringify(data));

export const postToMobileApp = (action$: ActionsObservable<SetUserAction>) =>
  action$.pipe(
    ofType("SET_USER"),
    filter(inMobileApp),
    map(({ data }) => {
      return postMessage({
        userSignedIn: isSignedIn(data),
        user: data,
        siteSettings: SiteSettings,
      });
    }),
    filter(() => false),
  ); // No need to return any action to the stream

export type SignOutAction = {
  type: "SIGN_OUT_MOBILE_APP";
};

export const signOutMobileApp = (action$: ActionsObservable<SignOutAction>) =>
  action$.pipe(
    ofType("SIGN_OUT_MOBILE_APP"),
    filter(inMobileApp),
    map(() => {
      return postMessage({
        userSignedIn: false,
      });
    }),
    filter(() => false),
  ); // No need to return any action to the stream

export const callMobileAppFunction = (
  action$: ActionsObservable<CallMobileAppFunctionAction>,
) =>
  action$.pipe(
    ofType("CALL_MOBILE_APP_FN"),
    filter(inMobileApp),
    map(({ data }) => {
      return postMessage({
        nativeFunction: {
          functionName: data.functionName,
          parameters: data.parameters,
        },
      });
    }),
    filter(() => false),
  ); // No need to return any action to the stream
