import {
  reduce,
  map,
  isNumber,
  isEmpty,
  isNil,
  isString,
  find,
  flatten,
  some,
} from "lodash";
import { GradesConditions } from "shared/models/feedback";

import {
  InputForm as InputFormModel,
  // Section as SectionModel,
  Question as QuestionModel,
  DataPoint as DataPointModel,
  QuestionOrSubQuestion,
  Conditions,
} from "shared/models/inputForm.types";

// get value for given datapoint
const getValue = (dp?: DataPointModel) => {
  if (!dp) return null;
  if (isNumber(dp.choice)) return parseInt(dp.choice.toString());
  if (isNumber(dp.number)) return dp.number;
  if (isString(dp.text)) return dp.text && 1;
};

export const hasMandatoryQuestions = (questions?: QuestionModel[]): boolean => {
  if (!questions || questions.length <= 0) {
    return false;
  }

  return some(questions, isMandatoryQuestion);
};

const isMandatoryQuestion = (question: QuestionModel) => {
  return question.must_be_answered;
};

export const allQuestions = (form: InputFormModel) =>
  flatten(map(form.sections, s => s?.questions));

export const getVisibleQuestions = (
  form: InputFormModel,
  activeQuestions: QuestionModel[],
) => {
  const questions = allQuestions(form);
  return activeQuestions
    .filter(q => !q.hidden && !!fulfilledConditionQuestion(q, activeQuestions))
    .map(q =>
      !q.sub_question_ids
        ? q
        : // Map sub question ids to the actual questions
          Object.assign(
            {
              sub_questions: q.sub_question_ids.map(id =>
                find(questions, q => q?.id === id),
              ),
            },
            q,
          ),
    );
};

/**
 * Check if a question has its conditions been fulfilled
 *
 * This function prepares the conditionTarget, input data_point and calling 2 other functions isConditionFulfilled and
 * areConditionsFulfilled to check if conditions have been satisfied
 *
 * @see areConditionsFulfilled
 * @see isConditionFulfilled
 *
 * @param q QuestionModel - question to be resolved
 * @param questions QuestionModel[] - Form questions
 */
export const fulfilledConditionQuestion = (
  q: QuestionModel,
  questions: QuestionModel[],
) => {
  const solveConditions = (conditions?: Conditions, key = "OR"): boolean => {
    if (!conditions) {
      return true;
    }

    switch (key) {
      case "OR":
        return !!map(conditions, solveConditions).find(Boolean);
      case "AND":
        return map(conditions, solveConditions).every(Boolean);
      default: {
        const conditionTarget = find(questions, f => f.external_id === key);
        if (!conditionTarget) {
          return true;
        }
        return (
          conditionTarget &&
          isConditionFulfilled(questions, conditions, conditionTarget)
        );
      }
    }
  };

  return solveConditions(q?.conditions);
};

export const ConditionComparator: Record<
  keyof GradesConditions,
  (limit: number, value: any) => boolean
> = {
  equals: (conditionLimit, value) =>
    isNumber(value) && conditionLimit === value,
  greater_than: (conditionLimit, value) =>
    isNumber(value) && value > conditionLimit,
  less_than: (conditionLimit, value) =>
    isNumber(value) && value < conditionLimit,
  greater_than_or_equal: (conditionLimit, value) =>
    isNumber(value) && value >= conditionLimit,
  less_than_or_equal: (conditionLimit, value) =>
    isNumber(value) && value <= conditionLimit,
  not_equal: (conditionLimit, value) => value !== conditionLimit,
};

/**
 * Checks whether one specific question fulfills all of its conditions
 *
 * Check if the input data (data_point) fulfills all of its condition. This function is similar to leaf in a tree
 * where a question only need to be resolved by checking itself
 * NOTE: if the data_point is empty, the function will return FALSE
 *
 * @see areConditionsFulfilled
 * @see fulfilledConditionQuestion
 *
 * @param questions QuestionModel[]
 * @param conditions - example: {"greater_than": 2, "less_than": 5}
 * @param data_point DataPointModel
 * @param default_value number
 * @return boolean - true if the question fulfills conditions, false if not fulfilled or not answered
 */
const isConditionFulfilled = (
  questions: QuestionModel[],
  conditions: GradesConditions,
  {
    data_point,
    default_value,
  }: { data_point?: DataPointModel; default_value?: number },
) => {
  // eg. conditionComparator["greater_than"](1, 2) returns true
  // Reduce all condition booleans with AND operator
  return reduce(
    conditions,
    (memo, limit, comparator) =>
      memo &&
      ConditionComparator[comparator as keyof GradesConditions](
        limit as number,
        getChoiceValue(questions, data_point, default_value),
      ),
    true,
  );
};

const getChoiceValue = (
  questions: QuestionModel[],
  data_point?: DataPointModel,
  default_value?: number,
) => {
  if (!data_point) return default_value;
  const question = find(
    questions,
    question => question.id === data_point.field_id,
  );
  if (
    question &&
    "choices" in question &&
    !isEmpty(question.choices) &&
    !isNil(data_point.choice)
  ) {
    const choice = find(
      question.choices,
      choice => choice.external_id === data_point.choice,
    );
    return choice?.value;
  } else {
    return getValue(data_point);
  }
};

export const isLoaded = (form: InputFormModel) =>
  !isEmpty(form.sections) && !isEmpty(form.sections[form.activeSection]);

export const getAnswer = (
  question: Pick<
    QuestionOrSubQuestion,
    "default_value" | "external_id" | "type"
  >,
  answer: any,
  isCaption: boolean,
) => {
  if (answer === null && !isNil(question.default_value)) {
    answer = question.default_value;
  }

  return [
    question.external_id,
    Object.assign(
      { field_id: question.external_id },
      answer === null ? {} : { [getValueType(question, isCaption)]: answer },
    ),
  ];
};

// Get data point value type for saving

type ValueType = "number" | "text" | "choice" | "datetime" | "attachments";

export const getValueType = (
  question: Pick<QuestionOrSubQuestion, "type">,
  isCaption: boolean,
): ValueType => {
  if (isCaption) return "text";
  switch (question.type) {
    case "InputForm::IntegerField":
    case "InputForm::NumericField":
    case "InputForm::RangeField":
    case "InputForm::CircleField":
      return "number";
    case "InputForm::TextField":
      return "text";
    case "InputForm::DateTimeField":
      return "datetime";
    case "InputForm::ImageSubmissionField":
      return "attachments";
    default:
      return "choice";
  }
};
