import { addFlash, clearFlashes } from "common/actions/flash";
import pageChangeStream from "./pageChange";
import { parse } from "qs";
import { ActionsObservable, ofType } from "redux-observable";
import { filter, map, take } from "rxjs/operators";
import { SetUserAction } from "common/actions/user";

// Flash messages triggered by feature flags are defined in ./flipperUINotification.tsx

export const queryStringFlashes = (action$: ActionsObservable<SetUserAction>) =>
  action$.pipe(
    ofType("SET_USER"),
    take(1),
    map(() => {
      const queryString = window.location.href.match(/\?(.*)$/)?.[1] ?? "";
      return parse(queryString).error;
    }),
    filter(Boolean),
    map(msg => addFlash(msg as any, "alert")),
  );

export const clearFlashOnPageChange = (action$: ActionsObservable<any>) =>
  pageChangeStream(action$).pipe(map(() => clearFlashes()));
