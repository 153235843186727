import styled from "styled-components";

export const Popover = styled.div.attrs(() => ({
  "data-testid": "online-help-popover",
}))`
  padding: 0.25rem;
  > span {
    margin: 0.25rem;
    display: block;
    margin-bottom: 0.75rem;
  }
  button {
    display: block;
  }
`;
