import React from "react";
import { FormattedMessage } from "react-intl";
import { validationRegexp } from "common/utils/general";
import { CreateUserFormValues } from "hcp/components/Users/CreateUser";

export const focusOn = (selector: string) => {
  const element = document.querySelector(selector);
  (element as HTMLElement)?.focus();
};

export const validatePhone = (required: boolean) => (value: string) => {
  // Allow empty phone number if not required
  // "+" is considered as empty because it's prefilled and cannot be removed
  if (!required && (value === "" || value === "+")) return "";

  // Use the default phone validation from InvitePatients
  return validatePhoneFormat(value);
};

const validatePhoneFormat = async (value: string) => {
  const { isValidPhoneNumber } = await import("libphonenumber-js/min");
  return isValidPhoneNumber(value) ? undefined : (
    <FormattedMessage id="form_validation.invalid_phone_number" />
  );
};

export const validateEmail = (email: string) => {
  if (!email) {
    return (
      <FormattedMessage
        id="users.new.validation.required_field"
        values={{ field: <FormattedMessage id="attributes.email" /> }}
      />
    );
  }
  const emailRe = validationRegexp.email;
  if (!emailRe.test(email)) {
    return <FormattedMessage id="users.new.validation.invalid_email" />;
  }
};

export const validateEmailConfirmation = (
  confirmation: string,
  formValues: CreateUserFormValues,
) => {
  if (confirmation !== formValues.email) {
    return <FormattedMessage id="users.new.validation.email_confirmation" />;
  }
};

export const required = (value: any) => {
  const message = <FormattedMessage id="form_validation.required_field" />;
  if (Array.isArray(value)) {
    return value.length > 0 ? undefined : message;
  }
  return value ? undefined : message;
};
