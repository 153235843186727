import {
  FIRST,
  LAST,
  calculatePaging,
  indexFor,
  textFor,
  SensiblePagination,
} from "./SensiblePagination";
import Pagination from "./Pagination";

export default Pagination;
export { FIRST, LAST, calculatePaging, indexFor, textFor, SensiblePagination };
