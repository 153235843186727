import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @font-face {
    font-family: "Graphik";
    src: url(${require("../../../../assets/fonts/Graphik-Medium-Web.woff")}) format("woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: "Graphik";
    src: url(${require("../../../../assets/fonts/Graphik-Regular-Web.woff")}) format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
      font-family: 'Cooper Hewitt Light Italic';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-LightItalic.woff")}) format('woff');
      font-weight: 300;
      font-style: italic;
  }

  @font-face {
      font-family: 'Cooper Hewitt Book Italic';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-BookItalic.woff")}) format('woff');
      font-weight: normal;
      font-style: italic;
  }

  @font-face {
      font-family: 'Cooper Hewitt Heavy Italic';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-HeavyItalic.woff")}) format('woff');
      font-weight: 900;
      font-style: italic;
  }

  @font-face {
      font-family: 'Cooper Hewitt Semibold';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-Semibold.woff")}) format('woff');
      font-weight: 600;
      font-style: normal;
  }

  @font-face {
      font-family: 'Cooper Hewitt Medium';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-Medium.woff")}) format('woff');
      font-weight: 500;
      font-style: normal;
  }

  @font-face {
      font-family: 'Cooper Hewitt Heavy';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-Heavy.woff")}) format('woff');
      font-weight: 900;
      font-style: normal;
  }

  @font-face {
      font-family: 'Cooper Hewitt Bold';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-Bold.woff")}) format('woff');
      font-weight: bold;
      font-style: normal;
  }

  @font-face {
      font-family: 'Cooper Hewitt Thin';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-Thin.woff")}) format('woff');
      font-weight: 100;
      font-style: normal;
  }

  @font-face {
      font-family: 'Cooper Hewitt Semibold';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-SemiboldItalic.woff")}) format('woff');
      font-weight: 600;
      font-style: italic;
  }

  @font-face {
      font-family: 'Cooper Hewitt Bold Italic';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-BoldItalic.woff")}) format('woff');
      font-weight: bold;
      font-style: italic;
  }

  @font-face {
      font-family: 'Cooper Hewitt Light';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-Light.woff")}) format('woff');
      font-weight: 300;
      font-style: normal;
  }

  @font-face {
      font-family: 'Cooper Hewitt Book';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-Book.woff")}) format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'Cooper Hewitt Medium Italic';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-MediumItalic.woff")}) format('woff');
      font-weight: 500;
      font-style: italic;
  }

  @font-face {
      font-family: 'Cooper Hewitt Thin Italic';
      src: url(${require("../../../../assets/fonts/hug/CooperHewitt-ThinItalic.woff")}) format('woff');
      font-weight: 100;
      font-style: italic;
  }
`;
