import React, { useState, useEffect } from "react";
import ProgressBar from "common/components/ProgressBar";

const TransitionProgressBar = ({
  image_source,
}: TransitionProgressBarProps): JSX.Element => {
  const multipleImages = typeof image_source !== "string";
  const animationLength = multipleImages
    ? 1000 + (image_source.length - 1) * 4000
    : 1000;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (counter < animationLength) {
      timer = setInterval(() => {
        setCounter(counter + 10);
      }, 10);
    }
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  return (
    <div>
      <ProgressBar type="bar" value={counter} max={animationLength} />
    </div>
  );
};

type TransitionProgressBarProps = {
  image_source: string | Array<string>;
};

export default TransitionProgressBar;
