import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IconExtras } from "@netmedi/frontend-design-system";
type IconName = IconExtras.IconName;

export type InfoBox = {
  id: string;
  dismiss: (...args: any[]) => void;
  dismissable?: boolean;
  icon: IconName;
  title: React.ReactElement;
  body: React.ReactElement;
  buttons: React.ReactElement;
  elementId?: string;
  dismissButtonText?: string;
};

const infoBoxSlice = createSlice({
  name: "infoBox",
  initialState: [] as InfoBox[],
  reducers: {
    add(state, { payload }: PayloadAction<InfoBox[]>) {
      return state.concat(payload);
    },
    remove(state, { payload }: PayloadAction<string>) {
      return state.filter(({ id }) => id !== payload);
    },
  },
});

export default infoBoxSlice.reducer;
export const infoBoxActions = infoBoxSlice.actions;
