import dayjs from "dayjs";
import React, { ReactNode, useCallback } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  headerText,
  ValidInterval,
} from "shared/components/SymptomTable/SymptomTable";
import {
  SymptomHeaderDateStyled,
  SymptomHeaderTitleStyled,
  SymptomRowStyled,
} from "./SymptomPage.styles";

export interface GroupedColumnRule {
  type: "exactDate" | "betweenDates" | "beforeDate";
  date?: string; // Used when type is exactDate or beforeDate
  startDate?: string; // Used when type is betweenDates
  endDate?: string; // Used when type is betweenDates
  interval: ValidInterval;
}
interface SymptomTitleRowProps {
  titleRules: GroupedColumnRule[];
  intl: any;
}

const SymptomTitleRow: React.FC<SymptomTitleRowProps> = ({
  titleRules,
  intl,
}) => {
  const title = useCallback(
    (rule: GroupedColumnRule) => {
      const formatString = intl.formatMessage({
        id: `symptom_table.header_format.${rule.interval}`,
      }) as string;

      let title: ReactNode;

      if (rule.type === "betweenDates") {
        title = headerText(
          dayjs(rule.startDate),
          dayjs(rule.endDate),
          rule.interval,
          intl,
        );
      } else if (rule.type === "beforeDate") {
        const date = headerText(
          dayjs(rule.date),
          dayjs(rule.date),
          rule.interval,
          intl,
          true,
        );
        title = (
          <FormattedMessage
            id="pdf_export.symptoms.before_date"
            values={{ date }}
          />
        );
      } else {
        title = dayjs(rule.date).format(formatString);
      }

      return <>{title}</>;
    },
    [intl],
  );

  return (
    <SymptomRowStyled>
      {[
        <SymptomHeaderTitleStyled key="title-col" />,
        ...titleRules.map((rule, i) => {
          const currentYear = dayjs(rule.startDate ?? rule.date).year();

          return (
            <SymptomHeaderDateStyled
              key={i}
              rotateTitle={titleRules.length > 6}
            >
              <div>{currentYear}</div>
              <div>{title(rule)}</div>
            </SymptomHeaderDateStyled>
          );
        }),
      ]}
    </SymptomRowStyled>
  );
};

export default injectIntl(SymptomTitleRow);
