import React from "react";
import Numbers from "./Numbers";

export type GradesProps = {
  className?: string;
  value: number;
  includeZero?: boolean;
};

export default function Grades({ includeZero = false, ...props }: GradesProps) {
  return (
    <Numbers
      min={includeZero ? 0 : 1}
      max={4}
      selectedClassname={"grade-" + props.value}
      {...props}
    />
  );
}
