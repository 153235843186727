import { Viewport } from "common/epics/viewport";

// used in app reducer
// used in input form actions and utils/epic
export type CriticalErrorAction = {
  type: "CRITICAL_ERROR";
  data?: any;
};

export type AppAction =
  | CriticalErrorAction
  | {
      type: "ERROR_500" | "PAGE_LOAD_ERROR" | "UNAUTHORIZED" | "SET_HOLVIKAARI";
    }
  | {
      type: "VIEWPORT";
      size: typeof Viewport.MOBILE | typeof Viewport.SMALL;
    }
  | { type: "SET_FLAG"; data: { flag: string; value: unknown } }
  | { type: "OFFLINE"; forceRefresh?: boolean; data: any }
  | { type: "SET_SHOW_APP_DOWNLOAD_MODAL"; data: boolean };

export const setFlag = (flag: string, value: boolean) => ({
  type: "SET_FLAG",
  data: { flag, value },
});

export const setShowAppDownloadModal = (data: boolean) => ({
  type: "SET_SHOW_APP_DOWNLOAD_MODAL",
  data,
});

export type ResetStateAction = {
  type: "RESET_STATE";
};

export const resetState = (): ResetStateAction => ({ type: "RESET_STATE" });
