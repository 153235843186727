import React from "react";
import { Container, RelativeWrapper, Dots, Arrow } from "./SpeechBubble.styles";
interface ISpeechBubble {
  className?: string;
}

const SpeechBubble = ({ className }: ISpeechBubble) => (
  <Container className={className}>
    <RelativeWrapper>
      <Dots>
        <ul>
          <li />
          <li />
          <li />
        </ul>
      </Dots>
      <Arrow />
    </RelativeWrapper>
  </Container>
);

export default SpeechBubble;
