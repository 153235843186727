import {
  baseColors,
  symptomGrades,
  typography,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import styled from "styled-components";
import {
  marginLeft,
  padding,
  paddingLeft,
} from "@netmedi/frontend-design-system/dist/styles/styleHelpers";

export const SymptomPageStyled = styled.div`
  background: white;
  ${padding(35, 15, 0, 0)};
  font-size: ${typography.fontSizeMedium};
  width: 1000px;
`;

export const SymptomDataTitleStyled = styled.div`
  width: 300px;
  ${paddingLeft("xxs")}
`;
export const SymptomHeaderTitleStyled = styled(SymptomDataTitleStyled)`
  min-height: 3rem;
`;
export const SymptomHeaderDateStyled = styled(SymptomDataTitleStyled)<{
  rotateTitle: boolean;
}>`
  width: 5rem;
  ${({ rotateTitle }) =>
    rotateTitle && `transform: rotate(-50deg) translate(18px,13px);`}
  text-align: ${({ rotateTitle }) => (rotateTitle ? "left" : "center")};
  white-space: nowrap;
  font-size: 90%;
  height: 2rem;
  flex: 1;
  position: relative;

  div {
    position: absolute;
    width: 100%;

    &:first-child {
      transform: translateY(-15px);
      font-size: 85%;
    }
  }
`;

export const SymptomDataPointContainer = styled.div<{
  drawVerticalRuler?: boolean;
}>`
  width: 5rem;
  text-align: center;
  position: relative;
  height: 4rem;
  flex: 1;
  ${({ drawVerticalRuler }) =>
    drawVerticalRuler
      ? `background-image:
          linear-gradient(#e7e7e7, #e7e7e7),
          url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjgwIiB3aWR0aD0iMTAwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8bGluZQogICAgeDE9IjAiCiAgICB5MT0iMCIKICAgIHgyPSIwIgogICAgeTI9IjIwMCIKICAgIHN0eWxlPSJzdHJva2U6cmdiKDI1NSwwLDApO2ZpbGw6IG5vbmU7c3Ryb2tlOiByZ2IoMTcxLCAxNzEsIDE3MSk7c3Ryb2tlLXdpZHRoOiA0O3N0cm9rZS1kYXNoYXJyYXk6IDcsMTQ7c3Ryb2tlLWRhc2hvZmZzZXQ6IDA7c3Ryb2tlLWxpbmVjYXA6IHNxdWFyZTsiIC8+Cjwvc3ZnPg==);
        background-size: 1px 100%, 1000px 100%;
        background-repeat: no-repeat, repeat-y;
        background-position: center center, left top;`
      : `background-image: linear-gradient(#e7e7e7, #e7e7e7);
        background-size: 1px 100%;
        background-repeat: no-repeat;
        background-position: center center`}
`;

const symptomGradeColor = ({ grade }: { grade: number }) =>
  symptomGrades[`severity${grade}` as keyof typeof symptomGrades];
const MIN_SIZE = 2;
const MAX_GRADE = 4;
const symptomGradeSize = ({ grade }: { grade: number }) =>
  2 + Math.min(MAX_GRADE, grade) / 3;
const symptomGradeMargin = ({ grade }: { grade: number }) =>
  (MIN_SIZE + MAX_GRADE / 2 - symptomGradeSize({ grade })) / 2;
export const SymptomGradeStyled = styled.div<{ grade: number }>`
  font-weight: bold;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  background-color: ${symptomGradeColor};
  line-height: ${symptomGradeSize}rem;
  width: ${symptomGradeSize}rem;
  height: ${symptomGradeSize}rem;
  margin: ${symptomGradeMargin}rem auto;
`;

const scoreColors = ["#923b23", "#f68261", "#e6b328", "#569b3b", "#a7da92"];
const symptomScoreColor = ({ score }: { score: number }) =>
  scoreColors[Math.round(score * (scoreColors.length - 1))];

export const SymptomScoreStyled = styled.div<{ relativePositivity: number }>`
  font-weight: bold;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  border: 5px solid
    ${({ relativePositivity }) =>
      symptomScoreColor({ score: relativePositivity })};
  background-color: white;
  line-height: calc(3.333rem - 10px);
  width: 3.333rem;
  height: 3.333rem;
  margin: 0.333rem auto;
`;

export const EmptyDataPointStyled = styled.div`
  font-weight: bold;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  background-color: ${baseColors.gray300};
  line-height: 5rem;
  width: 0.5rem;
  height: 0.5rem;
  margin: 2rem auto;
`;

export const SymptomRowStyled = styled.div`
  :nth-child(even) {
    background: ${baseColors.gray50};
    ${EmptyDataPointStyled} {
      background: ${baseColors.gray500};
    }
    ${SymptomScoreStyled} {
      background-color: ${baseColors.gray50};
    }
  }
  display: flex;
  ${marginLeft("xxs")}
  align-items: center;
`;

export const FootstepContainer = styled.div`
  margin-top: "2rem";
`;

export const FootstepValue = styled.div`
  width: "5rem";
  flex: 1;
  text-align: center;
`;

export const FootstepRowStyled = styled.div`
  display: flex;
  ${marginLeft("xxs")}
  align-items: center;
`;

export const SymptomEventStyled = styled.div`
  font-weight: bold;
  position: relative;
  z-index: 1;
  background-color: ${baseColors.blue500};
  line-height: 1.5rem;
  color: white;
  width: 3.333rem;
  margin: 1.5rem auto;
`;

export const PdfFootstepContainer = styled.div`
  flex: 1 1;
`;

export const PrintableIcon = styled.div<{
  color: string;
  direction: "up" | "down" | "left" | "right";
  hidden: boolean;
}>`
  border-style: solid;
  border-top-width: ${({ direction }) => (direction === "up" ? "0" : "0.4rem")};
  border-bottom-width: ${({ direction }) =>
    direction === "down" ? "0" : "0.4rem"};
  border-left-width: ${({ direction }) =>
    direction === "left" ? "0" : "0.4rem"};
  border-right-width: ${({ direction }) =>
    direction === "right" ? "0" : "0.4rem"};
  border-bottom-color: ${({ direction, color }) =>
    direction === "up" ? color : "transparent"};
  border-top-color: ${({ direction, color }) =>
    direction === "down" ? color : "transparent"};
  border-left-color: ${({ direction, color }) =>
    direction === "left" ? color : "transparent"};
  border-right-color: ${({ direction, color }) =>
    direction === "right" ? color : "transparent"};
  display: inline-block;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;
