import React from "react";
import {
  PaginationList,
  PaginationListItem,
  CurrentListItem,
} from "./Pagination.styles";

// Left here for backwards compability for now, to be removed
/**
  Use the pagination component when content needs to be split over several pages.

  If you have multiple pages, you should provide a search function as well, since
  browsing through several pages to find the relevant item is tedious.
*/
interface ILinks {
  next: string | number;
  prev: string | number;
  first: string | number;
  last: string | number;
}

type Props = {
  /** Total pages */
  total?: number;
  /** Current page */
  page?: number;
  /** Total number of pages */
  total_pages?: number;
  /** Function to move to the next page */
  move(arg: string | number): void;
  /** Links to next, previous, first and last page */
  links: ILinks;
};

const Pagination = ({ links, page = 1, total_pages = 1, move }: Props) => (
  <PaginationList data-testid="pagination-list">
    {links.first !== links.prev && links.prev && (
      <PaginationListItem>
        <button name={`page-1`} type="button" onClick={() => move(links.first)}>
          1
        </button>
      </PaginationListItem>
    )}
    {page > 3 && <PaginationListItem>...</PaginationListItem>}
    {links.prev && (
      <PaginationListItem>
        <button
          name={`page-${page - 1}`}
          type="button"
          onClick={() => move(links.prev)}
        >
          {page - 1}
        </button>
      </PaginationListItem>
    )}
    <CurrentListItem>{page}</CurrentListItem>
    {links.next && (
      <PaginationListItem>
        <button
          name={`page-${page + 1}`}
          type="button"
          onClick={() => move(links.next)}
        >
          {page + 1}
        </button>
      </PaginationListItem>
    )}
    {total_pages - page > 2 && <PaginationListItem>...</PaginationListItem>}
    {links.last !== links.next && links.next && (
      <PaginationListItem>
        <button
          name={`page-${total_pages}`}
          type="button"
          onClick={() => move(links.last)}
        >
          {total_pages}
        </button>
      </PaginationListItem>
    )}
  </PaginationList>
);

export default Pagination;
