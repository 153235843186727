import React, { useEffect, useState } from "react";
import DataTable, {
  ColumnWithJsxName,
  Props as DataTableProps,
} from "./DataTable";

export type SortableColumn<T> = ColumnWithJsxName & {
  key: string;
  sortable?: boolean;
  sorter?: (a: T, b: T) => number;
};

export type AutoSortDataTableProps<T> = Omit<DataTableProps, "columns"> & {
  data: T[];
  columns: SortableColumn<T>[];
  defaultSort?: { column: keyof T; dir: "asc" | "desc" };
};

/**
 * This is the default DataTable with the added functionality of sorting the data
 * Sorting algorithms may be provided in the sorter callback of a column, otherwise
 * the default sorting algorithm is used
 */
const AutoSortDataTable = <T,>(props: AutoSortDataTableProps<T>) => {
  const [data, setData] = useState<T[]>(props.data);
  const [sortedColumns, setSortedColumns] = useState<SortableColumn<T>[]>([]);

  // Sorting stack is used to keep track of the order in which columns were sorted
  // The first priority is the last column that was clicked
  const [sortingStack, setSortingStack] = useState<
    { key: keyof T; dir: "asc" | "desc" }[]
  >([]);

  // Update sorted state if props change
  useEffect(() => {
    // Map string columns as ColumnWithJsxName using the column key as the key
    const columns = props.columns.map(
      column =>
        ({
          ...column,
          value: column.value ?? ((row: T) => row[column.key as keyof T]),
        }) as SortableColumn<T>,
    );

    setData(props.data);
    setSortedColumns(columns);
  }, [props.data, props.columns]);

  const updateColumnSorting = (columnKey: keyof T) => {
    const prevDirection =
      sortingStack[0]?.key === columnKey ? sortingStack[0].dir : "asc";

    // This is used to keep track of the order in which columns were sorted
    setSortingStack([
      // Add the last column that was clicked and reverse the direction
      { key: columnKey, dir: prevDirection === "asc" ? "desc" : "asc" },

      // Remove the column if it was already in the stack
      ...sortingStack.filter(column => column.key !== columnKey),
    ]);

    // This is only for the UI to show the sorting arrow
    setSortedColumns(
      sortedColumns.map(column => {
        if (column.key === columnKey) {
          return {
            ...column,
            sort: prevDirection === "asc" ? "desc" : "asc",
          };
        }

        // Only show the sort arrow for the last column that was clicked
        return { ...column, sort: undefined };
      }),
    );
  };

  // Update data based on sorted columns
  useEffect(() => {
    const sortedData = [...data];

    sortedData.sort((a: T, b: T) => {
      // Iterate over the sorting stack to sort the data
      // Stop when different values are found
      return sortingStack.reduce((acc, col) => {
        const { key, dir } = col;
        const column = sortedColumns.find(column => column.key === key);

        // Return if different values are found
        if (acc !== 0) return acc;

        const defaultSorter = (a: T, b: T) => {
          const aValue = a[key];
          const bValue = b[key];

          if (aValue === bValue) return 0;
          return aValue > bValue ? 1 : -1;
        };

        return (
          (column?.sorter ?? defaultSorter)(a, b) * (dir === "asc" ? 1 : -1) // Reverse the order if sorting in descending order
        );
      }, 0);
    });

    setData(sortedData);
  }, [sortedColumns]);

  return (
    <DataTable
      sortArrows="both"
      onHeaderClick={(_e, _name, element) =>
        updateColumnSorting(element.key as keyof T)
      }
      {...props}
      data={data}
      columns={sortedColumns}
      excludeSort={sortedColumns
        .filter(c => c.sortable === false)
        .map(c => c.name as string)}
    />
  );
};

export default AutoSortDataTable;
