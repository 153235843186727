import { storeAction } from "./store";
import {
  setTooltip,
  clearTooltip,
  setDropdownTooltip,
} from "common/actions/tooltip";

export const tooltip = storeAction(setTooltip);

export const rmTooltip = storeAction(clearTooltip);

export const dropdown = storeAction(setDropdownTooltip);
