import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { StaffMember } from "hcp/components/MonitoringAndCareTeam/MonitoringAndCareTeam.types";
import { RootState } from "store";

const staffSlice = createSlice({
  name: "staff",
  initialState: [] as StaffMember[],
  reducers: {
    set(_state, action: PayloadAction<StaffMember[]>) {
      return action.payload;
    },
  },
});

// actions
export const staffActions = staffSlice.actions;

// selectors
export const selectStaff = (state: RootState) => state.staff;
export const selectStaffMapById = createSelector(
  selectStaff,
  (staff: StaffMember[]) =>
    staff?.reduce<{ [id: string]: StaffMember }>(
      (acc, staff) => ({ ...acc, [staff.id]: staff }),
      {},
    ),
);

export default staffSlice.reducer;
