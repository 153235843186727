import React from "react";
import ImageSlide, { ImageSlideProps } from "./ImageSlide";
import CheckboxSlide, { CheckboxSlideProps } from "./CheckboxSlide";

import { SlideContainer, SlideHeading } from "./SlideCarousel.styles";

export type SlideContent = {
  heading?: string;
  image?: ImageSlideProps;
  checkbox?: CheckboxSlideProps;
};

export type SlideProps = {
  index?: number;
  slideContent?: SlideContent;
  children?: React.ReactElement;
};

export default function Slide(props: SlideProps) {
  const { index, slideContent, children } = props;

  return (
    <SlideContainer key={index}>
      {slideContent && (
        <>
          {slideContent.heading && (
            <SlideHeading>
              <p>{slideContent.heading}</p>
            </SlideHeading>
          )}
          {(slideContent.image && ImageSlide(slideContent.image)) ||
            (slideContent.checkbox && CheckboxSlide(slideContent.checkbox))}
        </>
      )}
      {children}
    </SlideContainer>
  );
}
