import {
  useMemo,
  useState,
  useCallback,
  KeyboardEvent,
  useEffect,
} from "react";

// A grid of booleans, True means that the element can be focused on.
// For example when using this for symptom dashboard,
// a point in the grid is focusable if it has a symptom in it.
type Focusable = boolean[][];

// Basic directions - find next focusable element.
//
// 1. take slice of the area that is being searched
// 2. reverse it if direction is left/up
// 3. get offset by finding index and adding 1
// 4. add/remove offset from current index

const previous = (focusable: Focusable, top: number, left: number) => {
  const rev = focusable[top].slice(0, left).reverse();
  return left - (rev.findIndex(Boolean) + 1);
};

const next = (focusable: Focusable, top: number, left: number) =>
  left + (focusable[top].slice(left + 1).findIndex(Boolean) + 1);

const above = (focusable: Focusable, top: number, left: number) => {
  const rev = focusable.slice(0, top).reverse();
  return top - (rev.findIndex(x => x[left]) + 1);
};

const below = (focusable: Focusable, top: number, left: number) =>
  top + (focusable.slice(top + 1).findIndex(x => x[left]) + 1);

// Finds the closest row with a focusable element by searching at an offset
// that moves progressively further away from the starting row
const findRow = (
  f: typeof next | typeof previous,
  focusable: Focusable,
  top: number,
  left: number,
  offset = 0,
): [number, number] | undefined => {
  const dir = f === next ? 1 : -1;
  const newOffset = dir * offset > 0 ? -offset : -offset + dir;

  // See if a row exists at the current offset. If yes, then we should
  // try to find a focusable element there
  if (focusable[top + offset]) {
    const newLeft = f(focusable, top + offset, left);

    // Did you find a new focusable element ?
    if (newLeft !== left) {
      return [newLeft, top + offset];
    }

    // Otherwise keep searching on the next row
    return findRow(f, focusable, top, left, newOffset);

    // If there is no row at the current offset
  } else {
    // Then, if there are still rows to explore
    if (focusable[top + newOffset]) {
      // Keep searching on the next row
      return findRow(f, focusable, top, left, newOffset);
    }
  }
};

const findRightmost = (row: boolean[]) =>
  row.length - row.slice().reverse().findIndex(Boolean) - 1;

const getRtlDependent = (length: number) => {
  const dir = document.documentElement.dir;
  const rtl = dir === "rtl";
  return {
    goBack: rtl ? next : previous,
    goForward: rtl ? previous : next,
    end: rtl ? -1 : length,
    start: rtl ? length : -1,
  };
};

const useGrid = (
  focusable: Focusable,
  gridName: string,
  selector = '[role="button"]',
) => {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const focusOn = (newLeft: number, newTop: number) => {
    const grid = `[data-gridname="${gridName}"]`;
    const indexSelector = `[data-gridindex="${newLeft}-${newTop}"]`;
    const totalSelector = `${grid} ${indexSelector} ${selector}`;
    const element: any = document.querySelector(totalSelector);
    element?.focus();
  };

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const { goBack, goForward, end, start } = getRtlDependent(
        focusable[0].length,
      );

      const old = [left, top];
      let newLeft = left;
      let newTop = top;
      switch (e.key) {
        case "ArrowLeft":
          [newLeft, newTop] = findRow(goBack, focusable, top, left) ?? old;
          break;
        case "ArrowRight":
          [newLeft, newTop] = findRow(goForward, focusable, top, left) ?? old;
          break;
        case "Home":
          newLeft = goForward(focusable, top, start);
          break;
        case "End":
          newLeft = goBack(focusable, top, end);
          break;
        case "ArrowUp":
          newTop = above(focusable, top, left);
          break;
        case "ArrowDown":
          newTop = below(focusable, top, left) ?? top;
          break;
        case "PageUp":
          newTop = below(focusable, -1, left) ?? top;
          break;
        case "PageDown":
          newTop = above(focusable, focusable.length, left);
          break;
        default:
          return;
      }

      setTop(newTop);
      setLeft(newLeft);
      focusOn(newLeft, newTop);

      e.preventDefault();
      e.stopPropagation();
    },
    [top, left, focusable],
  );

  useEffect(() => {
    // If `focusable` changes, then our previous selected cell at (top, right) may no
    // longer be focusable. This code will rescue us from that situation by selecting
    // a focusable cell. It also handles the initial setup on first render
    if (!focusable[top]?.[left]) {
      setTop(0);
      focusable[0] && setLeft(findRightmost(focusable[0]));
    }
  }, [focusable]);

  return useMemo(
    () => ({ focused: { top, left }, handleKeyDown }),
    [top, left, focusable],
  );
};

// these functions are exported for testing purposes
export { previous, next, above, below, findRow };
export default useGrid;
