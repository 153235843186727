import { Button, Icon } from "@netmedi/frontend-design-system";
import DatePicker from "common/components/Form/DatePicker";
import { formatDate } from "common/utils/general";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage } from "react-intl";
import { GenerationStatus } from "../types";
import {
  Checkbox,
  PdfSettingsPanelContainer,
  Setting,
  SettingInput,
  SettingLabel,
} from "./PdfSettingsPanel.styles";

interface ReportSectionSettings {
  symptoms: boolean;
  values: boolean;
  forms: boolean;
  messages: boolean;
}
type SectionType = keyof ReportSectionSettings;

export interface PdfSettings {
  sections?: ReportSectionSettings;
  startDate: string;
  endDate: string;
}

export interface PdfSettingsPanelProps {
  settings: PdfSettings;
  setSettings: (settings: PdfSettings) => void;
  onGeneratePdf: () => void;
  generationStatus?: GenerationStatus;
  loadingData?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const PdfSettingsPanel: React.FC<PdfSettingsPanelProps> = ({
  settings,
  setSettings,
  onGeneratePdf,
  generationStatus,
  loadingData = false,
}) => {
  const settingKeys = Object.keys(
    settings.sections as ReportSectionSettings,
  ) as SectionType[];

  const toggleSection = (section: SectionType) => {
    setSettings({
      ...settings,
      sections: {
        ...settings.sections,
        [section]: !(settings.sections as ReportSectionSettings)[section],
      } as ReportSectionSettings,
    });
  };

  const setStartDate = (date: string) => {
    setSettings({ ...settings, startDate: date });
  };
  const setEndDate = (date: string) => {
    setSettings({ ...settings, endDate: date });
  };

  // This may be removed when all of the content is implemented
  const DISABLED_SECTIONS: SectionType[] = ["forms", "values", "messages"];

  return (
    <PdfSettingsPanelContainer data-test-id="PdfSettingsPanel">
      <FormattedMessage id="pdf_export.settings.instructions" />
      <form
        onSubmit={e => {
          e.preventDefault();
          onGeneratePdf();
        }}
      >
        <Setting>
          <SettingLabel>
            <FormattedMessage id="pdf_export.settings.start_date" />
          </SettingLabel>
          <SettingInput>
            <DatePicker
              value={dayjs(settings.startDate)}
              onChange={date => setStartDate(dayjs(date).format())}
              max={formatDate(dayjs(settings.endDate))}
            />
          </SettingInput>
        </Setting>
        <Setting>
          <SettingLabel>
            <FormattedMessage id="pdf_export.settings.end_date" />
          </SettingLabel>
          <SettingInput>
            <DatePicker
              value={dayjs(settings.endDate)}
              onChange={date => setEndDate(date.format())}
              min={formatDate(dayjs(settings.startDate))}
              max={formatDate(dayjs())}
            />
          </SettingInput>
        </Setting>

        <Setting>
          <SettingLabel>
            <FormattedMessage id="pdf_export.settings.report_includes" />
          </SettingLabel>
          <SettingInput>
            <>
              {settingKeys.map(section => (
                <React.Fragment key={section}>
                  <label>
                    <Checkbox
                      type="checkbox"
                      disabled={DISABLED_SECTIONS.includes(section)}
                      checked={
                        (settings.sections as ReportSectionSettings)[section]
                      }
                      onChange={() => toggleSection(section)}
                    />{" "}
                    <FormattedMessage id={`pdf_export.${section}.title`} />
                  </label>
                </React.Fragment>
              ))}
            </>
          </SettingInput>
        </Setting>

        <Setting>
          <SettingLabel />
          <SettingInput>
            <Button
              size="small"
              type="primary"
              submit
              disabled={
                loadingData ||
                generationStatus === "generating" ||
                !Object.values(
                  settings.sections as ReportSectionSettings,
                ).includes(true)
              }
            >
              {generationStatus === "generating" ? (
                <Icon name="loader_circle" />
              ) : (
                <Icon name="download_16px" />
              )}{" "}
              <FormattedMessage id="pdf_export.settings.generate_button" />
            </Button>
          </SettingInput>
        </Setting>
      </form>
    </PdfSettingsPanelContainer>
  );
};

export default PdfSettingsPanel;
