import React, { useState, useEffect } from "react";
import TransitionProgressBar from "./TransitionProgressBar";

import {
  SlideImage,
  BackgroundImage,
  SlideContent,
  ProgressBarContainer,
} from "./SlideCarousel.styles";

export type ImageSlideProps = {
  background_image?: string;
  image_source: string | string[];
  image_style?: React.CSSProperties;
};

export default function ImageSlide(props: ImageSlideProps) {
  const { background_image, image_source, image_style } = props;

  const [imageKey, setImageKey]: [
    number,
    React.Dispatch<React.SetStateAction<number>>,
  ] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (
      typeof image_source !== "string" &&
      imageKey < image_source.length - 1
    ) {
      interval = setInterval(() => {
        setImageKey(imageKey => imageKey + 1);
      }, 4000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [imageKey]);

  return (
    <>
      <SlideContent>
        <BackgroundImage
          style={{ backgroundImage: `url(${background_image})` }}
        >
          {/* eslint-disable jsx-a11y/alt-text */}
          <SlideImage
            key={imageKey}
            style={image_style}
            src={
              typeof image_source === "string"
                ? image_source
                : image_source[imageKey]
            }
          />
          {/* eslint-ensable jsx-a11y/alt-text */}
        </BackgroundImage>
      </SlideContent>
      <ProgressBarContainer>
        <TransitionProgressBar image_source={image_source} />
      </ProgressBarContainer>
    </>
  );
}
