import { get, endpoints } from "common/utils/api";
import {
  MassNotification,
  IAttributes,
} from "shared/components/Messaging/MassNotification.types";

type Params = {
  page: { number: number };
  query?: string;
};

type Meta = {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
};

export type DataAndAttributes = {
  id?: number;
  data: MassNotification[];
  attributes?: IAttributes;
};

type Data = {
  data: DataAndAttributes;
  meta: Meta;
};

export type MassNotificationAction =
  | {
      type:
        | "SET_MASS_NOTIFICATIONS"
        | "SET_MASS_NOTIFICATION"
        | "RESET_MASS_NOTIFICATION"
        | "RESET_RECIPIENT_COUNTS";
      data: Data;
      params?: Params;
    }
  | {
      type: "SET_RECIPIENT_COUNTS";
      data: { lang: string; recipientCount: number };
      params?: Params;
    };

export const fetchMassNotifications = (params: Params) =>
  get(endpoints.massNotifications.index(params)).then(
    (data: MassNotification[]) => ({
      type: "SET_MASS_NOTIFICATIONS",
      data,
      params,
    }),
  );

export const fetchMassNotification = (id: number) =>
  get(endpoints.massNotifications.show(id)).then((data: Data) => ({
    type: "SET_MASS_NOTIFICATION",
    data,
  }));

export const resetMassNotification = () => ({
  type: "RESET_MASS_NOTIFICATION",
});

export const setMassNotificationRecipientCounts = (recipientCounts: {
  [lang: string]: number;
}) => ({
  type: "SET_RECIPIENT_COUNTS",
  data: recipientCounts,
});
