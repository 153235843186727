/**
 * Show success flash message for HCP side of React. Internally it's using
 * FlashMessage class from CoffeeScript which is exposed via window object.
 */
export const showSuccessFlash = (message: string) => {
  new window.FlashMessage(message, {
    type: "alert-success",
  });
};

/**
 * Show error flash message for HCP side of React. Internally it's using
 * FlashMessage class from CoffeeScript which is exposed via window object.
 */
export const showErrorFlash = (message: string) => {
  new window.FlashMessage(message, {
    type: "alert-success",
  });
};
