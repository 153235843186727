import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Icon, IconExtras } from "@netmedi/frontend-design-system";
type IconName = IconExtras.IconName;
import {
  BackButtonDescription,
  StyledBackButton,
} from "./MassNotification.styles";

export const BackButton = () => {
  const history = useHistory();
  return (
    <StyledBackButton onClick={() => history.goBack()}>
      <Icon name={"arrow_left" as IconName} size={"medium"} />
      <BackButtonDescription>
        <FormattedMessage id="back" tagName="span" />
      </BackButtonDescription>
    </StyledBackButton>
  );
};
