import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { Icon } from "@netmedi/frontend-design-system";
import {
  colors,
  forms,
  typography,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { SearchProps } from "./index";

type SearchIconProps = {
  material?: boolean;
  name?: string;
  size?: string;
};

export const SearchIcon = styled(Icon)<SearchIconProps>`
  position: absolute;
  margin-top: 3px;
  color: ${colors.gray400};
  ${rtl`margin-left: 6px;`};
`;

export const inputStyles = css`
  background: ${forms.background};
  border: ${forms.borderColor} 1px solid;
  border-radius: ${forms.borderRadius}px;
  padding: 0.5em;
  transition: border 0.2s;
  display: block;
  line-height: 1.25;

  &_small {
    width: 10em;
  }

  &:focus,
  &:active {
    outline: none;
    border-color: ${forms.borderColorActive};
    ${rtl`box-shadow: 0 0 0pt 2pt ${colors.blue50};`};
  }

  .error & {
    border-color: ${colors.red500};
  }

  &:disabled {
    background: ${colors.gray50};
    color: ${colors.gray700};
    border-color: ${forms.borderColor};
  }
`;

const additionalClassStyles = css`
  color: ${colors.gray700};
`;

export const SearchInput = styled.input.attrs(() => ({
  "data-testid": "search-input",
}))<SearchProps>`
  ${inputStyles}
  /* Override global styling by increasing specificity */
  && {
    font-size: ${typography.fontSizeMedium};
    height: 30px;
  }
  width: 100%;
  max-width: 440px;
  min-width: 140px;
  border-radius: ${forms.borderRadius}px;
  ${rtl`
    padding-left: 2rem;
    padding-right: 1rem;
    margin: 0.25rem 0 0;
  `};
  &::-webkit-input-placeholder {
    line-height: ${rem(16)};
  }
  ${({ additionalClass }) => additionalClass && additionalClassStyles}

  &:focus {
    outline: 1px solid ${colors.blue700};
  }
`;
