import React, { useEffect, useRef, useState } from "react";
import { area, line } from "d3-shape";
import { scaleLinear, scaleTime } from "d3-scale";
import { extent, max } from "d3-array";
import {
  Data,
  Margin,
  SimpleLineGraphPropTypes,
} from "./SimpleLineGraph.types";
import {
  Chart,
  SvgWrapper,
  SeriesGraph,
  SeriesArea,
  SeriesLine,
  SeriesPoint,
} from "./SimpleLineGraph.styles";

type Dimensions = {
  width: number;
  height: number;
};

const getGraph = (dimensions: Dimensions, marginOpts?: Margin) => {
  const margin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
    ...marginOpts,
  };
  const fullWidth = dimensions.width;
  const fullHeight = dimensions.height;
  const width = fullWidth - margin.left - margin.right;
  const height = fullHeight - margin.top - margin.bottom;

  const x = scaleTime().range([0, width]);
  const y = scaleLinear().range([height, 0]);

  const xAxis = x;
  const yAxis = y;

  const areaGenerator = area<Data>()
    .x((data: Data) => x(data.time) as number)
    .y0(height)
    .y1((data: Data) => y(data.value) as number);

  const lineGenerator = line<Data>()
    .x((data: Data) => x(data.time) as number)
    .y((data: Data) => y(data.value) as number);

  return {
    margin,
    fullWidth,
    fullHeight,
    width,
    height,
    x,
    y,
    xAxis,
    yAxis,
    areaGenerator,
    lineGenerator,
  };
};

export const SimpleLineGraph = ({
  data,
  width,
  height,
  margin,
}: SimpleLineGraphPropTypes) => {
  const graphRef = useRef<HTMLDivElement | null>(null);
  const [graph, setGraph] = useState<ReturnType<typeof getGraph> | null>(null);

  useEffect(() => {
    if (!graphRef.current) {
      return;
    }

    const graph = getGraph(
      {
        width: width ?? graphRef.current.clientWidth,
        height: height ?? graphRef.current.clientHeight,
      },
      margin,
    );

    setGraph(graph);

    const { x, y } = graph;

    const xExtent = extent<Data, Data["time"]>(data, (dp: Data) => dp.time);
    x.domain(xExtent as [number, number]);
    const maxValue = max<Data, number>(data, a => a.value);
    y.domain([0, maxValue as number]);
  }, [graphRef, data]);

  return (
    <div ref={graphRef}>
      {graph !== null && (
        <Chart>
          <SvgWrapper>
            <svg viewBox={`0 0 ${graph.fullWidth} ${graph.fullHeight}`}>
              <g
                transform={`translate(${graph.margin.left},${graph.margin.top})`}
              >
                <SeriesGraph>
                  <SeriesArea d={graph.areaGenerator(data) ?? undefined} />
                  <SeriesLine d={graph.lineGenerator(data) ?? undefined} />
                </SeriesGraph>
                <SeriesGraph>
                  {data.map(({ time, value }, i) => (
                    <SeriesPoint
                      key={i}
                      cx={graph.x(time)}
                      cy={graph.y(value)}
                      r={4}
                    />
                  ))}
                </SeriesGraph>
                <g transform={`translate(0, ${graph.height})`} />
              </g>
            </svg>
          </SvgWrapper>
        </Chart>
      )}
    </div>
  );
};
